export const SENTRY_DSN = 'https://cd935202a0014a11a680ce496399907e@o175050.ingest.sentry.io/6111526'
export const PROJ_ID_KEY = 'grafana.plugins.k6.settings.projId'
export const DS_ID_KEY = 'grafana.plugins.k6.settings.dsId'

export const MAX_VALUE_EMPTY_BARS = 20

export const BG_TASKS_POLL_INTERVAL = 10000

export const DATASOURCE_TYPE = 'grafana-k6-datasource'
export const HTTP_METHODS = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'HEAD', 'OPTIONS', 'CONNECT', 'TRACE']
