import React from 'react'

import { GRPC_STATUS } from 'types'

import { GRPC_STATUS_CODE, GRPC_STATUS_DESCRIPTION } from 'constants/grpcStatusCodes'

const getGRPCStatusText = (status: number) => {
  return GRPC_STATUS_CODE[status] || 'UNKNOWN'
}

interface GRPCStatusTooltipProps {
  status: GRPC_STATUS
}

export const GRPCStatusTooltipContent = ({ status }: GRPCStatusTooltipProps) => {
  const statusDescription = GRPC_STATUS_DESCRIPTION[status]

  return (
    <>
      <strong>{getGRPCStatusText(status)}</strong>
      <br />
      {statusDescription && <span>{statusDescription}</span>}
    </>
  )
}
