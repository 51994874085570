import React, { useEffect } from 'react'
import styled from 'styled-components'
import { VerticalGroup } from '@grafana/ui'

import { useAppContext } from 'appContext'
import { useBreadcrumbs } from 'breadcrumbsContext'
import { PageSection } from 'components/PagePanel'
import { CLIRunGuide } from 'components/CLIRunGuide'

const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  flex-flow: column nowrap;
  align-items: center;
  margin: 0 auto;
  max-width: 1100px;
`

const Text = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
`


export const CLIGuide = () => {
  const { project } = useAppContext()
  const { setBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    setBreadcrumbs([])
  }, [setBreadcrumbs])

  return (
    <PageSection>
      <Container>
        <VerticalGroup align="center" spacing="none">
          <h1>Run a k6 Cloud test from your CLI</h1>
          <Text>
            Download <b>k6</b> and and trigger local or cloud executed tests from the command line.
          </Text>
        </VerticalGroup>
        <CLIRunGuide projectId={project.id} testName="YOUR TEST NAME" script="" scriptName="test.js" />
      </Container>
    </PageSection>
  )
}
