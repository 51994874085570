import React, { useState } from 'react'
import { kebabCase } from 'lodash-es'
import { Button, ClipboardButton } from '@grafana/ui'

import { Test, TestRun } from 'types'
import { downloadScript } from 'utils/downloadFile'
import { CLIRunGuide } from 'components/CLIRunGuide'
import { CodeEditor } from 'components/CodeEditor/CodeEditor'
import { ScriptTabButtonsContainer, MessageContainer, ScriptTabWrapper, ScriptEditorWrapper } from './ScriptTab.styles'
import { CreateFromScriptButton } from './CreateFromScriptButton'

type Preview = 'cli-guide' | 'inline' | 'download'

interface ScriptTabProps {
  run: TestRun
  test: Test
}

interface ScriptPreviewProps {
  preview: Preview
  script: string
  test: Test
}

const ScriptPreview = ({ preview, script, test }: ScriptPreviewProps) => {
  const scriptName = test.name ? `${kebabCase(test.name)}.js` : 'test.js'

  function handleDownload() {
    downloadScript(script, scriptName)
  }

  if (preview === 'cli-guide') {
    return <CLIRunGuide projectId={test.project_id} testName={test.name} scriptName={scriptName} script={script} />
  }

  if (preview === 'download') {
    return (
      <ScriptTabWrapper>
        <MessageContainer>
          <div>Script is too large to preview</div>
          <Button fill="text" onClick={handleDownload} icon="download-alt">
            Download script
          </Button>
        </MessageContainer>
      </ScriptTabWrapper>
    )
  }

  return (
    <ScriptTabWrapper>
      <ScriptEditorWrapper>
        <CodeEditor value={script} readOnly={true} overlayMessage={null} />
      </ScriptEditorWrapper>
    </ScriptTabWrapper>
  )
}

export const ScriptTab = ({ run, test }: ScriptTabProps) => {
  const [preview, setPreview] = useState<Preview>('inline')

  const script = run.script ?? ''
  const isScriptShown = preview === 'inline'
  const isTooLargeForPreview = script.length > 200000

  function handleTogglePreviewClick() {
    const view = isTooLargeForPreview ? 'download' : 'inline'
    setPreview(preview === 'cli-guide' ? view : 'cli-guide')
  }

  if (!script) {
    return (
      <ScriptTabWrapper>
        <MessageContainer>No script to preview</MessageContainer>
      </ScriptTabWrapper>
    )
  }

  return (
    <ScriptTabWrapper>
      <ScriptTabButtonsContainer>
        {isScriptShown && !isTooLargeForPreview && (
          <>
            <ClipboardButton variant="secondary" getText={() => script}>
              Copy script
            </ClipboardButton>
            <CreateFromScriptButton scriptValue={script} />
          </>
        )}

        <Button
          onClick={handleTogglePreviewClick}
          variant="secondary"
          icon={isScriptShown ? 'info-circle' : 'file-alt'}
        >
          {preview !== 'cli-guide' ? 'Run script from CLI' : 'See test script'}
        </Button>
      </ScriptTabButtonsContainer>

      <ScriptPreview preview={preview} script={script} test={test} />
    </ScriptTabWrapper>
  )
}
