import React from 'react'

import { Scenario, TestRun } from 'types'
import { exhaustive } from 'utils/typescript'
import { ProtocolType } from '../RunOverview.types'
import { HttpTable } from './HttpTable'
import { WebSocketsTable } from './WebSocketsTable'
import { GRPCTable } from './GRPCTable'

export const ScenarioTable = ({
  protocol,
  testRun,
  scenarios,
}: {
  protocol: ProtocolType
  testRun: TestRun
  scenarios: Scenario[]
}) => {
  switch (protocol) {
    case 'http':
      return <HttpTable scenarios={scenarios} testRun={testRun} />
    case 'ws':
      return <WebSocketsTable scenarios={scenarios} testRun={testRun} />
    case 'grpc':
      return <GRPCTable scenarios={scenarios} testRun={testRun} />
    default:
      return exhaustive(protocol, null)
  }
}
