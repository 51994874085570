import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSearchParams } from './useSearchParams'

interface UseQueryParameterOptions<T> {
  /**
   * The name of the query parameter that the state is stored in.
   */
  name: string

  /**
   * A function that parses the query parameter value. If the URL does not
   * contain the query parameter, the value will be null and the parser
   * should return a default value.
   *
   * If value cannot be parsed, the parser should just ignore the input and
   * return some default value.
   */
  decoder: (value: string | null) => T

  /**
   * A function that takes a value and encodes as a string. If the encoder returns
   * null, the parameter will be removed from the query.
   */
  encoder: (value: T) => string | null
}

/**
 * Creates a new URL where the query parameter has been updated with the new value and
 * optionally navigates to it using the specified operation. If the new value is null,
 * the parameter will be removed from the URL.
 */
type UseQueryParameterSetter<T> = (op: 'push' | 'replace' | 'href', newValue: T) => string

export const useQueryParameter = <T>({
  name,
  decoder,
  encoder,
}: UseQueryParameterOptions<T>): [T, UseQueryParameterSetter<T>] => {
  const location = useLocation()

  const history = useHistory()
  const searchParams = useSearchParams()

  const rawValue = searchParams.get(name)

  const value = useMemo(() => {
    return decoder(rawValue)
  }, [decoder, rawValue])

  const setParam: UseQueryParameterSetter<T> = useCallback(
    (op, newValue) => {
      const searchParams = new URLSearchParams(history.location.search)
      const encodedValue = encoder(newValue)

      if (encodedValue === null) {
        searchParams.delete(name)
      } else {
        searchParams.set(name, encodedValue)
      }

      const search = searchParams.toString()
      const href = search.length > 0 ? `${location.pathname}?${searchParams.toString()}` : location.pathname

      switch (op) {
        case 'push':
          history.push(href)
          break

        case 'replace':
          history.replace(href)
          break
      }

      return href
    },
    [name, encoder, history, location]
  )

  return [value, setParam]
}
