import React, { useEffect, useState, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { Spinner } from '@grafana/ui'
import clsx from 'clsx'

const SpinnerOverlayStyles = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;

  &.enter {
    opacity: 1;
  }

  opacity: 0;
  transition: opacity 50ms 50ms ease-in-out;

  &.loaded,
  &.loaded.enter {
    opacity: 0;
  }
`

interface SpinnerOverlayProps {
  loading: boolean
  spinner: ReactNode
}

const SpinnerOverlay = ({ loading, spinner }: SpinnerOverlayProps) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <SpinnerOverlayStyles className={clsx(mounted && 'enter', !loading && 'loaded')}>{spinner}</SpinnerOverlayStyles>
  )
}

interface LoadingContainerStylesProps {
  $state: LoadingState
  $height: number | undefined
}

const LoadingContainerStyles = styled.div<LoadingContainerStylesProps>`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ $state, $height }) =>
    $state !== 'done' &&
    $height !== undefined &&
    css`
      height: ${$height}px;
    `}

  ${({ $state }) =>
    $state === 'transitioning' &&
    css`
      transition: height 200ms ease-in-out;
    `}

  > .wrapper {
    opacity: 0;
  }

  &.transitioning,
  &.done {
    > .wrapper {
      transition: opacity 200ms 200ms ease-in-out;
      opacity: 1;
    }
  }
`

type LoadingState = 'loading' | 'transitioning' | 'done'

export interface LoadingContainerProps {
  className?: string
  loading: boolean
  spinner?: ReactNode
  estimatedHeight?: number
  children: ReactNode
}

export const LoadingContainer = ({
  className,
  loading = false,
  spinner = <Spinner inline={false} />,
  estimatedHeight,
  children,
}: LoadingContainerProps) => {
  const [wrapperEl, setWrapperEl] = useState<HTMLDivElement | null>(null)

  const [height, setHeight] = useState(estimatedHeight)
  const [state, setState] = useState<LoadingState>(loading ? 'loading' : 'done')

  useEffect(() => {
    if (wrapperEl === null) {
      return
    }

    const observer = new ResizeObserver((entries) => {
      setHeight(entries[0]?.contentRect.height)
    })

    observer.observe(wrapperEl)

    return () => {
      observer.disconnect()
    }
  }, [wrapperEl])

  useEffect(() => {
    if (state === 'loading' && !loading) {
      setState('transitioning')
    }

    if (state !== 'loading' && loading) {
      setState('loading')
    }
  }, [loading, state])

  const handleTransitionEnd = () => {
    if (state === 'transitioning') {
      setState('done')
    }
  }

  return (
    <LoadingContainerStyles className={clsx(className, state)} $state={state} $height={height}>
      {spinner && state !== 'done' && <SpinnerOverlay loading={state === 'loading'} spinner={spinner} />}
      <div ref={setWrapperEl} className="wrapper" onTransitionEnd={handleTransitionEnd}>
        {children}
      </div>
    </LoadingContainerStyles>
  )
}
