import type { RestrictionObject } from 'constrained-editor-plugin'

// Get value map from restrictions array
// Used for updating restriction values
export function getValueMapFromRestrictions(restrictions: RestrictionObject[]) {
  const valueMap: Record<string, string> = {}
  for (const { label, value } of restrictions) {
    valueMap[label] = value
  }

  return valueMap
}
