import { isPast } from 'date-fns'
import { sortBy } from 'lodash-es'

import { useAppContext } from 'appContext'
import { dateFormatter } from 'utils/date'
import { SUBSCRIPTION_TYPE } from 'types'
import { useOrganization } from './useOrganization'

export const useSubscriptions = () => {
  const { account } = useAppContext()
  const org = useOrganization()

  if (!org) {
    return
  }

  return account.subscriptions.filter((sub) => org.subscription_ids.includes(sub.id))
}

export const usePaidSubscriptions = () => {
  const subscriptions = useSubscriptions()
  if (!subscriptions) {
    return
  }

  return subscriptions
    .filter((subscription) => subscription.type !== SUBSCRIPTION_TYPE.FREE && !subscription.is_addon)
    .map((subscription) => {
      return {
        ...subscription,
        isTrial: subscription.version === 4 && subscription.name === 'Trial',
        expiryDateDisplay: dateFormatter.tz(subscription.expires, 'MMM dd, yyyy'),
        isExpired: isPast(dateFormatter.parseTz(subscription.expires)),
        isStarted: isPast(dateFormatter.parseTz(subscription.starting)),
      }
    })
}

export const useActiveSubscription = () => {
  const filteredSubscriptions = usePaidSubscriptions()
  if (!filteredSubscriptions) {
    return
  }

  const nonExpired = filteredSubscriptions.filter((subscription) => !subscription.isExpired)

  const sortedSubscriptions = sortBy(sortBy(sortBySubscriptionType(nonExpired), 'isStarted'), 'is_addon', 'asc')

  return sortedSubscriptions[0] || null
}

const sortBySubscriptionType = <T extends { type: number }>(subscriptions: T[]) => {
  return sortBy(subscriptions, (sub) => {
    switch (sub.type) {
      case SUBSCRIPTION_TYPE.CUSTOM:
        return 0
      case SUBSCRIPTION_TYPE.PREMIUM:
        return 1
      case SUBSCRIPTION_TYPE.DATA_RETENTION:
        return 2
      case SUBSCRIPTION_TYPE.APP_DIRECT:
        return 3
      case SUBSCRIPTION_TYPE.TRIAL:
        return 4
      default:
        return 5
    }
  })
}
