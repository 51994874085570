import { useQuery } from '@tanstack/react-query'
import { useDatasource } from 'datasourceContext'
import { TestRun } from 'types'
import { toPerformanceInsightsQueryKey } from './queryKeys'

export const usePerformanceInsights = (testRun: TestRun, enabled: boolean) => {
  const { ds } = useDatasource()

  return useQuery({
    queryKey: toPerformanceInsightsQueryKey(testRun.id),
    queryFn: () => ds.fetchPerformanceInsights(testRun.id),
    enabled,
  })
}
