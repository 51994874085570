import { TabsBar as TabsBarComponent } from '@grafana/ui'
import styled from 'styled-components'

export const TabsBar = styled(TabsBarComponent)`
  border-bottom: none;

  > [role='tablist'] {
    height: auto;
  }
`
