import React, { ReactNode } from 'react'

interface SupportLinkProps {
  children: ReactNode
}

// TODO: Implement this...
export const SupportLink = ({ children }: SupportLinkProps) => {
  return <>{children}</>
}
