import { AppRootProps } from '@grafana/data'
import React, { createContext, SetStateAction } from 'react'
import { ThemeProvider } from 'styled-components'
import { useTheme2 } from '@grafana/ui'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { TestsPageState } from 'types'
import { AppContextProvider } from 'appContext'
import { SetupSentry } from 'components/SetupSentry'
import { Routes } from 'Routes'
import { Layout } from 'components/Layout'
import { queryClient } from 'data/useFetch'
import { DatasourceContextProvider } from 'datasourceContext'
import { BreadcrumbsContextProvider } from 'breadcrumbsContext'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { GlobalStyles } from 'pages/styles'

const defaultState: React.Dispatch<SetStateAction<TestsPageState>> = {} as React.Dispatch<
  SetStateAction<TestsPageState>
>

export const TestsContext = createContext({
  state: {} as TestsPageState,
  setState: defaultState,
})

export const RootPage = React.memo(function RootPage(props: AppRootProps) {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <DatasourceContextProvider>
          <AppContextProvider>
            <BreadcrumbsContextProvider>
              <ThemeProvider theme={useTheme2()}>
                <GlobalStyles />
                <SetupSentry pluginVersion={props.meta.info.version} />
                <Layout>
                  <Routes />
                </Layout>
                <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
              </ThemeProvider>
            </BreadcrumbsContextProvider>
          </AppContextProvider>
        </DatasourceContextProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  )
})
