import React from 'react'
import { PluginPageProps, PluginPage as RealPluginPage } from '@grafana/runtime'

import { isTopNavEnabled } from 'utils/topnav'
import logo from '../../img/logo.svg'

const PluginPageFallback = ({ children }: PluginPageProps) => {
  return <>{children}</>
}

const PluginPageTopNav = ({ pageNav, ...props }: PluginPageProps) => (
  <RealPluginPage {...(pageNav !== undefined && { pageNav: { ...pageNav, img: logo } })} {...props} />
)

export const PluginPage = isTopNavEnabled() ? PluginPageTopNav : PluginPageFallback
