import React, { useState } from 'react'
import { DataTable } from 'components/DataTable'

import { GrpcUrl, TestRun } from 'types'
import { separatorFormatter } from 'utils/formatters'
import { Status } from '../Status'
import { GRPCUrlsChart } from './GRPCUrlsChart'
import { useGRPC } from 'data/useGRPC'
import { formatGrpcTableData, getFormat, getGRPCStatusText } from './GRPCUrlsTab.utils'
import { GRPCStatusTooltipContent } from './GRPCStatusTooltipContent'
import { LoadingContainer } from 'components/LoadingContainer'
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types'
import { getFilledFilters, getShowFilterSelect } from '../Filters/Filters.utils'
import { useRunDetailsGRPCFilters } from '../Filters/FiltersContext.hooks'
import { RunDetailsTableEmptyState } from '../TableEmptyState'
import { GrpcUrlsFilters } from './GrpcUrlsFilters'
import { TooltipCell } from 'components/DataTable/TooltipCell'

interface Props {
  run: TestRun
}

const PAGE_SIZE = 20

const columns: Array<TableColumn<GrpcUrl>> = [
  {
    name: '',
    cell: (row) => <Status isValid={row.expected_response || false}></Status>,
    width: '4px',
    compact: true,
  },
  {
    name: 'URL',
    sortable: true,
    sortField: 'name',
    cell: (row) => <TooltipCell tooltip={`${row.host}${row.name}`}>{row.name}</TooltipCell>,
    minWidth: '250px',
  },
  {
    name: 'SCENARIO',
    sortable: true,
    sortField: 'scenario',
    // There won't be any point of a tooltip if scenario is default
    cell: (row) => <TooltipCell show={row.scenario !== 'default'}>{row.scenario}</TooltipCell>,
  },
  {
    name: 'STATUS',
    minWidth: '200px',
    sortable: true,
    sortField: 'status',
    cell: (row) => (
      <TooltipCell tooltip={<GRPCStatusTooltipContent status={row.status} />}>
        <>
          {getGRPCStatusText(row.status)} ({row.status}){' '}
        </>
      </TooltipCell>
    ),
  },
  {
    name: 'AVG RPS',
    sortable: true,
    sortField: 'grpc_metric_summary/rps_mean',
    selector: (row) => `${separatorFormatter(row.grpc_metric_summary.rps_mean, 1)} req/s`,
  },
  {
    name: 'COUNT',
    sortable: true,
    sortField: 'grpc_metric_summary/requests_count',
    selector: (row) => separatorFormatter(row.grpc_metric_summary.requests_count),
  },
  {
    name: 'MIN',
    sortable: true,
    sortField: 'grpc_metric_summary/duration/min',
    selector: (row) => getFormat('min', row),
  },
  {
    name: 'AVG',
    sortable: true,
    sortField: 'grpc_metric_summary/duration/mean',
    selector: (row) => getFormat('mean', row),
  },
  {
    name: 'STDDEV',
    sortable: true,
    sortField: 'grpc_metric_summary/duration/stdev',
    selector: (row) => getFormat('stdev', row),
  },
  {
    name: 'P95',
    sortable: true,
    sortField: 'grpc_metric_summary/duration/p95',
    selector: (row) => getFormat('p95', row),
  },
  {
    name: 'P99',
    sortable: true,
    sortField: 'grpc_metric_summary/duration/p99',
    selector: (row) => getFormat('p99', row),
  },
  {
    name: 'MAX',
    sortable: true,
    sortField: 'grpc_metric_summary/duration/max',
    selector: (row) => getFormat('max', row),
  },
]

export const GRPCUrlsTab = ({ run }: Props) => {
  const { filters, removeAllFilters } = useRunDetailsGRPCFilters()
  const [orderBy, setOrderBy] = useState<string>()

  const {
    data: grpcResponse,
    fetchPage,
    currentPage,
    isFetching,
    isLoading,
  } = useGRPC(run, { pageSize: PAGE_SIZE, filters: getFilledFilters(filters), refetchOnMount: false, orderBy })

  const data = formatGrpcTableData(grpcResponse?.value ?? [])
  const showFilters = getShowFilterSelect(data, filters)

  return (
    <LoadingContainer loading={!grpcResponse}>
      <div>
        {showFilters && <GrpcUrlsFilters run={run} />}

        <DataTable
          columns={columns}
          noDataComponent={<RunDetailsTableEmptyState filters={filters} onClearFiltersClick={removeAllFilters} />}
          data={data}
          highlightOnHover={true}
          pointerOnHover={true}
          expandableRowsHideExpander
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={({ data }) => <GRPCUrlsChart testRun={run} url={data} />}
          expandableRowsComponentProps={{ run }}
          currentPage={currentPage}
          pagination
          paginationServer
          paginationPerPage={PAGE_SIZE}
          paginationTotalRows={grpcResponse?.['@count']}
          onChangePage={fetchPage}
          isFetching={isFetching && !isLoading}
          sortServer
          onSort={setOrderBy}
        />
      </div>
    </LoadingContainer>
  )
}
