import React from 'react'
import { usePerformanceInsights } from 'data/usePerformanceInsights'
import { TestRun } from 'types'
import { InsightsHeader } from './InsightsHeader'
import { InsightList } from './InsightList'
import { isTestActive, isTestDone } from 'utils/testRun'
import { LoadingContainer } from 'components/LoadingContainer'
import { InsightsAnalyzingCarousel } from './InsightList/InsightsAnalyzingCarousel'
import { InsightsLoading } from './InsightList/InsightsLoading'
import { InsightsError } from './InsightList/InsightsError'
import { CollapsableSection } from 'components/CollapsableSection/CollapsableSection'
import { RunDetailsSection } from 'pages/TestRunPage/TestRunPage.styles'
import { useInsightFilter } from './Insights.hooks'

interface InsightsProps {
  testRun: TestRun
}

export const Insights = ({ testRun }: InsightsProps) => {
  const insights = usePerformanceInsights(testRun, isTestDone(testRun))

  const [{ open, filter }, setInsightFilter] = useInsightFilter(insights.data)

  const handleSectionToggle = () => {
    setInsightFilter('replace', {
      filter,
      open: !open,
    })
  }

  const handleRetry = () => {
    insights.refetch()
  }

  return (
    <RunDetailsSection>
      <CollapsableSection
        label={<InsightsHeader testRun={testRun} insights={insights} />}
        isOpen={open}
        onToggle={handleSectionToggle}
      >
        <LoadingContainer
          loading={insights.isLoading}
          spinner={isTestActive(testRun) ? <InsightsAnalyzingCarousel /> : undefined}
        >
          {insights.isLoading && <InsightsLoading />}
          {insights.isSuccess && <InsightList filter={filter} testRun={testRun} insights={insights.data} />}
          {insights.isError && <InsightsError onRetry={handleRetry} />}
        </LoadingContainer>
      </CollapsableSection>
    </RunDetailsSection>
  )
}
