import React from 'react'

import { Alert } from '@grafana/ui'

// TODO: add support link
export const ErrorMessage = () => {
  const handleReload = () => {
    window.location.reload()
  }

  return (
    <div className="page-container page-body">
      <Alert title="Encountered an error while loading the page" buttonContent="Reload" onRemove={handleReload}>
        Try to reload the page, if the problem persists please contact support
      </Alert>
    </div>
  )
}
