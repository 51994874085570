import styled from 'styled-components'

export const Card = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.background.secondary};
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  section {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  h5 {
    cursor: pointer;
    margin: ${({ theme }) => theme.spacing(0, 1)};
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
  }
`

export const ChartWrapper = styled.div`
  cursor: pointer;
`
export const ChartPlaceholderWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
`

export const SelectionOverlay = styled.div<{ disabled: boolean; selected: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: absolute;
  inset: 0;
  z-index: 2;
  transition: outline 100ms ease-in-out;
  outline: 2px solid ${({ selected, theme }) => (selected ? theme.colors.primary.border : 'transparent')};
  user-select: none;
`
