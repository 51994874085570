import React from 'react'
import { NavLink } from 'react-router-dom'
import { useBreadcrumbs } from 'breadcrumbsContext'

import { Breadcrumb, Icon, Label, TextContent } from './Breadcrumbs.styles'

export const Breadcrumbs = () => {
  const { breadcrumbs } = useBreadcrumbs()

  return (
    <>
      {breadcrumbs.map(({ name, path, label }, i) => (
        <Breadcrumb key={i}>
          <TextContent>
            {path ? <NavLink to={path}>{name}</NavLink> : <span>{name}</span>}
            {label && <Label>{label}</Label>}
          </TextContent>
          {i !== breadcrumbs.length - 1 && <Icon name="angle-right" className="icon" size="lg" aria-hidden />}
        </Breadcrumb>
      ))}
    </>
  )
}
