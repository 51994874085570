import { TabProps } from './CodeSnippet.types'

const LINE_HEIGHT = 22
const MIN_HEIGHT = 52

export const getEstimatedHeight = (str = '') => Math.max((str.trim().split('\n').length || 1) * LINE_HEIGHT, MIN_HEIGHT)

export const getTab = (activeTab: string | undefined, tabs: TabProps[] = []): TabProps => {
  return tabs.find((tab) => tab.value === activeTab) || tabs[0]!
}
