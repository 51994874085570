import React from 'react'
import { Menu, MenuItemProps } from '@grafana/ui'
import styled from 'styled-components'
import clsx from 'clsx'

const StyledMenuItem = styled(Menu.Item)`
  .fa {
    width: 16px;
  }
  &.disabled {
    color: ${({ theme }) => theme.colors.action.disabledText};
    opacity: ${({ theme }) => theme.colors.action.disabledOpacity};
    pointer-events: none;
  }
`

export const MenuItem = ({ disabled, ...props }: MenuItemProps & { disabled?: boolean }) => (
  <StyledMenuItem {...props} className={clsx(disabled && 'disabled')} />
)
