import React from 'react'
import { DocLink } from './DocLink'
import { DocArticle } from './docs'

interface ReadMoreProps {
  article: DocArticle
}

export const ReadMore = ({ article }: ReadMoreProps) => {
  return <DocLink article={article}>Read more</DocLink>
}
