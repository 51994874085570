import React from 'react'
import { Icon } from '@grafana/ui'

import { Flag } from 'components/Flag'
import { LoadZoneLabelProps } from './LoadZoneLabel.types'
import { FlagWrapper, Item, Label } from './LoadZoneLabel.styles'

export const LoadZoneLabel = ({
  country,
  city,
  loadPercent,
  isPublic,
  className,
  showLabel = false,
  showPrivateIcon = false,
  showPercentage = false,
}: LoadZoneLabelProps) => {
  return (
    <Label className={className}>
      <Item>
        {country && (
          <FlagWrapper>
            <Flag name={country.toLowerCase()} />
          </FlagWrapper>
        )}
      </Item>
      {!isPublic && showPrivateIcon && (
        <Item>
          <Icon name="eye-slash" />
        </Item>
      )}
      {showLabel && <Item>{city}</Item>}
      {showPercentage && loadPercent !== 100 && <Item>{loadPercent}%</Item>}
    </Label>
  )
}
