import React from 'react'
import { LoadingState } from '@grafana/data'
import { PanelRenderer } from '@grafana/runtime'
import { PanelChrome } from '@grafana/ui'
import AutoSizer from 'react-virtualized-auto-sizer'

import { createTimeRangeOn } from 'utils/date'
import { TrendingChartProps } from './TrendingChart.types'
import { createFieldConfig, createFieldOverrides, createPanelOptions, getDataFrames } from './TrendingChart.utils'
import { ChartPlaceholder } from 'components/Chart/Chart.styles'
import { ChartPlaceholderWrapper } from '../TestCard/TestCard.styles'

export const TrendingChart = ({ sortedRuns }: TrendingChartProps) => {
  const frames = getDataFrames(sortedRuns)
  const range = createTimeRangeOn('created', sortedRuns)
  const options = createPanelOptions(frames)
  const config = createFieldConfig(sortedRuns)
  const overrides = createFieldOverrides(frames)

  const panelData = {
    state: LoadingState.Done,
    series: frames,
    timeRange: range,
  }

  return (
    <AutoSizer disableHeight>
      {(size) => (
        <PanelChrome width={size.width} height={80}>
          {(innerWidth, innerHeight) =>
            sortedRuns.length > 0 ? (
              <PanelRenderer
                title="Trending p95 response time"
                pluginId="barchart"
                width={innerWidth}
                height={innerHeight}
                data={panelData}
                options={options}
                fieldConfig={{ ...config, overrides }}
              />
            ) : (
              <ChartPlaceholderWrapper>
                <ChartPlaceholder $height={46} />
              </ChartPlaceholderWrapper>
            )
          }
        </PanelChrome>
      )}
    </AutoSizer>
  )
}
