import React from 'react'
import { WsMetricSummary } from 'types'
import { duration, quantity, timing } from 'utils/formatters'

interface WebSocketTestSummaryProps {
  summary: WsMetricSummary
}

export const WebSocketTestSummary = ({ summary }: WebSocketTestSummaryProps) => {
  return (
    <>
      A total of <b>{quantity(summary.msgs_sent ?? 0)}</b> messages were sent and{' '}
      <b>{quantity(summary.msgs_received ?? 0)}</b> received over <b>{quantity(summary.sessions)}</b> sessions. The 95th
      percentile session duration was <b>{duration((summary.session_duration.p95 || 0) / 1000)}</b>, with an 95th
      percentile time to connect of <b>{timing(summary.connecting.p95 || 0)}</b>.
    </>
  )
}
