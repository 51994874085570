import { useEffect, useState } from 'react'

import { ChecksPayload } from 'datasource/datasource'
import { useDatasource } from 'datasourceContext'

import { Check, TestRunChecksFilter, TestRun } from 'types'
import { getFiltersValueString } from 'pages/TestRunPage/components/Filters/Filters.utils'

import { toChecksQueryKey } from './queryKeys'
import { QueryOptions, useFetch } from './useFetch'

export const useChecks = (
  testRun: TestRun,
  {
    pageSize = 20,
    filters = [],
    orderBy,
    ...options
  }: QueryOptions<ChecksPayload> & { filters?: TestRunChecksFilter[]; pageSize?: number; orderBy?: string } = {}
) => {
  const [currentPage, setCurrentPage] = useState(1)
  const { ds } = useDatasource()

  const filterValues = getFiltersValueString(filters)

  useEffect(() => {
    setCurrentPage(1)
  }, [filterValues, pageSize, orderBy])

  const response = useFetch(
    toChecksQueryKey(testRun.id, { page: currentPage, count: true, ended: testRun.ended, filters, orderBy }),
    () => ds.fetchChecks({ id: testRun.id, page: currentPage, pageSize, filters, orderBy }),
    {
      ...options,
      keepPreviousData: options.keepPreviousData ?? true,
    } as QueryOptions<ChecksPayload>
  )

  return { ...response, currentPage, fetchPage: setCurrentPage, orderBy }
}

type CheckName = Pick<Check, 'name'>

export const useCheckNames = (testRun: TestRun, options?: QueryOptions<CheckName[]>) => {
  const { ds } = useDatasource()

  return useFetch<CheckName[]>(
    toChecksQueryKey(testRun.id, { select: 'name', ended: testRun.ended }),
    () => ds.fetchChecks({ id: testRun.id, select: 'name', count: false }).then((res) => res.value ?? []),
    options
  )
}
