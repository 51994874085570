import { TestRun } from 'types';
import { ScenariosPayload } from 'datasource/datasource'
import { useDatasource } from 'datasourceContext'

import { toScenariosQueryKey } from './queryKeys'
import { QueryOptions, useFetch } from './useFetch'

export const useScenarios = (testRun: TestRun, options?: QueryOptions<ScenariosPayload>) => {
  const { ds } = useDatasource()
  return useFetch(toScenariosQueryKey(testRun.id, { ended: testRun.ended }), () => ds.fetchScenarios(testRun.id), options)
}
