import { IconButton, InlineToast } from '@grafana/ui'
import React, { useEffect, useRef, useState } from 'react'
import { ButtonContainer, Label, StatusIcon } from './ProjectIdLabel.styles'

interface ProjectIdLabelProps {
  id: number
}

export const ProjectIdLabel = ({ id }: ProjectIdLabelProps) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const buttonRef = useRef<null | HTMLDivElement>(null)

  const handleClick = () => {
    navigator.clipboard.writeText(id.toString())
    setShowSuccessMessage(true)
  }

  useEffect(() => {
    if (!showSuccessMessage) {
      return
    }

    const timeout = setTimeout(() => {
      setShowSuccessMessage(false)
    }, 2500)

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [showSuccessMessage])

  return (
    <>
      {showSuccessMessage && (
        <InlineToast placement="bottom" referenceElement={buttonRef.current}>
          Copied
        </InlineToast>
      )}
      <Label>
        Project id:&nbsp;<strong>{id}</strong>
        {/* This wrapper is a workaround for a positioning issue with InlineToast and IconButton */}
        <ButtonContainer ref={buttonRef}>
          {showSuccessMessage ? (
            <StatusIcon name="check" size="sm" />
          ) : (
            <IconButton name="copy" size="sm" tooltip="Copy" onClick={handleClick} ariaLabel="Copy Project Id" />
          )}
        </ButtonContainer>
      </Label>
    </>
  )
}
