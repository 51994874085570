import React, { MouseEvent } from 'react'
import { last } from 'lodash-es'
import { useHistory } from 'react-router-dom'
import { Checkbox } from '@grafana/ui'

import { Test } from 'types'
import { Tooltip } from 'components/Tooltip'
import { LastRunMeta } from '../LastRunMeta'
import { StatusIndicator } from '../StatusIndicator'
import { TrendingChart } from '../TrendingChart'
import { getSortedTestRuns } from './TestCard.utils'
import { useHasUserWriteAccess } from 'data/usePermissions'
import { TestMenu } from '../TestMenu'
import { routeMap } from 'routeMap'
import { isTestActive } from 'utils/testRun'
import { Card, ChartWrapper, Header, SelectionOverlay } from './TestCard.styles'

interface TestCardProps {
  test: Test
  selected: boolean
  showSelection: boolean
  onSelectTest: (test: Test, shiftKey?: boolean) => void
  onDeleteTest: (test: Test) => void
}

export const TestCard = ({ test, selected, showSelection, onSelectTest, onDeleteTest }: TestCardProps) => {
  const history = useHistory()
  const hasUserWriteAccess = useHasUserWriteAccess()

  const sortedRuns = getSortedTestRuns(test.test_runs)
  const latestRun = last(sortedRuns)
  const isActive = !!latestRun && isTestActive(latestRun)

  const handleOpenTest = () => {
    history.push(routeMap.test(test.id))
  }

  const handleSelectTest = (e: MouseEvent) => {
    if (!showSelection || isActive) {
      return
    }

    onSelectTest(test, e.shiftKey)
  }

  return (
    <Card>
      <Header>
        <StatusIndicator testRun={latestRun} />
        <h5 onClick={handleOpenTest}>{test.name}</h5>
        {hasUserWriteAccess &&
          (!showSelection ? (
            <TestMenu test={test} testRuns={sortedRuns} onDeleteTest={onDeleteTest} onSelectTest={onSelectTest} />
          ) : (
            <Checkbox checked={selected} readOnly />
          ))}
      </Header>

      <section>
        <LastRunMeta testRuns={sortedRuns} />
      </section>

      <ChartWrapper onClick={handleOpenTest}>
        <TrendingChart sortedRuns={sortedRuns} />
      </ChartWrapper>

      {showSelection && (
        <Tooltip show={isActive} content="Cannot select a test that is currently running." placement="top">
          <SelectionOverlay selected={selected} disabled={isActive} onClick={handleSelectTest} />
        </Tooltip>
      )}
    </Card>
  )
}
