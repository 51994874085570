import React from 'react'

import { combineLoadZonesDistribution } from 'utils'
import { useLoadZones } from 'data/useLoadZones'
import { LoadZoneTooltip } from './components/LoadZoneTooltip'
import { LoadZoneLabel } from './components/LoadZoneLabel'
import { LoadZoneProps } from './LoadZones.types'
import { List, Spinner } from './LoadZones.styles'

export const LoadZones = ({ nodes, distribution, limit = 4 }: LoadZoneProps) => {
  const { data: loadZones } = useLoadZones()

  if (!loadZones) {
    return <Spinner size={14} />
  }

  const zones = combineLoadZonesDistribution(distribution, nodes, loadZones)
  const visibleZones = zones.slice(0, limit)
  const restZones = zones.slice(limit)

  return (
    <LoadZoneTooltip loadZones={zones}>
      <List>
        {visibleZones.map((zone) => (
          <li key={zone.id}>
            <LoadZoneLabel
              country={zone.country}
              city={zone.city}
              loadPercent={zone.loadPercent}
              isPublic={zone.isPublic}
              showLabel={zones.length === 1}
            />
          </li>
        ))}

        {restZones.length > 0 && (
          <li>
            <span>
              ...<strong>({restZones.length})</strong>
            </span>
          </li>
        )}
      </List>
    </LoadZoneTooltip>
  )
}
