import { useEffect, useState } from 'react'

import { useDatasource } from 'datasourceContext'
import { TestRun, TestRunWebSocketsFilter, WSPayload, WSValue } from 'types'
import { getFiltersValueString } from 'pages/TestRunPage/components/Filters/Filters.utils'

import { QueryOptions, useFetch } from './useFetch'
import { toWebSocketsQueryKey } from './queryKeys'

export const useWebSocketsStats = (
  testRun: TestRun,
  {
    pageSize = 20,
    filters = [],
    orderBy,
    ...options
  }: QueryOptions<WSPayload> & { filters?: TestRunWebSocketsFilter[]; pageSize?: number; orderBy?: string } = {}
) => {
  const [currentPage, setCurrentPage] = useState(1)
  const { ds } = useDatasource()

  const filterValues = getFiltersValueString(filters)

  useEffect(() => {
    setCurrentPage(1)
  }, [filterValues, pageSize, orderBy])

  const response = useFetch(
    toWebSocketsQueryKey(testRun.id, { page: currentPage, count: true, ended: testRun.ended, filters, orderBy }),
    () => ds.fetchWS({ id: testRun.id, page: currentPage, pageSize, filters, orderBy }),
    {
      ...options,
      keepPreviousData: options.keepPreviousData ?? true,
    } as QueryOptions<WSPayload>
  )

  return { ...response, currentPage, fetchPage: setCurrentPage }
}

type WebSocketsName = Pick<WSValue, 'name'>

export const useWebSocketsNames = (testRun: TestRun, options?: QueryOptions<WebSocketsName[]>) => {
  const { ds } = useDatasource()

  return useFetch<WebSocketsName[]>(
    toWebSocketsQueryKey(testRun.id, { select: 'name', ended: testRun.ended }),
    () => ds.fetchWS({ id: testRun.id, select: 'name', count: false }).then((res) => res.value ?? []),
    options
  )
}

type WebSocketsStatus = Pick<WSValue, 'status'>

export const useWebSocketsStatus = (testRun: TestRun, options?: QueryOptions<WebSocketsStatus[]>) => {
  const { ds } = useDatasource()

  return useFetch<WebSocketsStatus[]>(
    toWebSocketsQueryKey(testRun.id, { select: 'status', ended: testRun.ended }),
    () => ds.fetchWS({ id: testRun.id, select: 'status', count: false }).then((res) => res.value ?? []),
    options
  )
}
