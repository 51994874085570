import React, { ReactNode } from 'react'
import styled from 'styled-components'

const StyledOverlay = styled.div`
  position: absolute;
  inset: 0;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.background.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.size.lg};
  padding: 20px;
`

export const Overlay = ({ children }: { children: ReactNode }) => <StyledOverlay>{children}</StyledOverlay>
