import React from 'react'
import { Icon, Tooltip } from '@grafana/ui'
import { useHistory } from 'react-router-dom'

import { Account, TestRun } from 'types'
import { useAccount } from 'data/useAccount'
import { getDateInTimezone } from 'utils/date'
import { responseTimeFormatter } from 'utils/formatters'
import { getTestStatusText } from 'pages/utils'
import { InfiniteScroll } from 'components/InfiniteScroll'
import { LoadZones } from 'components/LoadZones'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { NoteCellProps, RunsTableProps, StatusCellProps } from './RunsTable.types'
import { getConfig } from './RunsTable.utils'
import {
  CreateNoteButton,
  EditHighlight,
  EditNoteButton,
  NoteCellBody,
  RunsDataTable,
  StatusCellBody,
  StatusCellText,
} from './RunsTable.styles'
import { routeMap } from 'routeMap'
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types'

export const StatusCell = ({ testRun }: StatusCellProps) => {
  const statusText = getTestStatusText(testRun)

  if (testRun.is_baseline) {
    return (
      <StatusCellBody>
        <Tooltip content={'Baseline'} placement={'bottom-start'}>
          <Icon name="star" />
        </Tooltip>
        <StatusCellText $marginLeft="10px">{statusText}</StatusCellText>
      </StatusCellBody>
    )
  }

  if (testRun.delete_status === 1) {
    return (
      <StatusCellBody>
        <Tooltip content={'Saved test result'} placement={'bottom'}>
          <Icon name="lock" />
        </Tooltip>
        <StatusCellText $marginLeft="10px">{statusText}</StatusCellText>
      </StatusCellBody>
    )
  }

  return (
    <StatusCellBody>
      <StatusCellText>{statusText}</StatusCellText>
    </StatusCellBody>
  )
}

const getColumns = (
  { user }: Partial<Account> = {},
  { onEditNote, isReadOnly }: NoteCellProps
): Array<TableColumn<TestRun>> => [
  {
    name: 'Status',
    cell: (row) => <StatusCell testRun={row} />,
  },
  {
    name: 'Config',
    selector: (row) => getConfig(row),
  },
  {
    name: 'Load distribution',
    cell: (row) => <LoadZones nodes={row.nodes} distribution={row.distribution} />,
  },
  {
    name: 'Started',
    selector: (row) => (row.started ? getDateInTimezone(row.started, 'MMM dd, HH:mm', user?.time_zone) : ''),
  },
  {
    name: 'Response Time',
    selector: (row) => `${row.load_time ? responseTimeFormatter(row.load_time) : ' - '} ms`,
  },
  {
    name: 'Note',
    cell: (row) => {
      if (!row.note) {
        return (
          <NoteCellBody>
            <EditHighlight>
              <CreateNoteButton fill="text" onClick={() => onEditNote(row)} disabled={isReadOnly}>
                Create Note
              </CreateNoteButton>
            </EditHighlight>
          </NoteCellBody>
        )
      }

      return (
        <NoteCellBody>
          <EditNoteButton tooltip={row.note} fill="text" onClick={() => onEditNote(row)}>
            <p>{row.note}</p>
            <EditHighlight>
              <Icon name="pen" size="lg" />
            </EditHighlight>
          </EditNoteButton>
        </NoteCellBody>
      )
    },
  },
]

export const RunsTable = ({ runs, isLoading, isLoadingInitial, loadNext, onEditNote }: RunsTableProps) => {
  const { data: account } = useAccount()
  const history = useHistory()

  const handleRowClick = (row: TestRun) => {
    history.push(routeMap.testRun(row.id))
  }

  const columns = getColumns(account, { onEditNote })

  if (isLoadingInitial) {
    return <CenteredSpinner $height="100px" />
  }

  return (
    <InfiniteScroll isLoading={isLoading} loadNext={loadNext}>
      <RunsDataTable
        columns={columns}
        data={runs}
        onRowClicked={handleRowClick}
        highlightOnHover={true}
        pointerOnHover={true}
        noDataMessage="No tests have been run yet"
      />
    </InfiniteScroll>
  )
}
