import React from 'react'
import { Spinner } from '@grafana/ui'
import styled from 'styled-components'

const Wrapper = styled.div<{ $height: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.$height};
`

export const CenteredSpinner = ({ $height = '100%' }) => {
  return (
    <Wrapper $height={$height}>
      <Spinner />
    </Wrapper>
  )
}
