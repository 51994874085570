import React from 'react'
import { DocLink, docs, ReadMore } from 'components/DocLink'
import { InsightBody } from '../InsightBody'
import { InsightListItem } from '../InsightItem'
import { SkippedDescription } from '../SkippedDescription'
import { BestPracticeTooManyUrls } from 'types/insights/bestPractices'

export const TooManyUrls = () => {
  return (
    <InsightBody>
      <p>
        Redirects, dynamic parameters (such as those for token, resources, or session IDs),{' '}
        <DocLink article={docs.insights.bestPractices.thirdPartyContent}>third-party content</DocLink>, and
        programmatically creating requests with different{' '}
        <DocLink article={docs.httpRequests.urlGrouping}>name tags</DocLink> may cause a high number of unique URLs to
        be generated. Requesting too many URLs may make it more difficult to aggregate, display, and analyze your test
        results.
      </p>
      <h4>Recommendations:</h4>
      <ul>
        <li>
          Use <DocLink article={docs.httpRequests.urlGrouping}>URL grouping</DocLink> to collect similar requests.
        </li>
        <li>Consider removing requests to third-party domains that you do not control.</li>
        <li>
          Avoid adding dynamically generated <code>name</code> tags. If you want to track what a certain user is doing,
          use <code>console.log()</code> in your script to output information to the log instead.
        </li>
      </ul>
      <p>
        <ReadMore article={docs.insights.bestPractices.tooManyUrls} />
      </p>
    </InsightBody>
  )
}

interface TooManyUrlsItemProps {
  insight: BestPracticeTooManyUrls
}

const TooManyUrlsDescription = ({ insight }: TooManyUrlsItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          We did not detect an excessive number of URLs in your test.{' '}
          <ReadMore article={docs.insights.bestPractices.tooManyUrls} />
        </>
      )

    case 'FAILED':
      return <>Your test requested more than {insight.data.threshold.max_unique_urls} unique URLs.</>

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const TooManyUrlsItem = ({ insight }: TooManyUrlsItemProps) => {
  return (
    <InsightListItem header="Too Many URLs" insight={insight}>
      <TooManyUrlsDescription insight={insight} />
    </InsightListItem>
  )
}
