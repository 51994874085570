import React, { MouseEvent, ReactElement } from 'react'
import { Tab as TabComponent } from '@grafana/ui'
import { TabStyles } from './Tab.styles'
import { PropsOf } from 'utils/typescript'
import { useHistory } from 'react-router-dom'

type GrafanaTabProps = PropsOf<typeof TabComponent>

interface TabProps extends Omit<GrafanaTabProps, 'onChangeTab' | 'ref' | 'as' | 'suffix'> {
  suffix?: GrafanaTabProps['suffix'] | ReactElement<any>
  onChangeTab?: () => void
}

export const Tab = ({ suffix, href, onChangeTab, ...props }: TabProps) => {
  const history = useHistory()

  // We need to capture the click using a wrapper element instead the built-in onChangeTab-handler,
  // otherwise clicking the counter will trigger clicks in e.g. CollapsableSection.
  const handleTabClick = (ev: MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation()
    ev.preventDefault()

    if (href !== undefined) {
      history.push(href)
    }

    onChangeTab?.()
  }

  return (
    <TabStyles onClick={handleTabClick}>
      <TabComponent {...props} href={href} suffix={React.isValidElement(suffix) ? () => <>{suffix}</> : suffix} />
    </TabStyles>
  )
}
