import { capitalize, cloneDeep, every, filter, find, includes, isEmpty, map, merge, pick, pull, uniq } from 'lodash-es'
import { checksPanel } from 'panels/checks'
import { testRunPanel } from 'panels/testRun'
import { thresholdPanel } from 'panels/thresholds'
import { config } from '@grafana/runtime'
import { FieldConfigSource } from '@grafana/data'

import {
  Account,
  DELETE_STATUS,
  LoadZone,
  Metric,
  Metric2,
  MetricType,
  MetricTypeName,
  QueryType,
  SeriesTag,
  TestRun,
  TestRunNode,
  VariableQueryType,
} from './types'
import { K6DataSource } from 'datasource/datasource'

/**
 * For Grafana versions < 8.3.0 we need to add a custom
 * configuration page that enables/disables the app plugin
 */

export const requiresCustomConfigPage = () => {
  const splittedVersion = config.buildInfo.version.split('.')
  const MAJOR_VERSION = 8
  const MINOR_VERSION = 3

  if (parseInt(splittedVersion[0]!, 10) <= MAJOR_VERSION && parseInt(splittedVersion[1]!, 10) < MINOR_VERSION) {
    return true
  }

  return false
}

export const getMetricFromMetricNameAndTags = (
  metricsList: Metric2[],
  metricName: string,
  tags: Map<string, string> | undefined
) => {
  const filtered = filter(metricsList, { name: metricName })
  const findPredicate = (item: Metric2) => {
    const picked = pick(Object.fromEntries(tags || []), ['url', 'method', 'status'])
    const tagMatches = map(picked, (key, value) => {
      if (item.tags instanceof Map && item.tags?.has(key)) {
        return item.tags?.get(key) === value
      }
      return undefined
    })
    // TODO - fix how to handle placeholder tag
    const falseMatches = tagMatches.filter((m) => m === undefined)
    if (falseMatches.length === tagMatches.length) {
      return true
    }
    return tagMatches.length === 0 || every(tagMatches)
  }
  return find(filtered, findPredicate)
}

export const getTypeFromVariableQueryEnum = (type: VariableQueryType) => {
  switch (type) {
    case VariableQueryType.ORGANIZATIONS:
      return 'organizations'
    case VariableQueryType.PROJECTS:
      return 'projects'
    case VariableQueryType.TESTS:
      return 'tests'
    default:
      return 'test runs'
  }
}

export const getEnumFromMetricType = (type: string) => {
  switch (type) {
    case 'counter':
      return MetricType.COUNTER
    case 'gauge':
      return MetricType.GAUGE
    case 'rate':
      return MetricType.RATE
    default:
      return MetricType.TREND
  }
}

export const getTypeFromMetricEnum = (type: MetricType): MetricTypeName => {
  switch (type) {
    case MetricType.COUNTER:
      return 'counter'
    case MetricType.GAUGE:
      return 'gauge'
    case MetricType.RATE:
      return 'rate'
    default:
      return 'trend'
  }
}

export const getUnitFromMetric = (metric: Metric, aggregation?: string) => {
  switch (metric.type) {
    case MetricType.COUNTER:
      if (includes(['data_received', 'data_sent'], metric.name)) {
        return aggregation === 'rps' ? 'bps' : 'bytes'
      }
      return aggregation === 'rps' ? 'rps' : ''
    case MetricType.TREND:
      return metric.contains === 'time' ? 'ms' : ''
    case MetricType.RATE:
      return '/s'
    case MetricType.GAUGE:
      if (includes(['vus', 'vus_max'], metric.name)) {
        return 'VUs'
      }
      return ''
    default:
      return ''
  }
}

export const reduceByObjectProp = <T, K extends keyof T>(listOfObjects: T[], propName: K): Array<T[K]> =>
  listOfObjects.reduce((accumulator: Array<T[K]>, item: T) => {
    return [...accumulator, item[propName]]
  }, [])

export const toTitleCase = (s: string) => {
  return s.toLowerCase().replace(/\w\S*/g, function (t: string) {
    const i = t.indexOf('url')
    if (i === 0) {
      return t.substr(0, 3).toUpperCase() + t.substr(3).toLowerCase()
    }
    return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase()
  })
}

export function getMetricTypeEnumById(id: string, metricList: Metric2[] = []): MetricType {
  const metric = metricList.find((metric) => metric.id === id)!
  return metric.type ?? MetricType.TREND
}

export function getMetricTypeById(id: string, metricList: Metric2[] = []): MetricTypeName {
  return getTypeFromMetricEnum(getMetricTypeEnumById(id, metricList))
}

export function getStaticPanelConfig(
  ds: K6DataSource,
  queryType: QueryType,
  testRun: TestRun,
  itemId?: string,
  itemName?: string,
  itemValue?: number,
  fieldConfig?: FieldConfigSource
) {
  const panel = getPanelJson(queryType)
  panel.fieldConfig = merge(panel.fieldConfig, fieldConfig)

  if (queryType === QueryType.THRESHOLDS) {
    panel.fieldConfig.defaults.thresholds!.steps[1]!.value = itemValue!
    panel.fieldConfig.overrides[0]!.properties[0]!.value = itemName!
  }

  panel.datasource = {
    type: ds.type,
    uid: ds.uid,
  }

  for (const target of panel.targets) {
    target.qtype = queryType
    target.queryType = queryType
    target.testRunId = testRun.id
    target.projectId = testRun.project_id
    target.testId = testRun.test_id
    target.uid = itemId ?? ''
  }

  // Vus is the first query in the URLS chart - the Vus call doesn't pass a uid
  if (queryType === QueryType.URLS) {
    panel.targets[0]!.qtype = QueryType.METRIC
    panel.targets[0]!.queryType = QueryType.METRIC
    panel.targets[0]!.uid = ''
  }

  return panel
}

export function getPanelJson(queryType: QueryType) {
  if (queryType === QueryType.CHECKS) {
    return cloneDeep(checksPanel)
  } else if (queryType === QueryType.THRESHOLDS) {
    return cloneDeep(thresholdPanel)
  }
  return cloneDeep(testRunPanel)
}

export function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export function getTagValuesForMetric(
  metricName: string,
  tagName: string,
  tagValues: string[],
  currentTags: SeriesTag[],
  metricsList: Metric2[]
) {
  const specialTags = ['url', 'method', 'status']
  if (!specialTags.includes(tagName) || (currentTags && currentTags.length === 0)) {
    return tagValues
  }

  return uniq(
    metricsList
      .filter((metric) => metric.name === metricName)
      .reduce((result, value) => {
        const tagsMap = new Map()

        Object.keys(value.tags).forEach((property: any) => {
          tagsMap.set(property, value.tags[property])
        })

        const currentTagsToCheck = getCurrentTagsToCheck(tagName, specialTags, currentTags)
        const tagsToCheck = [...currentTagsToCheck.keys()]

        if (isEmpty(currentTagsToCheck)) {
          if (tagsMap.get(tagName)) {
            result.push(tagsMap.get(tagName))
          }
        } else {
          if (findTagMatch(tagsToCheck, currentTagsToCheck, tagsMap)) {
            result.push(tagsMap.get(tagName))
          }
        }

        return result
      }, [] as string[])
  )
}

function getCurrentTagsToCheck(tagName: string, specialTags: string[], currentTags: SeriesTag[]): Map<string, string> {
  return currentTags.reduce((result, value) => {
    if (pull(specialTags, tagName).includes(value.key) && value.value !== '') {
      result.set(value.key, value.value)
    }
    return result
  }, new Map<string, string>())
}

function findTagMatch(tagsToCheck: string[], currentTagsToCheck: Map<string, string>, tagsObj: Map<string, string>) {
  return every(
    tagsToCheck.reduce((result, value) => {
      if (tagsObj.has(value) && currentTagsToCheck.has(value) && tagsObj.get(value) === currentTagsToCheck.get(value)) {
        result.push(true)
      } else {
        result.push(false)
      }
      return result
    }, [] as boolean[])
  )
}

export function getDuration(duration: number) {
  if (duration === -1) {
    return '-'
  }
  const date = new Date(duration * 1000)
  const parts = [
    [date.getMinutes(), 'min'],
    [date.getSeconds(), 's'],
  ]

  return parts
    .filter(([value]) => Boolean(value))
    .map((pair) => pair.join(''))
    .join(' ')
}

export const extractDetailsFromZoneId = (zoneId: string) => {
  const [vendor, country, city] = zoneId.split(':')

  return {
    vendor,
    country: country?.toUpperCase(),
    city: capitalize(city),
  }
}

export const combineLoadZonesDistribution = (
  distribution: Array<[string, number]>,
  nodes: TestRunNode[],
  availableLoadZones: LoadZone[]
) => {
  return distribution.map((dist) => {
    const [zoneId, loadPercent] = dist
    const node = nodes.find((node) => node.load_zone_id === zoneId)
    const zone = availableLoadZones.find((zone) => zone.k6_load_zone_id === zoneId)
    const parsedDetails = extractDetailsFromZoneId(zoneId)

    const size = node?.size
    const publicIp = node?.public_ip
    const city = zone?.city || parsedDetails.city
    const country = zone?.country || parsedDetails.country
    const isPublic = !!zone?.public

    return {
      loadPercent,
      publicIp,
      size,
      city,
      country,
      isPublic,
      id: zoneId,
    }
  })
}

/* cspell:disable-next-line */
export const isTestSafe = (testRun: TestRun) => testRun.delete_status === DELETE_STATUS.NOEXPIRE
export const getDefaultProjectId = (account: Account) => {
  return account.additional_user_data[0].last_used_project_id
}
