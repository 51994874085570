import {
  DataFrame,
  EventBusSrv,
  FieldType,
  LoadingState,
  MutableDataFrame,
  PanelData,
  toDataFrame,
} from '@grafana/data'
import { PanelContext } from '@grafana/ui'
import { UseQueryResult } from '@tanstack/react-query'
import { formatISO, parseISO } from 'date-fns'
import { isTestActive } from 'utils/testRun'
import { TestRun } from 'types'
import { createTimeRange, toTimestamp } from 'utils/date'
import { ChartAnnotation, ChartSeries } from './Chart.types'
import { toGraphFieldConfig } from './fieldConfigs'

const getMaxTimestamp = (testRun: TestRun, series: Array<UseQueryResult<ChartSeries>>) => {
  const started = parseISO(testRun.started).getTime()

  const timestamps = series.map((series) => {
    const values = series?.data?.data?.values || []
    const last = values[values.length - 1]?.timestamp

    return last !== undefined ? parseISO(last).getTime() : started
  })

  return formatISO(Math.max(started, ...timestamps))
}

const toLoadingState = (isLoading: boolean, isActive: boolean) => {
  if (isLoading) {
    return LoadingState.Loading
  }

  if (isActive) {
    return LoadingState.Streaming
  }

  return LoadingState.Done
}

export const toPanelData = (
  testRun: TestRun,
  series: Array<UseQueryResult<ChartSeries>>,
  annotations: ChartAnnotation[]
): PanelData => {
  const isActive = isTestActive(testRun)
  const isLoading = series.some((series) => series.isLoading)

  const seriesFrames: DataFrame[] = series.map(({ data }) => {
    // This should not happen, since we are using placeholderData in our useTimeSeries hook.
    if (!data) {
      return {
        fields: [],
        length: 0,
      }
    }

    const values = data.data?.values ?? []

    const frame = new MutableDataFrame()

    frame.name = data.label

    frame.addField({
      name: 'Time',
      type: FieldType.time,
      values: values.map((value) => toTimestamp(value.timestamp)),
    })

    frame.addField({
      name: 'Value',
      type: FieldType.number,
      config: toGraphFieldConfig(data),
      values: values.map((value) => value.value),
    })

    return frame
  })

  const annotationFrames: DataFrame[] = annotations.map((annotation, index) => {
    // Type definition from here: https://github.com/grafana/grafana/blob/main/public/app/plugins/panel/timeseries/plugins/types.ts
    return toDataFrame([
      {
        time: parseISO(annotation.start).getTime(),
        timeEnd: parseISO(annotation.end).getTime(),
        title: annotation.label,
        color: annotation.color,
        isRegion: true,
      },
    ])
  })

  // When streaming we don't want to use the `ended` property. What happens is this:
  //
  // 1. The test run is re-fetched
  // 2. The x-axis of the chart is updated creating a black space to the right,
  //    because none of the time series will have reached that far.
  // 3. Time passes...
  // 4. Time series are re-fetched, finally filling in the blank space.
  //
  // I suspect that this was less of a problem with Highcharts, because everything
  // was smoothly animated.
  const rangeEnd = isActive ? getMaxTimestamp(testRun, series) : testRun.ended

  return {
    state: toLoadingState(isLoading, isActive),
    annotations: annotationFrames,
    series: seriesFrames,
    timeRange: createTimeRange(testRun.started, rangeEnd),
  }
}

const eventBus = new EventBusSrv()

export const panelContext: PanelContext = {
  eventBus,
  canAddAnnotations: () => false,
  canEditAnnotations: () => false,
  canDeleteAnnotations: () => false,
}
