import React, { ReactNode, useEffect, useState } from 'react'

interface DownloadLinkProps {
  data: string
  children: ReactNode
  contentType?: string
  filename: string
}

export const DownloadLink = ({
  children,
  data,
  filename,
  contentType = 'application/javascript',
}: DownloadLinkProps) => {
  const [href, setHref] = useState<string | undefined>()

  useEffect(() => {
    const blob = new Blob([data], { type: contentType })
    const url = URL.createObjectURL(blob)
    setHref(url)

    return () => {
      URL.revokeObjectURL(url)
    }
  }, [contentType, data])

  if (!href) {
    return null
  }

  return (
    <a href={href} download={filename} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}
