import React from 'react'
import { Button } from '@grafana/ui'
import { InsightsStateContainer } from './InsightsStateContainer'

interface InsightsErrorProps {
  onRetry: () => void
}

export const InsightsError = ({ onRetry }: InsightsErrorProps) => {
  const handleRetryClick = () => {
    onRetry()
  }

  return (
    <InsightsStateContainer>
      <p>An error occurred while fetching insights.</p>
      <p>
        <Button variant="secondary" onClick={handleRetryClick}>
          Retry
        </Button>
      </p>
    </InsightsStateContainer>
  )
}
