import { Scenario } from 'types'
import { exhaustive } from 'utils/typescript'
import { ProtocolType } from '../RunOverview.types'

export const filterScenariosByProtocol = (scenarios: Scenario[] = [], protocol: ProtocolType) => {
  return scenarios.filter((scenario) => {
    switch (protocol) {
      case 'http':
        return scenario.http_metric_summary.requests_count !== null

      case 'ws':
        return scenario.ws_metric_summary.sessions !== null

      case 'grpc':
        return scenario.grpc_metric_summary.requests_count !== null

      default:
        return exhaustive(protocol)
    }
  })
}
