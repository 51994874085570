import React, { useEffect, useMemo } from 'react'
import {
  TestBuilder,
  useGetTestBuilderTest,
  TestBuilderThemeProvider,
  grafanaThemeToMuiTheme,
  TestMetaInformation,
  useGetUnsavedChanges,
} from '@grafana/k6-test-builder'
import styled, { useTheme } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { HorizontalGroup, Button, Input } from '@grafana/ui'
import { useForm, SubmitHandler } from 'react-hook-form'
import { format } from 'date-fns'

import { Test } from 'types'
import { useCreateTest } from 'data/useCreateTest'
import { useAppContext } from 'appContext'
import { useUpdateTest } from 'data/useUpdateTest'
import { useValidateOptions } from 'data/useValidateOptions'
import { useLoadZones } from 'data/useLoadZones'
import { useHasCloudExecution } from 'data/usePermissions'
import { useRunTest } from 'data/useRunTest'
import { RunButtonController } from 'components/RunButtonController'
import { useFormStatus } from 'hooks/useFormStatus'
import { FormStatusMessage } from 'components/FormStatusMessage/FormStatusMessage'
import { DocsLink } from './DocsLink'
import { TestBuilderIllustration } from 'components/TestBuilderIllustration'
import { CodeEditorWrapper } from './CodeEditorWrapper'
import { parseBuilderConfig } from '../Builder.utils'
import { useConfirmPromptOnLeave } from 'hooks/useConfirmPrompt'
import { routeMap } from 'routeMap'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const BuilderWrapper = styled.div`
  flex-grow: 1;

  input[disabled] {
    background-color: transparent;
  }
`

const TopBar = styled.div`
  background: ${({ theme }) => theme.components.panel.background};
  border: 1px solid ${({ theme }) => theme.colors.border.weak};
  padding: 15px;
  padding-bottom: 5px;
`

type FormFields = {
  name: string
}

type FormProps = {
  testId: number | string
  isNewTest: boolean
  test?: Test
}

export const Form = ({ testId, isNewTest, test: _test }: FormProps) => {
  // Memoize test to avoid jumping to first request on update
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const test = useMemo(() => _test, [])

  const theme = useTheme()
  const history = useHistory()
  const { project } = useAppContext()
  const getTestBuilderTest = useGetTestBuilderTest()
  const [isBuilderDirty, setIsBuilderDirty] = useGetUnsavedChanges()

  const { data: loadZones } = useLoadZones()

  const { mutateAsync: updateTest, error: updateError } = useUpdateTest()
  const { mutateAsync: createTest, error: createError } = useCreateTest()
  const { mutateAsync: validateOptions } = useValidateOptions()
  const { mutateAsync: runTest, error: runError } = useRunTest()

  const hasCloudExecution = useHasCloudExecution()

  const {
    register,
    handleSubmit,
    setError,
    resetField,
    formState: { errors, isSubmitting, isDirty: isFormDirty, isValid },
  } = useForm<FormFields>({
    defaultValues: {
      name: test?.name || `Test builder (${format(new Date(), 'dd/MM/yyyy-HH:mm:ss')})`,
    },
  })

  const isDirty = isFormDirty || !!isBuilderDirty

  const formStatus = useFormStatus({
    errors,
    isSubmitting,
    test,
    isNewTest,
    isUnsaved: isDirty,
    isBuilderTest: true,
  })

  useEffect(() => {
    const saveErrors = [createError, updateError]
    const saveError = saveErrors.find(Boolean)

    if (saveError) {
      const message = saveError.data.error.field_errors.non_field_errors?.[0] || 'Validation failed'
      setError('name', { message })
      return
    }

    if (runError) {
      throw runError
    }
  }, [createError, updateError, runError, setError])

  useEffect(() => {
    // Need to clear builder's dirty state when test changes, otherwise
    // it will say "unsaved" for newly created tests
    setIsBuilderDirty(false)
  }, [test, setIsBuilderDirty])

  // Show builder errors when opening an existing test
  useEffect(() => {
    if (isNewTest) {
      return
    }

    getTestBuilderTest().then(([_, err]) => {
      err && setError('name', { message: err?.message })
    })
  }, [getTestBuilderTest, setError, isNewTest])

  useConfirmPromptOnLeave({
    show: isDirty && !isSubmitting,
  })

  const validateTest = async () => {
    const [testPayload, builderError] = await getTestBuilderTest()

    if (builderError) {
      setError('name', { message: builderError?.message })
      return
    }

    const options = parseBuilderConfig(testPayload.request_builder_config)

    if (!options) {
      setError('name', { message: 'Invalid options' })
      return
    }

    const optionsValidationError = await validateOptions({
      options,
      project_id: project.id,
    })

    if (optionsValidationError) {
      setError('name', { message: optionsValidationError.data.error.message })
      return
    }

    return true
  }

  const saveTest = async ({ name }: { name: string }) => {
    const [testPayload, _error] = await getTestBuilderTest()

    const payload = {
      ...testPayload,
      project_id: project.id,
      name: name,
    }

    // Need to create new object for Typescript to see that payload.id is actually not null
    const saveFn = payload.id ? updateTest.bind(null, { ...payload, id: payload.id }) : createTest.bind(null, payload)

    const response = await saveFn()

    // Use resetField instead of reset to preserve isSubmitting state
    resetField('name', { defaultValue: response.name })
    setIsBuilderDirty(false)

    return response
  }

  const handleSave: SubmitHandler<FormFields> = async (data) => {
    const response = await saveTest(data)
    validateTest()
    isNewTest && redirectToCreatedTest(response.id)
  }

  const handleSaveAndRun: SubmitHandler<FormFields> = async (data) => {
    if (isDirty) {
      const response = await saveTest(data)
      const valid = await validateTest()

      if (!valid && isNewTest) {
        return redirectToCreatedTest(response.id)
      }

      return valid && runTestAndRedirect(response.id)
    }

    return runTestAndRedirect(+testId)
  }

  const runTestAndRedirect = async (testId: number) => {
    const run = await runTest(testId)
    run && history.push(routeMap.testRun(run.id))
  }

  const redirectToCreatedTest = (testId: number) => {
    history.replace(routeMap.builder(testId))
  }

  const isRunButtonEnabled = () => {
    if (isSubmitting) {
      return false
    }

    // Disable run button for new untouched tests
    if (isNewTest && !isBuilderDirty) {
      return false
    }

    return isValid || isBuilderDirty
  }

  const saveButtonLabel = isNewTest ? 'Create' : 'Save'
  const saveAndRunButtonLabel = isDirty ? `${saveButtonLabel} and Run` : 'Run test'
  const saveButtonEnabled = isDirty && !isSubmitting
  const runButtonEnabled = isRunButtonEnabled()
  // @ts-ignore For some reason some theme attributes are optional in Grafana's types
  const builderTheme = grafanaThemeToMuiTheme(theme)

  return (
    <Wrapper>
      <TestBuilderThemeProvider theme={builderTheme}>
        <TopBar>
          <form onSubmit={handleSubmit(handleSave)}>
            <HorizontalGroup justify="space-between">
              <Input width={50} {...register('name', { required: { value: true, message: 'name is required' } })} />
              <HorizontalGroup>
                <Button type="submit" disabled={!saveButtonEnabled}>
                  {saveButtonLabel}
                </Button>
                <RunButtonController test={test}>
                  {({ isDisabled }) => (
                    <Button onClick={handleSubmit(handleSaveAndRun)} disabled={!runButtonEnabled || isDisabled}>
                      {saveAndRunButtonLabel}
                    </Button>
                  )}
                </RunButtonController>
              </HorizontalGroup>
            </HorizontalGroup>
          </form>
          <HorizontalGroup justify="space-between" height="auto">
            <FormStatusMessage {...formStatus} />
            <TestMetaInformation />
          </HorizontalGroup>
        </TopBar>
        <BuilderWrapper>
          <TestBuilder
            k6Test={test}
            availableLoadZones={loadZones}
            hasCloudExecution={hasCloudExecution}
            ScriptEditorComponent={CodeEditorWrapper}
            DocsLinkComponent={DocsLink}
            TestBuilderIconComponent={TestBuilderIllustration}
            readonlyScriptScenarios={true} // Disabled to test CodeEditor with editable ranges
          />
        </BuilderWrapper>
      </TestBuilderThemeProvider>
    </Wrapper>
  )
}
