import React from 'react'
import { TableColumn } from 'react-data-table-component'
import { DataTable } from 'components/DataTable'
import { Scenario, TestRun } from 'types'
import { separatorFormatter, timing } from 'utils/formatters'
import { MetricBuilder, TagQueryBuilder } from 'utils/metrics'
import { VUS_METRIC, WS_SESSION_DURATION, WS_SESSION_RATE } from 'constants/metrics'
import { Chart } from 'components/Chart'
import { TooltipCell } from 'components/DataTable/TooltipCell'

export const columns: Array<TableColumn<Scenario>> = [
  {
    name: 'Scenario',
    selector: (row) => row.name,
    sortable: true,
    sortField: 'name',
    cell: ({ name }) => <TooltipCell>{name}</TooltipCell>,
  },
  {
    name: 'Session count',
    selector: (row) => separatorFormatter(row.ws_metric_summary.sessions),
    sortable: true,
    sortField: 'sessions',
  },
  {
    name: 'P95 connecting time',
    selector: (row) => timing(row.ws_metric_summary.connecting.p95 || 0),
    sortable: true,
    sortField: 'connecting.p95',
  },

  {
    name: 'P95 session duration',
    selector: (row) => timing(row.ws_metric_summary.session_duration.p95 || 0),
    sortable: true,
    sortField: 'session_duration.p95',
  },
]

const ChartWrapper = ({ data: { name: scenarioName }, testRun }: { data: Scenario; testRun?: TestRun }) => {
  const scenarioTag = new TagQueryBuilder().equal('scenario', scenarioName).build()

  const metrics = [
    VUS_METRIC,
    new MetricBuilder(WS_SESSION_RATE).withTags(scenarioTag).build(),
    new MetricBuilder(WS_SESSION_DURATION).withTags(scenarioTag).build(),
  ]

  return <Chart title={`WebSockets ${scenarioName}`} metrics={metrics} testRun={testRun!} />
}

export const WebSocketsTable = ({ scenarios, testRun }: { scenarios: Scenario[]; testRun: TestRun }) => {
  return (
    <DataTable
      data={scenarios}
      columns={columns}
      expandableRowsHideExpander
      highlightOnHover
      expandableRows
      expandOnRowClicked
      expandableRowsComponent={ChartWrapper}
      expandableRowsComponentProps={{ testRun }}
    />
  )
}
