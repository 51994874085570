// @ts-ignore
import appEvents from 'grafana/app/core/app_events'
import { AppEvents } from '@grafana/data'

const severityMapping = {
  success: AppEvents.alertSuccess,
  warning: AppEvents.alertWarning,
  error: AppEvents.alertError,
}

export const showAlert = (message: string, severity: keyof typeof severityMapping = 'success') => {
  appEvents.emit(severityMapping[severity], [message])
}
