import React from 'react'

import { useHasUserWriteAccess } from 'data/usePermissions'
import { LinkButton } from 'components/LinkButton'
import { routeMap } from 'routeMap'

export const CreateFromScriptButton = ({ scriptValue }: { scriptValue: string }) => {
  const hasUserWriteAccess = useHasUserWriteAccess()

  if (!hasUserWriteAccess) {
    return null
  }

  return (
    <LinkButton to={{ pathname: routeMap.script('new'), state: { scriptValue } }} button={{ variant: 'secondary' }}>
      Create test from script
    </LinkButton>
  )
}
