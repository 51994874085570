import React, { ReactNode } from 'react'
import { DOCS_ARTICLE_LINKS, DocsArticleLink } from '@grafana/k6-test-builder'

import { ExternalLink } from 'components/ExternalLink'

export const DocsLink = ({ article, children }: { article: DocsArticleLink; children: ReactNode }) => {
  const docsLink = 'https://k6.io'
  const articleLink = DOCS_ARTICLE_LINKS[article] || ''
  return <ExternalLink url={`${docsLink}${articleLink}`}>{children}</ExternalLink>
}
