import { useTheme2 } from '@grafana/ui'
import { ChartAnnotation } from 'components/Chart/Chart.types'
import { useMemo } from 'react'
import { InsightSegment, Segments } from 'types/insights/http'

const toSegmentAnnotation = (label: string, color: string, segment: InsightSegment): ChartAnnotation => {
  return {
    label,
    color,
    start: segment.start,
    end: segment.end,
  }
}

export const useSegmentAnnotations = <T extends InsightSegment>(
  segments: Segments<T>,
  formatter: (segment: T, index: number) => string
) => {
  const { visualization } = useTheme2()

  return useMemo(() => {
    return [
      toSegmentAnnotation('Training data.', visualization.getColorByName('green'), segments.training),
      ...segments.detected_problems.map((problem, index) =>
        toSegmentAnnotation(formatter(problem, index), visualization.getColorByName('red'), problem)
      ),
    ]
  }, [visualization, segments, formatter])
}
