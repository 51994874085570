import React from 'react'
import styled from 'styled-components'

import { Select } from '@grafana/ui'
import { SelectableValue } from '@grafana/data'

export function FilterPicker<T>({
  options,
  onChange,
}: {
  options: Array<SelectableValue<T>>
  onChange: (value: SelectableValue<T>) => void
}) {
  return (
    <Container>
      <Select options={options} onChange={onChange} value={null} placeholder="Add filter" width={200} />
    </Container>
  )
}

const Container = styled.div`
  width: 200px;
`
