import { ComponentClass } from 'react'
import { AppPlugin, AppRootProps } from '@grafana/data'
import { RootPage } from './RootPage'
import { K6AppSettings } from './types'
import { ConfigurationPage } from 'config/ConfigurationPage'
import { requiresCustomConfigPage } from 'utils'

export const plugin = new AppPlugin<K6AppSettings>().setRootPage(RootPage as unknown as ComponentClass<AppRootProps>)

if (requiresCustomConfigPage()) {
  plugin.addConfigPage({
    title: 'Configuration',
    icon: 'apps',
    body: ConfigurationPage,
    id: 'config',
  })
}
