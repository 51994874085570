import { Modal } from '@grafana/ui'
import styled from 'styled-components'

export const NoteModal = styled(Modal)`
  width: 560px;

  textarea {
    padding: ${({ theme }) => theme.spacing(1)};
    height: 200px;
  }
`
