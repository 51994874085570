import {
  TestRunHttpFilterBy,
  TestRunThresholdsFilterBy,
  TestRunChecksFilterBy,
  TestRunGRPCFilterBy,
  TestRunWebSocketsFilterBy,
} from 'types'

export const HTTP_FILTER_OPTIONS = [
  { label: 'URL', value: TestRunHttpFilterBy.URL },
  { label: 'Status', value: TestRunHttpFilterBy.Status },
  { label: 'Method', value: TestRunHttpFilterBy.Method },
  { label: 'Scenario', value: TestRunHttpFilterBy.Scenario },
  { label: 'Expected response', value: TestRunHttpFilterBy.ExpectedResponse },
]

export const THRESHOLDS_FILTER_OPTIONS = [
  { label: 'Name', value: TestRunThresholdsFilterBy.Name },
  { label: 'Tainted', value: TestRunThresholdsFilterBy.Tainted },
]

export const CHECKS_FILTER_OPTIONS = [{ label: 'Name', value: TestRunChecksFilterBy.Name }]

export const GRPC_FILTER_OPTIONS = [
  { label: 'URL', value: TestRunGRPCFilterBy.URL },
  { label: 'Status', value: TestRunGRPCFilterBy.Status },
  { label: 'Scenario', value: TestRunGRPCFilterBy.Scenario },
]

export const WEB_SOCKETS_FILTER_OPTIONS = [
  { label: 'URL', value: TestRunWebSocketsFilterBy.URL },
  { label: 'Status', value: TestRunWebSocketsFilterBy.Status },
  { label: 'Scenario', value: TestRunWebSocketsFilterBy.Scenario },
]
