import { useMemo } from 'react'
import { colorManipulator, GrafanaTheme2, ThemeColors } from '@grafana/data'
import { createTheme, Theme } from 'react-data-table-component'
import { useTheme2 } from '@grafana/ui'
import { DeepPartial } from 'utils/typescript'

export const getHoverHighlightColor = (isDark: boolean, colors: ThemeColors) => {
  return isDark
    ? colorManipulator.lighten(colors.background.primary, colors.hoverFactor)
    : colorManipulator.darken(colors.background.primary, colors.hoverFactor)
}

const createTableTheme = ({ isDark, colors }: GrafanaTheme2) => {
  createTheme<DeepPartial<Theme>>('grafana-theme', {
    text: {
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    },
    background: {
      default: colors.background.primary,
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: colors.border.weak,
    },
    highlightOnHover: {
      default: getHoverHighlightColor(isDark, colors),
      text: colors.text.maxContrast,
    },
    // button: {
    //   default: '#2aa198',
    //   hover: 'rgba(0,0,0,.08)',
    //   focus: 'rgba(255,255,255,.12)',
    //   disabled: 'rgba(255, 255, 255, .34)',
    // },
    button: {
      default: 'transparent',
      hover: 'transparent',
    },
  })
}

export const useTableTheme = () => {
  const theme = useTheme2()

  return useMemo(() => {
    createTableTheme(theme)

    return 'grafana-theme'
  }, [theme])
}
