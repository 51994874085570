import { useCallback, useMemo, useState } from 'react'
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types'

export type DataTableSortValue = {
  sortBy: string | undefined | number | null
  sortOrder: 'desc' | 'asc' | undefined
}

const SORT_ORDER_CHOICES = ['desc', 'asc', undefined] as const

export const useSorting = (defaultSorting: DataTableSortValue) => {
  const [sort, setSort] = useState<DataTableSortValue>(defaultSorting)

  const orderBy = useMemo(() => {
    return sort && sort.sortBy && sort.sortOrder ? `${sort.sortBy} ${sort.sortOrder}` : undefined
  }, [sort])

  const onSortHandler = useCallback(<T>(column: TableColumn<T>) => {
    setSort((prevState) => {
      const sortBy = column.sortField

      if (prevState?.sortBy !== sortBy) {
        return {
          sortBy,
          sortOrder: 'desc',
        }
      }

      const nextSortOrderIndex = SORT_ORDER_CHOICES.indexOf(prevState?.sortOrder) + 1
      const nextSortOrder = SORT_ORDER_CHOICES[nextSortOrderIndex]

      if (!nextSortOrder) {
        return {
          sortBy: undefined,
          sortOrder: undefined,
        }
      }

      return {
        sortBy,
        sortOrder: nextSortOrder,
      }
    })
  }, [])

  return { orderBy, onSortHandler }
}
