import { useEffect, useRef } from 'react'

import { useQueries, UseQueryResult } from '@tanstack/react-query'
import { BG_TASKS_POLL_INTERVAL } from '../../constants'
import { useDatasource } from 'datasourceContext'
import { isTestActive } from 'utils/testRun'
import { TestRun, TimeSeries } from 'types'
import { ChartMetric, ChartSeries } from './Chart.types'

interface UseTimeSeriesArgs {
  testRun: TestRun
  metrics: ChartMetric[]
  enabled?: boolean
}

export const useTimeSeries = ({
  testRun,
  metrics,
  enabled = true,
}: UseTimeSeriesArgs): Array<UseQueryResult<ChartSeries>> => {
  const { ds } = useDatasource()

  const isActive = isTestActive(testRun)
  const needsRefetching = useRef(isActive)

  const queries = useQueries({
    queries: metrics.map((metric) => ({
      queryKey: ['time-series', testRun.id, metric.query],
      queryFn: () => ds.fetchRunTimeSeries(testRun.id, metric.query),
      refetchInterval: isActive ? BG_TASKS_POLL_INTERVAL : 0,
      placeholderData: () => null,
      select(data: TimeSeries | null) {
        return { ...metric, data }
      },
      enabled,
    })),
  })

  useEffect(() => {
    // Refetch one last time if the test run finished streaming.
    if (needsRefetching.current && !isActive) {
      queries.forEach((query) => query.refetch({ cancelRefetch: true }))
      needsRefetching.current = false
    }
  }, [queries, isActive])

  return queries
}
