import React from 'react'

import { getUserOS } from 'utils/platform'
import { CodeSnippet } from 'components/CodeSnippet'
import { DocLink, docs } from 'components/DocLink'
import { DownloadLink } from 'components/DownloadLink'
import { Instructions } from 'components/Instructions'
import { getLoginCommand, getProjectSnippet, getRunCommands, installationTabs } from './CLIRunGuide.utils'
import { GuideContainer, Text } from './CLIRunGuide.styles'
import { useAppContext } from 'appContext'

interface CLIRunGuideProps {
  projectId: number
  testName: string
  script: string
  scriptName: string
}

export const CLIRunGuide = ({ projectId, script, scriptName, testName }: CLIRunGuideProps) => {
  const { account } = useAppContext()

  return (
    <GuideContainer>
      <Instructions>
        <Instructions.Step title="Install k6 on your platform">
          <CodeSnippet code="" lang="plain" tabs={installationTabs} initialTab={getUserOS()} />
          <Text>
            For additional ways of installing, visit our{' '}
            <a href="https://k6.io/docs/getting-started/installation/" target="_blank" rel="noreferrer">
              installation docs
            </a>
          </Text>
        </Instructions.Step>

        <Instructions.Step title="Login to your account">
          <CodeSnippet code={getLoginCommand(account.token.key)} lang="plain" />
        </Instructions.Step>

        <Instructions.Step title={!!script ? 'Download the test script' : 'Create a test script'}>
          {!!script && (
            <Text>
              Download the{' '}
              <DownloadLink data={script} filename={scriptName}>
                test script
              </DownloadLink>
              .
            </Text>
          )}

          <Text>
            In the{' '}
            <a href="https://k6.io/docs/using-k6/k6-options/" target="_blank" rel="noreferrer">
              script options
            </a>
            , you can specify the test name and which project the test should run for.
          </Text>
          <CodeSnippet code={getProjectSnippet(testName, projectId)} lang="js" dedent={false} />
        </Instructions.Step>

        <Instructions.Step title="Run cloud test">
          <CodeSnippet code="" tabs={getRunCommands(scriptName)} lang="plain" />
          <Text>
            If you need help understanding the results, see our docs on{' '}
            <DocLink article={docs.insights.overview}>result analysis</DocLink>.
          </Text>
        </Instructions.Step>
      </Instructions>
    </GuideContainer>
  )
}
