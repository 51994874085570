import { TestRun } from 'types'
import { timeFormatter } from 'utils/formatters'

export const getConfig = (row: TestRun) => {
  const vus = `${row.vus} VUS`

  if (row.duration === -1) {
    return vus
  }

  return `${vus}, ${timeFormatter(row.duration)}`
}
