import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types'
import React from 'react'
import { Icon } from '@grafana/ui'
import {
  EmptyTableColumnHead,
  EmptyTableHead,
  EmptyTableRow,
  DataTableEmptyStateWrapper,
} from './DataTableEmptyState.styles'

interface DataTableEmptyStateProps<T> {
  columns: Array<TableColumn<T>>
  message: string
}
export const DataTableEmptyState = <T,>({ columns, message }: DataTableEmptyStateProps<T>) => {
  return (
    <DataTableEmptyStateWrapper>
      <EmptyTableHead>
        {columns.map(({ name, id, sortable }) => (
          <EmptyTableColumnHead key={id}>
            {name} {sortable ? <Icon name="angle-right" /> : null}
          </EmptyTableColumnHead>
        ))}
      </EmptyTableHead>
      <EmptyTableRow>{message}</EmptyTableRow>
    </DataTableEmptyStateWrapper>
  )
}
