import React from 'react'
import { ToolbarButtonRow, ToolbarButton } from '@grafana/ui'

import { TestRun } from 'types'
import { ParsedLog } from 'types/logs'
import { LOGS_LIMIT } from 'constants/logs'
import { isTestActive } from 'utils/testRun'
import { DocLink, docs } from 'components/DocLink'

interface LogControlsProps {
  isEmptyQuery: boolean
  logs: ParsedLog[]
  page: number
  testRun: TestRun
  onPageChange: (page: number) => void
}

export const LogControls = ({ isEmptyQuery, logs, page, testRun, onPageChange }: LogControlsProps) => {
  const isMaxLogCount = logs.length === LOGS_LIMIT
  const isTestRunActive = isTestActive(testRun)

  function handleOlderClick() {
    onPageChange(page + 1)
  }

  function handleNewerClick() {
    onPageChange(page - 1)
  }

  return (
    <>
      {!isEmptyQuery && (
        <ToolbarButtonRow>
          <ToolbarButton variant="primary" disabled={isTestRunActive || !isMaxLogCount} onClick={handleOlderClick}>
            Older
          </ToolbarButton>
          <ToolbarButton variant="primary" disabled={isTestRunActive || page === 0} onClick={handleNewerClick}>
            Newer
          </ToolbarButton>
        </ToolbarButtonRow>
      )}

      <div>
        Read more about Logs in the <DocLink article={docs.logs.main}>docs</DocLink>.
      </div>
    </>
  )
}
