import { Card } from '@grafana/ui'
import React from 'react'
import styled from 'styled-components'

import { isTopNavEnabled } from 'utils/topnav'

interface SummaryStatisticProps {
  label: string
  value: string
  unit: string
}

const StyledCard = styled(Card)`
  background-color: ${({ theme }) => isTopNavEnabled() && theme.colors.secondary.main};
`

export const Statistic = ({ label, value, unit }: SummaryStatisticProps) => {
  return (
    <StyledCard disabled>
      <Card.Heading>{label}</Card.Heading>
      <Card.Meta>
        <div>
          {value} {unit}
        </div>
      </Card.Meta>
    </StyledCard>
  )
}

export const EmptyStatistic = () => {
  return (
    <StyledCard disabled>
      <Card.Heading>&nbsp;</Card.Heading>
      <Card.Meta>&nbsp;</Card.Meta>
    </StyledCard>
  )
}
