import styled from 'styled-components'
import { Button } from '@grafana/ui'
import { DataTable } from 'components/DataTable'
import { TestRun } from 'types'

export const StatusCellBody = styled.span`
  pointer-events: none;
  display: inline-flex;
  align-items: center;
`

export const StatusCellText = styled.span<{ $marginLeft?: string }>`
  margin-left: ${({ $marginLeft = '10px' }) => $marginLeft};
`

export const CreateNoteButton = styled(Button)`
  padding: 0;
  border: none;
  background-color: transparent !important;
`

export const NoteCellBody = styled.div`
  width: 100%;
`

export const EditHighlight = styled.span`
  visibility: hidden;
`

export const EditNoteButton = styled(Button)`
  width: 100%;
  line-height: unset;
  color: unset;
  font-size: 13px;
  padding: 0;

  p {
    margin: 0 5px 0 0;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    width: calc(100% - 25px);
  }

  ${EditHighlight} {
    width: unset;
  }

  &:hover {
    background-color: transparent;
  }
`

export const RunsDataTable = styled(DataTable<TestRun>)`
  .rdt_TableRow:hover {
    ${EditHighlight} {
      visibility: visible;
      color: ${({ theme }) => theme.colors.primary.main};
    }
  }
`
