import { useDatasource } from 'datasourceContext'
import { useAppContext } from 'appContext'

import { useFetch } from './useFetch'
import { toOrgRolesQueryKey } from './queryKeys'

export const useOrgRoles = () => {
  const { ds } = useDatasource()
  const { orgId } = useAppContext()

  return useFetch(toOrgRolesQueryKey(orgId), () => ds.fetchOrgRoles(orgId as number), {
    placeholderData: [],
    enabled: !!orgId,
  })
}
