import React from 'react'
import styled from 'styled-components'
import { TestRun, TestRunFilter } from 'types'
import { isTopNavEnabled } from 'utils/topnav'

import { CustomFilter } from './CustomFilter'
import { FilterPicker } from './FilterPicker'

import { getIsFilterAllowedCustomValue } from './Filters.utils'

interface RunDetailsFiltersProps<T extends TestRunFilter> {
  filters: T[]
  options: Array<{ label: string; value: string }>
  run: TestRun
  onRemoveFilterClick: ({ by }: { by: T['by'] }) => void
  onAddFilterValue: ({ by, value }: { by: T['by']; value: string[] }) => void
  onAddNewFilterClick: ({ by, label }: { by: T['by']; label: string }) => void
}

export function RunDetailsFilters<T extends TestRunFilter>({
  filters,
  options,
  run,
  onRemoveFilterClick,
  onAddFilterValue,
  onAddNewFilterClick,
}: RunDetailsFiltersProps<T>) {
  return (
    <FiltersContainer>
      {filters.map((filter) => (
        <CustomFilter
          key={filter.by}
          filter={filter}
          run={run}
          allowCustomValue={getIsFilterAllowedCustomValue(filter.by)}
          onRemoveButtonClick={(by) => onRemoveFilterClick({ by })}
          onAddFilterValue={(value) => onAddFilterValue({ by: filter.by, value })}
        />
      ))}

      {options.length > 0 && (
        <FilterPicker
          options={options}
          onChange={(value) => {
            onAddNewFilterClick({ by: value.value as T['by'], label: value.label! })
          }}
        />
      )}
    </FiltersContainer>
  )
}

const FiltersContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
  display: flex;
  flex-wrap: wrap;
  background-color: ${({ theme }) => isTopNavEnabled() && theme.colors.background.secondary};

  > div {
    margin-right: ${({ theme }) => theme.spacing(2)};
    padding: ${({ theme }) => theme.spacing(1)} 0;
  }
`
