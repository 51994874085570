import React from 'react'
import { Tooltip, Button } from '@grafana/ui'

import { useHasUserWriteAccess } from 'data/usePermissions'
import { LinkButton } from './LinkButton'
import { routeMap } from 'routeMap'

export const CreateTestButton = () => {
  const hasUserWriteAccess = useHasUserWriteAccess()

  if (hasUserWriteAccess) {
    return <LinkButton to={routeMap.newTest}>New Test</LinkButton>
  }

  return (
    <Tooltip content="You don't have permission to create new tests in this project">
      <Button disabled={true}>New Test</Button>
    </Tooltip>
  )
}
