import styled from 'styled-components'

export const InsightAnalyzingMessage = styled.div`
  padding-top: 20px;

  font-size: 0.9rem;
  line-height: 21px;

  text-align: center;

  p {
    margin-bottom: 0;
  }
`
