import { ComponentType } from 'react'

export type DeepPartial<T> = T extends object ? { [P in keyof T]?: DeepPartial<T[P]> } : T

export type PropsOf<T extends ComponentType<any>> = T extends ComponentType<infer P> ? P : never

/**
 * Used to give a type error when not every possibility has been checked in e.g. a switch-statement.
 */
export const exhaustive = <T, R = never>(value: never, defaultValue?: R): R => {
  return defaultValue ?? (value as any as R)
}
