import React, { useState } from 'react'
import styled from 'styled-components'
import { Alert, IconButton } from '@grafana/ui'

import { useTimeout } from 'hooks/useTimeout'
import { copyToClipboard } from 'utils/events'
import { stringify } from 'utils/string'

interface ClipboardProps {
  className?: string
  data: unknown
  hasAlert?: boolean
  onCopy?: (str: string) => void
}

const ClipboardAlert = styled(Alert)`
  position: absolute;
  z-index: 1000;
  top: -75px;
  right: 5px;
  width: 300px;
`

export const Clipboard = ({ className, data, hasAlert = true, onCopy }: ClipboardProps) => {
  const [alert, setAlert] = useState(false)

  function handleCopy() {
    const str = stringify(data)
    copyToClipboard(str)
    onCopy?.(str)
    hasAlert && setAlert(true)
  }

  function handleRemoveAlert() {
    setAlert(false)
  }

  useTimeout(handleRemoveAlert, alert ? 3000 : null)

  return (
    <>
      <IconButton name="clipboard-alt" onClick={handleCopy} className={className} size="xl" />
      {alert && <ClipboardAlert title="Copied to Clipboard!" severity="success" onRemove={handleRemoveAlert} />}
    </>
  )
}
