import styled from 'styled-components'

export const CollapsableSectionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  margin: 0 16px;

  svg {
    display: block;
  }
`

export const CollapsableSectionHeaderRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 0;
  font-size: 1rem;
`

export const CollapsableSectionLabel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
`

export const CollapsableSectionHeading = styled.div`
  font-weight: 600;
`

export const CollapsableSectionDescription = styled.div`
  font-size: 0.9rem;
`

export const CollapsableSectionActions = styled.div``
