import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { useAppContext } from 'appContext'
import { ProjectPage } from 'pages/ProjectPage'
import { TestPage } from 'pages/TestPage'
import { TestRunPage } from 'pages/TestRunPage'
import { Script } from 'pages/Script'
import { Builder } from 'pages/Builder'
import { NewTest } from 'pages/NewTest'
import { CLIGuide } from 'pages/CLIGuide'
import { routeMap } from './routeMap'
import { Settings } from 'pages/Settings'

export const Routes = () => {
  const { projectId } = useAppContext()

  return (
    <Switch>
      <Route path={routeMap.project(':projectId')}>
        <ProjectPage />
      </Route>
      <Route path={routeMap.script(':testId')}>
        <Script />
      </Route>
      <Route path={routeMap.builder(':testId')}>
        <Builder />
      </Route>
      <Route exact path={routeMap.newTest}>
        <NewTest />
      </Route>
      <Route path={routeMap.cliGuide}>
        <CLIGuide />
      </Route>
      <Route path={routeMap.test(':testId')}>
        <TestPage />
      </Route>
      <Route path={routeMap.testRun(':runId')}>
        <TestRunPage />
      </Route>
      <Route path={routeMap.settings}>
        <Settings />
      </Route>
      <Route path={routeMap.home} exact>
        <Redirect to={routeMap.project(projectId)} />
      </Route>
    </Switch>
  )
}
