import { LoadingContainer, LoadingContainerProps } from 'components/LoadingContainer'
import styled from 'styled-components'
// @ts-ignore
import ChartPlaceholderImage from 'assets/images/chart_placeholder.svg'

interface FixedWidthLoadingContainerProps extends LoadingContainerProps {
  $width: number
}

export const FixedWidthLoadingContainer = styled(LoadingContainer)<FixedWidthLoadingContainerProps>`
  width: ${({ $width }) => $width}px;
`

export const ChartPlaceholder = styled.div<{ $height: number }>`
  width: 100%;
  height: ${({ $height }) => $height}px;
  background-image: url(${ChartPlaceholderImage});
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: auto 100%;
`
