import React from 'react'
import { SelectableValue } from '@grafana/data'
import { InlineFormLabel, MultiSelect } from '@grafana/ui'

import { LogLevel } from 'types/logs'

type LogLevelSelectableValue = Array<SelectableValue<LogLevel>>

interface LogLevelSelectProps {
  value?: LogLevel[]
  onChange: (value: LogLevel[]) => void
}

const options: LogLevelSelectableValue = [
  { label: 'Debug', value: 'debug' },
  { label: 'Info', value: 'info' },
  { label: 'Error', value: 'error' },
  { label: 'Warning', value: 'warning' },
]

export function LogLevelSelect({ value, onChange }: LogLevelSelectProps) {
  function handleChange(values: LogLevelSelectableValue = []) {
    onChange(values.map(({ value }) => value!))
  }

  return (
    <div className="gf-form" data-testid="log-level-select">
      <InlineFormLabel className="query-keyword" htmlFor="level" width={'auto'}>
        Level
      </InlineFormLabel>
      <MultiSelect menuPlacement="bottom" options={options} inputId="level" value={value} onChange={handleChange} />
    </div>
  )
}
