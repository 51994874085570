import React from 'react'

import { TestRun } from 'types'
import { LogsFilter, ParsedLog } from 'types/logs'
import { hasActiveFilter } from '../LogsTab.utils'
import { EmptyQueryMessage } from './EmptyQueryMessage'
import { LogControls } from './LogControls'
import { LogFilter } from './LogFilter/LogFilter'
import { LogList } from './LogList'
import { Footer, Header } from './LogsView.styles'

interface LogsViewProps {
  filters: LogsFilter
  logs: ParsedLog[]
  page: number
  testRun: TestRun
  onFilterChange: (filters: LogsFilter) => void
  onPageChange: (page: number) => void
}

export const LogsView = ({ filters, logs, page, testRun, onFilterChange, onPageChange }: LogsViewProps) => {
  const isEmptyQuery = !logs.length && hasActiveFilter(filters)

  return (
    <>
      <Header>
        <LogFilter value={filters} testRun={testRun} onChange={onFilterChange} />
      </Header>

      {isEmptyQuery ? (
        <EmptyQueryMessage filters={filters} onFilterChange={onFilterChange} />
      ) : (
        <LogList filters={filters} logs={logs} />
      )}

      <Footer $isEmptyQuery={isEmptyQuery}>
        <LogControls isEmptyQuery={isEmptyQuery} logs={logs} page={page} testRun={testRun} onPageChange={onPageChange} />
      </Footer>
    </>
  )
}
