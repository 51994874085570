import React, { useMemo } from 'react'
import { SelectableValue } from '@grafana/data'
import { InlineFormLabel, MultiSelect } from '@grafana/ui'

import { LoadZone, TestRun } from 'types'
import { useLoadZones } from 'data/useLoadZones'

type LevelSelectableValue = Array<SelectableValue<string>>

interface LoadZoneSelectProps {
  value?: string[]
  testRun: TestRun
  onChange: (value: string[]) => void
}

const createLoadZoneOptions = (testRun: TestRun, loadZones: LoadZone[]) => {
  return testRun.distribution.map(([loadZoneId]) => {
    const zone = loadZones.find(({ k6_load_zone_id }) => k6_load_zone_id === loadZoneId) ?? {
      name: loadZoneId,
    }

    return { label: zone.name, value: loadZoneId }
  })
}

export function LoadZoneSelect({ value, testRun, onChange }: LoadZoneSelectProps) {
  const { data: loadZones = [], isLoading } = useLoadZones()
  const loadZoneOptions = useMemo(() => createLoadZoneOptions(testRun, loadZones), [testRun, loadZones])

  function handleLoadZoneChange(values: LevelSelectableValue) {
    onChange(values.map(({ value }) => value!))
  }

  return (
    <div className="gf-form" data-testid="load-zone-select">
      <InlineFormLabel className="query-keyword" htmlFor="load-zone" width={'auto'}>
        Load Zone
      </InlineFormLabel>
      <MultiSelect
        inputId="load-zone"
        isLoading={isLoading}
        menuPlacement="bottom"
        options={loadZoneOptions}
        value={value}
        onChange={handleLoadZoneChange}
      />
    </div>
  )
}
