import { useDatasource } from 'datasourceContext'

import { toTestQueryKey } from './queryKeys'
import { useFetch } from './useFetch'

export const useTest = (id: number | null | undefined) => {
  const { ds } = useDatasource()

  return useFetch(toTestQueryKey(id), () => ds.fetchTest(id as number), {
    enabled: !!id,
  })
}
