import React from 'react'
import { ScriptEditorProps } from '@grafana/k6-test-builder'
import { ThemeProvider } from 'styled-components'
import { useTheme2 } from '@grafana/ui'

import { CodeEditor } from 'components/CodeEditor/CodeEditor'

export const CodeEditorWrapper = ({
  value,
  readOnly,
  mode,
  onChange,
  error,
  constrainedRanges,
  onBodyChange,
  template,
  functionName,
}: Pick<
  ScriptEditorProps,
  | 'value'
  | 'onChange'
  | 'readOnly'
  | 'mode'
  | 'error'
  | 'constrainedRanges'
  | 'onBodyChange'
  | 'template'
  | 'functionName'
>) => {
  const languages: Record<NonNullable<typeof mode>, React.ComponentProps<typeof CodeEditor>['language']> = {
    test: 'javascript',
    scenario: 'javascript',
    'body.json': 'json',
    'body.text': 'text',
  }

  const language = mode ? languages[mode] : 'javascript'

  const overlay =
    error &&
    'Unable to generate script: Test contains errors that need to be addressed before a script can be generated'

  return (
    // TestBuilder's theme context overrides our theme provider, need to provide it again
    <ThemeProvider theme={useTheme2()}>
      <CodeEditor
        key={functionName}
        value={template || value}
        readOnly={readOnly}
        language={language}
        onChange={onChange}
        overlayMessage={overlay}
        constrainedRanges={constrainedRanges}
        onDidChangeContentInEditableRange={onBodyChange}
      />
    </ThemeProvider>
  )
}
