import { useDatasource } from 'datasourceContext'
import { Project } from 'types'

import { toProjectQueryKey } from './queryKeys'
import { QueryOptions, useFetch } from './useFetch'

export const useProject = (projectId: number, options?: QueryOptions<Project>) => {
  const { ds } = useDatasource()
  return useFetch(toProjectQueryKey(projectId), () => ds.fetchProject(projectId), options)
}
