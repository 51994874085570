import React, { ReactElement } from 'react'

import { Tooltip, TooltipProps } from 'components/Tooltip'

interface TooltipCellProps extends Omit<TooltipProps, 'children' | 'content'> {
  children: ReactElement | string
  tooltip?: TooltipProps['content']
}

export const TooltipCell = ({ tooltip, children, placement = 'top', ...tooltipProps }: TooltipCellProps) => (
  <Tooltip content={tooltip || children} placement={placement} interactive {...tooltipProps}>
    <span data-tag="allowRowEvents">{children}</span>
  </Tooltip>
)
