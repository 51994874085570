import React, { ReactNode } from 'react'
import { HorizontalGroup } from '@grafana/ui'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import clsx from 'clsx'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { Logo } from 'components/Logo'
import { routeMap } from 'routeMap'
import { isTopNavEnabled } from 'utils/topnav'

type LayoutProps = {
  children: ReactNode
}

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0;
`

const Content = styled.div`
  flex-grow: 1;
`

const Header = styled.div`
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)} 0;
`

export const Layout = ({ children }: LayoutProps) => {
  const isTopNav = isTopNavEnabled()

  return (
    <Container className={clsx(!isTopNav && 'page-container')}>
      {!isTopNav && (
        <Header>
          <HorizontalGroup spacing="xs">
            <Link to={routeMap.home}>
              <Logo />
            </Link>
            <Breadcrumbs />
          </HorizontalGroup>
        </Header>
      )}
      <Content>{children}</Content>
    </Container>
  )
}
