import React, { useMemo } from 'react'

import { Http, TestRun } from 'types'
import { Chart } from 'components/Chart'
import { ChartMetric } from 'components/Chart/Chart.types'
import { HTTP_FAILURE_RATE, HTTP_REQUEST_RATE, HTTP_RESPONSE_TIME, VUS_METRIC } from 'constants/metrics'
import { MetricBuilder, TagQueryBuilder } from 'utils/metrics'

interface Props {
  run: TestRun
  url: Http
}

const baseMetrics: ChartMetric[] = [HTTP_REQUEST_RATE, HTTP_RESPONSE_TIME, HTTP_FAILURE_RATE]

export const HttpChart = ({ run, url }: Props) => {
  const metrics = useMemo(() => {
    const query = new TagQueryBuilder()
      .equal('method', url.method)
      .equal('name', url.name)
      .equal('status', url.status)
      .equal('expected_response', url.expected_response)
      .build()

    return [VUS_METRIC, ...baseMetrics.map((metric) => new MetricBuilder(metric).withTags(query).build())]
  }, [url.method, url.name, url.status, url.expected_response])

  return <Chart title={`${url.method} ${url.name}`} testRun={run} metrics={metrics} />
}
