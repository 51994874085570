import { dateTime, LoadingState, LogsSortOrder, LogsDedupStrategy, PanelData } from '@grafana/data'

import { LogsFilter, ParsedLog } from 'types/logs'
import { LogPanelOptions } from 'panels/types'
import { createTimeRange } from 'utils/date'
import { createLogsDataFrame } from 'utils/logs'

export const createPanelData = (logs: ParsedLog[], filters: LogsFilter): PanelData => {
  const searchWords = filters.queryFilter ? [filters.queryFilter] : []
  const startDate = logs.at(0)!.values[0]
  const endDate = logs.at(-1)!.values[0]

  return {
    state: LoadingState.Done,
    series: [createLogsDataFrame('logs', logs, { searchWords })],
    timeRange: createTimeRange(dateTime(new Date(startDate)), dateTime(new Date(endDate))),
  }
}

export const panelOptions: LogPanelOptions = {
  showTime: true,
  showLabels: false,
  showCommonLabels: false,
  wrapLogMessage: false,
  prettifyLogMessage: false,
  enableLogDetails: true,
  dedupStrategy: LogsDedupStrategy.none,
  sortOrder: LogsSortOrder.Ascending,
}
