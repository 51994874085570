import styled, { css } from 'styled-components'

const TABLE_HEADER_HEIGHT = 52

export const DataTableWrapper = styled.div<{ $isFetching?: boolean }>`
  .rdt_TableCell {
    flex: 1 1 auto;
    width: 100px;
    &,
    > * {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .rdt_TableRow button[aria-label='Expand Row'] {
    transition: none;
    border-radius: 0;
  }

  .rdt_TableCol_Sortable.active {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  .rdt_TableCol_Sortable:hover,
  .rdt_TableCol_Sortable:focus {
    span,
    span * {
      opacity: 1 !important;
    }
  }

  .rdt_TableHeadRow {
    height: ${TABLE_HEADER_HEIGHT}px;
  }

  .rdt_TableCol {
    text-transform: uppercase;
  }

  ${({ $isFetching, theme }) =>
    $isFetching &&
    css`
      .rdt_Table::after {
        content: '';
        display: block;
        position: absolute;
        top: ${TABLE_HEADER_HEIGHT}px;
        height: calc(100% - ${TABLE_HEADER_HEIGHT}px);
        width: 100%;
        background-color: ${theme.isDark ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.4)'};
      }
    `}
`

export const SpinnerWrapper = styled.div`
  position: absolute;
  top: ${TABLE_HEADER_HEIGHT}px;
  height: calc(100% - ${TABLE_HEADER_HEIGHT}px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PaginationWrapper = styled.div`
  margin: 20px;
  padding-bottom: 20px;
`

export const DataTableEmptyStateWrapper = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.spacing(3)};

  button {
    display: block;
    margin: ${({ theme }) => theme.spacing(2)} auto;
  }
`

export const SortIconWrapper = styled.div<{ $isSorted?: boolean }>`
  & svg {
    transform: none !important;
    opacity: 1 !important;

    fill: ${({ $isSorted, theme }) =>
      $isSorted ? theme.colors.primary.main : theme.isDark ? 'rgb(204, 204, 220)' : 'rgba(36,41,46,1)'} !important;
  }
`
