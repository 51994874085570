import React from 'react'
import { useSummary } from 'data/useSummary'
import { TestRun } from 'types'
import { useAvailableProtocols, useOverviewState } from './RunOverview.hooks'
import { SummaryGrid } from './RunOverview.styles'
import { ProtocolSummary } from './ProtocolSummary'
import { Chart } from 'components/Chart'
import { LoadingContainer } from 'components/LoadingContainer'
import { CollapsableSection } from 'components/CollapsableSection/CollapsableSection'
import { CollapsableSectionHeader } from 'components/CollapsableSection/CollapsableSectionHeader'
import { RunOverviewIcon } from './RunOverviewIcon'
import { TestSummary } from './TestSummary'
import { SectionContent } from '../styles'
import { RunDetailsSection } from 'pages/TestRunPage/TestRunPage.styles'
import { Tab } from 'components/Tabs/Tab'
import { TabsBar } from 'components/Tabs/TabsBar'
import { Scenarios } from './Scenarios'


interface RunOverviewProps {
  testRun: TestRun
}

export const RunOverview = ({ testRun }: RunOverviewProps) => {
  const summary = useSummary(testRun)
  const protocols = useAvailableProtocols(summary.data)

  const [{ open, active }, setOverviewState] = useOverviewState(protocols)

  const handleSectionToggle = () => {
    setOverviewState('replace', {
      open: !open,
      active,
    })
  }

  return (
    <RunDetailsSection>
      <CollapsableSection
        isOpen={open}
        label={
          <CollapsableSectionHeader
            icon={<RunOverviewIcon />}
            heading="PERFORMANCE OVERVIEW"
            description={<TestSummary run={testRun} protocol={active} protocols={protocols} />}
            actions={
              <LoadingContainer loading={summary.isLoading} spinner={null}>
                <TabsBar>
                  {protocols.length > 1 &&
                    protocols.map((protocol) => (
                      <Tab
                        key={protocol.type}
                        label={protocol.label}
                        href={setOverviewState('href', { open: true, active: protocol })}
                        active={active?.type === protocol.type}
                      />
                    ))}
                </TabsBar>
              </LoadingContainer>
            }
          />
        }
        onToggle={handleSectionToggle}
      >
        <SectionContent>
          <LoadingContainer loading={summary.isLoading}>
            <SummaryGrid>
              <ProtocolSummary protocol={active} />
            </SummaryGrid>
          </LoadingContainer>
          <Chart
            title={`Performance Overview (${active?.label ?? 'HTTP'})`}
            loading={summary.isLoading}
            testRun={testRun}
            metrics={active?.metrics ?? []}
          />
        </SectionContent>
        {active && (
          <SectionContent>
            <Scenarios testRun={testRun} protocol={active.type} />
          </SectionContent>
        )}
      </CollapsableSection>
    </RunDetailsSection>
  )
}
