import React, { FormEvent, useEffect, useState } from 'react'
import { isUndefined } from 'lodash-es'
import styled from 'styled-components'
import { InlineField, Input } from '@grafana/ui'

import { TestRun } from 'types'
import { LogLevel, LogsFilter } from 'types/logs'
import { useDebounce } from 'hooks/useDebounce'
import { LoadZoneSelect } from './LoadZoneSelect'
import { LogLevelSelect } from './LogLevelSelect'

interface LogFilterProps {
  onChange: (filter: LogsFilter) => void
  value: LogsFilter
  testRun: TestRun
}

const FormWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  position: relative;
  text-align: left;
  gap: ${({ theme }) => theme.spacing(0.5)};
`

const LogFilterInlineField = styled(InlineField)`
  margin: 0;

  label {
    color: #6e9fff;
    font-weight: 500;
  }
`

export function LogFilter({ value, onChange, testRun }: LogFilterProps) {
  const [queryFilter, setQueryFilter] = useState(value?.queryFilter)
  const debouncedText = useDebounce(queryFilter)

  function handleLevelChange(level: LogLevel[]) {
    onChange({ level })
  }

  function handleLoadZoneChange(loadZone: string[]) {
    onChange({ loadZone })
  }

  function handleTextChange(event: FormEvent<HTMLInputElement>) {
    setQueryFilter(event.currentTarget.value)
  }

  useEffect(() => {
    if (!isUndefined(debouncedText)) {
      onChange({ queryFilter: debouncedText })
    }
  }, [debouncedText, onChange])

  // Clear input state if query has been cleared externally
  useEffect(() => {
    if (!value?.queryFilter) {
      setQueryFilter(value?.queryFilter)
    }
  }, [value?.queryFilter])

  return (
    <FormWrapper data-testid="log-filter">
      <LogFilterInlineField label="Filter" htmlFor="query-filter">
        <Input id="query-filter" placeholder="Log line contains" value={queryFilter} onChange={handleTextChange} />
      </LogFilterInlineField>
      <LogLevelSelect value={value?.level} onChange={handleLevelChange} />
      <LoadZoneSelect value={value?.loadZone} onChange={handleLoadZoneChange} testRun={testRun} />
    </FormWrapper>
  )
}
