import { FieldConfig, ThresholdsMode } from '@grafana/data'
import {
  GraphFieldConfig,
  GraphDrawStyle,
  AxisPlacement,
  GraphGradientMode,
  GraphTresholdsStyleMode,
  LineInterpolation,
  ScaleDistribution,
  StackingMode,
  VisibilityMode,
  BarAlignment,
} from '@grafana/schema'
import { NamedColors } from 'types'
import { ChartMetric, ChartThreshold } from './Chart.types'

const areaStyles: GraphFieldConfig = {
  fillOpacity: 10,
  stacking: {
    mode: StackingMode.Normal,
  },
}

const barStyles: GraphFieldConfig = {
  drawStyle: GraphDrawStyle.Bars,
  barAlignment: BarAlignment.After,
  lineWidth: 4,
  barMaxWidth: 1,
  fillOpacity: 100,
  spanNulls: true,
  pointSize: 5,
}

const getSoftMaxFromThresholds = (thresholds: ChartThreshold[] | undefined) => {
  if (thresholds === undefined || thresholds.length === 0) {
    return undefined
  }

  const maxThreshold = Math.max(...thresholds.map((threshold) => threshold.value))

  // We set the soft max of the chart to something a little bit larger than
  // the max threshold, so that there is some spacing between the top of the
  // chart and threshold lines.
  return maxThreshold + maxThreshold * 0.3
}

export const toGraphFieldConfig = ({
  label,
  unit,
  color,
  type,
  thresholds,
}: ChartMetric): FieldConfig<GraphFieldConfig> => {
  const softMax = getSoftMaxFromThresholds(thresholds)

  return {
    displayName: label,
    unit: unit,
    color:
      color !== undefined
        ? {
            mode: 'fixed',
            fixedColor: color,
          }
        : undefined,
    custom: {
      ...(type === 'area' && areaStyles),
      ...(type === 'bars' && barStyles),
      ...(thresholds !== undefined && {
        thresholdsStyle: {
          mode: GraphTresholdsStyleMode.Dashed,
        },
      }),
      axisSoftMax: softMax,
    },
    thresholds: thresholds && {
      mode: ThresholdsMode.Absolute,
      steps: [
        { color: NamedColors.Green, value: 0 },
        ...thresholds.map((threshold) => ({
          color: threshold.color ?? NamedColors.Red,
          value: threshold.value,
        })),
      ],
    },
  }
}

export const DEFAULT_FIELD_CONFIG: FieldConfig<GraphFieldConfig> = {
  min: 0,
  custom: {
    drawStyle: GraphDrawStyle.Line,
    lineInterpolation: LineInterpolation.Linear,
    barAlignment: 0,
    lineWidth: 2,
    fillOpacity: 0,
    gradientMode: GraphGradientMode.None,
    spanNulls: false,
    showPoints: VisibilityMode.Never,
    pointSize: 5,
    stacking: {
      mode: StackingMode.None,
      group: 'A',
    },
    axisPlacement: AxisPlacement.Auto,
    axisLabel: '',
    scaleDistribution: {
      type: ScaleDistribution.Linear,
    },
    hideFrom: {
      tooltip: false,
      viz: false,
      legend: false,
    },
    thresholdsStyle: {
      mode: GraphTresholdsStyleMode.Off,
    },
  },
  color: {
    mode: 'palette-classic',
  },
  mappings: [],
}
