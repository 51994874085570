import styled from 'styled-components'

import { isTopNavEnabled } from 'utils/topnav'

export const Body = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 0 auto;
  max-width: 940px;
`

export const Card = styled.div`
  padding: 30px;
  margin: 20px;
  max-width: 400px;
  background: ${({ theme }) => theme.colors.background.secondary};
  border: 1px solid ${({ theme }) => theme.colors.border.weak};
`

export const CLIWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2.5)};
  width: 100%;
`

export const Heading = styled.h1`
  margin-top: ${({ theme }) => isTopNavEnabled() && theme.spacing(8)};
`

export const TextSecondary = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
`
