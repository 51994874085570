import React, { ReactNode } from 'react'
import { isEmpty } from 'lodash'
import { FieldError } from 'react-hook-form'
import { IconName, Badge } from '@grafana/ui'

import { Test } from 'types'
import { isCLITest, isSecureTest } from 'utils/test'
import { FormStatusLevel } from 'components/FormStatusMessage/FormStatusMessage.types'

type MessageMap = {
  [key in Exclude<FormStatusLevel, 'default'>]: {
    level: key
    title: string
    icon: IconName
    text?: ReactNode
  }
}

const messageMap: MessageMap = {
  loading: {
    level: 'loading',
    title: 'LOADING...',
    icon: 'fa fa-spinner',
  },
  saving: {
    level: 'saving',
    icon: 'fa fa-spinner',
    title: 'SAVING...',
  },
  success: {
    level: 'success',
    icon: 'check-circle',
    title: 'PERFECT!',
  },
  error: {
    level: 'error',
    icon: 'circle',
    title: 'ERROR',
  },
  unsaved: {
    level: 'unsaved',
    icon: 'check-circle',
    title: 'UNSAVED',
  },
  warning: {
    level: 'warning',
    icon: 'check-circle',
    title: 'WARNING',
  },
}

type UseFormProps = {
  errors: {
    [key: string]: FieldError | undefined
  }
  isUnsaved?: boolean
  isScriptError?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  test?: Test | null
  isNewTest?: boolean
  isBuilderTest?: boolean
}

export const useFormStatus = ({
  isUnsaved,
  errors,
  isScriptError,
  isSubmitting,
  isLoading,
  test,
  isNewTest,
  isBuilderTest,
}: UseFormProps) => {
  const type = isBuilderTest ? 'Configuration' : 'Script'

  if (isLoading) {
    return messageMap.loading
  }

  if (isSubmitting) {
    return messageMap.saving
  }

  if (!isEmpty(errors)) {
    return {
      ...messageMap.error,
      text: Object.values(errors)[0]?.message,
    }
  }

  if (isScriptError) {
    return {
      ...messageMap.error,
      text: 'There are validation errors, please fix them in order to run the test',
    }
  }

  if (isUnsaved) {
    return messageMap.unsaved
  }

  if (test && isCLITest(test)) {
    return {
      ...messageMap.warning,
      text: (
        <>
          This script was executed from CLI. To modify the script, you need to edit it locally and run{' '}
          <Badge text="k6 cloud" color="purple" /> or <Badge text="k6 run -o cloud" color="purple" /> command again
        </>
      ),
      icon: 'lock' as IconName,
      title: 'READ ONLY',
    }
  }

  if (test && isSecureTest(test)) {
    return {
      ...messageMap.warning,
      text: 'Cannot modify CLI k6 cloud (secure)',
      icon: 'lock' as IconName,
      title: 'READ ONLY',
    }
  }

  if (isNewTest) {
    return {
      ...messageMap.success,
      text: `${type} looks fine and you are ready to build`,
    }
  }

  return {
    ...messageMap.success,
    text: `${type} looks fine and is ready to run`,
  }
}
