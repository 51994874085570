import { Icon } from '@grafana/ui'
import styled from 'styled-components'

export const SuccessIcon = styled(Icon)`
  width: 64px;
  height: 64px;
  color: ${({ theme }) => theme.colors.success.main};
`

export const FailureIcon = styled(Icon)`
  width: 64px;
  height: 64px;
  color: ${({ theme }) => theme.colors.error.main};
`

export const WarningIcon = styled(Icon)`
  width: 64px;
  height: 64px;
  color: ${({ theme }) => theme.colors.warning.text};
`
