import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Link = styled.a`
  color: ${({ theme }) => theme.colors.text.link};
  &:hover {
    text-decoration: underline;
  }
`

export const ExternalLink = ({ url, children }: { url: string; children: ReactNode }) => (
  <Link href={url} target="_blank" rel="noopener noreferrer">
    {children}
  </Link>
)
