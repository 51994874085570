import React from 'react'

import { separatorFormatter, responseTimeFormatter } from 'utils/formatters'
import { HttpMetricSummary } from 'types'
import { Statistic } from './Statistic'

interface HttpSummaryProps {
  summary: HttpMetricSummary
}

export const HttpSummary = ({ summary }: HttpSummaryProps) => {
  return (
    <>
      <Statistic label="REQUESTS MADE" value={separatorFormatter(summary.requests_count)} unit="reqs" />
      <Statistic label="HTTP FAILURES" value={separatorFormatter(summary.failures_count)} unit="reqs" />
      <Statistic label="PEAK RPS" value={separatorFormatter(summary.rps_max, 2, ' ')} unit="reqs/s" />
      <Statistic label="P95 RESPONSE TIME" value={responseTimeFormatter(summary.duration.p95 ?? 0)} unit="ms" />
    </>
  )
}
