import { useEffect, useState } from 'react'

import { GrpcUrlsPayload } from 'datasource/datasource'
import { useDatasource } from 'datasourceContext'

import { getFiltersValueString } from 'pages/TestRunPage/components/Filters/Filters.utils'
import { GrpcUrl, TestRunGRPCFilter, TestRun } from 'types'

import { QueryOptions, useFetch } from './useFetch'
import { toGrpcQueryKey } from './queryKeys'

export const useGRPC = (
  testRun: TestRun,
  {
    pageSize = 20,
    filters = [],
    orderBy,
    ...options
  }: QueryOptions<GrpcUrlsPayload> & { filters?: TestRunGRPCFilter[]; pageSize?: number; orderBy?: string } = {}
) => {
  const [currentPage, setCurrentPage] = useState(1)
  const { ds } = useDatasource()

  const filterValues = getFiltersValueString(filters)

  useEffect(() => {
    setCurrentPage(1)
  }, [filterValues, pageSize, orderBy])

  const response = useFetch(
    toGrpcQueryKey(testRun.id, { page: currentPage, count: true, ended: testRun.ended, filters, orderBy }),
    () => ds.fetchGrpcUrls({ id: testRun.id, page: currentPage, pageSize, filters, orderBy }),
    {
      ...options,
      keepPreviousData: options.keepPreviousData ?? true,
    } as QueryOptions<GrpcUrlsPayload>
  )

  return { ...response, currentPage, fetchPage: setCurrentPage, orderBy }
}

type GrpcName = Pick<GrpcUrl, 'name'>

export const useGRPCNames = (testRun: TestRun, options?: QueryOptions<GrpcName[]>) => {
  const { ds } = useDatasource()

  return useFetch<GrpcName[]>(
    toGrpcQueryKey(testRun.id, { select: 'name', ended: testRun.ended }),
    () => ds.fetchGrpcUrls({ id: testRun.id, select: 'name', count: false }).then((res) => res.value ?? []),
    options
  )
}
