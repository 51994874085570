import React, { createContext, ReactNode, useReducer } from 'react'

import { runDetailsFilterContextInitState, runDetailsFiltersContextReducer } from './FiltersContext.reducer'
import { FilterContextAction, FiltersState } from './Filters.types'

export const FiltersContext = createContext<undefined | [FiltersState, React.Dispatch<FilterContextAction>]>(undefined)

export const FiltersContextProvider = ({ children }: { children: ReactNode }) => {
  const [filters, dispatch] = useReducer(runDetailsFiltersContextReducer, runDetailsFilterContextInitState)
  return <FiltersContext.Provider value={[filters, dispatch]}>{children}</FiltersContext.Provider>
}
