import React from 'react'
import { InsightsStateContainer } from './InsightsStateContainer'

export const InsightsLoading = () => {
  return (
    <InsightsStateContainer>
      <p>Loading...</p>
    </InsightsStateContainer>
  )
}
