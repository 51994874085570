import { DocLink, docs, ReadMore } from 'components/DocLink'
import React from 'react'
import { HttpHighFailureRate, HttpHighFailureRateFailed } from 'types/insights/http'
import { percentage } from 'utils/formatters'
import { InsightBody } from '../InsightBody'
import { InsightListItem } from '../InsightItem'
import { SkippedDescription } from '../SkippedDescription'

interface HighFailureRateProps {
  insight: HttpHighFailureRateFailed
}

export const HighFailureRate = ({ insight: { data } }: HighFailureRateProps) => {
  return (
    <InsightBody>
      <p>
        The average HTTP error rate during the test was {percentage(data.avg_failure_rate)}. By default, HTTP responses
        from the application server with the codes 4xx or 5xx are considered HTTP errors. Failed responses are often
        returned much faster than successful responses, so an increased HTTP error rate may produce misleading request
        rate and response time metrics.
      </p>
      <h4>Failing URLs</h4>
      <p>The URLs with the highest failure rates were:</p>
      <ul>
        {data.top_failing_urls.map((url) => (
          <li key={url.method + url.url}>
            {percentage(url.failure_rate)} failure rate of {url.method} requests to {url.url}
          </li>
        ))}
      </ul>
      <h4>Recommendations:</h4>
      <ul>
        <li>
          Run a single iteration of the script locally to troubleshoot the failing requests before running a load test.
        </li>
        <li>
          In the script, verify that the failing requests are formulated correctly and return the expected response.
        </li>
        <li>Verify that any user accounts used have sufficient permissions to access the application.</li>
        <li>
          Verify that the application is publicly accessible. If it is behind a firewall, consider using{' '}
          <DocLink article={docs.tutorials.automatedPerformanceTesting.localOrCloudExecution}>local execution</DocLink>{' '}
          for your tests.
        </li>
        <li>
          Check for web server misconfiguration or internal errors caused by saturation of a resource (CPU, memory, disk
          I/O or database connections).
        </li>
        <li>
          If you are intentionally testing HTTP error responses, you can{' '}
          <DocLink article={docs.javascript.http.setResponseCallback}>
            change which HTTP codes should be classified as errors
          </DocLink>
          .
        </li>
      </ul>
      <p>
        <ReadMore article={docs.insights.http.highFailureRate} />
      </p>
    </InsightBody>
  )
}

interface HighFailureRateItemProps {
  insight: HttpHighFailureRate
}

const HighFailureRateDescription = ({ insight }: HighFailureRateItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          The average error rate remained below {percentage(insight.data.threshold.failure_rate)} during the test.{' '}
          <ReadMore article={docs.insights.http.highFailureRate} />
        </>
      )

    case 'FAILED':
      return <>A high HTTP error rate of {percentage(insight.data.avg_failure_rate)} was detected.</>

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const HighFailureRateItem = ({ insight }: HighFailureRateItemProps) => {
  return (
    <InsightListItem header="High Failure Rate" insight={insight}>
      <HighFailureRateDescription insight={insight} />
    </InsightListItem>
  )
}
