import React from 'react'
import { TestRunFilter } from 'types'

import { DataTableFilteredEmptyState } from 'components/DataTable/DataTableFilteredEmptyState'
import { DataTableDefaultEmptyState } from 'components/DataTable/DataTableDefaultEmptyState'


export const RunDetailsTableEmptyState = ({
  filters,
  onClearFiltersClick,
}: {
  filters: TestRunFilter[]
  onClearFiltersClick: () => void
}) => {
  if (filters.length > 0) {
    return <DataTableFilteredEmptyState onClearFiltersClick={onClearFiltersClick} />
  }

  return <DataTableDefaultEmptyState />
}
