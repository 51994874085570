import React from 'react'
import { docs, DocLink } from 'components/DocLink'
import { TestRun, TestRunStatus } from 'types'
import { InsightsByStatus } from 'types/insights'
import { isTestAborted, isTestActive, isTestFailed, isTestTimedOut } from 'utils/testRun'
import { UseQueryResult } from '@tanstack/react-query'

interface AbortedMessageProps {
  testRun?: TestRun
}

const AbortedMessage = ({ testRun: run }: AbortedMessageProps) => {
  switch (run?.run_status) {
    case TestRunStatus.ABORTED_USER: {
      return (
        <>
          The test was <DocLink article={docs.insights.abortReason.user}>aborted by the user</DocLink>, but our
          automated algorithms could not find any issues leading to that moment. The results may be unreliable.
        </>
      )
    }
    case TestRunStatus.ABORTED_SYSTEM: {
      return (
        <>
          The test was <DocLink article={docs.insights.abortReason.system}>aborted by the system</DocLink>, but our
          automated algorithms could not find any issues leading to that moment. The results may be unreliable.
        </>
      )
    }
    case TestRunStatus.ABORTED_SCRIPT_ERROR: {
      return (
        <>
          The test was <DocLink article={docs.insights.abortReason.scriptError}>aborted due to a script error</DocLink>,
          but our automated algorithms could not find any issues leading to that moment. The results may be unreliable.
        </>
      )
    }
    case TestRunStatus.ABORTED_THRESHOLD: {
      return (
        <>
          The test was <DocLink article={docs.insights.abortReason.threshold}>aborted due to a threshold</DocLink>{' '}
          exceeding its defined value, but our automated algorithms could not find any issues leading to that moment.
        </>
      )
    }
    case TestRunStatus.ABORTED_LIMIT: {
      return (
        <>
          The test was <DocLink article={docs.insights.abortReason.limit}>aborted due to a limitation</DocLink>, but our
          automated algorithms could not find any issues leading to that moment. The results may be unreliable.
        </>
      )
    }

    default: {
      return (
        <>
          Our automated algorithms could not find any issues, but the results may be unreliable because the test was
          aborted.
        </>
      )
    }
  }
}

const LoadingMessage = () => <>Loading...</>

const ErrorMessage = () => <>An error occurred while fetching insights.</>

const AnalyzingMessage = () => <>Your test results are being analyzed...</>

const FailedMessage = () => (
  <>
    While our automated algorithms could not find any issues, the test run failed. You should dig deeper into the data
    to find the cause of the failure.
  </>
)

const TimedOutMessage = () => (
  <>Our automated algorithms could not find any issues, but the results may be unreliable because the test timed out.</>
)

const IssuesFoundMessage = () => (
  <>Our automated algorithms have analyzed the test results and have found some issues.</>
)

const GeneralMessage = () => <>Our automated algorithms could not find any issues.</>

interface InsightsDescriptionMessageProps {
  testRun: TestRun
  insights: UseQueryResult<InsightsByStatus>
}

export const InsightsSummary = ({ testRun, insights }: InsightsDescriptionMessageProps) => {
  if (isTestActive(testRun)) {
    return <AnalyzingMessage />
  }

  if (insights.isLoading) {
    return <LoadingMessage />
  }

  if (insights.isError) {
    return <ErrorMessage />
  }

  if (insights.data.FAILED.length > 0) {
    return <IssuesFoundMessage />
  }

  if (isTestAborted(testRun)) {
    return <AbortedMessage testRun={testRun} />
  }

  if (isTestFailed(testRun)) {
    return <FailedMessage />
  }

  if (isTestTimedOut(testRun)) {
    return <TimedOutMessage />
  }

  return <GeneralMessage />
}
