import { isString } from 'lodash-es'

export const dedent = (str: string) => {
  // join lines when there is a suppressed newline
  // handle escaped backticks
  let result = str.replace(/\\\n[ \t]*/g, '').replace(/\\`/g, '`')

  let minIndent: number | null = null
  const lines = result.split('\n')

  // strip indentation
  lines.forEach((line) => {
    const match = line.match(/^(\s+)\S+/)

    if (match) {
      const indent = match[1]?.length ?? 0

      if (!minIndent) {
        minIndent = indent
      } else {
        minIndent = Math.min(minIndent, indent)
      }
    }
  })

  if (minIndent !== null) {
    result = lines.map((line) => (line[0] === ' ' ? line.slice(minIndent as number) : line)).join('\n')
  }

  return result.trim().replace(/\\n/g, '\n')
}

export const replace = (match: string, replace: string) => (str: string) => str.replace(match, replace)

export const stringify = (x: unknown) => (!isString(x) ? JSON.stringify(x, null, 2) : x)
