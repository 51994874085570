import styled from 'styled-components'

export const InsightListExpanderIcon = styled.div`
  display: none;

  align-items: center;
  justify-content: center;
  flex: 1 1 0;

  color: ${({ theme }) => theme.colors.text.secondary};
`

export const InsightListWrapper = styled.div`
  .rdt_TableRow {
    flex-direction: row-reverse;

    &.failed ${InsightListExpanderIcon} {
      display: flex;
    }

    > .rdt_TableCell:nth-child(2) {
      padding-left: 16px;
    }
  }
`
