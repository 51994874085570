import React from 'react'
import { SupportLink } from 'components/SupportLink'
import { isTestActive } from 'utils/testRun'
import { ErrorCode, TestRun, TestRunStatus } from 'types'
import { Protocol } from '../RunOverview.types'
import { GRPCTestSummary } from './GRPCTestSummary'
import { HttpTestSummary } from './HttpTestSummary'
import { WebSocketTestSummary } from './WebSocketTestSummary'
import { DocLink, docs } from 'components/DocLink'
import { exhaustive } from 'utils/typescript'
import { QueryParamLink } from 'components/QueryParamLink'
import { BreakdownTab } from '../../Breakdown.types'

interface EndOfTestSummaryProps {
  run: TestRun | undefined
  protocol: Protocol | undefined
  protocols: Protocol[]
}

const TryAgainOrContactSupport = () => {
  return (
    <>
      Please try to run the test again. If the problem still persist, please{' '}
      <SupportLink>contact our support</SupportLink>.
    </>
  )
}

export const TestSummary = ({ run, protocol, protocols }: EndOfTestSummaryProps) => {
  if (!run || !protocol) {
    return <>Loading...</>
  }

  // const onLinkClick = (targetTab: Tabs) => {
  //   onTabChangeRequested(targetTab)
  // }

  if (isTestActive(run)) {
    return <>The test is running...</>
  }

  if (run.run_status === TestRunStatus.ABORTED_SCRIPT_ERROR) {
    return (
      <>
        The test was aborted due to a script error. Please review your test script and make sure that it does not
        contain any errors then re-run the test.
      </>
    )
  }

  if (run.run_status === TestRunStatus.ABORTED_SYSTEM) {
    return (
      <>
        The test was aborted by the system. <TryAgainOrContactSupport />
      </>
    )
  }

  if (run.run_status === TestRunStatus.ABORTED_LIMIT) {
    return <>The test was aborted due to hard limit being crossed.</>
  }

  if (run.run_status === TestRunStatus.ABORTED_THRESHOLD) {
    // TODO: Implement tab navigation
    // return (
    //   <>
    //     The test was aborted because a threshold that was configured to stop the test was exceeded.
    //     See the{' '}
    //     <TextButton
    //       onClick={e => {
    //         onLinkClick('thresholds')
    //         e.stopPropagation()
    //       }}
    //     >
    //       Thresholds
    //     </TextButton>{' '}
    //     tab for more details.
    //   </>
    // )

    return (
      <>
        The test was aborted because a threshold that was configured to stop the test was exceeded. See the{' '}
        <QueryParamLink name="tab" value={BreakdownTab.THRESHOLDS}>
          Thresholds
        </QueryParamLink>{' '}
        tab for more details.
      </>
    )
  }

  if (run.run_status === TestRunStatus.ABORTED_USER) {
    return <>The test was manually aborted.</>
  }

  if (run.run_status === TestRunStatus.TIMED_OUT) {
    if (run.error_code === ErrorCode.TEST_RUN_NO_DATA_TIMEOUT) {
      return (
        <>
          The test run timed-out while waiting for data. <TryAgainOrContactSupport />
        </>
      )
    }

    return (
      <>
        The test timed-out. <TryAgainOrContactSupport />
      </>
    )
  }

  if (protocols.length === 0) {
    return (
      <>
        No data was recorded for this test run. Try to increase the duration of the test. Read more about test
        configurations in the <DocLink article={docs.root}>docs</DocLink>.
      </>
    )
  }

  switch (protocol.type) {
    case 'http':
      return <HttpTestSummary summary={protocol.summary} />

    case 'ws':
      return <WebSocketTestSummary summary={protocol.summary} />

    case 'grpc':
      return <GRPCTestSummary summary={protocol.summary} />

    default:
      return exhaustive(protocol, <></>)
  }
}
