import React, { useState, useEffect } from 'react'
import { InsightAnalyzingMessage } from './InsightsAnalyzingCarousel.styles'

const TIME_BETWEEN_MESSAGES = 10000

const messages = [
  <>
    <p>Our algorithms are analyzing the test results.</p>
    <p>Please hold until we have enough data to show you.</p>
  </>,
  <>
    <p>k6 Cloud&apos;s Performance Insights are algorithms built into k6 Cloud Test Results.</p>
    <p>Our algorithms are automatically executed as part of test result processing. </p>
  </>,
  <>
    <p>Test Health Performance Insights are alerts that intend to highlight test or script related issues. </p>
    <p>These issues, if not addressed, can either skew your results or make result analysis harder to parse through.</p>
  </>,
]

export const InsightsAnalyzingCarousel = () => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const handle = setTimeout(() => {
      setIndex((index) => (index + 1) % messages.length)
    }, TIME_BETWEEN_MESSAGES)

    return () => clearTimeout(handle)
  }, [index])

  return <InsightAnalyzingMessage>{messages[index]}</InsightAnalyzingMessage>
}
