import { DocLink, docs, ReadMore } from 'components/DocLink'
import React from 'react'
import { BestPracticeTooManyTimeSeries, BestPracticeTooManyTimeSeriesFailed } from 'types/insights/bestPractices'
import { quantity } from 'utils/formatters'
import { InsightBody } from '../InsightBody'
import { InsightListItem } from '../InsightItem'
import { SkippedDescription } from '../SkippedDescription'

interface TooManyTimeSeriesProps {
  insight: BestPracticeTooManyTimeSeriesFailed
}

export const TooManyTimeSeries = ({ insight: { data } }: TooManyTimeSeriesProps) => {
  return (
    <InsightBody>
      <p>
        A time series is a sequence of metric data points over time with a distinct set of tags. Having too many time
        series, or high metric cardinality, means that your test is generating an excessive number of unique values for{' '}
        <DocLink article={docs.httpRequests.tags}> tags</DocLink>. This causes data processing to be resource-intensive
        and inefficient, compromising the accuracy of the test, and can also increase the noise in a test, making it
        difficult to analyze test results.
      </p>
      <p>A typical cause of this error is:</p>
      <ul>
        <li>URL parameters that vary per request (tokens, resource or session IDs, etc.).</li>
        <li>
          Many different values on a custom tag (for example, setting a new randomly generated value on every request).
        </li>
      </ul>
      <p>To resolve this, reduce the number of unique time series. We recommend:</p>
      <ul>
        <li>
          <DocLink article={docs.httpRequests.urlGrouping}>Aggregating data from dynamic URLs</DocLink> using the name
          tag in your test script.
        </li>
        <li>
          Using few meaningful tag values when using <DocLink article={docs.httpRequests.tags}>custom tags.</DocLink>
        </li>
      </ul>
      <p>These metrics generated a high number of time series:</p>
      <ul>
        {data.largest_metrics.map((metric) => (
          <li key={metric.metric_name}>
            <strong>{metric.metric_name}</strong> (at least {metric.time_series_count} time series)
          </li>
        ))}
      </ul>
      <p>These tags had a high number of unique values: </p>
      <ul>
        {data.largest_tags.map((tag) => (
          <li key={tag.tag_name}>
            <strong>{tag.tag_name}</strong> (at least {tag.estimated_value_count} unique values)
          </li>
        ))}
      </ul>
      <p>
        <ReadMore article={docs.insights.bestPractices.tooManyTimeSeries} />
      </p>
    </InsightBody>
  )
}

interface TooManyTimeSeriesItemProps {
  insight: BestPracticeTooManyTimeSeries
}

const TooManyTimeSeriesDescription = ({ insight }: TooManyTimeSeriesItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          Your test did not generate more than {quantity(insight.data.threshold.time_series_count_soft)} time series.{' '}
          <ReadMore article={docs.insights.bestPractices.tooManyTimeSeries} />
        </>
      )

    case 'FAILED':
      return (
        <>
          Your test generated more than{' '}
          {quantity(
            insight.data.count > insight.data.threshold.time_series_count_hard
              ? insight.data.threshold.time_series_count_hard
              : insight.data.threshold.time_series_count_soft
          )}{' '}
          distinct metric time series.
        </>
      )

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const TooManyTimeSeriesItem = ({ insight }: TooManyTimeSeriesItemProps) => {
  return (
    <InsightListItem header="Too Many Time Series" insight={insight}>
      <TooManyTimeSeriesDescription insight={insight} />
    </InsightListItem>
  )
}
