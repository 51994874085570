import {
  TestRunFilter,
  TestRunFilterBy,
  TestRunGRPCFilterBy,
  TestRunHttpFilterBy,
  TestRunThresholdsFilterBy,
  TestRunWebSocketsFilterBy,
} from 'types'

export const getFiltersValueString = (filters: TestRunFilter[]) => {
  return filters.reduce((value, filter) => {
    if (filter.values.length > 0) {
      return value + `${value.length > 0 ? '&' : ''}by=${filter.by},values=${filter.values.toString()}`
    }

    return value
  }, '')
}

export function getFilledFilters<T extends TestRunFilter>(filters: T[]) {
  return filters.filter((f) => f.values.length > 0)
}

export function getShowFilterSelect<T extends unknown, F extends TestRunFilter>(data: T[], filters: F[]) {
  return data.length > 0 || filters.length > 0
}

export function getIsFilterAllowedCustomValue(by: TestRunFilterBy) {
  const customValuesNotAllowed: TestRunFilterBy[] = [
    TestRunHttpFilterBy.Scenario,
    TestRunHttpFilterBy.ExpectedResponse,
    TestRunGRPCFilterBy.Scenario,
    TestRunThresholdsFilterBy.Tainted,
    TestRunThresholdsFilterBy.Tainted,
    TestRunWebSocketsFilterBy.Scenario,
  ]

  return !customValuesNotAllowed.includes(by)
}
