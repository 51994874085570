import { DocLink, docs, ReadMore } from 'components/DocLink'
import React from 'react'
import { BestPracticeTooManyMetrics } from 'types/insights/bestPractices'
import { InsightBody } from '../InsightBody'
import { InsightListItem } from '../InsightItem'
import { SkippedDescription } from '../SkippedDescription'

export const TooManyMetrics = () => {
  return (
    <InsightBody>
      <p>
        Using too many <DocLink article={docs.metrics.custom}>custom metrics</DocLink>, especially for scripts with a
        high number of requests, may significantly increase the CPU or memory utilization of your load generators and
        make your results less accurate. They can also make it more difficult to aggregate, display, and analyze your
        test results.
      </p>
      <h4>Recommendations:</h4>
      <ul>
        <li>
          Review the <DocLink article={docs.metrics.builtIn}>built-in metrics</DocLink> and see if any of them already
          meet your needs.
        </li>
        <li>Avoid dynamically generating custom metrics using loops and counter variables.</li>
        <li>
          Use <DocLink article={docs.httpRequests.urlGrouping}>URL grouping</DocLink> to aggregate metrics as well as
          requests.
        </li>
      </ul>
      <p>
        <ReadMore article={docs.insights.bestPractices.tooManyMetrics} />
      </p>
    </InsightBody>
  )
}

interface TooManyMetricsItemProps {
  insight: BestPracticeTooManyMetrics
}

const TooManyMetricsDescription = ({ insight }: TooManyMetricsItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          We did not detect an excessive number of metrics in your test.{' '}
          <ReadMore article={docs.insights.bestPractices.tooManyMetrics} />
        </>
      )

    case 'FAILED':
      return <>Your test is generating too many requests and metrics.</>

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const TooManyMetricsItem = ({ insight }: TooManyMetricsItemProps) => {
  return (
    <InsightListItem header="Too Many Metrics" insight={insight}>
      <TooManyMetricsDescription insight={insight} />
    </InsightListItem>
  )
}
