import { useEffect } from 'react'
import { sentryClient } from 'services/sentryClient'

import { useAppContext } from '../appContext'
import { SENTRY_DSN } from 'constants/index'

export const SetupSentry = ({ pluginVersion }: { pluginVersion: string }) => {
  const { account, env } = useAppContext()

  /**
   * Start Sentry
   */
  useEffect(() => {
    sentryClient.configure({
      dsn: SENTRY_DSN,
      version: pluginVersion,
      environment: env,
    })
  }, [env, pluginVersion])

  /**
   * Configure User
   */
  useEffect(() => {
    if (!account) {
      return
    }

    sentryClient.setUserContext({ id: account.user.id })
  }, [account])

  /**
   * Pause Sentry
   */
  useEffect(() => {
    return sentryClient.disable
  }, [])

  return null
}
