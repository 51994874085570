import React from 'react'
import { Tooltip as GrafanaTooltip } from '@grafana/ui'

export type TooltipProps = React.ComponentProps<typeof GrafanaTooltip>

export const Tooltip = ({ show = true, children, ...rest }: TooltipProps) => {
  if (!show) {
    return children
  }

  return <GrafanaTooltip {...rest}>{children}</GrafanaTooltip>
}
