import React, { Component, ReactNode } from 'react'

import { sentryClient } from 'services/sentryClient'
import { ErrorMessage } from './ErrorMessage'

export class ErrorBoundary extends Component<{ children?: ReactNode }> {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    sentryClient.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessage />
    }

    return this.props.children
  }
}
