import { ChartMetric } from 'components/Chart/Chart.types'
import { TagQuery, Color } from 'types'

export class MetricBuilder {
  metric: ChartMetric

  constructor(metric: ChartMetric) {
    this.metric = metric
  }

  withLabel(label: string | false | null | undefined) {
    if (typeof label !== 'string') {
      return this
    }

    this.metric = {
      ...this.metric,
      label,
    }

    return this
  }

  withType(type: ChartMetric['type'] | false | null | undefined) {
    if (typeof type !== 'string') {
      return this
    }

    this.metric = {
      ...this.metric,
      type,
    }

    return this
  }

  withTags(tags: TagQuery) {
    this.metric = {
      ...this.metric,
      query: {
        ...this.metric.query,
        tags: {
          ...this.metric.query.tags,
          ...tags,
        },
      },
    }

    return this
  }

  withColor(color: Color | false | null | undefined) {
    if (typeof color !== 'string') {
      return this
    }

    this.metric = {
      ...this.metric,
      color,
    }

    return this
  }

  build() {
    return this.metric
  }
}

type TagValue = string | number | boolean | null | undefined

export class TagQueryBuilder {
  query: TagQuery = {}

  equal(name: string, value: TagValue) {
    if (value === undefined || value === null) {
      return this
    }

    const expression = this.query[name] ?? {
      name,
      operator: 'equal',
      values: [],
    }

    if (expression.operator !== 'equal') {
      throw new Error(
        `Failed to build tag query. Cannot use the 'equal' operator with tag '${name}', because it is already being used with the 'not-equal' operator.`
      )
    }

    this.query = {
      ...this.query,
      [name]: {
        ...expression,
        values: [...expression.values, value.toString()],
      },
    }

    return this
  }

  notEqual(name: string, value: TagValue) {
    if (value === undefined || value === null) {
      return this
    }

    const expression = this.query[name] ?? {
      name,
      operator: 'not-equal',
      values: [],
    }

    if (expression.operator !== 'not-equal') {
      throw new Error(
        `Failed to build tag query. Cannot use the 'not-equal' operator with tag '${name}', because it is already being used with the 'equal' operator.`
      )
    }

    this.query = {
      ...this.query,
      [name]: {
        ...expression,
        values: [...expression.values, value.toString()],
      },
    }

    return this
  }

  build() {
    return this.query
  }
}
