import React, { useMemo } from 'react'
import { TestRun } from 'types'
import { Insight, InsightsByStatus } from 'types/insights'
import { InsightFilter } from '../Insights.types'
import { filterInsights } from '../Insights.utils'
import { EmptyInsightList } from './EmptyInsightList'
import { exhaustive } from 'utils/typescript'
import { DataTable } from 'components/DataTable'
import { TableColumn } from 'react-data-table-component'
import { ExpanderComponentProps } from 'react-data-table-component/dist/src/DataTable/types'
import * as BestPractice from './BestPractices'
import * as Http from './HTTP'
import * as Health from './Health'
import { InsightListExpanderIcon, InsightListWrapper } from './InsightList.styles'
import { Icon } from '@grafana/ui'

interface InsightRowProps {
  testRun: TestRun
  insight: Insight
}

const InsightRowItem = ({ insight }: InsightRowProps) => {
  /* spell-checker: disable */
  switch (insight.name) {
    case 'http_load_throughput_limit':
      return <Http.ThroughputLimitItem insight={insight} />

    case 'http_load_increased_failure_rate':
      return <Http.IncreasedFailureRateItem insight={insight} />

    case 'http_load_high_failure_rate':
      return <Http.HighFailureRateItem insight={insight} />

    case 'best_practice_too_many_urls':
      return <BestPractice.TooManyUrlsItem insight={insight} />

    case 'best_practice_too_many_groups':
      return <BestPractice.TooManyGroupsItem insight={insight} />

    case 'best_practice_too_many_metrics':
      return <BestPractice.TooManyMetricsItem insight={insight} />

    case 'best_practice_too_many_time_series':
      return <BestPractice.TooManyTimeSeriesItem insight={insight} />

    case 'best_practice_third_party_content':
      return <BestPractice.ThirdPartyContentItem insight={insight} />

    case 'best_practice_outdated_k6_release_used':
      return <BestPractice.OutdatedK6ReleaseUsedItem insight={insight} />

    case 'health_high_loadgen_cpu_usage':
      return <Health.HighLoadGeneratorCPUUsageItem insight={insight} />

    case 'health_high_loadgen_mem_usage':
      return <Health.HighLoadGeneratorMemoryUsageItem insight={insight} />

    default:
      return exhaustive(insight, <></>)
  }
  /* spell-checker: enable */
}

interface InsightRowBodyProps extends ExpanderComponentProps<InsightRowProps> {}

const InsightRowBody = ({ data: { testRun, insight } }: InsightRowBodyProps) => {
  if (insight.status !== 'FAILED') {
    return null
  }

  switch (insight.name) {
    case 'http_load_throughput_limit':
      return <Http.ThroughputLimit testRun={testRun} insight={insight} />

    case 'http_load_increased_failure_rate':
      return <Http.IncreasedFailureRate testRun={testRun} insight={insight} />

    case 'http_load_high_failure_rate':
      return <Http.HighFailureRate insight={insight} />

    case 'best_practice_too_many_urls':
      return <BestPractice.TooManyUrls />

    case 'best_practice_too_many_groups':
      return <BestPractice.TooManyGroups />

    case 'best_practice_too_many_metrics':
      return <BestPractice.TooManyMetrics />

    case 'best_practice_too_many_time_series':
      return <BestPractice.TooManyTimeSeries insight={insight} />

    case 'best_practice_third_party_content':
      return <BestPractice.ThirdPartyContent insight={insight} />

    case 'best_practice_outdated_k6_release_used':
      return <BestPractice.OutdatedK6ReleaseUsed insight={insight} />

    case 'health_high_loadgen_cpu_usage':
      return <Health.HighLoadGeneratorCPUUsage testRun={testRun} insight={insight} />

    case 'health_high_loadgen_mem_usage':
      return <Health.HighLoadGeneratorMemoryUsage testRun={testRun} insight={insight} />

    default:
      return exhaustive(insight, <></>)
  }
}

const columns: Array<TableColumn<InsightRowProps>> = [{ cell: InsightRowItem }]

interface InsightsListProps {
  testRun: TestRun
  filter: InsightFilter
  insights: InsightsByStatus
}

export const InsightList = ({ testRun, filter, insights }: InsightsListProps) => {
  const filteredInsights = useMemo(
    () => filterInsights(insights, filter).map((insight) => ({ testRun, insight })),
    [testRun, insights, filter]
  )

  if (filteredInsights.length === 0) {
    return <EmptyInsightList testRun={testRun} filter={filter} insights={insights} />
  }

  return (
    <InsightListWrapper>
      <DataTable
        columns={columns}
        data={filteredInsights}
        noHeader
        noTableHead
        highlightOnHover
        conditionalRowStyles={[{ when: (row) => row.insight.status === 'FAILED', classNames: ['failed'] }]}
        expandOnRowClicked
        expandableRows
        expandableIcon={{
          collapsed: (
            <InsightListExpanderIcon>
              <Icon name="angle-down" />
            </InsightListExpanderIcon>
          ),
          expanded: (
            <InsightListExpanderIcon>
              <Icon name="angle-up" />
            </InsightListExpanderIcon>
          ),
        }}
        expandableRowsComponent={InsightRowBody}
        expandableRowDisabled={(props) => props.insight.status !== 'FAILED'}
      />
    </InsightListWrapper>
  )
}
