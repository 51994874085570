import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { HorizontalGroup, Icon } from '@grafana/ui'

import { isTestActive } from 'utils/testRun'
import { useAppContext } from 'appContext'
import { useBreadcrumbs } from 'breadcrumbsContext'
import { useRuns } from 'data/useRuns'
import { useTest } from 'data/useTest'
import { createBreadcrumbs } from './TestPage.utils'
import { RunsTable, TrendingChart } from './components'

import { PageSection } from 'components/PagePanel'
import { NoteModal } from 'components/NoteModal'
import { TestRun } from 'types'
import { PluginPage } from 'components/PluginPage'
import { isTopNavEnabled } from 'utils/topnav'
import { TestActions } from './components/TestActions'

export const TestPage = () => {
  const [focusedTestRun, setFocusedTestRun] = useState<TestRun>()
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false)

  const { testId: testIdStr } = useParams<{ testId: string }>()
  const testId = Number(testIdStr)
  const { project } = useAppContext()
  const { pageNav, setBreadcrumbs } = useBreadcrumbs()
  const { runs, fetchNextPage, isFetching, isLoading } = useRuns(testId)
  const { data: test } = useTest(testId)

  const isTopNav = isTopNavEnabled()
  const isRunActive = runs.some(isTestActive)

  const handleDismissNoteModal = () => {
    setShowNoteModal(false)
  }

  const handleEditNoteClick = (row?: TestRun) => {
    setFocusedTestRun(row)
    setShowNoteModal(true)
  }

  useEffect(() => {
    if (!project || !test) {
      return
    }

    setBreadcrumbs(createBreadcrumbs(test))
  }, [project, test, setBreadcrumbs])

  return (
    <PluginPage pageNav={pageNav} subTitle="Test overview" actions={<TestActions test={test} />}>
      <PageSection>
        <HorizontalGroup justify={'space-between'}>
          <div>
            {isRunActive && (
              <h3>
                <Icon name="fa fa-spinner" /> Tests are running
              </h3>
            )}
          </div>
          {!isTopNav && <TestActions test={test} />}
        </HorizontalGroup>
        <TrendingChart runs={runs} isLoading={isLoading || isFetching} />
        <RunsTable
          runs={runs}
          isLoading={isFetching}
          loadNext={fetchNextPage}
          isLoadingInitial={isLoading}
          onEditNote={handleEditNoteClick}
        />
        {focusedTestRun && <NoteModal isOpen={showNoteModal} onDismiss={handleDismissNoteModal} run={focusedTestRun} />}
      </PageSection>
    </PluginPage>
  )
}
