import { useState } from 'react'
import { ConfirmModalProps } from 'components/ConfirmModal'

export const useConfirmation = (
  handler: () => Promise<void> | void
): [Pick<ConfirmModalProps, 'isOpen' | 'isPending' | 'onConfirm' | 'onDismiss'>, () => void] => {
  const [isOpen, setIsOpen] = useState(false)
  const [isPending, setIsPending] = useState(false)

  const onConfirm = async () => {
    setIsPending(true)
    await handler()
    setIsPending(false)
    setIsOpen(false)
  }

  const onDismiss = () => {
    setIsOpen(false)
  }

  const requestConfirmation = () => {
    setIsOpen(true)
  }

  return [
    {
      isOpen,
      isPending,
      onConfirm,
      onDismiss,
    },
    requestConfirmation,
  ]
}
