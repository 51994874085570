import { useInfiniteQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { union } from 'lodash-es'

import { useDatasource } from 'datasourceContext'
import { Test, TestRun, TestSortOptions } from 'types'
import { mergeArraysByKey } from 'utils/array'
import { useActiveRuns } from './useActiveRuns'
import { toTestsQueryKey } from './queryKeys'

type GetActiveTests = (
  testsFlat: Test[],
  activeRuns: TestRun[],
  setNewTestIds: (fn: (ids: number[]) => number[]) => void
) => Test[]

const getActiveTests: GetActiveTests = (testsFlat, activeRuns, setNewTestIds) => {
  return activeRuns.flatMap((testRun) => {
    const test = testsFlat.find((t) => t.id === testRun.test_id)

    if (!test) {
      // New test started, fetch it
      setNewTestIds((ids) => union(ids, [testRun.test_id]))
      return []
    }

    const runs = mergeArraysByKey(test.test_runs, [testRun], 'id')
    return [{ ...test, test_runs: runs }]
  })
}

export const useTests = (projectId: number, search?: string, order?: TestSortOptions) => {
  const { ds } = useDatasource()
  const [testsFlat, setTestsFlat] = useState<Test[]>([])
  const [newTestIds, setNewTestIds] = useState<number[]>([])

  const { data, isFetching, fetchNextPage } = useInfiniteQuery(
    toTestsQueryKey(projectId, search, order),
    ({ pageParam = 1 }) => ds.fetchTests({ project: projectId, page: pageParam, search, orderBy: order }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length === lastPage.meta.count) {
          return undefined
        }

        return pages.length + 1
      },
    }
  )

  const { data: activeRuns } = useActiveRuns()

  const flattenTests = () => {
    if (!data) {
      return
    }

    setTestsFlat(data.pages.flatMap((page) => page['k6-tests']))
  }

  const setActiveTests = () => {
    if (!activeRuns || !data) {
      return
    }

    setTestsFlat((currentTests) => {
      const activeTests = getActiveTests(currentTests, activeRuns, setNewTestIds)

      return activeTests.reduce((tests, activeTest) => {
        const activeTestIndex = tests.findIndex((test) => test.id === activeTest.id)

        if (activeTestIndex !== -1) {
          tests[activeTestIndex] = activeTest
        } else {
          tests.push(activeTest)
        }

        return tests
      }, currentTests)
    })
  }

  const fetchNewlyStartedTests = () => {
    if (newTestIds.length === 0) {
      return
    }

    newTestIds.forEach((id) => {
      ds.fetchTest(id).then((newTest) => {
        setTestsFlat((tests) => {
          if (tests.find((test) => test.id === newTest.id)) {
            return tests
          }

          if (order === TestSortOptions.LastTestRun) {
            return [newTest, ...tests]
          }

          return [...tests, newTest]
        })
      })
    })
    setNewTestIds([])
  }

  useEffect(flattenTests, [data])
  useEffect(setActiveTests, [activeRuns, setNewTestIds, data])
  useEffect(fetchNewlyStartedTests, [ds, testsFlat, newTestIds, order])

  return {
    isFetching,
    fetchNextPage,
    tests: testsFlat,
  }
}
