import React, { useMemo } from 'react'

import { PanelChrome } from '@grafana/ui'
import { isFetched } from 'utils/reactQuery'
import { useTimeSeries } from './Chart.hooks'
import { ChartAnnotation, ChartMetric } from './Chart.types'
import { ChartPanel } from './ChartPanel'
import { QueryType, TestRun } from 'types'
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer'
import { FixedWidthLoadingContainer } from './Chart.styles'
import { ChartClipboard as Clipboard } from 'components/ChartClipboard'
import { useDatasource } from 'datasourceContext'
import { toPanelConfig } from './Chart.utils'

interface ChartProps {
  title: string
  testRun: TestRun
  loading?: boolean
  height?: number
  annotations?: ChartAnnotation[]
  metrics: ChartMetric[]
}

export const Chart = ({ title, testRun, metrics, annotations = [], loading = false, height = 300 }: ChartProps) => {
  const { ds } = useDatasource()

  const series = useTimeSeries({
    testRun,
    metrics,
    enabled: metrics.length > 0,
  })

  const panelConfig = useMemo(() => toPanelConfig(ds, title, testRun, metrics), [ds, title, testRun, metrics])

  return (
    <ReactVirtualizedAutoSizer disableHeight>
      {(size) => (
        <FixedWidthLoadingContainer $width={size.width} loading={loading || !series.every(isFetched)}>
          <PanelChrome
            width={size.width}
            height={height}
            leftItems={[
              <Clipboard
                key="clipboard"
                ds={ds}
                testRun={testRun}
                queryType={QueryType.METRIC}
                panelConfig={panelConfig}
              />,
            ]}
          >
            {(innerWidth, innerHeight) => (
              <ChartPanel
                testRun={testRun}
                series={series}
                annotations={annotations}
                width={innerWidth}
                height={innerHeight}
              />
            )}
          </PanelChrome>
        </FixedWidthLoadingContainer>
      )}
    </ReactVirtualizedAutoSizer>
  )
}
