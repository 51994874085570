import { useQueryParameter } from 'hooks/useQueryParameter'
import { InsightsByStatus } from 'types/insights'
import { InsightFilter } from './Insights.types'

interface InsightState {
  open: boolean
  filter: InsightFilter
}

export const useInsightFilter = (insights: InsightsByStatus | undefined) => {
  return useQueryParameter<InsightState>({
    name: 'insights',
    decoder: (value) => {
      const open = value !== null && !value.startsWith('-')
      const filter = value?.replace(/^-/, '')

      switch (filter) {
        case 'all':
        case 'passed':
        case 'failed':
        case 'skipped':
          return { open, filter }

        default:
          return {
            open: (insights?.FAILED.length ?? 0) > 0,
            filter: 'failed',
          }
      }
    },
    encoder: ({ open, filter }) => (open ? filter : `-${filter}`),
  })
}
