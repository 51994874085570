import React from 'react'

import { exhaustive } from 'utils/typescript'
import { Protocol } from '../RunOverview.types'
import { GrpcSummary } from './GrpcSummary'
import { HttpSummary } from './HttpSummary'
import { EmptyStatistic } from './Statistic'
import { WebSocketsSummary } from './WebSocketSummary'

interface ProtocolSummaryProps {
  protocol: Protocol | undefined
}

export const ProtocolSummary = ({ protocol }: ProtocolSummaryProps) => {
  if (protocol === undefined) {
    return <EmptyStatistic />
  }

  switch (protocol.type) {
    case 'http':
      return <HttpSummary summary={protocol.summary} />

    case 'ws':
      return <WebSocketsSummary summary={protocol.summary} />

    case 'grpc':
      return <GrpcSummary summary={protocol.summary} />

    default:
      return exhaustive(protocol)
  }
}
