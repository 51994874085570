import styled from 'styled-components'
import { Link } from 'react-router-dom'

const smallText = '13px'
const opacity = '0.7'

export const TitleBody = styled.p`
  margin-bottom: 5px;
`

export const TitleText = styled.span`
  opacity: 0.7;
  margin-right: 10px;
`

export const Time = styled.time`
  font-size: ${smallText};
  opacity: ${opacity};
`

export const BadgeLink = styled(Link)`
  &:hover {
    filter: brightness(1.3);
  }
`

export const DetailsBody = styled.span`
  display: flex;
  flex: 1 1 0;
  font-size: ${smallText};

  > div {
    align-self: center;
  }

  > div:not(:first-child) {
    border-left: 1px rgba(255, 255, 255, 0.1) solid;
    padding: 0 16px;
    margin-left: 15px;
  }
`

export const TextOpaque = styled.span`
  opacity: ${opacity};
`

export const TextNoBreak = styled.span`
  white-space: nowrap;
`
