import { ThresholdsMode } from '@grafana/data'
import {
  SortOrder,
  VisibilityMode,
  AxisPlacement,
  GraphDrawStyle,
  GraphGradientMode,
  GraphTresholdsStyleMode,
  LegendDisplayMode,
  LineInterpolation,
  ScaleDistribution,
  StackingMode,
  TooltipDisplayMode,
} from '@grafana/schema'
import { QueryType } from 'types'
import { TimeSeriesPanelConfig } from './types'

export const thresholdPanel: TimeSeriesPanelConfig = {
  id: 23763571993,
  gridPos: {
    x: 0,
    y: 0,
    w: 12,
    h: 8,
  },
  type: 'timeseries',
  title: 'Thresholds',
  options: {
    tooltip: {
      mode: TooltipDisplayMode.Single,
      sort: SortOrder.None,
    },
    legend: {
      showLegend: true,
      displayMode: LegendDisplayMode.List,
      placement: 'bottom',
      calcs: [],
    },
  },
  fieldConfig: {
    defaults: {
      custom: {
        drawStyle: GraphDrawStyle.Line,
        lineInterpolation: LineInterpolation.Linear,
        barAlignment: 0,
        lineWidth: 1,
        fillOpacity: 0,
        gradientMode: GraphGradientMode.None,
        spanNulls: false,
        showPoints: VisibilityMode.Auto,
        pointSize: 5,
        stacking: {
          mode: StackingMode.None,
          group: 'A',
        },
        axisPlacement: AxisPlacement.Auto,
        axisLabel: '',
        scaleDistribution: {
          type: ScaleDistribution.Linear,
        },
        hideFrom: {
          tooltip: false,
          viz: false,
          legend: false,
        },
        thresholdsStyle: {
          mode: GraphTresholdsStyleMode.Line,
        },
      },
      color: {
        mode: 'palette-classic',
      },
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [
          {
            value: 0,
            color: 'green',
          },
          {
            value: 10000,
            color: 'red',
          },
        ],
      },
      mappings: [],
    },
    overrides: [
      {
        matcher: {
          id: 'byName',
          options: 'Value',
        },
        properties: [
          {
            id: 'displayName',
            value: 'Value',
          },
        ],
      },
    ],
  },
  datasource: { type: 'grafana-k6-datasource' },
  targets: [
    {
      refId: 'A',
      qtype: QueryType.THRESHOLDS,
      queryType: QueryType.THRESHOLDS,
      projectId: 1283,
      testId: 3185,
      testRunId: 135315,
      uid: '49696',
    },
  ],
}
