import React from 'react'

import { TestRun } from 'types'

import { useHttpFilterOptions, useRunDetailsHttpFilters } from '../Filters/FiltersContext.hooks'
import { RunDetailsFilters } from '../Filters/Filters'

export const HttpFilters = ({ run }: { run: TestRun }) => {
  const { filters, addNewFilter, removeFilter, addFilterValue } = useRunDetailsHttpFilters()
  const options = useHttpFilterOptions()

  return (
    <RunDetailsFilters
      filters={filters}
      options={options}
      run={run}
      onAddNewFilterClick={addNewFilter}
      onRemoveFilterClick={removeFilter}
      onAddFilterValue={addFilterValue}
    />
  )
}
