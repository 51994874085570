import React from 'react'
import styled from 'styled-components'

// @ts-ignore
const flagDir = require.context('circle-flags/flags')

const StyledFlag = styled.img`
  height: 100%;
  display: block;
`

export const Flag = ({ name }: { name: string }) => {
  const iconSrc = getFlagSrc(name)

  if (!iconSrc) {
    return null
  }

  return <StyledFlag src={iconSrc} />
}

const normalizeCountryCode = (code: string) => {
  const mapping: Record<string, string | undefined> = {
    // We use SA for South Africa which is ZA in circle flags repo
    sa: 'za',
  }

  return mapping[code] || code
}

const getFlagSrc = (name: string) => {
  try {
    return flagDir(`./${normalizeCountryCode(name)}.svg`)
  } catch {
    return null
  }
}
