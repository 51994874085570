import styled from 'styled-components'
import { Icon as GrafanaIcon } from '@grafana/ui'

export const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.h5.fontSize};

  a {
    color: ${({ theme }) => theme.colors.text.primary};

    &:hover {
      text-decoration: none;
    }
  }
`

export const TextContent = styled.div`
  display: inline-flex;
  flex-direction: column;
`

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: ${({ theme }) => theme.typography.h6.fontSize};
`
export const Icon = styled(GrafanaIcon)`
  margin: ${({ theme }) => theme.spacing(0, 1)};
`
