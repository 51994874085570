import { useMutation } from '@tanstack/react-query'

import { useDatasource } from 'datasourceContext'
import { ApiError, TestSortOptions } from 'types'
import { showAlert } from 'utils/showAlert'
import { toTestsQueryKey } from './queryKeys'
import { queryClient } from './useFetch'

export const useDeleteTests = (projectId: number, search?: string, order?: TestSortOptions) => {
  const { ds } = useDatasource()

  return useMutation<unknown, ApiError, number[], unknown>((ids: number[]) => ds.deleteTests(ids), {
    onSuccess: (_, ids) => {
      queryClient.invalidateQueries(toTestsQueryKey(projectId, search, order))
      showAlert(`Successfully deleted ${ids.length} tests.`, 'success')
    },
    onError: (_, test) => showAlert('Failed to delete one or more of the selected tests.', 'error'),
  })
}
