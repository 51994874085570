import { ThroughputLimitInsightSegment } from 'types/insights/http'
import { quantity } from 'utils/formatters'

export const formatThroughputLimitAnnotation = (segment: ThroughputLimitInsightSegment) => {
  if (segment.avg_request_rate) {
    return `Throughput limited to an average of ${quantity(segment.avg_request_rate)} reqs/s.`
  }

  return `Throughput limit detected.`
}
