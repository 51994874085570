import React, { useState } from 'react'

import { TestRun, WSValue } from 'types'
import { separatorFormatter } from 'utils/formatters'
import { useWebSocketsStats } from 'data/useWebSocketsStats'
import { WSChart } from './WSChart'
import { WSFilters } from './WSFilters'
import { getSessionDurationFormat, getConnectingFormat } from './WSTab.utils'
import { LoadingContainer } from 'components/LoadingContainer'
import { DataTable } from 'components/DataTable'
import { TableColumn } from 'react-data-table-component/dist/src/DataTable/types'

import { useRunDetailsWebSocketsFilters } from '../Filters/FiltersContext.hooks'
import { getFilledFilters, getShowFilterSelect } from '../Filters/Filters.utils'
import { RunDetailsTableEmptyState } from '../TableEmptyState'
import { TooltipCell } from 'components/DataTable/TooltipCell'

interface Props {
  run: TestRun
}

const PAGE_SIZE = 20

const columns: Array<TableColumn<WSValue>> = [
  {
    name: 'URL',
    sortable: true,
    sortField: 'name',
    cell: ({ name }) => <TooltipCell>{name}</TooltipCell>,
    minWidth: '300px',
  },
  {
    name: 'SCENARIO',
    sortable: true,
    sortField: 'scenario',
    cell: ({ scenario }) => <TooltipCell show={scenario !== 'default'}>{scenario}</TooltipCell>,
  },
  {
    name: 'STATUS',
    sortable: true,
    sortField: 'status',
    selector: (row) => row.status,
  },
  {
    name: 'SESSION COUNT',
    sortable: true,
    sortField: 'ws_metric_summary/sessions',
    selector: (row) => separatorFormatter(row.ws_metric_summary.sessions),
  },
  {
    name: 'P95 CONNECTING TIME',
    sortable: true,
    sortField: 'ws_metric_summary/connecting/p95',
    selector: (row) => getConnectingFormat('p95', row),
  },
  {
    name: 'P95 SESSION DURATION',
    sortable: true,
    sortField: 'ws_metric_summary/session_duration/p95',
    selector: (row) => getSessionDurationFormat('p95', row),
  },
]

export const WSTab = ({ run }: Props) => {
  const { filters, removeAllFilters } = useRunDetailsWebSocketsFilters()
  const [orderBy, setOrderBy] = useState<string>()

  const {
    data: wsResponse,
    fetchPage,
    currentPage,
    isFetching: isFetchingWS,
    isLoading: isLoadingWS,
  } = useWebSocketsStats(run, {
    pageSize: PAGE_SIZE,
    filters: getFilledFilters(filters),
    refetchOnMount: false,
    orderBy,
  })

  const tableData = wsResponse?.value || []
  const showFilters = getShowFilterSelect(tableData, filters)

  return (
    <LoadingContainer loading={!tableData.length}>
      <div>
        {showFilters && <WSFilters run={run} />}

        <DataTable
          columns={columns}
          noDataComponent={<RunDetailsTableEmptyState filters={filters} onClearFiltersClick={removeAllFilters} />}
          data={tableData}
          highlightOnHover
          pointerOnHover
          expandableRowsHideExpander
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={({ data }) => <WSChart run={run} url={data} />}
          expandableRowsComponentProps={{ run }}
          pagination
          paginationServer
          paginationPerPage={PAGE_SIZE}
          currentPage={currentPage}
          paginationTotalRows={wsResponse?.['@count']}
          onChangePage={fetchPage}
          isFetching={isFetchingWS || isLoadingWS}
          sortServer
          onSort={setOrderBy}
        />
      </div>
    </LoadingContainer>
  )
}
