import React, { ReactNode } from 'react'
import { DocArticle } from './docs'

interface DocsLinkProps {
  article: DocArticle
  children: ReactNode
}

export const DocLink = ({ article, children }: DocsLinkProps) => {
  return (
    <a href={`https://k6.io/docs${article}`} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}
