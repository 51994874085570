import { ChartMetric } from 'components/Chart/Chart.types'
import { VUS_METRIC } from 'constants/metrics'
import { TimeSeriesUnit } from 'panels/types'

import {
  AggregationMethod,
  NamedColors,
  PercentileAggregation,
  TagQuery,
  Threshold,
  ThresholdPercentile,
  ThresholdStat,
} from 'types'
import { exhaustive } from 'utils/typescript'

class ThresholdParserError extends Error {
  constructor(input: string, reason: string) {
    super(`Failed to parse threshold '${input}'. ${reason}`)
  }
}

const parseTags = (input: string, expression: string): TagQuery => {
  if (expression === '') {
    return {}
  }

  const result: TagQuery = {}

  const pairs = expression.split(',').map((pair) => pair.trim())

  for (let pair of pairs) {
    const separatorIndex = pair.indexOf(':')

    if (separatorIndex === -1) {
      throw new ThresholdParserError(input, `Missing a colon after the tag '${pair}'.`)
    }

    const tag = pair.substring(0, separatorIndex)
    const value = pair.substring(separatorIndex + 1)

    if (tag === '') {
      throw new ThresholdParserError(input, `Found a colon without a tag name before it.`)
    }

    if (value === '') {
      throw new ThresholdParserError(input, `The tag '${tag}' does not have a value.`)
    }

    result[tag] = {
      name: tag,
      operator: 'equal',
      values: [value],
    }
  }

  return result
}

export interface ParsedThreshold {
  name: string
  metric: string
  tags: TagQuery
  condition: string
}

/**
 * This function parses the threshold name to get the metric name and tags.
 * Ideally this would be done by backend and I've posted a feature request for
 * it here: https://github.com/grafana/k6-backend/issues/507.
 *
 * The logic here is the same as the k6 parser code (), with the addition of
 * removing the threshold expression that is included by the API.
 *
 * https://github.com/grafana/k6/blob/28a567de1d895f6239cf0dc636ca9f189121d33f/metrics/metric.go#L90
 */
export const parseThresholdName = (input: string): ParsedThreshold => {
  const separatorIndex = input.lastIndexOf(':')

  if (separatorIndex === -1) {
    throw new ThresholdParserError(input, 'Missing a colon after the metric definition.')
  }

  const name = input.substring(0, separatorIndex)
  const condition = input.substring(separatorIndex + 1).trim()

  const tagsStart = name.indexOf('{')
  const tagsEnd = name.lastIndexOf('}')

  if (tagsStart === -1 && tagsEnd === -1) {
    return {
      name,
      metric: name,
      tags: {},
      condition,
    }
  }

  // If we've gotten this far, we know there's at least one curly brace. Next we make sure that they
  // are matched properly.
  if (tagsStart === -1 || tagsStart > tagsEnd) {
    throw new ThresholdParserError(input, 'Found a closing curly brace without a matching opening curly brace.')
  }

  if (tagsEnd === -1) {
    throw new ThresholdParserError(input, 'Found an opening curly brace without a matching closing curly brace.')
  }

  if (tagsEnd !== name.length - 1) {
    throw new ThresholdParserError(
      input,
      `Expected to find a colon after the tag definition, but instead found '${name.substring(tagsEnd)}'.`
    )
  }

  const metric = name.substring(0, tagsStart)

  if (metric.includes('}')) {
    throw new ThresholdParserError(input, 'Found a closing curly brace before the tag definition.')
  }

  const tags = name.substring(tagsStart + 1, tagsEnd)

  return {
    name,
    metric: metric,
    tags: parseTags(input, tags),
    condition,
  }
}

const isPercentileStat = (stat: ThresholdStat): stat is ThresholdPercentile => /^p|P\(/.test(stat)

/**
 * This function parses the threshold stat to get the appropriate aggregation method.
 * Ideally this would be done by backend and I've posted a feature request for
 * it here: https://github.com/grafana/k6-backend/issues/507.
 */
export const parseThresholdStat = (metric: string, stat: ThresholdStat): AggregationMethod => {
  if (isPercentileStat(stat)) {
    const [, percentile = '0'] = /^(?:p|P)\((\d+(\.\d+)?)\)$/.exec(stat) ?? []
    const [int = '0', fraction = ''] = percentile.split('.')

    return `0.${int.padStart(2, '0')}${fraction}` as PercentileAggregation
  }

  switch (stat) {
    case 'med':
      return '0.50'

    case 'avg':
    case 'min':
    case 'max':
    case 'rate':
    case 'count':
      return stat

    case 'value':
      // Unfortunately these are special cased. Hopefully backend can give us the
      // correct mapping if my feature request is implemented.
      if (metric === 'vus' || metric === 'vus_max' || metric === 'ramping') {
        return 'sum[last]'
      }

      return 'max[last]'

    default:
      return exhaustive(stat)
  }
}

export const getThresholdMetrics = (threshold: Threshold) => {
  const parsedExpression = parseThresholdName(threshold.name)
  const parsedMethod = parseThresholdStat(parsedExpression.metric, threshold.stat)

  const metric: ChartMetric = {
    label: parsedExpression.metric,
    unit: TimeSeriesUnit.None,
    type: 'line',
    thresholds: [{ color: threshold.tainted ? NamedColors.Red : NamedColors.Red, value: threshold.value }],
    query: {
      metric: parsedExpression.metric,
      method: parsedMethod as any,
      tags: parsedExpression.tags,
    },
  }

  return [VUS_METRIC, metric]
}
