import React, { useMemo } from 'react'

import { GrpcUrl, NamedColors, TagQuery, TestRun } from 'types'
import { Chart } from 'components/Chart'
import { GRPC_REQUEST_RATE, GRPC_RESPONSE_TIME_P95, VUS_METRIC } from 'constants/metrics'
import { getGRPCStatusText } from './GRPCUrlsTab.utils'
import { MetricBuilder, TagQueryBuilder } from 'utils/metrics'

export type GRPCUrlsChartProps = {
  url: GrpcUrl
  testRun: TestRun
}

export const GRPCUrlsChart = ({ testRun, url }: GRPCUrlsChartProps) => {
  const metrics = useMemo(() => {
    const query: TagQuery = new TagQueryBuilder()
      .equal('name', url.name)
      .equal('method', url.method)
      .equal('status', url.status)
      .build()

    const requestRate = new MetricBuilder(GRPC_REQUEST_RATE)
      .withLabel(!url.expected_response && 'Failed requests rate')
      .withColor(!url.expected_response && NamedColors.Red)
      .withType(!url.expected_response && 'bars')
      .withTags(query)
      .build()

    const responseTime = new MetricBuilder(GRPC_RESPONSE_TIME_P95).withTags(query).build()

    return [VUS_METRIC, requestRate, ...(url.expected_response ? [responseTime] : [])]
  }, [url.name, url.method, url.status, url.expected_response])

  return (
    <Chart title={`${url.host}${url.name} (${getGRPCStatusText(url.status)})`} testRun={testRun} metrics={metrics} />
  )
}
