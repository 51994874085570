import React from 'react'
import { GrpcMetricSummary } from 'types'
import { quantity, requestRate, timing } from 'utils/formatters'

interface GRPCTestSummaryProps {
  summary: GrpcMetricSummary
}

export const GRPCTestSummary = ({ summary }: GRPCTestSummaryProps) => {
  return (
    <>
      The 95th percentile response time of the system being tested was <b>{timing(summary.duration.p95 || 0)}</b>, and{' '}
      <b>{quantity(summary.requests_count)}</b> requests were made at an average rate of{' '}
      <b>{requestRate(summary.rps_mean, { unit: 'none' })}</b> requests/second.
    </>
  )
}
