import React, { ReactNode, useRef, useEffect } from 'react'

import { CenteredSpinner } from './CenteredSpinner'

type InfiniteScrollProps = {
  children: ReactNode
  isLoading: boolean
  loadNext: () => void
}

export const InfiniteScroll = ({ children, isLoading, loadNext }: InfiniteScrollProps) => {
  const loadingRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleObserver = (entities: IntersectionObserverEntry[]) => {
      const target = entities[0]
      if (target?.isIntersecting && !isLoading) {
        loadNext()
      }
    }

    const options = {
      root: null,
      rootMargin: '50px',
      threshold: 1.0,
    }

    const observer = new IntersectionObserver(handleObserver, options)

    if (loadingRef.current) {
      observer.observe(loadingRef.current)
    }

    const current = loadingRef.current
    return () => {
      if (current) {
        observer.unobserve(current)
      }
    }
  }, [isLoading, loadNext])

  return (
    <>
      {children}
      <div ref={loadingRef}>{isLoading && <CenteredSpinner $height="58px" />}</div>
    </>
  )
}
