import { FieldType } from '@grafana/data'
import {
  SortOrder,
  VisibilityMode,
  AxisPlacement,
  GraphDrawStyle,
  GraphGradientMode,
  LegendDisplayMode,
  LineInterpolation,
  ScaleDistribution,
  StackingMode,
  TooltipDisplayMode,
} from '@grafana/schema'
import { QueryType } from 'types'
import { TimeSeriesPanelConfig } from './types'

export const checksPanel: TimeSeriesPanelConfig = {
  id: 23763571993,
  gridPos: {
    x: 0,
    y: 0,
    w: 12,
    h: 8,
  },
  type: 'timeseries',
  title: 'Checks',
  transformations: [
    {
      id: 'convertFieldType',
      options: {
        conversions: [
          {
            destinationType: FieldType.time,
            targetField: 'Timestamp',
          },
        ],
      },
    },
  ],
  options: {
    tooltip: {
      mode: TooltipDisplayMode.Single,
      sort: SortOrder.None,
    },
    legend: {
      showLegend: true,
      displayMode: LegendDisplayMode.List,
      placement: 'bottom',
      calcs: [],
    },
  },
  fieldConfig: {
    defaults: {
      custom: {
        drawStyle: GraphDrawStyle.Bars,
        lineInterpolation: LineInterpolation.Linear,
        barAlignment: 0,
        lineWidth: 7,
        barMaxWidth: 1,
        fillOpacity: 100,
        gradientMode: GraphGradientMode.None,
        spanNulls: true,
        showPoints: VisibilityMode.Auto,
        pointSize: 12,
        stacking: {
          mode: StackingMode.None,
          group: 'A',
        },
        axisPlacement: AxisPlacement.Auto,
        axisLabel: '',
        scaleDistribution: {
          type: ScaleDistribution.Linear,
        },
        hideFrom: {
          tooltip: false,
          viz: false,
          legend: false,
        },
      },
      color: {
        mode: 'palette-classic',
      },
      mappings: [],
    },
    overrides: [],
  },
  datasource: { type: 'grafana-k6-datasource' },
  targets: [
    {
      refId: 'A',
      qtype: QueryType.CHECKS,
      queryType: QueryType.CHECKS,
      projectId: 1283,
      testId: 3185,
      testRunId: 135315,
      metric: 'passes',
      aggregation: 'count',
      uid: '01faf35e-9caf-5038-a3a1-3423b1b50d9e',
    },
    {
      refId: 'B',
      hide: false,
      qtype: QueryType.CHECKS,
      queryType: QueryType.CHECKS,
      projectId: 1283,
      testId: 3185,
      testRunId: 135315,
      metric: 'failures',
      aggregation: 'count',
      uid: '01faf35e-9caf-5038-a3a1-3423b1b50d9e',
    },
  ],
}
