import { dateTime, DateTime, TimeRange } from '@grafana/data'
import { isString } from 'lodash-es'
import { format as formatDateFn, formatDistance, formatDistanceStrict, parseISO } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

import { DateLike, ISODateString } from 'types'

const DEFAULT_FORMAT = 'MM/dd/yyyy'
const DEFAULT_TIME_ZONE = 'UTC'

export const formatDate = (date: string) => {
  return new Date(date).toLocaleString(undefined, {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })
}

export const getRelativeDateDifference = (from: string | number, to: string | number) => {
  return formatDistanceStrict(new Date(from), to ? new Date(to) : new Date())
}

export const ensureZulu = (dateTimeString = '') => {
  return isString(dateTimeString) && dateTimeString.endsWith('Z') ? dateTimeString : `${dateTimeString}Z`
}

export const parseTimezone = (date: string | number | Date, timezone = DEFAULT_TIME_ZONE) => {
  return utcToZonedTime(date, timezone)
}

export const getDateObj = (date: string, timezone = DEFAULT_TIME_ZONE) => {
  return isString(date) ? parseTimezone(ensureZulu(date), timezone) : date
}

export const getDateInTimezone = (date: string, dateFormat = DEFAULT_FORMAT, timezone = DEFAULT_TIME_ZONE) => {
  const dateObj = getDateObj(date, timezone)

  return dateObj && formatDateFn(dateObj, dateFormat)
}

const ensureDateObject = (dateOrString: Date | string) => {
  if (typeof dateOrString === 'string') {
    return new Date(dateOrString)
  }

  return dateOrString
}

export const dateFormatter = (function () {
  const UTCToZonedTime = (date: string) => {
    return utcToZonedTime(date, DEFAULT_TIME_ZONE)
  }

  return {
    formatDate: formatDateFn,
    UTCToZonedTime,
    formatUTCDate: (date: string, format: string) => {
      return formatDateFn(UTCToZonedTime(date), format)
    },

    format: (date: string, format = DEFAULT_FORMAT) => {
      return formatDateFn(new Date(date), format)
    },
    parseTz: parseTimezone,
    tz: (date: string, format = DEFAULT_FORMAT) => {
      return formatDateFn(getDateObj(date), format)
    },
    zonedToUTC: (date: string, format = DEFAULT_FORMAT) => {
      return zonedTimeToUtc(ensureDateObject(date).toISOString(), format, {
        timeZone: DEFAULT_TIME_ZONE,
      })
    },
    relative: (startDate: string, endDate: string) => {
      return formatDistance(new Date(startDate), endDate ? new Date(endDate) : new Date())
    },
    relativeTz: (startDate: string, endDate: string) => {
      return formatDistance(parseTimezone(startDate), endDate ? parseTimezone(endDate) : new Date())
    },
  }
})()

export const createTimeRange = (from: DateTime | string, to: DateTime | string): TimeRange => ({
  from: dateTime(from),
  to: dateTime(to),
  raw: { from, to },
})

export const createTimeRangeOn = <T extends object>(key: keyof T, arr: T[] = []): TimeRange => {
  const from = arr[0]?.[key] as unknown as DateTime
  const to = arr[arr.length - 1]?.[key] as unknown as DateTime

  return createTimeRange(from, to)
}

export const toISODate = (value: DateLike): ISODateString => {
  return new Date(value).toISOString()
}

export const toTimestamp = (value: ISODateString) => {
  return parseISO(value).getTime()
}
