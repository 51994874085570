import { orderBy } from 'lodash-es'
import { FieldType, MutableDataFrame } from '@grafana/data'

import { TestRun } from 'types'
import { Log, LogsFilter, ParsedLog } from 'types/logs'
import { nanoToMilliseconds } from 'utils/math'

interface DataFrameLogOptions {
  searchWords?: string[]
}

export const getOrderedLogEntries = (logs: Log[]) => {
  const flatLogs = logs.reduce<ParsedLog[]>((result, { stream, values }) => {
    return [...result, ...values.map((values) => ({ stream, values }))]
  }, [])

  return orderBy(flatLogs, ({ values }) => values[0], 'asc')
}

export const createLogsDataFrame = (name: string, logs: ParsedLog[], options: DataFrameLogOptions = {}) => {
  const frame = new MutableDataFrame<ParsedLog[]>({
    name,
    meta: {
      custom: {
        frameType: 'LabeledTimeValues',
      },
      searchWords: options.searchWords || [],
    },
    fields: [],
  })

  frame.addField({
    name: 'labels',
    type: FieldType.other,
    values: logs.map(({ stream }) => {
      return stream
    }),
  })

  frame.addField({
    name: 'time',
    type: FieldType.time,
    values: logs.map(({ values }) => {
      return nanoToMilliseconds(values[0])
    }),
  })

  frame.addField({
    name: 'message',
    type: FieldType.string,
    values: logs.map(({ values }) => {
      return values[1]
    }),
  })

  return frame
}

export const serializeLokiQuery = (testRun: TestRun, { level = [], loadZone = [], queryFilter }: LogsFilter = {}) => {
  const lokiQueryParams = new Map()

  if (!testRun.id) {
    throw new Error('Cannot construct CloudLogs query, missing required param test_run_id.')
  }

  lokiQueryParams.set('test_run_id=', `"${testRun.id}"`)

  if (level.length > 0) {
    lokiQueryParams.set('level=~', `"(${level.join('|')})"`)
  }

  if (loadZone.length > 0) {
    lokiQueryParams.set('lz=~', `"(${loadZone.join('|')})"`)
  }

  const params = [...lokiQueryParams.entries()].map((pairs) => pairs.join('')).join(', ')

  return queryFilter ? `{${params}}|=\`${queryFilter}\`` : `{${params}}`
}
