import React, { ReactNode } from 'react'
import { Icon, IconName } from '@grafana/ui'

import { StyledMessage, Title, Text } from './FormStatusMessage.styles'
import { FormStatusLevel } from './FormStatusMessage.types'

type Props = {
  title: string
  icon: IconName
  level?: FormStatusLevel
  text?: ReactNode
}

export const FormStatusMessage = ({ level = 'default', title, text, icon }: Props) => {
  return (
    <StyledMessage $level={level}>
      {icon && <Icon name={icon} size="xl" />}
      {title && <Title>{title}</Title>}
      {text && <Text>{text}</Text>}
    </StyledMessage>
  )
}
