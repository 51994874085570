import React from 'react'
import { Alert } from '@grafana/ui'
import { locationService } from '@grafana/runtime'

import { useDatasource } from 'datasourceContext'

export const DatasourceErrorMessage = () => {
  const { ds } = useDatasource()
  const redirectToDatasource = () => {
    locationService.push(`/datasources/edit/${ds.uid}`)
  }

  return (
    <div className="page-container page-body">
      <Alert
        title="Encountered an error while loading the page"
        buttonContent="Data Source Settings"
        onRemove={redirectToDatasource}
      >
        Could not query the k6 API. Is your API token configured?
      </Alert>
    </div>
  )
}
