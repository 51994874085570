import React, { ReactNode, useEffect, useState } from 'react'
import { CodeEditor as GrafanaCodeEditor } from '@grafana/ui'
import type * as monacoType from 'monaco-editor/esm/vs/editor/editor.api'
import styled from 'styled-components'
import { css } from 'emotion'

import k6Types from './types'
import { Overlay } from 'components/Overlay'

import { constrainedEditor, ConstrainedEditorInstance, RestrictionObject } from 'constrained-editor-plugin'
import { getValueMapFromRestrictions } from './CodeEditor.utils'

const addK6Types = (monaco: typeof monacoType) => {
  Object.entries(k6Types).map(([name, type]) => {
    // Import types as modules for code completions
    monaco.languages.typescript.javascriptDefaults.addExtraLib(`declare module '${name}' { ${type} }`)
  })

  // Remove TS errors for remote libs imports
  monaco.languages.typescript.javascriptDefaults.addExtraLib("declare module 'https://*'")
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;

  // Override TestBuilder's font smoothing
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: auto;

  // Override TestBuilder's width: 0, causes flickering in editor
  width: 100% !important;
`

const containerStyles = css`
  height: 100%;
  min-height: 600px;

  // Background styling for editable ranges (multi)
  .editableArea--multi-line {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.1);
  }

  > section {
    min-height: inherit;
  }
`

type CodeEditorProps = {
  value: string
  onChange?: (value: string) => void
  onValidation?: (hasError: boolean) => void
  readOnly?: boolean
  renderHeader?: ({ scriptValue }: { scriptValue: string }) => ReactNode
  overlayMessage?: ReactNode
  language?: 'javascript' | 'json' | 'text'
}

type ConstrainedEditorProps = {
  constrainedRanges?: RestrictionObject[]
  onDidChangeContentInEditableRange?: (...params: any) => void
}

export const CodeEditor = ({
  value,
  onChange,
  onValidation,
  readOnly,
  overlayMessage,
  renderHeader,
  language = 'javascript',
  constrainedRanges,
  onDidChangeContentInEditableRange,
}: CodeEditorProps & ConstrainedEditorProps) => {
  const [monacoRef, setMonacoRef] = useState<null | typeof monacoType>(null)
  const [editorRef, setEditorRef] = useState<null | monacoType.editor.IStandaloneCodeEditor>(null)
  const [constrainedInstance, setConstrainedInstance] = useState<null | ConstrainedEditorInstance>(null)

  // Initialize constrained instance if applicable
  useEffect(() => {
    if (constrainedInstance || !monacoRef || !constrainedRanges || !editorRef) {
      return
    }
    const instance = constrainedEditor(monacoRef)
    instance.initializeIn(editorRef)
    const model = instance.addRestrictionsTo(editorRef.getModel()!, constrainedRanges)
    const valueMap = getValueMapFromRestrictions(constrainedRanges)
    model.updateValueInEditableRanges(valueMap)
    if (typeof onDidChangeContentInEditableRange === 'function') {
      model.onDidChangeContentInEditableRange(onDidChangeContentInEditableRange)
    } else {
      throw new Error('Prop `onDidChangeContentInEditableRange` is required when passing `constrainedRanges`.')
    }

    setConstrainedInstance(instance)
    // eslint-disable-next-line
  }, [monacoRef, editorRef, constrainedRanges])

  // GC
  useEffect(() => {
    return () => {
      if (constrainedInstance) {
        constrainedInstance.disposeConstrainer()
      }

      if (editorRef) {
        editorRef.dispose()
      }
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = (editor: monacoType.editor.IStandaloneCodeEditor) => () => {
    const value = editor.getValue()
    onChange && onChange(value)
  }

  const handleValidation = (monaco: typeof monacoType) => {
    if (!onValidation) {
      return
    }

    const markers = monaco.editor.getModelMarkers({})
    const hasError = markers.some((marker) => marker.severity > 1)
    onValidation(hasError)
  }

  const handleBeforeEditorMount = (monaco: typeof monacoType) => {
    setMonacoRef(monaco)
    addK6Types(monaco)

    const compilerOptions = monaco.languages.typescript.javascriptDefaults.getCompilerOptions()
    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      ...compilerOptions,
      checkJs: true, // show errors for JS files, by default it check only TS
    })

    // Needed to make `checkJs` work and highlight errors
    monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSyntaxValidation: false,
      noSemanticValidation: false,
      noSuggestionDiagnostics: false,
    })
  }

  const handleEditorDidMount = (editor: monacoType.editor.IStandaloneCodeEditor, monaco: typeof monacoType) => {
    setEditorRef(editor)
    editor.onDidChangeModelContent(handleChange(editor))

    monaco.editor.onDidChangeMarkers(() => {
      handleValidation(monaco)
    })
  }

  return (
    <Wrapper>
      {renderHeader && renderHeader({ scriptValue: value })}
      {overlayMessage && <Overlay>{overlayMessage}</Overlay>}
      <GrafanaCodeEditor
        value={value}
        language={language}
        showLineNumbers={true}
        showMiniMap={false}
        monacoOptions={{
          scrollBeyondLastLine: false,
        }}
        onBeforeEditorMount={handleBeforeEditorMount}
        onEditorDidMount={handleEditorDidMount}
        readOnly={readOnly}
        containerStyles={containerStyles}
      />
    </Wrapper>
  )
}
