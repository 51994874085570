import React from 'react'
import { TabContent } from '@grafana/ui'

import { Test, TestRun } from 'types'
import { HttpTab } from './HttpTab'
import { ThresholdTab } from './ThresholdTab'
import { ChecksTab } from './ChecksTab'
import { ScriptTab } from './ScriptTab'
import { LogsTab } from './LogsTab'
import { GRPCUrlsTab } from './GRPCUrlsTab'
import { useSummary } from 'data/useSummary'
import { exhaustive } from 'utils/typescript'
import { LoadingContainer } from 'components/LoadingContainer'
import { RunDetailsSection } from '../TestRunPage.styles'
import { FiltersContextProvider } from './Filters/FiltersContext'
import { useBreakdownTab, useSupportedProtocols } from './Breakdown.hooks'
import { Tab } from 'components/Tabs/Tab'
import { TabsBar } from 'components/Tabs/TabsBar'
import { WSTab } from './WSTab'
import { getChecksCount, getGrpcCount, getHttpCount, getThresholdsCount, getWSCount } from './Breakdown.utils'
import { BreakdownTab } from './Breakdown.types'

export type BreakdownProps = { testRun: TestRun; test: Test }

interface BreakdownContentProps {
  activeTab: BreakdownTab
  test: Test
  run: TestRun
}

const BreakdownContent = ({ activeTab, run, test }: BreakdownContentProps) => {
  switch (activeTab) {
    case BreakdownTab.CHECKS:
      return <ChecksTab run={run} />
    case BreakdownTab.THRESHOLDS:
      return <ThresholdTab run={run} />
    case BreakdownTab.HTTP:
      return <HttpTab run={run} />
    case BreakdownTab.GRPC:
      return <GRPCUrlsTab run={run} />
    case BreakdownTab.SCRIPT:
      return <ScriptTab run={run} test={test} />
    case BreakdownTab.LOGS:
      return <LogsTab run={run} test={test} />
    case BreakdownTab.WS:
      return <WSTab run={run} />
    default:
      // With this, typescript can help us find all the places where we need to handle a tab having been added
      return exhaustive(activeTab)
  }
}

export const Breakdown = ({ testRun, test }: BreakdownProps) => {
  const { data: summary } = useSummary(testRun)
  const supportedProtocols = useSupportedProtocols(summary)

  const [tab, toTabHref] = useBreakdownTab(supportedProtocols)

  return (
    <FiltersContextProvider>
      <LoadingContainer loading={!summary} spinner={null}>
        {summary && (
          <TabsBar>
            <Tab
              label="THRESHOLDS"
              href={toTabHref('href', BreakdownTab.THRESHOLDS)}
              active={tab === BreakdownTab.THRESHOLDS}
              suffix={<div>{getThresholdsCount(summary)}</div>}
            />
            <Tab
              label="CHECKS"
              href={toTabHref('href', BreakdownTab.CHECKS)}
              active={tab === BreakdownTab.CHECKS}
              suffix={<div>{getChecksCount(summary)}</div>}
            />
            {supportedProtocols.http && (
              <Tab
                label="HTTP"
                href={toTabHref('href', BreakdownTab.HTTP)}
                active={tab === BreakdownTab.HTTP}
                suffix={<div>{getHttpCount(supportedProtocols.http)}</div>}
              />
            )}
            {supportedProtocols.ws && (
              <Tab
                label="WEBSOCKETS"
                href={toTabHref('href', BreakdownTab.WS)}
                active={tab === BreakdownTab.WS}
                suffix={<div>{getWSCount(supportedProtocols.ws)}</div>}
              />
            )}
            {supportedProtocols.grpc && (
              <Tab
                label="GRPC"
                href={toTabHref('href', BreakdownTab.GRPC)}
                active={tab === BreakdownTab.GRPC}
                suffix={<div>{getGrpcCount(supportedProtocols.grpc)}</div>}
              />
            )}
            <Tab
              label="SCRIPT"
              href={toTabHref('href', BreakdownTab.SCRIPT)}
              active={tab === BreakdownTab.SCRIPT}
              suffix={<div>View executed script</div>}
            />
            <Tab
              label="LOGS"
              href={toTabHref('href', BreakdownTab.LOGS)}
              active={tab === BreakdownTab.LOGS}
              suffix={() => <div>Execution logs</div>}
            />
          </TabsBar>
        )}
      </LoadingContainer>
      <RunDetailsSection>
        <>
          <TabContent>
            <BreakdownContent run={testRun} test={test} activeTab={tab} />
          </TabContent>
        </>
      </RunDetailsSection>
    </FiltersContextProvider>
  )
}
