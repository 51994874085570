import { useCallback, useState } from 'react'
import { Test } from 'types'

export const useTestSelection = (tests: Test[]) => {
  const [selection, setSelection] = useState<number[]>([])
  const [showSelection, setShowSelection] = useState(false)

  const onClearSelection = useCallback(() => {
    setShowSelection(false)
    setSelection([])
  }, [])

  // This function is not memoized, because its dependency on selection, showSelection, and tests
  // makes its memoization ineffective.
  const onSelectTest = (test: Test, shiftKey = false) => {
    if (!showSelection) {
      setShowSelection(true)
    }

    if (shiftKey && selection.length > 0) {
      // Replicate the selection behavior in k6 Cloud:
      // The selection anchor is always the first selected element.
      const anchorIndex = tests.findIndex((i) => i.id === selection[0])
      const focusIndex = tests.findIndex((i) => i.id === test.id)

      // The user might have clicked on an item before the first selected
      // item so the endIndex might actually be lower than the startIndex.
      // In that case we want to select "backwards".
      const lowerIndex = Math.min(anchorIndex, focusIndex)
      const upperIndex = Math.max(anchorIndex, focusIndex)

      // If the user did a "backwards" selection, we want to keep the
      // item that was selected first at the start of the list so that
      // another range select will still pivot around that item.
      const newSelection = tests.slice(lowerIndex, upperIndex + 1).map(({ id }) => id)
      setSelection(anchorIndex > focusIndex ? newSelection.reverse() : newSelection)
      return
    }

    if (selection.includes(test.id)) {
      setSelection(selection.filter((id) => id !== test.id))
      return
    }

    setSelection([...selection, test.id])
  }

  return {
    selection,
    showSelection,
    onSelectTest,
    onClearSelection,
  }
}
