import React, { useCallback, useState } from 'react'
import { isUndefined } from 'lodash-es'

import { ISODateString, Test, TestRun } from 'types'
import { LogsFilter } from 'types/logs'
import { toISODate } from 'utils/date'
import { serializeLokiQuery } from 'utils/logs'
import { nanoToMilliseconds } from 'utils/math'
import { isTestActive, isTestRunFromIngest } from 'utils/testRun'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { LogsView } from './LogsView'
import { EmptyView } from './EmptyView'
import { isZeroState } from './LogsTab.utils'
import { useLogs } from './LogsTab.hooks'
import { Code, IngestMessage, Section } from './LogsTab.styles'

interface LogsTabProps {
  run: TestRun
  test: Test
}

type LogState = {
  end?: ISODateString
  filters: LogsFilter
  page: number
}

export const initialState: LogState = {
  end: undefined,
  filters: {},
  page: 0,
}

export const LogsTab = ({ run }: LogsTabProps) => {
  // ensure these are perfectly in sync.
  const [{ end, filters, page }, setParams] = useState(initialState)

  const {
    data: logs = [],
    isFetching,
    isLoading,
  } = useLogs(
    {
      query: serializeLokiQuery(run, filters),
      start: toISODate(run.created),
      end,
    },
    {
      enabled: !!run.created,
      page,
      streaming: isTestActive(run),
    }
  )

  const handleFilterChange = useCallback((newFilters: LogsFilter) => {
    setParams((prevParams) => ({
      ...initialState,
      filters: { ...prevParams.filters, ...newFilters },
    }))
  }, [])

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (isFetching) {
        return
      }

      const newEndDate = logs.at(0)?.values[0]

      setParams((prevParams) => ({
        ...prevParams,
        end: newPage > page && !isUndefined(newEndDate) ? toISODate(nanoToMilliseconds(newEndDate) + 1) : undefined,
        page: newPage,
      }))
    },
    [isFetching, logs, page]
  )

  if (isLoading) {
    return (
      <Section>
        <CenteredSpinner $height="250px" />
      </Section>
    )
  }

  if (isTestRunFromIngest(run)) {
    return (
      <Section>
        <IngestMessage data-testid="logs-ingest-message">
          <p>
            Logs are not available in the cloud for <Code>k6 run -o cloud</Code> {'"locally executed"'} tests.
          </p>
        </IngestMessage>
      </Section>
    )
  }

  if (isZeroState(logs, filters)) {
    return (
      <Section>
        <EmptyView />
      </Section>
    )
  }

  return (
    <Section>
      <LogsView
        filters={filters}
        logs={logs}
        page={page}
        testRun={run}
        onFilterChange={handleFilterChange}
        onPageChange={handlePageChange}
      />
    </Section>
  )
}
