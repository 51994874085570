import { useQueryParameter } from 'hooks/useQueryParameter'
import { useCallback, useMemo } from 'react'
import { GrpcMetricSummary, HttpMetricSummary, Summary, WsMetricSummary } from 'types'
import { BreakdownTab } from './Breakdown.types'

interface SupportedProtocols {
  http?: HttpMetricSummary
  grpc?: GrpcMetricSummary
  ws?: WsMetricSummary
}

export const useSupportedProtocols = (summary: Summary | undefined): SupportedProtocols => {
  return useMemo(() => {
    if (summary === undefined) {
      return {}
    }

    return {
      http: summary.http_metric_summary.requests_count !== null ? summary.http_metric_summary : undefined,
      ws: summary.ws_metric_summary.sessions !== null ? summary.ws_metric_summary : undefined,
      grpc: summary.grpc_metric_summary.requests_count !== null ? summary.grpc_metric_summary : undefined,
    }
  }, [summary])
}

const getDefaultTab = (protocols: SupportedProtocols) => {
  if (protocols.http) {
    return BreakdownTab.HTTP
  }

  if (protocols.grpc) {
    return BreakdownTab.GRPC
  }

  if (protocols.ws) {
    return BreakdownTab.WS
  }

  return BreakdownTab.CHECKS
}

export const useBreakdownTab = (protocols: SupportedProtocols) => {
  const parser = useCallback(
    (value: string | null) => {
      switch (value) {
        case BreakdownTab.CHECKS:
        case BreakdownTab.LOGS:
        case BreakdownTab.SCRIPT:
        case BreakdownTab.THRESHOLDS:
          return value

        case BreakdownTab.HTTP:
          return protocols.http ? BreakdownTab.HTTP : getDefaultTab(protocols)

        case BreakdownTab.GRPC:
          return protocols.http ? BreakdownTab.GRPC : getDefaultTab(protocols)

        case BreakdownTab.WS:
          return protocols.http ? BreakdownTab.WS : getDefaultTab(protocols)

        default:
          return getDefaultTab(protocols)
      }
    },
    [protocols]
  )

  return useQueryParameter({
    name: 'tab',
    decoder: parser,
    encoder: (value) => value,
  })
}
