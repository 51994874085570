import { groupBy } from 'lodash'
import {
  AxisPlacement,
  LegendDisplayMode,
  GraphFieldConfig,
  SortOrder,
  StackingMode,
  TooltipDisplayMode,
  VizOrientation,
  VisibilityMode,
} from '@grafana/schema'
import { ConfigOverrideRule, DataFrame, DataFrameView, FieldConfigSource, FieldMatcherID } from '@grafana/data'

import { TestRun } from 'types'
import { BarChartPanelOptions, TimeSeriesUnit } from 'panels/types'
import { MAX_VALUE_EMPTY_BARS } from 'constants/index'
import { createDataFrame } from 'utils/dataFrame'
import { getMaxValueByKey } from 'utils/math'
import { isTestActive } from 'utils/testRun'
import { getTestRunColorString, getTestStatusText, padEmptyBars } from 'pages/utils'

const BAR_COUNT = 40
const GHOST_SERIES_NAME = 'ghost'
// TODO: To be replaced by customizable metrics
const METRIC_KEY = 'load_time'

export const createFieldConfig = (runs: TestRun[] = []): FieldConfigSource<GraphFieldConfig> => ({
  defaults: {
    custom: {
      lineWidth: 0,
      fillOpacity: 100,
      axisPlacement: AxisPlacement.Left,
    },
    min: 0,
    max: getMaxValueByKey(METRIC_KEY, runs) || MAX_VALUE_EMPTY_BARS,
    unit: TimeSeriesUnit.Milliseconds,
  },
  overrides: [],
})

export const createFieldOverrides = (frames: DataFrame[] = []): ConfigOverrideRule[] => {
  return frames.map((frame) => {
    const view = new DataFrameView<{ metadata: TestRun }>(frame).get(0)

    return {
      matcher: {
        id: FieldMatcherID.byName,
        options: frame.name,
      },
      properties: [
        {
          id: 'color',
          value: {
            fixedColor: frame.name !== GHOST_SERIES_NAME ? getTestRunColorString(view.metadata) : 'gray',
            mode: 'fixed',
          },
        },
        {
          id: 'custom.fillOpacity',
          value: frame.name !== GHOST_SERIES_NAME ? 100 : 20,
        },
      ],
    }
  })
}

export const getDataFrames = (runs: TestRun[] = []): DataFrame[] => {
  const maxValue = getMaxValueByKey(METRIC_KEY, runs) || MAX_VALUE_EMPTY_BARS
  const runsWithDefaults = runs.map(assignDefaultValues(maxValue))
  const runsWithMaxValue = runs.map(assignMaxValue(maxValue))
  const groupedRuns = groupBy(getRunsToPlot(runsWithDefaults), getTestStatusText)

  return [
    ...Object.entries(groupedRuns).map(([name, values]) => {
      return createDataFrame(name, values, {
        dateKey: 'created',
        metricKey: METRIC_KEY,
        unit: TimeSeriesUnit.Milliseconds,
      })
    }),
    createDataFrame(GHOST_SERIES_NAME, runsWithMaxValue, {
      dateKey: 'created',
      metricKey: METRIC_KEY,
      unit: TimeSeriesUnit.Milliseconds,
    }),
  ]
}

export const createPanelOptions = (frames: DataFrame[] = []): BarChartPanelOptions => {
  return {
    barWidth: 0.6,
    groupWidth: 0.2,
    barRadius: 0,
    legend: {
      showLegend: false,
      calcs: [],
      displayMode: LegendDisplayMode.Hidden,
      placement: 'bottom',
    },
    orientation: VizOrientation.Vertical,
    showValue: VisibilityMode.Never,
    // required to render correctly where only a single field exists
    stacking: frames.length > 1 ? StackingMode.Normal : StackingMode.None,
    tooltip: {
      mode: TooltipDisplayMode.None,
      sort: SortOrder.None,
    },
    xTickLabelMaxLength: 0,
    xTickLabelRotation: 0,
    xTickLabelSpacing: 200,
  }
}

export const assignDefaultValues = (maxValue: number) => (run: TestRun) => ({
  ...run,
  [METRIC_KEY]: isTestActive(run) ? maxValue : run[METRIC_KEY] ?? 0,
})

export const assignMaxValue = (maxValue: number) => (run: TestRun) => ({
  ...run,
  [METRIC_KEY]: maxValue,
})

export const getRunsToPlot = (runs: TestRun[]) => {
  return runs.length < BAR_COUNT ? padEmptyBars(runs, BAR_COUNT - runs.length) : runs.slice(0, BAR_COUNT)
}
