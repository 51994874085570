import {
  SortOrder,
  VisibilityMode,
  AxisPlacement,
  GraphDrawStyle,
  GraphGradientMode,
  GraphTresholdsStyleMode,
  LegendDisplayMode,
  LineInterpolation,
  ScaleDistribution,
  StackingMode,
  TooltipDisplayMode,
} from '@grafana/schema'

import { QueryType } from 'types'
import { TimeSeriesPanelConfig, TimeSeriesUnit } from './types'

export const testRunPanel: TimeSeriesPanelConfig = {
  id: 23763571993,
  gridPos: {
    h: 5,
    w: 12,
    x: 0,
    y: 5,
  },
  type: 'timeseries',
  title: 'Test Run',
  datasource: { type: 'grafana-k6-datasource' },
  fieldConfig: {
    defaults: {
      custom: {
        drawStyle: GraphDrawStyle.Line,
        lineInterpolation: LineInterpolation.Linear,
        barAlignment: 0,
        lineWidth: 1,
        fillOpacity: 0,
        gradientMode: GraphGradientMode.None,
        spanNulls: false,
        showPoints: VisibilityMode.Auto,
        pointSize: 5,
        stacking: {
          mode: StackingMode.None,
          group: 'A',
        },
        axisPlacement: AxisPlacement.Auto,
        axisLabel: '',
        scaleDistribution: {
          type: ScaleDistribution.Linear,
        },
        hideFrom: {
          tooltip: false,
          viz: false,
          legend: false,
        },
        thresholdsStyle: {
          mode: GraphTresholdsStyleMode.Off,
        },
      },
      color: {
        mode: 'palette-classic',
      },
      mappings: [],
    },
    overrides: [
      {
        matcher: {
          id: 'byName',
          options: 'rps(http_reqs)',
        },
        properties: [
          {
            id: 'unit',
            value: TimeSeriesUnit.RequestsPerSecond,
          },
        ],
      },
      {
        matcher: {
          id: 'byName',
          options: '0.95(http_req_duration)',
        },
        properties: [
          {
            id: 'unit',
            value: TimeSeriesUnit.Milliseconds,
          },
        ],
      },
      {
        matcher: {
          id: 'byName',
          options: 'value(vus)',
        },
        properties: [
          {
            id: 'unit',
            value: TimeSeriesUnit.VUs,
          },
        ],
      },
      {
        matcher: {
          id: 'byName',
          options: 'nz_rps(http_req_failed)',
        },
        properties: [
          {
            id: 'unit',
            value: TimeSeriesUnit.RequestsPerSecond,
          },
        ],
      },
    ],
  },
  options: {
    tooltip: {
      mode: TooltipDisplayMode.Multi,
      sort: SortOrder.None,
    },
    legend: {
      showLegend: true,
      displayMode: LegendDisplayMode.List,
      placement: 'bottom',
      calcs: [],
    },
  },
  targets: [
    {
      refId: 'A',
      hide: false,
      qtype: QueryType.METRIC,
      queryType: QueryType.METRIC,
      projectId: 1283,
      testId: 3185,
      testRunId: 135247,
      metric: 'vus',
      uid: '01c96485888dde4185c080a747e9272e',
      aggregation: 'value',
    },
    {
      aggregation: 'rps',
      metric: 'http_reqs',
      projectId: 1283,
      qtype: QueryType.METRIC,
      queryType: QueryType.METRIC,
      refId: 'B',
      testId: 3185,
      testRunId: 135247,
      uid: '0206f0a22d574a1fcc535194bd89717d',
    },
    {
      refId: 'C',
      hide: false,
      qtype: QueryType.METRIC,
      queryType: QueryType.METRIC,
      projectId: 1283,
      testId: 3185,
      testRunId: 135247,
      metric: 'http_req_duration',
      uid: '01c96485888dde4185c080a747e9272e',
      aggregation: '0.95',
    },
    {
      refId: 'D',
      hide: false,
      qtype: QueryType.METRIC,
      queryType: QueryType.METRIC,
      projectId: 1283,
      testId: 3185,
      testRunId: 135247,
      metric: 'http_req_failed',
      uid: '01c96485888dde4185c080a747e9272e',
      aggregation: 'nz_rps',
    },
  ],
}
