import React from 'react'
import { FieldConfigSource } from '@grafana/data'

import { QueryType, TestRun } from 'types'
import { TimeSeriesPanelConfig } from 'panels/types'
import { K6DataSource } from 'datasource/datasource'
import { getStaticPanelConfig } from 'utils'
import { useTrackFeature } from 'hooks/useTrackFeature'
import { Clipboard } from 'components/Clipboard'

interface ChartClipboardProps {
  ds: K6DataSource
  queryType: QueryType
  testRun: TestRun
  itemId?: string
  itemName?: string
  itemValue?: number
  fieldConfig?: FieldConfigSource
  panelConfig?: TimeSeriesPanelConfig
}

export const ChartClipboard = ({
  ds,
  queryType,
  testRun,
  itemId,
  itemName,
  itemValue,
  fieldConfig,
  panelConfig = getStaticPanelConfig(ds, queryType, testRun, itemId, itemName, itemValue, fieldConfig),
}: ChartClipboardProps) => {
  const trackFeature = useTrackFeature()

  function handleCopy(panelConfigString: string) {
    window.localStorage['panel-copy'] = panelConfigString
    trackFeature('copy_to_clipboard')
  }

  return (
    <Clipboard data={panelConfig} onCopy={handleCopy} />
  )
}
