import { K6DataSource } from 'datasource/datasource'
import { TimeSeriesPanelConfig } from 'panels/types'
import { Query, QueryType, TestRun } from 'types'
import { ChartMetric } from './Chart.types'
import { panelOptions } from './panelOptions'
import { ConfigOverrideRule, DynamicConfigValue } from '@grafana/data'
import { DEFAULT_FIELD_CONFIG, toGraphFieldConfig } from './fieldConfigs'

interface MetricWithQuery {
  metric: ChartMetric
  query: Query
}

const LETTERS_IN_ALPHABET = 26
const CHAR_CODE_A = 'A'.charCodeAt(0)

const toGrafanaLikeRefID = (index: number) => {
  return String.fromCharCode(CHAR_CODE_A + (index % LETTERS_IN_ALPHABET))
}

const toMetricWithQuery =
  (testRun: TestRun) =>
  (metric: ChartMetric, index: number): MetricWithQuery => {
    return {
      metric,
      query: {
        refId: toGrafanaLikeRefID(index),
        qtype: QueryType.METRIC,
        queryType: QueryType.METRIC,
        testRunId: testRun.id,
        testId: testRun.test_id,
        projectId: testRun.project_id,
        metric: metric.query.metric,
        aggregation: metric.query.method,
        tags: Object.values(metric.query.tags).map((tag) => ({
          id: 0,
          key: tag.operator === 'not-equal' ? tag.name + '!' : tag.name,
          value: tag.values[0] ?? '',
        })),
      },
    }
  }

const ignoredObjectPaths = new Set(['color', 'thresholds', 'custom.thresholdsStyle'])

const toDynamicValueOverrides = (target: object, path = ''): DynamicConfigValue[] => {
  if (target === null) {
    return []
  }

  const result: DynamicConfigValue[] = []

  for (let [property, value] of Object.entries(target)) {
    if (value === undefined) {
      continue
    }

    const propertyPath = path + property

    if (!ignoredObjectPaths.has(propertyPath) && typeof value === 'object' && value !== null && !Array.isArray(value)) {
      result.push(...toDynamicValueOverrides(value, propertyPath + '.'))

      continue
    }

    result.push({
      id: propertyPath,
      value,
    })
  }

  return result
}

const toConfigOverrideRule = ({ metric, query }: MetricWithQuery): ConfigOverrideRule => {
  let config = toGraphFieldConfig(metric)

  return {
    matcher: {
      id: 'byFrameRefID',
      options: query.refId,
    },
    properties: toDynamicValueOverrides(config),
  }
}

export const toPanelConfig = (
  datasource: K6DataSource,
  title: string,
  testRun: TestRun,
  metrics: ChartMetric[]
): TimeSeriesPanelConfig => {
  const queries = metrics.map(toMetricWithQuery(testRun))

  return {
    id: 0,
    gridPos: {
      h: 5,
      w: 12,
      x: 0,
      y: 5,
    },
    title: title,
    fieldConfig: {
      defaults: DEFAULT_FIELD_CONFIG,
      overrides: queries.flatMap((item) => toConfigOverrideRule(item)),
    },
    type: 'timeseries',
    options: panelOptions,
    datasource: {
      uid: datasource.uid,
      type: datasource.type,
    },
    targets: queries.map((item) => item.query),
  }
}
