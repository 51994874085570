import React from 'react'

import { GrpcMetricSummary } from 'types'
import { separatorFormatter, responseTimeFormatter } from 'utils/formatters'
import { Statistic } from './Statistic'

interface GrpcSummaryProps {
  summary: GrpcMetricSummary
}

export const GrpcSummary = ({ summary }: GrpcSummaryProps) => {
  return (
    <>
      <Statistic label="REQUESTS MADE" value={separatorFormatter(summary.requests_count)} unit="reqs" />
      <Statistic label="AVG. RPS" value={separatorFormatter(summary.rps_mean)} unit="reqs/s" />
      <Statistic label="P95 RESPONSE TIME" value={responseTimeFormatter(summary.duration.p95 ?? 0)} unit="ms" />
    </>
  )
}
