import styled from 'styled-components'

import { LoadZoneLabel } from '../LoadZoneLabel'

export const Container = styled.div<{ $gridColumns: number }>`
  font-size: 13px;
  display: grid;
  grid-template-columns: ${({ $gridColumns = 3 }) => `repeat(${$gridColumns}, auto)`};
  column-gap: 10px;
  row-gap: 8px;
`

export const Title = styled.span`
  font-size: 14px;
  font-weight: 600;
`

export const Label = styled(LoadZoneLabel)`
  margin: 0;
`
