export const calculatePrecision = (value: number) => {
  if (value < 1) {
    return 2
  }

  if (value < 10) {
    return 1
  }

  return 0
}

export const getMaxValueByKey = <T = unknown>(key: keyof T, xs: T[]) => {
  return Math.max(...xs.map((x) => (!isNaN(Number(x[key])) ? Number(x[key]) : 0)))
}

export const toPrecise = (val: number, precision = 2) => Number(Number(val).toFixed(precision))

export const isMultipleOf = (multiple: number, n: number) => n % multiple === 0

export const isNumeric = (n: number | string) => {
  const num = parseFloat(n as string)
  return !isNaN(num) && isFinite(num)
}

export const nanoToMilliseconds = (nanoseconds: number | string) => Number(nanoseconds) / 1000000
