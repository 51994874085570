import React from 'react'
import { Chart } from 'components/Chart'
import { ChartMetric } from 'components/Chart/Chart.types'
import { DocLink, docs, ReadMore } from 'components/DocLink'
import { HTTP_FAILURE_RATE, HTTP_REQUEST_RATE, VUS_METRIC } from 'constants/metrics'
import { TestRun } from 'types'
import { HttpIncreasedFailureRate, HttpIncreasedFailureRateFailed } from 'types/insights/http'
import { percentage } from 'utils/formatters'
import { InsightBody } from '../InsightBody'
import { InsightListItem } from '../InsightItem'
import { SkippedDescription } from '../SkippedDescription'
import { useSegmentAnnotations } from './hooks'

const formatSegmentMessage = () => 'Increase in failure rate detected.'

const metrics: ChartMetric[] = [VUS_METRIC, HTTP_REQUEST_RATE, HTTP_FAILURE_RATE]

interface IncreasedFailureRateProps {
  testRun: TestRun
  insight: HttpIncreasedFailureRateFailed
}

export const IncreasedFailureRate = ({ testRun, insight }: IncreasedFailureRateProps) => {
  const annotations = useSegmentAnnotations(insight.data.segments, formatSegmentMessage)

  return (
    <InsightBody>
      <p>
        During the test, the HTTP error rate increased by {percentage(insight.data.threshold.rate_of_increase)} or
        higher compared to the beginning of the test. By default, HTTP responses from the application server with the
        codes 4xx or 5xx are considered HTTP errors. Failed responses are often returned much faster than successful
        responses, so an increased HTTP error rate may produce misleading aggregated request rate and response time
        metrics.
      </p>
      <p>
        The graph below shows the number of errors throughout the test compared to the number of requests per second
        (request rate).
      </p>
      <p>
        <Chart title="Increased Failure Rate" testRun={testRun} metrics={metrics} annotations={annotations} />
      </p>
      <p>An increase in the HTTP error rate suggests that the target system is close to its performance limit.</p>
      <h4>Recommendations:</h4>
      <ul>
        <li>
          Review web server configuration for timeouts, rate limiting, or anything else that might explain why it&apos;s
          returning errors.
        </li>
        <li>
          Check for internal errors caused by saturation of a resource (CPU, memory, disk I/O or database connections).
        </li>
        <li>
          If you are intentionally testing HTTP error responses, you can{' '}
          <DocLink article={docs.javascript.http.setResponseCallback}>
            change which HTTP codes should be classified as errors
          </DocLink>
          .
        </li>
      </ul>
      <p>
        <ReadMore article={docs.insights.http.increasedFailureRate} />
      </p>
    </InsightBody>
  )
}

interface IncreasedFailureRateItemProps {
  insight: HttpIncreasedFailureRate
}

const IncreasedFailureRateDescription = ({ insight }: IncreasedFailureRateItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          We did not detect any periods of increased HTTP errors during your test (
          {percentage(insight.data.threshold.rate_of_increase)} higher than in the beginning of the test).{' '}
          <ReadMore article={docs.insights.http.increasedFailureRate} />
        </>
      )

    case 'FAILED':
      return (
        <>
          HTTP error rates increased significantly during the test (
          {percentage(insight.data.threshold.rate_of_increase)} higher than in the beginning of the test).
        </>
      )

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const IncreasedFailureRateItem = ({ insight }: IncreasedFailureRateItemProps) => {
  return (
    <InsightListItem header="Increased HTTP Failure Rate" insight={insight}>
      <IncreasedFailureRateDescription insight={insight} />
    </InsightListItem>
  )
}
