import { uniqBy } from 'lodash-es'

import { TagValue } from 'types'
import { ScenariosPayload } from 'datasource/datasource'
import { HTTP_METHODS } from 'constants/index'

export function toOptions<T extends {}>(data: T[] = [], prop: keyof T) {
  return uniqBy(
    data.map((item) => ({ label: item[prop] as string, value: item[prop] as string })),
    (item) => item.value
  )
}

export function toScenariosByMetric(
  payload: ScenariosPayload | undefined,
  by: 'http_metric_summary' | 'grpc_metric_summary' | 'ws_metric_summary'
) {
  return (payload?.value ?? [])
    .filter((scenario) => {
      if (by === 'ws_metric_summary') {
        return scenario[by].session_duration.count > 0
      }

      return scenario[by].duration.count > 0
    })
    .map((scenario) => ({ value: scenario.name }))
}

export const booleanOptions = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
]

export function tagValuesToHttpMethods(data: TagValue[] | undefined) {
  return (data ?? []).filter((item) => HTTP_METHODS.includes(item.value))
}

export function tagValuesTogRPCStatus(data: TagValue[] | undefined) {
  return (data ?? []).filter((item) => {
    const statusCode = Number.parseInt(item.value, 10)
    return statusCode >= 0 && statusCode <= 16
  })
}
