import React from 'react'
import { PanelRenderer } from '@grafana/runtime'
import AutoSizer from 'react-virtualized-auto-sizer'
import { PanelChrome } from '@grafana/ui'
import { LoadingState } from '@grafana/data'

import { createTimeRangeOn } from 'utils/date'
import { styles } from 'pages/styles'
import { TestingChartProps } from './TrendingChart.types'
import { createFieldConfig, createFieldOverrides, createPanelOptions, getDataFrames } from './TrendingChart.utils'
import { ChartPlaceholder } from 'components/Chart/Chart.styles'
import { ChartPlaceholderWrapper } from '../../TestPage.styles'
import { CenteredSpinner } from 'components/CenteredSpinner'

export const TrendingChart = ({ runs, isLoading }: TestingChartProps) => {
  const frames = getDataFrames(runs)
  const range = createTimeRangeOn('created', runs)
  const options = createPanelOptions(frames)
  const config = createFieldConfig(runs)
  const overrides = createFieldOverrides(frames)

  const panelData = {
    state: LoadingState.Done,
    series: frames,
    timeRange: range,
  }

  return (
    <div className={styles.chartWrapper}>
      <AutoSizer disableHeight>
        {(size) => (
          <PanelChrome
            title="Trending response time (95th percentile)"
            width={size.width}
            height={200}
            leftItems={[<PanelChrome.LoadingIndicator loading={false} onCancel={() => {}} key="loading-indicator" />]}
          >
            {(innerWidth, innerHeight) =>
              runs.length > 0 ? (
                <PanelRenderer
                  title="Trending response time (95th percentile)"
                  pluginId="barchart"
                  width={innerWidth}
                  height={innerHeight}
                  data={panelData}
                  options={options}
                  fieldConfig={{ ...config, overrides }}
                />
              ) : (
                <ChartPlaceholderWrapper>
                  {isLoading ? (
                    <CenteredSpinner $height="100%" />
                  ) : (
                    <>
                      <p>Trending will appear after your first run</p>
                      <ChartPlaceholder $height={innerHeight - 51} />
                    </>
                  )}
                </ChartPlaceholderWrapper>
              )
            }
          </PanelChrome>
        )}
      </AutoSizer>
    </div>
  )
}
