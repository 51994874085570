export const getUserOS = () => {
  const platformString = navigator.platform.toLowerCase()

  if (platformString.includes('mac')) {
    return 'osx'
  } else if (platformString.includes('linux')) {
    return 'debian'
  } else if (platformString.includes('win')) {
    return 'windows'
  }

  return platformString
}
