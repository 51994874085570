import styled from 'styled-components'

export const InsightsStateContainer = styled.div`
  min-height: 250px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  p {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  > div {
    margin-top: 10px;
  }
`
