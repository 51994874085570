import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { Button, ModalProps, TextArea } from '@grafana/ui'
import { NoteModal as Modal } from './NoteModal.styles'
import { showAlert } from 'utils/showAlert'
import { useUpdateTestRunNote } from 'data/useUpdateTestRunNote'
import { TestRun } from 'types'

export interface NoteModalProps extends Omit<ModalProps, 'title'> {
  run: TestRun
}

export const NoteModal = ({ onDismiss, run, isOpen }: NoteModalProps) => {
  const hasNote = run.note && run.note.length > 0
  const [note, setNote] = useState('')
  const [hasChanged, setHasChanged] = useState(false)

  const updateTestRunNote = useUpdateTestRunNote(run)

  // The modal is controlled
  useEffect(() => {
    setNote(run.note)
  }, [run])

  useEffect(() => {
    const isUpdatingNote = note !== run.note
    const isDeletingNote = isUpdatingNote && note.length === 0
    const isCreatingNote = run.note.length === 0 && note.length > 0
    setHasChanged(isDeletingNote || isCreatingNote || isUpdatingNote)
  }, [note, run.note])

  const onNoteChanged = (event: FormEvent<HTMLTextAreaElement>) => {
    setNote(event.currentTarget.value.trim())
  }

  const handleOnSave = useCallback(
    () =>
      updateTestRunNote.mutate(
        { note },
        {
          onSuccess: onDismiss,
          onError: () => {
            onDismiss && onDismiss()
            showAlert(`Failed to save test run note.`, 'error')
          },
        }
      ),
    [updateTestRunNote, note, onDismiss]
  )

  return (
    <Modal title={hasNote ? 'Edit note' : 'Create note'} isOpen={isOpen} onDismiss={onDismiss}>
      <TextArea onChange={onNoteChanged}>{run.note}</TextArea>
      <Modal.ButtonRow>
        <Button disabled={updateTestRunNote.isLoading} onClick={onDismiss} variant="secondary">
          Cancel
        </Button>
        <Button disabled={!hasChanged || updateTestRunNote.isLoading} onClick={handleOnSave}>
          {hasNote ? 'Save' : 'Create'}
        </Button>
      </Modal.ButtonRow>
    </Modal>
  )
}
