import React, { useState } from 'react'
import { Check, TestRun } from 'types'
import { separatorFormatter } from 'utils/formatters'
import { useChecks } from 'data/useChecks'
import { ChecksChart } from './ChecksChart'
import { getSuccessRate } from './ChecksTab.utils'
import { ChecksFilters } from './ChecksFilters'
import { LoadingContainer } from 'components/LoadingContainer'
import { DataTable } from 'components/DataTable'
import { TableColumn } from 'react-data-table-component'

import { useRunDetailsChecksFilters } from '../Filters/FiltersContext.hooks'
import { getFilledFilters, getShowFilterSelect } from '../Filters/Filters.utils'
import { RunDetailsTableEmptyState } from '../TableEmptyState'
import { ChecksCounters } from './ChecksCounters'
import { DataTableSortValue } from 'components/DataTable/hooks/useSorting'
import { Status } from '../Status'

interface Props {
  run: TestRun
}

const PAGE_SIZE = 20

const columns: Array<TableColumn<Check>> = [
  {
    name: '',
    cell: (row) => (
      <Status isValid={row.metric_summary.fail_count === 0}>
        <ChecksCounters summary={row.metric_summary} />
      </Status>
    ),
    width: '150px',
    compact: true,
  },
  {
    name: 'NAME',
    selector: (row) => row.name,
    minWidth: '200px',
    sortable: true,
    sortField: 'name',
  },
  {
    name: 'SUCCESS RATE',
    sortable: true,
    sortField: 'metric_summary/success_rate',
    selector: (row: any) => getSuccessRate(row.metric_summary),
  },
  {
    name: 'SUCCESS COUNT',
    sortable: true,
    sortField: 'metric_summary/success_count',
    selector: (row: any) => separatorFormatter(row.metric_summary.success_count),
  },
  {
    name: 'FAIL COUNT',
    sortable: true,
    sortField: 'metric_summary/fail_count',
    selector: (row: any) => separatorFormatter(row.metric_summary.fail_count),
  },
]

export const ChecksTab = ({ run }: Props) => {
  const { filters, removeAllFilters } = useRunDetailsChecksFilters()

  const defaultSort: DataTableSortValue = {
    sortBy: 'metric_summary/success_rate',
    sortOrder: 'asc',
  }

  const [orderBy, setOrderBy] = useState<string | undefined>(`${defaultSort.sortBy} ${defaultSort.sortOrder}`)

  const {
    data: checks,
    fetchPage,
    currentPage,
    isFetching,
    isLoading,
  } = useChecks(run, { pageSize: PAGE_SIZE, filters: getFilledFilters(filters), refetchOnMount: false, orderBy })

  const data = checks?.value || []
  const showFilters = getShowFilterSelect(data, filters)

  return (
    <LoadingContainer loading={!checks}>
      <div>
        {showFilters && <ChecksFilters run={run} />}

        <DataTable
          columns={columns}
          noDataComponent={<RunDetailsTableEmptyState filters={filters} onClearFiltersClick={removeAllFilters} />}
          data={data}
          highlightOnHover={true}
          pointerOnHover={true}
          expandableRowsHideExpander
          expandableRows
          expandOnRowClicked
          pagination
          paginationServer
          paginationPerPage={PAGE_SIZE}
          paginationTotalRows={checks?.['@count']}
          currentPage={currentPage}
          onChangePage={fetchPage}
          expandableRowsComponent={(row) => <ChecksChart run={run} check={row.data} />}
          expandableRowsComponentProps={{ run }}
          isFetching={isFetching && !isLoading}
          sortServer
          onSort={setOrderBy}
          defaultSortFieldId={defaultSort.sortBy}
          defaultSortAsc={defaultSort.sortOrder === 'asc'}
        />
      </div>
    </LoadingContainer>
  )
}
