import React from 'react'

import { TestRun } from 'types'

import { useChecksFilterOptions, useRunDetailsChecksFilters } from '../Filters/FiltersContext.hooks'
import { RunDetailsFilters } from '../Filters/Filters'

export const ChecksFilters = ({ run }: { run: TestRun }) => {
  const { filters, addNewFilter, removeFilter, addFilterValue } = useRunDetailsChecksFilters()
  const options = useChecksFilterOptions()

  return (
    <RunDetailsFilters
      filters={filters}
      options={options}
      run={run}
      onAddNewFilterClick={addNewFilter}
      onRemoveFilterClick={removeFilter}
      onAddFilterValue={addFilterValue}
    />
  )
}
