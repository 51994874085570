import * as Sentry from '@sentry/react'

// Disable sentry for local development
const isDev = location.hostname === 'localhost' || location.hostname === '127.0.0.1'
const isEnabled = !isDev

export const sentryClient = {
  configure: ({ dsn, version, environment }: { dsn: string; version: string; environment: string }) => {
    if (!isEnabled) {
      return
    }

    if (Sentry.getCurrentHub().getClient()) {
      sentryClient.enable()
      return
    }

    Sentry.init({
      dsn,
      environment,
      release: `grafana-k6-plugin@${version}`,
      normalizeDepth: 5,
    })
  },
  captureException: (...args: Parameters<typeof Sentry.captureException>) => {
    if (!isEnabled) {
      return
    }

    Sentry.captureException(...args)
  },
  captureEvent: (...args: Parameters<typeof Sentry.captureEvent>) => {
    if (!isEnabled) {
      return
    }

    Sentry.captureEvent(...args)
  },
  setContext: (...args: Parameters<typeof Sentry.setContext>) => {
    if (!isEnabled) {
      return
    }

    Sentry.setContext(...args)
  },
  setUserContext: (user: any) => {
    if (!isEnabled) {
      return
    }

    Sentry.configureScope(function (scope) {
      scope.setUser(user)
    })
  },
  clearUserContext: () => {
    if (!isEnabled) {
      return
    }

    Sentry.configureScope(function (scope) {
      scope.setUser(null)
    })
  },
  disable: () => {
    const client = Sentry.getCurrentHub().getClient()

    if (client) {
      client.getOptions().enabled = false
    }
  },
  enable: () => {
    const client = Sentry.getCurrentHub().getClient()

    if (client) {
      client.getOptions().enabled = true
    }
  },
}
