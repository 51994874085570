import { LogOptions } from '@grafana/k6-test-builder';

export const parseBuilderConfig = (config: string) => {
  try {
    const reqBuilderConfig = JSON.parse(config);
    return reqBuilderConfig?.[0]?.log?.options as LogOptions;
  } catch (e) {
    console.error(e);
    return;
  }
};
