import styled from 'styled-components'

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  padding-bottom: ${({ theme }) => theme.spacing(1)};

  h2 {
    margin: 0;
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    font-size: ${({ theme }) => theme.typography.size.base};
    text-transform: uppercase;
  }
`

export const Line = styled.div`
  height: 2px;
  background: ${({ theme }) => theme.colors.border.weak};
  flex: 1;
`
