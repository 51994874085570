import { NavModelItem } from '@grafana/data'
import { useRouteMatch } from 'react-router-dom'
import React, { createContext, useContext, useState, ReactNode } from 'react'
import { useAppContext } from 'appContext'
import { routeMap } from 'routeMap'
import { ProjectIdLabel } from 'components/ProjectIdLabel'

export type Breadcrumb = {
  path?: string
  name: string
  label?: JSX.Element | string
}

type BreadcrumbsContextType = {
  breadcrumbs: Breadcrumb[]
  pageNav: NavModelItem
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void
}

type ProviderProps = {
  children: ReactNode
}

const BreadcrumbsContext = createContext<undefined | BreadcrumbsContextType>(undefined)

export const BreadcrumbsContextProvider = ({ children }: ProviderProps) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])
  const { project } = useAppContext()
  const isSettingsRoute = useRouteMatch(routeMap.settings)

  const defaultRoute = isSettingsRoute
    ? { path: routeMap.settings, name: 'Settings' }
    : {
        path: routeMap.home,
        name: project?.name || 'k6',
        label: project ? <ProjectIdLabel id={project.id} /> : 'Project dashboard',
      }

  const breadcrumbsWithDefaultRoute = [defaultRoute, ...breadcrumbs]

  const pageNav = breadcrumbsWithDefaultRoute.reduce<NavModelItem>(
    (acc, breadcrumb, i) => {
      if (i === 0) {
        return {
          text: breadcrumb.name,
          url: breadcrumb.path,
        }
      }

      return { text: breadcrumb.name, url: breadcrumb.path, parentItem: { ...acc } }
    },
    { text: '' }
  )

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs: breadcrumbsWithDefaultRoute, pageNav, setBreadcrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  )
}

export const useBreadcrumbs = () => {
  const context = useContext(BreadcrumbsContext)

  if (context === undefined) {
    throw new Error('useBreadcrumbs must be used within a BreadcrumbsContextProvider')
  }

  return context
}
