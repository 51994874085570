import { Icon } from '@grafana/ui'
import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  cursor: pointer;
  box-sizing: border-box;
  flex-direction: row-reverse;
  position: relative;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  padding: ${({ theme }) => theme.spacing(0.5)} 0;

  &:focus-within {
    outline: 2px dotted transparent;
    outline-offset: '2px';
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.background.canvas},
      0 0 0px 4px ${({ theme }) => theme.colors.primary.main};
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-duration: '0.2s';
    transition-property: 'outline, outline-offset, box-shadow';
  }
`

export const ChevronIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const Button = styled.button`
  all: unset;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;

  &:focus-visible {
    outline: none;
    outline-offset: unset;
    transition: none;
    box-shadow: none;
  }
`

export const Content = styled.div``

export const Label = styled.div`
  display: flex;
  flex: 1 1 0;
`
