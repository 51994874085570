import { useDatasource } from 'datasourceContext'
import { toAccountQueryKey } from './queryKeys'
import { useFetch } from './useFetch'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { Account } from 'types'

const isDevMode = process.env.NODE_ENV === 'development'

export const useAccount = () => {
  const { ds } = useDatasource()
  const key = toAccountQueryKey(ds.uid)
  const [storedAccount, setStoredAccount] = useLocalStorage<Account | undefined>(key.join(''), undefined)

  return useFetch(toAccountQueryKey(ds.uid), ds.fetchAccount.bind(ds), {
    placeholderData: isDevMode ? storedAccount : undefined,
    onSuccess: (data) => {
      isDevMode && setStoredAccount(data)
    },
  })
}
