import { ChartMetric } from 'components/Chart/Chart.types'
import { TimeSeriesUnit } from 'panels/types'
import { TagQuery } from 'types'

export const NON_ZERO_STATUS_QUERY: TagQuery = {
  status: { name: 'status', operator: 'not-equal', values: ['0'] },
}

export const VUS_METRIC: ChartMetric = {
  label: 'VUs',
  type: 'area',
  unit: TimeSeriesUnit.VUs,
  color: '#86868659',
  query: {
    metric: 'vus',
    method: 'sum[last]',
    tags: {},
  },
}

export const HTTP_REQUEST_RATE: ChartMetric = {
  label: 'Request rate',
  type: 'line',
  unit: TimeSeriesUnit.RequestsPerSecond,
  query: {
    metric: 'http_reqs',
    method: 'rps',
    tags: NON_ZERO_STATUS_QUERY,
  },
}

export const HTTP_RESPONSE_TIME: ChartMetric = {
  label: 'Response time',
  type: 'line',
  unit: TimeSeriesUnit.Milliseconds,
  query: {
    metric: 'http_req_duration',
    method: '0.95',
    tags: NON_ZERO_STATUS_QUERY,
  },
}

export const HTTP_FAILURE_RATE: ChartMetric = {
  label: 'Failure rate',
  type: 'line',
  unit: TimeSeriesUnit.RequestsPerSecond,
  query: {
    metric: 'http_req_failed',
    method: 'nz_rps',
    tags: NON_ZERO_STATUS_QUERY,
  },
}

export const WS_SESSION_RATE: ChartMetric = {
  label: 'Sessions',
  type: 'line',
  unit: TimeSeriesUnit.SessionsPerSecond,
  query: {
    metric: 'ws_sessions',
    method: 'rps',
    tags: {},
  },
}

export const WS_SESSION_DURATION: ChartMetric = {
  label: 'Session duration',
  type: 'line',
  unit: TimeSeriesUnit.Milliseconds,
  query: {
    metric: 'ws_session_duration',
    method: '0.95',
    tags: {},
  },
}

export const GRPC_REQUEST_RATE: ChartMetric = {
  label: 'Request rate',
  type: 'line',
  unit: TimeSeriesUnit.RequestsPerSecond,
  query: {
    metric: 'grpc_reqs',
    method: 'rps',
    tags: {},
  },
}

export const GRPC_RESPONSE_TIME_P95: ChartMetric = {
  label: 'Response time',
  type: 'line',
  unit: TimeSeriesUnit.Milliseconds,
  query: {
    metric: 'grpc_req_duration',
    method: '0.95',
    tags: {},
  },
}

export const LOAD_GENERATOR_CPU_PERCENT: ChartMetric = {
  label: 'CPU Usage',
  type: 'line',
  unit: TimeSeriesUnit.Percent,
  query: {
    metric: 'load_generator_cpu_percent',
    method: 'max[last]',
    tags: {},
  },
}

export const LOAD_GENERATOR_MEMORY_USED_PERCENT: ChartMetric = {
  label: 'CPU Usage',
  type: 'line',
  unit: TimeSeriesUnit.Percent,
  query: {
    metric: 'load_generator_memory_used_percent',
    method: 'max[last]',
    tags: {},
  },
}

export const CHECK_PASSES: ChartMetric = {
  label: 'Passes',
  type: 'bars',
  unit: TimeSeriesUnit.Checks,
  query: {
    metric: 'checks',
    method: 'passes',
    tags: {},
  },
}

export const CHECK_FAILURES: ChartMetric = {
  label: 'Failures',
  type: 'bars',
  unit: TimeSeriesUnit.Checks,
  query: {
    metric: 'checks',
    method: 'failures',
    tags: {},
  },
}
