import styled from 'styled-components'

export const DataTableEmptyStateWrapper = styled.div`
  cursor: no-drop;
  width: 100%;
`
export const EmptyTableHead = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px ${({ theme }) => (theme.isDark ? 'rgba(204, 204, 220, 0.07)' : 'rgba(36,41,46,0.12)')} solid;
`

export const EmptyTableColumnHead = styled.div`
  flex: 1;
  min-height: 52px;
  padding: 0 ${({ theme }) => theme.spacing(2)};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
`

export const EmptyTableRow = styled.div`
  width: 100%;
  min-height: 52px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`
