import { MutableDataFrame, FieldConfig, FieldType } from '@grafana/data'

import { TimeSeriesValue } from 'types'

type DataFrameFieldConfig<T> = {
  dateKey?: keyof T | 'timestamp'
  metricKey?: keyof T | 'value'
}

export const createDataFrame = <T = TimeSeriesValue>(
  name: string,
  values: T[] = [],
  config: FieldConfig & DataFrameFieldConfig<T> = {}
) => {
  const { dateKey = 'timestamp', metricKey = 'value', ...fieldConfig } = config
  const frame = new MutableDataFrame<T>({ name, fields: [] })

  frame.addField({
    config: {},
    name: 'timestamp',
    type: FieldType.time,
    values: values.map((props) => new Date(props[dateKey as keyof T] as unknown as string).getTime()),
  })

  frame.addField({
    config: {
      ...fieldConfig,
      displayName: name,
    },
    name: metricKey as string,
    type: FieldType.number,
    values: values.map((props) => props[metricKey as keyof T] as unknown as string),
  })

  frame.addField({
    config: {
      custom: {
        hideFrom: {
          tooltip: true,
          viz: true,
          legend: true,
        },
      },
    },
    name: 'metadata',
    type: FieldType.other,
    values,
  })

  return frame
}
