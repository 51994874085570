import { separatorFormatter } from 'utils/formatters'
import { calculatePrecision } from 'utils/math'

export const getSessionDurationFormat = (key: string, row: any) => {
  const value: number = row.ws_metric_summary?.session_duration?.[key] ?? 0
  if (!value) {
    return '-'
  }
  return `${separatorFormatter(value, calculatePrecision(value))}ms`
}

export const getConnectingFormat = (key: string, row: any) => {
  const value: number = row.ws_metric_summary?.connecting?.[key] ?? 0
  if (!value) {
    return '-'
  }
  return `${separatorFormatter(value, calculatePrecision(value))}ms`
}
