import React, { useMemo } from 'react'

import { TestRun, WSValue } from 'types'
import { VUS_METRIC, WS_SESSION_DURATION, WS_SESSION_RATE } from 'constants/metrics'
import { MetricBuilder, TagQueryBuilder } from 'utils/metrics'
import { Chart } from 'components/Chart'

interface WSChartProps {
  run: TestRun
  url: WSValue
}

export const WSChart = ({ run, url }: WSChartProps) => {
  const metrics = useMemo(() => {
    const tagQuery = new TagQueryBuilder().equal('url', url.name).equal('status', url.status).build()

    return [
      VUS_METRIC,
      new MetricBuilder(WS_SESSION_RATE).withTags(tagQuery).build(),
      new MetricBuilder(WS_SESSION_DURATION).withTags(tagQuery).build(),
    ]
  }, [url])

  return <Chart title={`${url.name} (${url.status})`} testRun={run} metrics={metrics} />
}
