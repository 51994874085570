import styled from 'styled-components'
import { PageSection } from 'components/PagePanel'
import { isTopNavEnabled } from 'utils/topnav'
import { DataTableEmptyStateWrapper } from 'components/DataTable/DataTable.styles'
import { Header } from 'components/CodeSnippet/CodeSnippet.styles'

export const RunDetailsSection = styled(PageSection)`
  padding: 0;
  margin-bottom: 24px;
  background-color: ${({ theme }) => isTopNavEnabled() && theme.colors.background.secondary};

  .loading,
  .enter,
  .rdt_TableRow,
  .rdt_TableHeadRow,
  .rdt_ExpanderRow {
    background-color: ${({ theme }) => isTopNavEnabled() && theme.colors.background.secondary};
  }

  .rdt_TableRow:hover {
    background-color: ${({ theme }) => isTopNavEnabled() && theme.colors.action.hover};
  }

  ${DataTableEmptyStateWrapper} {
    width: 100%;
    background-color: ${({ theme }) => isTopNavEnabled() && theme.colors.background.secondary};
  }

  ${Header} {
    background-color: ${({ theme }) => isTopNavEnabled() && theme.colors.secondary.main};
  }

  .wrapper > div {
    background-color: ${isTopNavEnabled() && 'transparent'};
  }
`
