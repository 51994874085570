const PLUGIN_ROOT = '/a/grafana-k6-app'

export const routeMap = {
  home: `${PLUGIN_ROOT}/`,
  project: (projectId: number | ':projectId') => `${PLUGIN_ROOT}/projects/${projectId}`,
  test: (testId: number | ':testId') => `${PLUGIN_ROOT}/tests/${testId}`,
  testRun: (runId: number | ':runId') => `${PLUGIN_ROOT}/runs/${runId}`,
  script: (testId: number | 'new' | ':testId') => `${PLUGIN_ROOT}/tests/${testId}/script`,
  builder: (testId: number | 'new' | ':testId') => `${PLUGIN_ROOT}/tests/${testId}/builder`,
  cliGuide: `${PLUGIN_ROOT}/cli`,
  newTest: `${PLUGIN_ROOT}/tests/new`,
  settings: `${PLUGIN_ROOT}/settings`,
}
