import React, { ComponentType, useMemo } from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import { ChartAnnotation, ChartSeries } from './Chart.types'
import { TestRun } from 'types'
import { panelContext, toPanelData } from './ChartPanel.utils'
import { GraphFieldConfig, PanelContextProvider } from '@grafana/ui'
import { PanelRenderer, PanelRendererProps } from '@grafana/runtime'
import { panelOptions } from './panelOptions'
import { overviewPanelConfig } from 'utils/panelConfig'
import { TimeSeriesOptions } from 'panels/types'
import { PanelProps } from '@grafana/data'

const TypedPanelRenderer = PanelRenderer as ComponentType<
  PanelRendererProps<PanelProps<TimeSeriesOptions>['options'], GraphFieldConfig>
>

interface ChartPanelProps {
  testRun: TestRun
  series: Array<UseQueryResult<ChartSeries>>
  annotations: ChartAnnotation[]
  width: number
  height: number
}

export const ChartPanel = ({ testRun, series, annotations, width, height }: ChartPanelProps) => {
  const panelData = useMemo(() => toPanelData(testRun, series, annotations), [testRun, series, annotations])

  return (
    <PanelContextProvider value={panelContext}>
      <TypedPanelRenderer
        title=""
        pluginId="timeseries"
        onOptionsChange={() => {}}
        width={width}
        height={height}
        data={panelData}
        options={panelOptions}
        fieldConfig={overviewPanelConfig}
      />
    </PanelContextProvider>
  )
}
