import { css } from 'emotion'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  .page-scrollbar-content > div:first-child {
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
  }

  // Used with topnav only. We hack it, because full-width layout doesn't really work for k6.
  [class$="-page-inner"] {
    display: grid;
    grid-template-rows: max-content auto;
    grid-template-columns: minmax(auto, 1820px);
    justify-content: center;
  }

  a {
    color: ${({ theme }) => theme.colors.text.link};

    &:hover {
      text-decoration: underline;
    }
  }

`

export const styles = {
  capitalize: css`
    text-transform: capitalize;
  `,
  chartWrapper: css`
    div {
      div {
        border: none;
      }
    }
  `,
  wrapper: css`
    display: grid;
    gap: 16px;

    @media (min-width: 901px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: 1240px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (min-width: 1600px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  `,
  mb2: css`
    margin-bottom: 8px;
  `,
}
