import { useDatasource } from 'datasourceContext'
import { TagValue, TestRun } from 'types'

import { toTagValueQueryKey } from './queryKeys'
import { QueryOptions, useFetch } from './useFetch'

export const useTagValues = (testRun: TestRun, tagName: string, options?: QueryOptions<TagValue[]>) => {
  const { ds } = useDatasource()
  
  return useFetch(
    toTagValueQueryKey(testRun.id, { tagName, ended: testRun.ended }),
    () => ds.fetchTagValues(testRun.id, tagName),
    options
  )
}
