import styled from 'styled-components'

export const Header = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`

export const Footer = styled.footer<{ $isEmptyQuery: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ $isEmptyQuery }) => ($isEmptyQuery ? 'end' : 'space-between')};
`
