import styled from 'styled-components'

export const TextButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.text.link};
  &:hover {
    text-decoration: underline;
  }
`
