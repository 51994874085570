import styled from 'styled-components'

export const Container = styled.div`
  min-height: 250px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)};

  > p {
    margin-bottom: 0;
  }
`

export const EmptyMessage = styled.p`
  text-decoration: underline;
`
