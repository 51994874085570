import React from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown, IconButton, Menu } from '@grafana/ui'
import { last } from 'lodash-es'

import { ConfirmModal } from 'components/ConfirmModal'
import { RunButtonController } from 'components/RunButtonController'
import { MenuItem } from 'components/MenuItem'
import { useRunTest } from 'data/useRunTest'
import { useStopTestRun } from 'data/useStopTestRun'
import { useConfirmation } from 'hooks/useConfirmation'
import { Test, TestRun } from 'types'
import { isTestActive, isTestRunning } from 'utils/testRun'
import { getTestConfigUrl } from 'utils/test'
import { routeMap } from 'routeMap'

interface TestMenuProps {
  test: Test
  testRuns: TestRun[]
  onDeleteTest: (test: Test) => void
  onSelectTest: (test: Test) => void
}

export const TestMenu = ({ test, testRuns, onDeleteTest, onSelectTest }: TestMenuProps) => {
  const history = useHistory()

  const hasTestRuns = testRuns.length > 0
  const lastRun = last(testRuns)
  const isActive = !!lastRun && isTestActive(lastRun)
  const isRunning = !!lastRun && isTestRunning(lastRun)

  const { mutateAsync: runTest, isLoading: isRunPending } = useRunTest()
  const { mutateAsync: stopTestRun, isLoading: isStopPending } = useStopTestRun()
  const [deleteConfirmationProps, handleConfirmDelete] = useConfirmation(() => onDeleteTest(test))
  const [stopConfirmationProps, handleConfirmStop] = useConfirmation(async () => {
    await stopTestRun(lastRun)
  })

  const handleRunTest = () => {
    runTest(test.id).then((data) => history.push(routeMap.testRun(data.id)))
  }

  const handleConfigureTest = () => {
    history.push(getTestConfigUrl(test))
  }

  const handleSelectTest = () => {
    onSelectTest(test)
  }

  const menu = (
    <Menu>
      <MenuItem label="Configure" icon="cog" onClick={handleConfigureTest} />
      {!isActive && (
        <RunButtonController test={test}>
          {({ isDisabled }) =>
            !isDisabled && (
              <MenuItem
                label={hasTestRuns ? 'Re-run test' : 'Run test'}
                // Grafana UI does not include a replay icon, so we hack it to get the font awesome icon.
                // @ts-expect-error
                icon={hasTestRuns ? 'fa fa-rotate-left' : 'play'}
                onClick={handleRunTest}
                disabled={isRunPending}
              />
            )
          }
        </RunButtonController>
      )}

      {isRunning && (
        // Grafana UI does not include a stop icon, so we hack it to get the font awesome icon.
        // @ts-expect-error
        <MenuItem label="Stop test" icon="fa fa-stop" onClick={handleConfirmStop} disabled={isStopPending} />
      )}
      {<MenuItem label="Select" icon="check" onClick={handleSelectTest} disabled={isActive} />}
      {<MenuItem label="Delete test" icon="trash-alt" onClick={handleConfirmDelete} disabled={isActive} destructive />}
    </Menu>
  )

  return (
    <>
      <Dropdown overlay={menu} placement="bottom-end">
        <IconButton name="ellipsis-v" size="lg" />
      </Dropdown>
      <ConfirmModal
        {...deleteConfirmationProps}
        title={`Delete test "${test.name}"?`}
        body="This action cannot be undone."
        confirmText="Delete"
      />
      <ConfirmModal
        {...stopConfirmationProps}
        title="Stop test run"
        body={`Are you sure that you want to stop test run "${test.name}"?`}
        confirmText="Stop test run"
      />
    </>
  )
}
