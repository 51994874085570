import React, { ReactNode } from 'react'
import styled from 'styled-components'

const StyledInstructions = styled.ol`
  padding-left: ${({ theme }) => theme.spacing(2.5)};
  list-style: none;
  counter-reset: step;
`

const StyledStep = styled.li`
  position: relative;
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  counter-increment: step;

  p {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }

  h3 {
    position: relative;
  }

  &:before {
    content: counters(step, '.') ' ';

    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-12px, 1px);
    z-index: 1;
    font-size: 20px;
    line-height: 1;
  }

  &:after {
    content: '';

    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: ${({ theme }) => theme.colors.background.canvas};
    transform: translate(-21.5px, -4px);
  }
`

interface StepProps {
  title: string
  children: ReactNode
}

interface InstructionsProps {
  children: ReactNode
}

const Step = ({ title, children }: StepProps) => {
  return (
    <StyledStep>
      <header>
        <h4>{title}</h4>
      </header>

      {children}
    </StyledStep>
  )
}

export const Instructions = ({ children }: InstructionsProps) => {
  return <StyledInstructions>{children}</StyledInstructions>
}

Instructions.Step = Step
