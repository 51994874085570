import React from 'react'

export const InsightsIcon = () => {
  return (
    <svg viewBox="0 0 26.115 33.5" style={{ maxHeight: '100%' }}>
      <path
        d="M16.91 29.618v3.632H8.705v-3.632m8.205 0v-4.453a12.308 12.308 0 1 0-8.205 0v4.453m8.205 0H8.705"
        transform="translate(.25 -.5)"
        style={{
          fill: 'none',
          stroke: 'currentColor',
          strokeLinejoin: 'round',
          strokeWidth: '1.5px',
        }}
      />
      <path
        d="m8.5 13.7-.6.555.6.65.6-.65zm-3.776-2.877L7.9 14.258l1.2-1.11-3.174-3.434zM9.1 14.258l6.345-6.868-1.2-1.11L7.9 13.148z"
        transform="translate(2.975 2.734)"
        style={{
          fill: 'currentColor',
        }}
      />
    </svg>
  )
}
