import _ from 'lodash'
import {
  TestRun,
  TestRunStatus,
  TestRunStatusText,
  TestRunResultStatus,
  TestRunResultStatusText,
  BadgeColors,
  BadgeIcons,
} from '../types'
import { dateTime } from '@grafana/data'
import { canShowResultStatus, isTestActive, isTestProcessing } from 'utils/testRun'

export const padEmptyBars = (runs: TestRun[], padLength: number) => {
  const hasRuns = Boolean(runs.length)
  // runs are in reverse order
  const firstDate = dateTime(runs.at(-1)?.created)
  const lastDate = dateTime(runs.at(0)?.created)
  // calculate the date difference between the first and last run
  const diffInMilliseconds = lastDate.diff(firstDate)
  // @ts-ignore
  const firstDateMilliseconds = firstDate.milliseconds()
  // pad by the total amount of milliseconds and divide by the total number of active runs
  const padInMilliseconds = runs.length > 1 ? diffInMilliseconds / runs.length : firstDateMilliseconds

  const padding = Array.from({ length: padLength }, (_, index) => {
    const created = hasRuns ? lastDate.add(padInMilliseconds, 'milliseconds').toISOString() : null

    return {
      created,
      run_status: null,
      result_status: 0,
    }
  })

  return [...padding.reverse(), ...runs] as TestRun[]
}

export const getTestStatusText = (testRun: TestRun) => {
  if (isTestProcessing(testRun)) {
    if (testRun.run_status === TestRunStatus.ABORTED_USER) {
      return `Stopping test`
    }

    if (testRun.run_status === TestRunStatus.FINISHED) {
      return `Finalizing test`
    }

    return `Processing result`
  }

  if (testRun.run_status === null) {
    return 'Not run'
  }

  return getTestRunResultText(testRun)
}

export const getTestRunColorString = (testRun: TestRun) => {
  if (canShowResultStatus(testRun)) {
    return getResultStatusColor(testRun.result_status)
  }

  return getRunStatusColor(testRun.run_status)
}

export const getTestRunBadgeIcon = (testRun: TestRun) => {
  if (isTestActive(testRun)) {
    return BadgeIcons.SPINNER
  }

  if (testRun.run_status === TestRunStatus.FINISHED && testRun.result_status === TestRunResultStatus.PASSED) {
    return BadgeIcons.CHECK
  }

  if (testRun.run_status === TestRunStatus.ABORTED_USER) {
    return BadgeIcons.MINUS_CIRCLE
  }

  return BadgeIcons.EXCLAMATION_TRIANGLE
}

const getResultStatusColor = (status: number) => {
  switch (status) {
    case TestRunResultStatus.PASSED:
      return BadgeColors.GREEN
    case TestRunResultStatus.FAILED:
      return BadgeColors.RED
    default:
      return BadgeColors.GRAY
  }
}

export const getRunStatusColor = (status: number) => {
  switch (status) {
    case TestRunStatus.FINISHED:
      return BadgeColors.GREEN
    case TestRunStatus.RUNNING:
      return BadgeColors.BLUE
    case TestRunStatus.INITIALIZING:
      return BadgeColors.PURPLE
    case TestRunStatus.VALIDATED:
      return BadgeColors.GRAY
    case TestRunStatus.ABORTED_USER:
      return BadgeColors.ORANGE
    case TestRunStatus.TIMED_OUT:
    case TestRunStatus.ABORTED_SYSTEM:
    case TestRunStatus.ABORTED_SCRIPT_ERROR:
    case TestRunStatus.ABORTED_THRESHOLD:
    case TestRunStatus.ABORTED_LIMIT:
      return BadgeColors.RED
    default:
      return BadgeColors.GRAY
  }
}

const getTestRunResultText = (testRun: TestRun) => {
  if (canShowResultStatus(testRun)) {
    return getResultStatusText(testRun.result_status)
  }

  return getRunStatusText(testRun.run_status)
}

const getResultStatusText = (status: TestRunResultStatus) => {
  return TestRunResultStatusText[status] || ''
}

const getRunStatusText = (status: TestRunStatus) => {
  return TestRunStatusText[status] || ''
}
