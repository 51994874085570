import { ApplicationUrl } from 'constants/environment'
import { useAppContext } from 'appContext'

export const useK6Url = () => {
  const { isProd } = useAppContext()

  return isProd ? ApplicationUrl.ProductionUrl : ApplicationUrl.StagingUrl
}

export const useCloudAppUrl = (relativePath = '') => {
  const { isProd } = useAppContext()
  const url = new URL(relativePath, isProd ? ApplicationUrl.ProductionUrl : ApplicationUrl.StagingUrl)

  return url.toString()
}
