import { css, cx } from '@emotion/css'
import { Button, ButtonVariant, Modal, useStyles2 } from '@grafana/ui'
import { GrafanaTheme2 } from '@grafana/data'

import React, { useEffect, useRef } from 'react'

export interface ConfirmModalProps {
  isOpen: boolean
  isPending?: boolean
  title: string
  body: React.ReactNode
  description?: React.ReactNode
  confirmText: string
  dismissText?: string
  modalClass?: string
  confirmButtonVariant?: ButtonVariant
  onConfirm(): void
  onDismiss(): void
}

// For the most part, this is a copy of ConfirmModal from @grafana/ui
// with the extra addition of the `isPending` prop.
export const ConfirmModal = ({
  isOpen,
  isPending = false,
  title,
  body,
  description,
  confirmText,
  dismissText = 'Cancel',
  modalClass,
  onConfirm,
  onDismiss,
  confirmButtonVariant = 'destructive',
}: ConfirmModalProps): JSX.Element => {
  const styles = useStyles2(getStyles)
  const buttonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    // for some reason autoFocus property did no work on this button, but this does
    if (isOpen) {
      buttonRef.current?.focus()
    }
  }, [isOpen])

  return (
    <Modal className={cx(styles.modal, modalClass)} title={title} isOpen={isOpen} onDismiss={onDismiss}>
      <div className={styles.modalText}>
        {body}
        {description ? <div className={styles.modalDescription}>{description}</div> : null}
      </div>
      <Modal.ButtonRow>
        <Button variant="secondary" onClick={onDismiss} fill="outline" disabled={isPending}>
          {dismissText}
        </Button>
        <Button
          variant={confirmButtonVariant}
          onClick={onConfirm}
          ref={buttonRef}
          disabled={isPending}
          icon={isPending ? 'fa fa-spinner' : undefined}
        >
          {confirmText}
        </Button>
      </Modal.ButtonRow>
    </Modal>
  )
}

const getStyles = (theme: GrafanaTheme2) => ({
  modal: css`
    width: 500px;
  `,
  modalText: css({
    fontSize: theme.typography.h5.fontSize,
    color: theme.colors.text.primary,
  }),
  modalDescription: css({
    fontSize: theme.typography.body.fontSize,
  }),
})
