import React from 'react'
import styled from 'styled-components'
import { CheckMetricSummary } from 'types'
import { Icon } from '@grafana/ui'
import { unitFormatter } from '../../../../utils/formatters'

type CheckStatusProps = { summary: CheckMetricSummary }

const CountersWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 0 12px;

  p {
    margin: 0;
    padding-left: 2px;
  }

  & > p {
    margin: 0 4px;
    font-size: 13px;
  }
`

const Counter = styled.div<{ $isValid: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  &,
  & > div {
    color: ${({ $isValid, theme }) => ($isValid ? theme.colors.success.main : theme.colors.error.main)};
  }
`
export const ChecksCounters = ({ summary }: CheckStatusProps) => {
  const { fail_count, success_count } = summary

  return (
    <CountersWrapper>
      <Counter $isValid={true}>
        <Icon name="check" size="md" />
        <p>{unitFormatter(success_count)}</p>
      </Counter>
      <p>/</p>
      <Counter $isValid={false}>
        <Icon name="times" size="md" />
        <p> {unitFormatter(fail_count)}</p>
      </Counter>
    </CountersWrapper>
  )
}
