import styled from 'styled-components'

export const InsightBody = styled.div`
  padding: 20px;

  ul {
    padding-left: 20px;
    margin-bottom: 20px;
  }
`
