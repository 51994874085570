import { useMutation } from '@tanstack/react-query'

import { ApiError, TestRun } from 'types'
import { useTrackFeature } from 'hooks/useTrackFeature'
import { useDatasource } from 'datasourceContext'

export const useRunTest = () => {
  const { ds } = useDatasource()
  const trackFeature = useTrackFeature()

  const handleSuccess = () => {
    trackFeature('start_test')
  }

  return useMutation<TestRun, ApiError, any, unknown>((id: number) => ds.runTest(id), {
    onSuccess: handleSuccess,
  })
}
