import styled from 'styled-components'
import { isTopNavEnabled } from 'utils/topnav'

export const Code = styled.code`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.9em;
  background-color: ${({ theme }) => theme.colors.background.canvas};
  padding: 3px 4px;
  white-space: nowrap;
`

export const IngestMessage = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
  }
`

export const Section = styled.section`
  padding: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => isTopNavEnabled() && theme.colors.background.secondary};
  min-height: 250px;

  [class$='logDetailsDefaultCursor']:hover {
    background-color: ${({ theme }) => isTopNavEnabled() && 'transparent'};
  }

  .scrollbar-view > div {
    margin-top: 0;
  }
`
