import React, { RefObject, useEffect, useRef, useState } from 'react'
import { Icon, Pagination, Spinner } from '@grafana/ui'
import { noop } from 'lodash-es'

import DataTableComponent, { IDataTableProps, PaginationComponentProps } from 'react-data-table-component'
import { PaginationChangePage, TableColumn } from 'react-data-table-component/dist/src/DataTable/types'

import { calculateTotalPages } from 'utils/pagination'

import { useTableTheme } from './DataTable.hooks'
import { DataTableWrapper, PaginationWrapper, SortIconWrapper, SpinnerWrapper } from './DataTable.styles'
import { useSorting } from './hooks/useSorting'
import { DataTableEmptyState } from './DataTableEmptyState'

interface DataTableProps<T> extends Omit<IDataTableProps<T>, 'theme' | 'onSort'> {
  isFetching?: boolean
  currentPage?: number
  onChangePage?: PaginationChangePage
  orderBy?: string
  onSort?: (orderBy?: string) => void
  noDataMessage?: string
}

export function DataTable<T>({
  paginationComponent = GrafanaPagination,
  paginationPerPage = 20,
  isFetching = false,
  onChangePage = noop,
  currentPage,
  columns,
  onSort,
  defaultSortFieldId,
  defaultSortAsc,
  noDataMessage = 'There are no records to display',
  ...rest
}: DataTableProps<T>) {
  const theme = useTableTheme()

  const PaginationComponent = paginationComponent
  const paginationProps = currentPage !== undefined ? { currentPage } : {}

  const { orderBy, onSortHandler } = useSorting({
    sortBy: defaultSortFieldId,
    sortOrder: defaultSortAsc === true ? 'asc' : 'desc',
  })

  useEffect(() => {
    onSort && onSort(orderBy)
  }, [onSort, orderBy])

  const handleSort = (column: TableColumn<T>) => {
    onSortHandler(column)
    onSort && onSort(orderBy)
  }

  return (
    <DataTableWrapper $isFetching={isFetching} className="data-table-wrapper">
      <DataTableComponent<T>
        noDataComponent={<DataTableEmptyState columns={columns} message={noDataMessage} />}
        paginationPerPage={paginationPerPage}
        paginationComponent={(props) => (
          <PaginationComponent {...props} {...paginationProps} onChangePage={onChangePage} />
        )}
        columns={columns}
        sortIcon={<SortIcon orderBy={orderBy} columns={columns} />}
        {...rest}
        theme={theme}
        onSort={handleSort}
      />

      {isFetching && (
        <SpinnerWrapper>
          <Spinner size={34} />
        </SpinnerWrapper>
      )}
    </DataTableWrapper>
  )
}

const GrafanaPagination = ({ currentPage, rowCount, rowsPerPage, onChangePage }: PaginationComponentProps) => {
  const numberOfPages = calculateTotalPages({ totalSize: rowCount, pageSize: rowsPerPage })

  if (numberOfPages <= 1) {
    return null
  }

  return (
    <PaginationWrapper>
      <Pagination
        currentPage={currentPage}
        numberOfPages={numberOfPages}
        onNavigate={(page: number) => onChangePage(page, rowsPerPage)}
      />
    </PaginationWrapper>
  )
}

interface SortIconProps<T> {
  orderBy?: string
  columns: Array<TableColumn<T>>
}

const SortIcon = <T,>({ orderBy, columns }: SortIconProps<T>) => {
  const ref = useRef<HTMLDivElement>(null)
  const [column, setColumn] = useState<TableColumn<T>>()

  // When default sorting is set
  useEffect(() => {
    if (ref) {
      setColumn(columns[getSortedColumnIndex(ref)])
    }
  }, [columns, ref])

  const order = getColumnSortOrder({ column, orderBy })

  if (!order) {
    ref.current?.parentElement?.parentElement?.classList.remove('active')
  } else {
    ref.current?.parentElement?.parentElement?.classList.add('active')
  }

  return (
    <SortIconWrapper ref={ref} $isSorted={!!order}>
      <Icon name={!order ? 'angle-right' : order === 'desc' ? 'angle-down' : 'angle-up'} />
    </SortIconWrapper>
  )
}

const getSortedColumnIndex = (ref: RefObject<HTMLDivElement>) => {
  const columnId = ref.current?.parentElement?.parentElement?.getAttribute('data-column-id')
  return !ref.current || !columnId ? -1 : +columnId - 1
}

const getColumnSortOrder = <T,>({ column, orderBy }: { orderBy?: string; column?: TableColumn<T> }) => {
  const [field, order] = (orderBy || '').split(' ')
  return !!field && !!order && column?.sortField === field ? order : undefined
}
