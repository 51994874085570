import React from 'react'
import { PanelRenderer } from '@grafana/runtime'

import { LogsFilter, ParsedLog } from 'types/logs'
import { createPanelData, panelOptions } from './LogList.utils'
import { Container } from './LogList.styles'

interface LogsPanelProps {
  filters: LogsFilter
  logs: ParsedLog[]
}

export const LogList = ({ filters, logs }: LogsPanelProps) => {
  return (
    <Container data-testid="log-list">
      <PanelRenderer
        title=""
        pluginId="logs"
        width={0}
        height={0}
        data={createPanelData(logs, filters)}
        options={panelOptions}
      />
    </Container>
  )
}
