import React from 'react'
import { Button, ButtonProps } from '@grafana/ui'
import { Link, LinkProps } from 'react-router-dom'

type LinkButtonProps = {
  to: LinkProps['to']
  children: ButtonProps['children']
  button?: ButtonProps
}

export const LinkButton = ({ to, children, button }: LinkButtonProps) => (
  <Link to={to} style={{ textDecoration: 'none' }}>
    <Button {...button}>{children}</Button>
  </Link>
)
