import React, { useEffect, useState, FormEvent } from 'react'
import { HorizontalGroup, Icon, Input, Select } from '@grafana/ui'
import { SelectableValue } from '@grafana/data'
import debounce from 'lodash/debounce'

import { TestSortOptions } from 'types'
import { styles } from '../styles'
import { useAppContext } from 'appContext'
import { useBreadcrumbs } from 'breadcrumbsContext'
import { useTests } from 'data/useTests'
import { ButtonTypes, GoToK6Button } from 'components/GoToK6Button'
import { InfiniteScroll } from 'components/InfiniteScroll'
import { TestCard } from './components/TestCard'
import { CreateTestButton } from 'components/CreateTestButton'
import { PageSection } from 'components/PagePanel'
import { useDeleteTest } from 'data/useDeleteTest'
import { useDeleteTests } from 'data/useDeleteTests'
import { SelectionActions } from './components/SelectionActions'
import { useTestSelection } from './hooks/useTestSelection'
import { PluginPage } from 'components/PluginPage'
import { isTopNavEnabled } from 'utils/topnav'
import { ProjectIdLabel } from 'components/ProjectIdLabel'

const selectOptions = [
  { label: 'Last test run', value: TestSortOptions.LastTestRun },
  { label: 'Created', value: TestSortOptions.Created },
  { label: 'Name', value: TestSortOptions.Name },
]

const SEARCH_WAIT_INTERVAL = 300

export const ProjectPage = () => {
  const [searchValue, setSearchValue] = useState('')
  const [sortOption, setSortOption] = useState<TestSortOptions | undefined>(TestSortOptions.LastTestRun)
  const { projectId } = useAppContext()
  const { pageNav, setBreadcrumbs } = useBreadcrumbs()
  const { tests, isFetching, fetchNextPage } = useTests(projectId, searchValue, sortOption)
  const { mutateAsync: deleteTest } = useDeleteTest(projectId, searchValue, sortOption)
  const { mutateAsync: deleteTests } = useDeleteTests(projectId, searchValue, sortOption)
  const { selection, showSelection, onSelectTest, onClearSelection } = useTestSelection(tests)
  const isTopNav = isTopNavEnabled()

  const onSearchValueChanged = (search: string) => {
    setSearchValue(encodeURI(search))
  }

  const onSortOptionChanged = (selectedOption: SelectableValue<TestSortOptions>) => {
    setSortOption(selectedOption.value)
  }

  const handleDeleteTests = async (ids: number[]) => {
    await deleteTests(ids)
    onClearSelection()
  }

  const debouncedOnInputChange = debounce(onSearchValueChanged, SEARCH_WAIT_INTERVAL)

  useEffect(() => {
    setBreadcrumbs([])
  }, [setBreadcrumbs])

  const actions = showSelection ? (
    <SelectionActions selection={selection} onDeleteTests={handleDeleteTests} onClearSelection={onClearSelection} />
  ) : (
    <HorizontalGroup>
      <CreateTestButton />
      <GoToK6Button type={ButtonTypes.Projects} id={projectId}></GoToK6Button>
    </HorizontalGroup>
  )

  return (
    <PluginPage pageNav={pageNav} actions={actions} subTitle={<ProjectIdLabel id={projectId} />}>
      <PageSection>
        <HorizontalGroup justify={'space-between'}>
          <HorizontalGroup>
            <Input
              prefix={<Icon name="search" />}
              placeholder={'Search by test name'}
              onInput={(e: FormEvent<HTMLInputElement>) => {
                debouncedOnInputChange(e.currentTarget.value)
              }}
            />
            <Select options={selectOptions} value={sortOption} onChange={onSortOptionChanged} />
          </HorizontalGroup>
          {!isTopNav && actions}
        </HorizontalGroup>
        <br />
        <InfiniteScroll isLoading={isFetching} loadNext={fetchNextPage}>
          <div className={styles.wrapper}>
            {tests.map((test) => (
              <TestCard
                key={test.id}
                test={test}
                onDeleteTest={deleteTest}
                onSelectTest={onSelectTest}
                selected={selection.includes(test.id)}
                showSelection={showSelection}
              />
            ))}
          </div>
        </InfiniteScroll>
      </PageSection>
    </PluginPage>
  )
}
