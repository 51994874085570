import { Icon } from '@grafana/ui'
import styled from 'styled-components'

export const Label = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing(1)};
  font-size: 0;

  button {
    margin: 0;
  }
`

export const StatusIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.success.text};
`
