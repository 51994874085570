import { useMutation } from '@tanstack/react-query'

import { useDatasource } from 'datasourceContext'
import { ApiError, Test, TestSortOptions } from 'types'
import { showAlert } from 'utils/showAlert'
import { toTestsQueryKey } from './queryKeys'
import { queryClient } from './useFetch'

export const useDeleteTest = (projectId: number, search?: string, order?: TestSortOptions) => {
  const { ds } = useDatasource()

  return useMutation<unknown, ApiError, Test, unknown>((test: Test) => ds.deleteTest(test.id), {
    onSuccess: (_, test) => {
      queryClient.invalidateQueries(toTestsQueryKey(projectId, search, order))
      showAlert(`Successfully deleted test "${test.name}".`, 'success')
    },
    onError: (_, test) => showAlert(`Failed to delete test "${test.name}".`, 'error'),
  })
}
