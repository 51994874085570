import { useQuery } from '@tanstack/react-query'

import { LogsQuery } from 'types/logs'
import { LOGS_POLLING_INTERVAL } from 'constants/logs'
import { useDatasource } from 'datasourceContext'

interface UseLogsOptions {
  enabled: boolean
  page: number
  streaming: boolean
}

export const useLogs = (params: Omit<LogsQuery, 'limit' | 'direction'>, options: UseLogsOptions) => {
  const { ds } = useDatasource()

  return useQuery({
    queryKey: ['logs', params.query, options.page],
    queryFn: () => ds.fetchLogs(params),
    enabled: options.enabled,
    keepPreviousData: true,
    refetchInterval: options.streaming && LOGS_POLLING_INTERVAL,
  })
}
