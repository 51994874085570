import React, { ReactNode } from 'react'
import styled from 'styled-components'

type StatusProps = {
  isValid: boolean
  children?: ReactNode
}

const Bar = styled.div`
  width: 4px;
  height: 100%;
`

const Wrapper = styled.div<{ $isValid: boolean }>`
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  ${Bar} {
    background-color: ${({ $isValid, theme }) => ($isValid ? theme.colors.success.main : theme.colors.error.main)};
  }
`
export const Status = ({ isValid, children }: StatusProps) => {
  return (
    <Wrapper $isValid={isValid}>
      <Bar>&nbsp;</Bar>
      {children}
    </Wrapper>
  )
}
