import React, { useMemo, useState } from 'react'
import { TestRun, Threshold } from 'types'
import { calculatePrecision } from 'utils/math'
import { separatorFormatter } from 'utils/formatters'
import { useThresholds } from 'data/useThresholds'
import { Status } from '../Status'
import { ThresholdChart } from './ThresholdChart'
import { ThresholdsFilters } from './ThresholdsFilters'
import { LoadingContainer } from 'components/LoadingContainer'
import { DataTable } from 'components/DataTable'
import { TableColumn } from 'react-data-table-component'

import { useRunDetailsThresholdsFilters } from '../Filters/FiltersContext.hooks'
import { getFilledFilters, getShowFilterSelect } from '../Filters/Filters.utils'
import { RunDetailsTableEmptyState } from '../TableEmptyState'
import { ParsedThreshold, parseThresholdName } from './ThresholdChart.utils'

const PAGE_SIZE = 20

interface ThresholdRow {
  threshold: Threshold
  parsed: ParsedThreshold
}

const columns: Array<TableColumn<ThresholdRow>> = [
  {
    name: '',
    cell: ({ threshold }) => <Status isValid={!threshold.tainted}></Status>,
    width: '4px',
    compact: true,
  },
  {
    name: 'NAME',
    sortable: true,
    sortField: 'name',
    selector: ({ parsed }) => parsed.name,
  },
  {
    name: 'CONDITION',
    selector: ({ parsed }) => parsed.condition,
  },
  {
    name: 'CALCULATED VALUE',
    selector: ({ threshold }) => {
      return threshold.calculated_value
        ? `${threshold.stat} = ${separatorFormatter(
            threshold.calculated_value,
            calculatePrecision(threshold.calculated_value)
          )}`
        : '-'
    },
  },
]

interface ThresholdTabProps {
  run: TestRun
}

export const ThresholdTab = ({ run }: ThresholdTabProps) => {
  const { filters, removeAllFilters } = useRunDetailsThresholdsFilters()
  const [orderBy, setOrderBy] = useState<string>()

  const {
    data: thresholds,
    isFetching,
    isLoading,
    fetchPage,
    currentPage,
  } = useThresholds(run, { pageSize: PAGE_SIZE, filters: getFilledFilters(filters), refetchOnMount: false, orderBy })

  const data = useMemo(() => {
    return thresholds?.value.map((threshold) => ({ threshold, parsed: parseThresholdName(threshold.name) })) ?? []
  }, [thresholds])
  const showFilters = getShowFilterSelect(data, filters)

  return (
    <LoadingContainer loading={!thresholds}>
      <div>
        {showFilters && <ThresholdsFilters run={run} />}

        <DataTable
          columns={columns}
          noDataComponent={<RunDetailsTableEmptyState filters={filters} onClearFiltersClick={removeAllFilters} />}
          data={data}
          highlightOnHover={true}
          pointerOnHover={true}
          expandableRowsHideExpander
          expandableRows
          expandOnRowClicked
          pagination
          paginationServer
          paginationPerPage={PAGE_SIZE}
          paginationTotalRows={thresholds?.['@count']}
          onChangePage={fetchPage}
          expandableRowsComponent={({ data }) => <ThresholdChart run={run} threshold={data.threshold} />}
          expandableRowsComponentProps={{ run }}
          currentPage={currentPage}
          isFetching={isFetching && !isLoading}
          sortServer
          onSort={setOrderBy}
        />
      </div>
    </LoadingContainer>
  )
}
