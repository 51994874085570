import {
  MetricPayload,
  TestSortOptions,
  TestRunHttpFilter,
  TestRunThresholdsFilter,
  TestRunChecksFilter,
  TestRunGRPCFilter,
  TestRunWebSocketsFilter,
} from 'types'

export const toAccountQueryKey = (id: string) => ['account', id]
export const toActiveRunsQueryKey = (projectId: number) => ['active-runs-background-tasks', projectId]
export const toHttpStatsQueryKey = (testRunId: number) => ['http', testRunId]
export const toLoadZonesQueryKey = (orgId?: number) => ['loadZones', orgId]
export const toOrgRolesQueryKey = (orgId?: number) => ['orgRoles', orgId]
export const toOverviewQueryKey = (testRunId: number) => ['overview', testRunId]
export const toTestRunQueryKey = (testRunId: number) => ['testRun', testRunId]
export const toTestRunsQueryKey = (testId: number) => ['testRuns', testId]
export const toSummaryQueryKey = (testRunId: number) => ['summary', testRunId]
export const toPerformanceInsightsQueryKey = (testRunId: number) => ['performance-insights', testRunId]
export const toTestQueryKey = (testId?: number | null) => ['test', testId]
export const toTimeSeriesQueryKey = (testRunId: number, types: MetricPayload[]) => ['timeSeries', testRunId, types]
export const toProjectQueryKey = (projectId?: number) => ['project', projectId]
export const toScenariosQueryKey = (testRunId: number, params?: { ended?: string }) => ['scenarios', testRunId, params]

export const toTagValueQueryKey = (testRunId: number, params?: { tagName: string; ended?: string }) => [
  'tagValues',
  testRunId,
  params,
]

export const toHttpQueryKey = (
  testRunId: number,
  params?: {
    page?: number
    select?: string
    count?: true
    filters?: TestRunHttpFilter[]
    ended?: string
    orderBy?: string
  }
) => ['http', testRunId, params]

export const toChecksQueryKey = (
  testRunId: number,
  params?: {
    page?: number
    select?: string
    count?: true
    filters?: TestRunChecksFilter[]
    ended?: string
    orderBy?: string
  }
) => ['checks', testRunId, params]

export const toThresholdsQueryKey = (
  testRunId: number,
  params?: {
    page?: number
    select?: string
    count?: true
    filters?: TestRunThresholdsFilter[]
    ended?: string
    orderBy?: string
  }
) => ['thresholds', testRunId, params]

export const toGrpcQueryKey = (
  testRunId: number,
  params?: {
    page?: number
    select?: string
    count?: true
    filters?: TestRunGRPCFilter[]
    ended?: string
    orderBy?: string
  }
) => ['grpc', testRunId, params]

export const toWebSocketsQueryKey = (
  testRunId: number,
  params?: {
    page?: number
    select?: string
    count?: true
    filters?: TestRunWebSocketsFilter[]
    ended?: string
    orderBy?: string
  }
) => ['websockets', testRunId, params]

export const toTestsQueryKey = (projectId: number, search?: string, order?: TestSortOptions) => [
  'tests',
  projectId,
  search,
  order,
]
