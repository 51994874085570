import styled from 'styled-components'

export const GuideContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 2, 1)};
  width: 100%;
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
`
