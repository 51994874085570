import React from 'react'

export const RunOverviewIcon = () => {
  return (
    <svg viewBox="0 0 32.487 29.238">
      <g>
        <path fill="#beb9d7" d="M1.083 28.156V0H0v28.7a.541.541 0 0 0 .541.541h31.946v-1.085z" />
        <path
          fill="#beb9d7"
          d="M72.851 160.535a2.173 2.173 0 0 0 3.349-1.82 2.137 2.137 0 0 0-.266-1.019l4.335-3.251a2.152 2.152 0 0 0 3.2-.6l3.562 1.022v.054a2.166 2.166 0 1 0 4.332 0 2.166 2.166 0 0 0-.44-1.288l4.819-6.022a2.144 2.144 0 0 0 1.035.275 2.182 2.182 0 1 0-1.829-1.017l-4.873 6.091a2.082 2.082 0 0 0-.271-.1 2.166 2.166 0 0 0-2.463.975l-3.563-1.018v-.054a2.176 2.176 0 0 0-1.559-2.09 2.153 2.153 0 0 0-2.6 2.906l-4.424 3.32a2.166 2.166 0 1 0-2.347 3.641zm23.924-15.9a1.083 1.083 0 1 1-1.083 1.083 1.083 1.083 0 0 1 1.083-1.082zm-8.62 10a1.073 1.073 0 1 1-.043.289.952.952 0 0 1 .043-.292zm-7.58-2.187a1.083 1.083 0 0 1 2.122.31v.008a.952.952 0 0 1-.043.289 1.083 1.083 0 0 1-2.079-.606zm-6.541 5.187a1.083 1.083 0 1 1-1.083 1.083 1.083 1.083 0 0 1 1.083-1.087z"
          transform="translate(-66.995 -133.807)"
        />
        <path
          fill="#beb9d7"
          d="M74.166 56.121a2.156 2.156 0 0 0 1.606-3.6l5.312-8.421a2.073 2.073 0 0 0 1.97-.337l4.291 3.375a2.16 2.16 0 0 0 1.981 3.036 2.162 2.162 0 0 0 2.081-2.745l3.9-2.287a2.166 2.166 0 0 0 1.593.7h.008a2.169 2.169 0 1 0-2.174-2.166 2.225 2.225 0 0 0 .064.507l-3.936 2.3a2.17 2.17 0 0 0-1.543-.646h-.006a2.142 2.142 0 0 0-1.3.449l-4.293-3.377a2.166 2.166 0 1 0-3.56.608l-5.3 8.4a2.14 2.14 0 0 0-.7-.127 2.166 2.166 0 0 0 0 4.332zm22.741-13.537a1.085 1.085 0 0 1 0 2.17 1.083 1.083 0 0 1-.939-.541 1.163 1.163 0 0 1-.143-.545 1.083 1.083 0 0 1 1.082-1.084zm-7.58 4.335A1.083 1.083 0 1 1 88.244 48a1.083 1.083 0 0 1 1.082-1.08zm-7.58-5.96a1.083 1.083 0 1 1-1.083 1.083 1.083 1.083 0 0 1 1.082-1.082zm-7.58 11.912a1.083 1.083 0 1 1-1.083 1.083 1.083 1.083 0 0 1 1.082-1.082z"
          transform="translate(-67.127 -37.17)"
        />
      </g>
    </svg>
  )
}
