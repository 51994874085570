import React from 'react'
import styled from 'styled-components'

import { SelectableValue } from '@grafana/data'
import { IconButton, InlineFormLabel, MultiSelect } from '@grafana/ui'

import { TestRun, TestRunFilter } from 'types'
import { useCustomFilterOptions } from './CustomerFilter.hooks'

export function CustomFilter<T extends TestRunFilter>({
  run,
  filter,
  onRemoveButtonClick,
  onAddFilterValue,
  allowCustomValue = true,
}: {
  run: TestRun
  filter: T
  onRemoveButtonClick: (by: T['by']) => void
  onAddFilterValue: (value: string[]) => void
  allowCustomValue?: boolean
}) {
  const { options, isFetching } = useCustomFilterOptions(filter, run)

  const handleOnCreateOption = (value: string) => {
    const sanitizedValue = value.replace(/["']/g, '')
    onAddFilterValue([...filter.values, sanitizedValue])
  }

  const handleOnChange = (value: Array<SelectableValue<string>>) => {
    const values = value.map((value) => value.value ?? '')
    onAddFilterValue(values)
  }

  return (
    <CustomFilterContainer>
      <InlineFormLabel width="auto" className="query-keyword">
        {filter.label}
      </InlineFormLabel>

      <MultiSelect
        autoFocus={filter.values.length === 0}
        openMenuOnFocus
        options={options}
        value={filter.values}
        isLoading={isFetching}
        allowCustomValue={allowCustomValue}
        loadingMessage=""
        onCreateOption={handleOnCreateOption}
        onChange={handleOnChange}
        width="auto"
        menuPlacement="auto"
      />

      <IconButton name="times" onClick={() => onRemoveButtonClick(filter.by)} />
    </CustomFilterContainer>
  )
}

const CustomFilterContainer = styled.div`
  display: flex;
  align-items: center;

  > div {
    min-width: 200px;
  }

  > button {
    margin-left: ${({ theme }) => theme.spacing(1)};

    svg {
      color: ${({ theme }) => theme.colors.text.secondary};
    }
  }
`
