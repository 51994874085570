import { useMutation } from '@tanstack/react-query'

import { useDatasource } from 'datasourceContext'
import { ApiError, Test } from 'types'

import { queryClient } from './useFetch'
import { toTestQueryKey } from './queryKeys'

export const useUpdateTest = () => {
  const { ds } = useDatasource()

  return useMutation<Test, ApiError, any, unknown>(ds.updateTest.bind(ds), {
    onSuccess: (data) => {
      // Data needs to be re-fetched because `ds.updateTest` response does not include test.test_runs
      queryClient.invalidateQueries(toTestQueryKey(data.id))
    },
  })
}
