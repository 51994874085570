import { GrpcMetricSummary, HttpMetricSummary, Summary, WsMetricSummary } from 'types'
import { unitFormatter } from 'utils/formatters'

export const getHttpCount = ({ requests_count, failures_count }: HttpMetricSummary) => {
  return (
    requests_count !== null && `(${unitFormatter(requests_count - failures_count)}/${unitFormatter(requests_count)})`
  )
}

export const getGrpcCount = ({ requests_count }: GrpcMetricSummary) => {
  return `(${unitFormatter(requests_count)})`
}

export const getWSCount = ({ sessions }: WsMetricSummary) => {
  return `(${unitFormatter(sessions)})`
}

export const getChecksCount = (summary: Summary) => {
  const { hits_total, hits_successes } = summary.checks_metric_summary
  return `(${unitFormatter(hits_successes ?? 0)}/${unitFormatter(hits_total ?? 0)})`
}

export const getThresholdsCount = (summary: Summary) => {
  const { total, successes } = summary.thresholds_summary
  return `(${successes}/${total})`
}
