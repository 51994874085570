import { useCallback, useContext, useEffect, useRef } from 'react'
import { FiltersContext } from './FiltersContext'

import {
  TestRunHttpFilterBy,
  TestRunThresholdsFilterBy,
  TestRunChecksFilterBy,
  TestRunGRPCFilterBy,
  TestRunWebSocketsFilterBy,
  TestRunFilterType,
  TestRunFilterBy,
} from 'types'

import {
  CHECKS_FILTER_OPTIONS,
  GRPC_FILTER_OPTIONS,
  HTTP_FILTER_OPTIONS,
  THRESHOLDS_FILTER_OPTIONS,
  WEB_SOCKETS_FILTER_OPTIONS,
} from './Filters.constants'

function useFilters<FilterType extends TestRunFilterType, ByType extends TestRunFilterBy>(filterType: FilterType) {
  const context = useContext(FiltersContext)

  if (context === undefined) {
    throw new Error('useFilters must be used within a FiltersContextProvider')
  }

  const [filters, dispatch] = context

  return {
    filters: filters[filterType],
    addNewFilter: useCallback(
      ({ by, label }: { by: ByType; label: string }) =>
        dispatch({ type: 'ADD_NEW_FILTER', payload: { filterType, by: by as any, label } }),
      [dispatch, filterType]
    ),
    removeFilter: useCallback(
      ({ by }: { by: ByType }) => dispatch({ type: 'REMOVE_FILTER', payload: { filterType, by: by as any } }),
      [dispatch, filterType]
    ),
    removeAllFilters: useCallback(
      () => dispatch({ type: 'REMOVE_ALL_FILTERS', payload: { filterType } }),
      [dispatch, filterType]
    ),
    addFilterValue: useCallback(
      ({ by, value }: { by: ByType; value: string[] }) =>
        dispatch({ type: 'SET_FILTER_VALUE', payload: { filterType, by: by as any, value } }),
      [dispatch, filterType]
    ),
  }
}

export const useRunDetailsHttpFilters = () =>
  useFilters<TestRunFilterType.Http, TestRunHttpFilterBy>(TestRunFilterType.Http)

export const useRunDetailsThresholdsFilters = () =>
  useFilters<TestRunFilterType.Thresholds, TestRunThresholdsFilterBy>(TestRunFilterType.Thresholds)

export const useRunDetailsChecksFilters = () =>
  useFilters<TestRunFilterType.Checks, TestRunChecksFilterBy>(TestRunFilterType.Checks)

export const useRunDetailsGRPCFilters = () =>
  useFilters<TestRunFilterType.GRPC, TestRunGRPCFilterBy>(TestRunFilterType.GRPC)

export const useRunDetailsWebSocketsFilters = () =>
  useFilters<TestRunFilterType.WebSockets, TestRunWebSocketsFilterBy>(TestRunFilterType.WebSockets)

function useFilterOptions<FilterType extends TestRunFilterType, ByType extends TestRunFilterBy>(
  type: FilterType,
  initOptions: Array<{ label: string; value: ByType }>
) {
  const { filters, removeFilter } = useFilters(type)

  // Don't show already selected filters as viable options
  const options = initOptions.filter((option) => !(filters as any[]).find((filter) => filter.by === option.value))

  const filtersRef = useRef(filters)
  filtersRef.current = filters

  useEffect(() => {
    return () => {
      filtersRef.current.forEach((filter) => {
        if (filter.values.length === 0) {
          removeFilter(filter) // Remove empty filters on unmount
        }
      })
    }
  }, [removeFilter])

  return options
}

export const useHttpFilterOptions = () =>
  useFilterOptions<TestRunFilterType.Http, TestRunHttpFilterBy>(TestRunFilterType.Http, HTTP_FILTER_OPTIONS)

export const useThresholdsFilterOptions = () =>
  useFilterOptions<TestRunFilterType.Thresholds, TestRunThresholdsFilterBy>(
    TestRunFilterType.Thresholds,
    THRESHOLDS_FILTER_OPTIONS
  )

export const useChecksFilterOptions = () =>
  useFilterOptions<TestRunFilterType.Checks, TestRunChecksFilterBy>(TestRunFilterType.Checks, CHECKS_FILTER_OPTIONS)

export const useGRPCFilterOptions = () =>
  useFilterOptions<TestRunFilterType.GRPC, TestRunGRPCFilterBy>(TestRunFilterType.GRPC, GRPC_FILTER_OPTIONS)

export const useWebSocketsFilterOptions = () =>
  useFilterOptions<TestRunFilterType.WebSockets, TestRunWebSocketsFilterBy>(
    TestRunFilterType.WebSockets,
    WEB_SOCKETS_FILTER_OPTIONS
  )
