import React, { useEffect, useMemo } from 'react'

import { TestRun, Threshold } from 'types'
import { Chart } from 'components/Chart'
import { sentryClient } from 'services/sentryClient'
import { getThresholdMetrics } from './ThresholdChart.utils'

interface ThresholdChartProps {
  run: TestRun
  threshold: Threshold
}

export const ThresholdChart = ({ run, threshold }: ThresholdChartProps) => {
  const metrics = useMemo(() => getThresholdMetrics(threshold), [threshold])

  useEffect(() => {
    if (metrics === null) {
      sentryClient.captureException(new Error(`Unable to parse the following threshold name: ${threshold.name}`))
    }
  }, [metrics, threshold.name])

  return <Chart title={`Threshold: ${threshold.name}`} testRun={run} metrics={metrics ?? []} />
}
