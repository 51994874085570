import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useAppContext } from 'appContext'
import { useBreadcrumbs } from 'breadcrumbsContext'
import { formatDate } from 'utils/date'
import { useRun } from 'data/useRun'
import { useTest } from 'data/useTest'
import { isTestStarting } from 'utils/testRun'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { Breakdown, MetaBar } from './components'
import { RunOverview } from './components/RunOverview'
import { Insights } from './components/Insights'
import { routeMap } from 'routeMap'
import { PluginPage } from 'components/PluginPage'

export const TestRunPage = () => {
  const { runId } = useParams<{ runId: string }>()
  const { project } = useAppContext()
  const { pageNav, setBreadcrumbs } = useBreadcrumbs()
  const { data: testRun } = useRun(+runId)
  const { data: test } = useTest(testRun?.test_id)

  useEffect(() => {
    if (!project || !test || !testRun) {
      return
    }

    setBreadcrumbs([
      { path: routeMap.test(test.id), name: test.name, label: 'Test overview' },
      { path: routeMap.testRun(testRun.id), name: formatDate(testRun.created), label: 'Run details' },
    ])
  }, [setBreadcrumbs, project, test, testRun])

  if (!testRun || !test) {
    return (
      <PluginPage pageNav={pageNav}>
        <CenteredSpinner $height="300px" />
      </PluginPage>
    )
  }

  return (
    <PluginPage pageNav={pageNav} subTitle="Run details">
      <MetaBar testRun={testRun} />

      {!isTestStarting(testRun) && (
        <>
          <RunOverview testRun={testRun} />
          <Insights testRun={testRun} />
          <Breakdown test={test} testRun={testRun} />
        </>
      )}
    </PluginPage>
  )
}
