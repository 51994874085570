import React, { useState } from 'react'

import { Http, TestRun } from 'types'
import { separatorFormatter } from 'utils/formatters'
import { useHttpStats } from 'data/useHttpStats'
import { Status } from '../Status'
import { HttpChart } from './HttpChart'
import { getFormat } from './HttpTab.utils'
import { TableColumn } from 'react-data-table-component'
import { HttpFilters } from './HttpFilters'
import { LoadingContainer } from 'components/LoadingContainer'
import { DataTable } from 'components/DataTable'

import { useRunDetailsHttpFilters } from '../Filters/FiltersContext.hooks'
import { getFilledFilters, getShowFilterSelect } from '../Filters/Filters.utils'
import { RunDetailsTableEmptyState } from '../TableEmptyState'
import { TooltipCell } from 'components/DataTable/TooltipCell'

const PAGE_SIZE = 20

const columns: Array<TableColumn<Http>> = [
  {
    name: '',
    cell: (row) => <Status isValid={row.expected_response}></Status>,
    width: '4px',
    compact: true,
  },
  {
    name: 'URL',
    sortable: true,
    sortField: 'name',
    cell: (row) => <TooltipCell>{row.name}</TooltipCell>,
    minWidth: '250px',
  },
  {
    name: 'METHOD',
    sortable: true,
    sortField: 'method',
    selector: (row) => row.method,
  },
  {
    name: 'STATUS',
    sortable: true,
    sortField: 'status',
    selector: (row) => row.status,
  },
  {
    name: 'COUNT',
    sortable: true,
    sortField: 'http_metric_summary/requests_count',
    selector: (row) => separatorFormatter(row.http_metric_summary.requests_count),
  },
  {
    name: 'MIN',
    sortable: true,
    sortField: 'http_metric_summary/duration/min',
    selector: (row) => getFormat('min', row),
  },
  {
    name: 'AVG',
    sortable: true,
    sortField: 'http_metric_summary/duration/mean',
    selector: (row) => getFormat('mean', row),
  },
  {
    name: 'STDDEV',
    sortable: true,
    sortField: 'http_metric_summary/duration/stdev',
    selector: (row) => getFormat('stdev', row),
  },
  {
    name: 'P95',
    sortable: true,
    sortField: 'http_metric_summary/duration/p95',
    selector: (row) => getFormat('p95', row),
  },
  {
    name: 'P99',
    sortable: true,
    sortField: 'http_metric_summary/duration/p99',
    selector: (row) => getFormat('p99', row),
  },
  {
    name: 'MAX',
    sortable: true,
    sortField: 'http_metric_summary/duration/max',
    selector: (row) => getFormat('max', row),
  },
]

interface HttpTabProps {
  run: TestRun
}

export const HttpTab = ({ run }: HttpTabProps) => {
  const { filters, removeAllFilters } = useRunDetailsHttpFilters()
  const [orderBy, setOrderBy] = useState<string>()

  const {
    data: http,
    fetchPage,
    currentPage,
    isFetching,
    isLoading,
  } = useHttpStats(run, { pageSize: PAGE_SIZE, filters: getFilledFilters(filters), refetchOnMount: false, orderBy })

  const data = http?.value || []
  const showFilters = getShowFilterSelect(data, filters)
  return (
    <LoadingContainer loading={!http}>
      <div>
        {showFilters && <HttpFilters run={run} />}

        <DataTable
          columns={columns}
          noDataComponent={<RunDetailsTableEmptyState filters={filters} onClearFiltersClick={removeAllFilters} />}
          data={data}
          highlightOnHover
          pointerOnHover
          expandableRowsHideExpander
          expandableRows
          expandOnRowClicked
          pagination
          paginationServer
          paginationPerPage={PAGE_SIZE}
          paginationTotalRows={http?.['@count']}
          currentPage={currentPage}
          onChangePage={fetchPage}
          expandableRowsComponent={(row) => <HttpChart run={run} url={row.data} />}
          expandableRowsComponentProps={{ run }}
          isFetching={isFetching && !isLoading}
          sortServer
          onSort={setOrderBy}
        />
      </div>
    </LoadingContainer>
  )
}
