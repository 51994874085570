import React, { ReactNode } from 'react'
import { Button, Icon, IconName } from '@grafana/ui'
import styled from 'styled-components'

type Props = {
  icon?: IconName
  children: ReactNode
  className?: string
}

const StyledMetaItem = styled.div`
  display: flex;
  align-items: center;
`

export const EditNoteButton = styled(Button)`
  // Override both default and the on hover background colors
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 400;
  line-height: unset;
  padding: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.shade};
  }
  svg {
    margin-right: 5px;
  }
`

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`

export const MetaItem = ({ icon, children, className }: Props) => {
  return (
    <StyledMetaItem className={className}>
      {icon && <StyledIcon name={icon} />}
      {children}
    </StyledMetaItem>
  )
}
