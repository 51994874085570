import { DocLink, docs } from 'components/DocLink'
import { SupportLink } from 'components/SupportLink'
import { isTestAborted } from 'utils/testRun'
import React from 'react'
import { TestRun } from 'types'
import { InsightsByStatus } from 'types/insights'
import { exhaustive } from 'utils/typescript'
import { InsightFilter } from '../Insights.types'
import { FailureIcon, SuccessIcon, WarningIcon } from './EmptyInsightList.styles'
import { hasFailed } from './EmptyInsightList.utils'
import { InsightsStateContainer } from './InsightsStateContainer'

interface EmptyInsightListPRops {
  testRun: TestRun
  filter: InsightFilter
  insights: InsightsByStatus
}

export const EmptyInsightList = ({ testRun, filter, insights }: EmptyInsightListPRops) => {
  switch (filter) {
    case 'all':
      return <InsightsStateContainer>There&apos;s nothing to see here...</InsightsStateContainer>

    case 'skipped':
      return (
        <InsightsStateContainer>
          <div>
            <p>No insights were skipped during this run. </p>
            <p>
              <DocLink article={docs.insights.disabling}>Read more.</DocLink>
            </p>
          </div>
        </InsightsStateContainer>
      )

    case 'passed':
    case 'failed':
      if (insights.PASSED.length === 0 && insights.FAILED.length === 0) {
        return (
          <InsightsStateContainer>
            <div>
              <p>This test run was not analyzed because all of the insights were skipped.</p>
              <p>
                If you have any particular reason for disabling the insights, please{' '}
                <SupportLink>let us know</SupportLink> so that we can make this feature better.
              </p>
            </div>
          </InsightsStateContainer>
        )
      }

      if (filter === 'passed') {
        return (
          <InsightsStateContainer>
            <FailureIcon name="exclamation-circle" />
            <div>
              <p>All of the insights for this test run failed.</p>
              <p>You should dig a bit deeper to see what&apos;s up.</p>
            </div>
          </InsightsStateContainer>
        )
      }

      if (hasFailed(testRun, insights)) {
        return (
          <InsightsStateContainer>
            <FailureIcon name="exclamation-circle" />
            <div>
              <p>We could not find any issues, but the test run failed.</p>
              <p>You should dig a bit deeper to see what&apos;s up.</p>
            </div>
          </InsightsStateContainer>
        )
      }

      if (isTestAborted(testRun)) {
        return (
          <InsightsStateContainer>
            <WarningIcon name="exclamation-circle" />
            <div>
              <p>We could not find any issues, but the results may be unreliable because the test run was aborted.</p>
            </div>
          </InsightsStateContainer>
        )
      }

      return (
        <InsightsStateContainer>
          <SuccessIcon name="check-circle" />
          <div>
            {/* cspell:disable-next-line */}
            <p>Yay! Our analysis couldn&apos;t find any issues.</p>
            <p>It&apos;s still a good idea to dig deeper, but this is a good sign.</p>
          </div>
        </InsightsStateContainer>
      )

    default:
      return exhaustive(filter, <></>)
  }
}
