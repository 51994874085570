import React, { Fragment, PropsWithChildren } from 'react'
import { Tooltip } from '@grafana/ui'

import { LoadZoneTooltipProps } from './LoadZoneTooltip.types'
import { Container, Title, Label } from './LoadZoneTooltip.styles'

const LoadZoneTooltipContent = ({ loadZones }: LoadZoneTooltipProps) => {
  const hasPrivate = loadZones.some((lz) => lz.isPublic === false)

  return (
    <Container $gridColumns={hasPrivate ? 4 : 3}>
      <Title>Load zone</Title>
      <Title>Public IP</Title>
      <Title>Size</Title>
      {hasPrivate && <Title>Private</Title>}

      {loadZones.map((zone) => (
        <Fragment key={zone.id}>
          <Label
            country={zone.country}
            city={zone.city}
            loadPercent={zone.loadPercent}
            isPublic={zone.isPublic}
            showLabel
            showPercentage
          />
          <span>{zone.publicIp}</span>
          <span>{zone.size}</span>
          {hasPrivate && <span>{zone.isPublic ? '-' : 'Yes'}</span>}
        </Fragment>
      ))}
    </Container>
  )
}

export const LoadZoneTooltip = ({ loadZones, children }: PropsWithChildren<LoadZoneTooltipProps>) => {
  const Content = <LoadZoneTooltipContent loadZones={loadZones} />

  return (
    <Tooltip content={Content} placement="bottom">
      <div>{children}</div>
    </Tooltip>
  )
}
