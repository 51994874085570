import styled from 'styled-components'
import { isTopNavEnabled } from 'utils/topnav'

export const ScriptTabWrapper = styled.div`
  background-color: ${({ theme }) => isTopNavEnabled() && theme.colors.background.secondary};
`

export const ScriptEditorWrapper = styled.div`
  padding: ${({ theme }) => isTopNavEnabled() && theme.spacing(0, 2, 2)};
`

export const ScriptTabButtonsContainer = styled.div`
  display: grid;
  width: 100%;
  justify-content: end;
  grid-auto-flow: column;
  padding: ${({ theme }) => theme.spacing(1, 2)};
  gap: ${({ theme }) => theme.spacing(1)};
`

export const MessageContainer = styled.div`
  flex-direction: column;
  text-align: center;
  padding: ${({ theme }) => theme.spacing(2, 0, 8)};

  &:only-child {
    padding-top: ${({ theme }) => theme.spacing(8)};
  }

  button {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }
`
