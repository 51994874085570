import React from 'react'
import { Alert } from '@grafana/ui'
import { getBackendSrv, locationService, getDataSourceSrv } from '@grafana/runtime'

import { DATASOURCE_TYPE } from 'constants/index'

const addNewDataSource = async () => {
  const { datasource } = await getBackendSrv().post('/api/datasources', {
    name: 'k6 Cloud',
    type: DATASOURCE_TYPE,
    access: 'proxy',
  })

  getDataSourceSrv().reload()
  locationService.push(`/datasources/edit/${datasource.uid}`)
}

export const AddDatasource = () => {
  return (
    <div className="page-container page-body">
      <Alert title="Missing Data Source" severity="info" buttonContent="Add" onRemove={addNewDataSource}>
        <div>You must add a k6 Data Source.</div>
      </Alert>
    </div>
  )
}
