import { GrpcUrl, Stats } from 'types'
import { separatorFormatter } from 'utils/formatters'
import { GRPC_ERROR_STATUSES, GRPC_STATUS_CODE } from 'constants/grpcStatusCodes'
import { calculatePrecision } from 'utils/math'

export const getFormat = (key: keyof Stats, row: GrpcUrl) => {
  const value: number = row.grpc_metric_summary?.duration?.[key] ?? 0
  return `${separatorFormatter(value, calculatePrecision(value))}ms`
}

export const getGRPCStatusText = (status: number) => {
  return GRPC_STATUS_CODE[status] || 'UNKNOWN'
}

export const formatGrpcTableData = (grpcUrls: GrpcUrl[]) => {
  return grpcUrls.map((url) => ({
    ...url,
    expected_response: !GRPC_ERROR_STATUSES.includes(url.status),
  }))
}
