import { RUN_PROCESS, CREATION_PROCESS, Test } from 'types'
import { isSecureRun } from './testRun'
import { routeMap } from 'routeMap'

export const isCLITest = (test: Test) => {
  const latestRun = test.test_runs[0]
  return latestRun?.id && [RUN_PROCESS.K6_CLOUD, RUN_PROCESS.K6_INGEST].includes(latestRun.run_process)
}

export const isIngestTest = (test: Test) => {
  return test.test_runs[0]?.run_process === RUN_PROCESS.K6_INGEST
}

export const isBuilderTest = (test: Test) => {
  return test.creation_process === CREATION_PROCESS.REQUEST_BUILDER
}

export const isReadOnlyTest = (test: Test) => {
  return isCLITest(test) || isBuilderTest(test) || isSecureTest(test)
}

export const isTestRunnable = (test: Test) => {
  const latestRun = test.test_runs[0]
  const isLastTestRunFromIngest = latestRun?.run_process === RUN_PROCESS.K6_INGEST
  const isTestCreatedCorrectly = [
    CREATION_PROCESS.REQUEST_BUILDER,
    CREATION_PROCESS.SCRIPT_HANDWRITTEN,
    CREATION_PROCESS.SCRIPT_CHROME,
    CREATION_PROCESS.SCRIPT_FIREFOX,
    CREATION_PROCESS.K6_CLOUD,
  ].includes(test.creation_process)

  return isTestCreatedCorrectly && !isLastTestRunFromIngest && !isSecureTest(test)
}

export function isSecureTest(test: Test) {
  const lastTestRun = test.test_runs[0]
  if (!lastTestRun) {
    return false
  }

  return isSecureRun(lastTestRun)
}

export const getTestConfigUrl = (test?: Test) => {
  if (!test) {
    return '/'
  }

  if (test.creation_process === CREATION_PROCESS.REQUEST_BUILDER) {
    return routeMap.builder(test.id)
  }

  return routeMap.script(test.id)
}
