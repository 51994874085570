import { useCallback, useMemo } from 'react'
import { Summary } from 'types'
import { Protocol } from './RunOverview.types'
import {
  GRPC_REQUEST_RATE,
  GRPC_RESPONSE_TIME_P95,
  HTTP_FAILURE_RATE,
  HTTP_REQUEST_RATE,
  HTTP_RESPONSE_TIME,
  VUS_METRIC,
  WS_SESSION_DURATION,
  WS_SESSION_RATE,
} from 'constants/metrics'
import { useQueryParameter } from 'hooks/useQueryParameter'
import { first } from 'lodash-es'

export const useAvailableProtocols = (summary: Summary | undefined) => {
  return useMemo(() => {
    const result: Protocol[] = []

    if (summary === undefined) {
      return []
    }

    if (summary.http_metric_summary.requests_count !== null) {
      result.push({
        type: 'http',
        label: 'HTTP',
        summary: summary.http_metric_summary,
        metrics: [VUS_METRIC, HTTP_REQUEST_RATE, HTTP_RESPONSE_TIME, HTTP_FAILURE_RATE],
      })
    }

    if (summary.ws_metric_summary.msgs_sent !== null) {
      result.push({
        type: 'ws',
        label: 'WebSockets',
        summary: summary.ws_metric_summary,
        metrics: [VUS_METRIC, WS_SESSION_RATE, WS_SESSION_DURATION],
      })
    }

    if (summary.grpc_metric_summary.requests_count !== null) {
      result.push({
        type: 'grpc',
        label: 'gRPC',
        summary: summary.grpc_metric_summary,
        metrics: [VUS_METRIC, GRPC_REQUEST_RATE, GRPC_RESPONSE_TIME_P95],
      })
    }

    return result
  }, [summary])
}

interface OverviewState {
  open: boolean
  active: Protocol | undefined
}

export const useOverviewState = (protocols: Protocol[]) => {
  const parser = useCallback(
    (value: string | null): OverviewState => {
      const open = value === null || !value.startsWith('-')
      const type = value?.replace(/^-/, '')

      return {
        open,
        active: protocols.find((protocol) => protocol.type === type) ?? first(protocols),
      }
    },
    [protocols]
  )

  return useQueryParameter({
    name: 'overview',
    decoder: parser,
    encoder: ({ open, active: protocol }) => {
      if (protocol === undefined) {
        return null
      }

      return open ? protocol.type : `-${protocol.type}`
    },
  })
}
