import React from 'react'

import { BadgeColors, BadgeColorCodes } from 'types'
import { getTestRunColorString } from 'pages/utils'
import { StatusIndicatorProps } from './StatusIndicator.types'
import { Indicator } from './StatusIndicator.styles'

export const StatusIndicator = ({ testRun }: StatusIndicatorProps) => {
  const statusColor = BadgeColorCodes[testRun ? getTestRunColorString(testRun) : BadgeColors.GRAY]

  return <Indicator $color={statusColor ?? BadgeColors.GRAY} />
}
