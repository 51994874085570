import { useMutation } from '@tanstack/react-query'

import { ApiError, TestRun } from 'types'
import { useDatasource } from 'datasourceContext'
import { showAlert } from 'utils/showAlert'

export const useStopTestRun = () => {
  const { ds } = useDatasource()
  
  return useMutation<TestRun, ApiError, any, unknown>(
    (run: TestRun) => ds.stopTestRun(run.id),
    {
      onError: () => showAlert('An error occurred when trying to stop test run.', 'error'),
    }
  )
}
