import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { HorizontalGroup, VerticalGroup } from '@grafana/ui'

import { useAppContext } from 'appContext'
import { useBreadcrumbs } from 'breadcrumbsContext'
import { routeMap } from 'routeMap'
import { LinkButton } from 'components/LinkButton'
import { TestBuilderIllustration } from 'components/TestBuilderIllustration'
import { ScriptEditorIllustration } from 'components/ScriptEditorIllustration'
import { PageSection } from 'components/PagePanel'
import { PluginPage } from 'components/PluginPage'
import { TabbedCLIGuide } from './components/TabbedCLIGuide'
import { Body, Card, CLIWrapper, Heading, TextSecondary } from './NewTest.styles'

export const NewTest = () => {
  const { pageNav, setBreadcrumbs } = useBreadcrumbs()
  const { project } = useAppContext()

  useEffect(() => {
    setBreadcrumbs([{ name: 'New test', label: 'Create a new test' }])
  }, [setBreadcrumbs])

  return (
    <PluginPage pageNav={pageNav}>
      <PageSection>
        <VerticalGroup align="center" spacing="none">
          <Heading>Create new test</Heading>
          <p>Choose one of two test types</p>
        </VerticalGroup>
        <Body>
          <HorizontalGroup justify="center" wrap={true}>
            <Card>
              <HorizontalGroup>
                <VerticalGroup>
                  <h3>Test Builder</h3>
                  <p>Use our interactive UI to compose GET, POST, PUT, PATCH, DELETE, HEAD and OPTIONS requests.</p>
                  <LinkButton to={routeMap.builder('new')}>Start Building</LinkButton>
                </VerticalGroup>
                <HorizontalGroup>
                  <TestBuilderIllustration />
                </HorizontalGroup>
              </HorizontalGroup>
            </Card>
            <Card>
              <HorizontalGroup>
                <VerticalGroup>
                  <h3>Script Editor</h3>
                  <p>Use our code samples as a foundation for your script or start from a clean slate.</p>
                  <LinkButton to={routeMap.script('new')}>Start Scripting</LinkButton>
                </VerticalGroup>
                <HorizontalGroup>
                  <ScriptEditorIllustration />
                </HorizontalGroup>
              </HorizontalGroup>
            </Card>
          </HorizontalGroup>
          <CLIWrapper>
            <VerticalGroup align="center" spacing="none">
              <h3>Starting a cloud test via k6 CLI</h3>
              <TextSecondary>
                You can specify the project ID and test name in the script options.{' '}
                <Link to={routeMap.cliGuide}>Want to learn more about CLI?</Link>
              </TextSecondary>
            </VerticalGroup>
            <TabbedCLIGuide project={project} />
          </CLIWrapper>
        </Body>
      </PageSection>
    </PluginPage>
  )
}
