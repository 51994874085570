import { docs, ReadMore } from 'components/DocLink'
import React from 'react'
import { BestPracticeThirdPartyContent, BestPracticeThirdPartyContentFailed } from 'types/insights/bestPractices'
import { quantity } from 'utils/formatters'
import { InsightBody } from '../InsightBody'
import { InsightListItem } from '../InsightItem'
import { SkippedDescription } from '../SkippedDescription'

interface ThirdPartyContentProps {
  insight: BestPracticeThirdPartyContentFailed
}

export const ThirdPartyContent = ({ insight: { data } }: ThirdPartyContentProps) => {
  return (
    <InsightBody>
      <p>
        Your script includes requests made for resources that are hosted by domains you may not own. Common examples of
        these resources are social media scripts, fonts, analytics tracking scripts, ads, and assets hosted by a content
        delivery network (CDN).
      </p>
      <p>
        We found {quantity(data.third_party_requests)} requests made to {quantity(data.domains.length)} different
        third-party domains:
      </p>
      <ul>
        {data.domains.map((domain) => (
          <li key={domain.host}>
            {domain.host} ({quantity(domain.count)} requests)
          </li>
        ))}
      </ul>
      <p>
        Running a load test including third-party resources may be in violation of the terms of service of third-party
        providers, and they may throttle your requests, skewing response times. You may also incur increased costs from
        your CDN as a result of load testing their servers.
      </p>
      <h4>Recommendations:</h4>
      <ul>
        <li>
          In your script, remove requests for resources hosted on the domains listed above. You may safely ignore this
          recommendation for domains that you own and if you are intentionally testing your CDN.
        </li>
      </ul>
      <p>
        <ReadMore article={docs.insights.bestPractices.thirdPartyContent} />
      </p>
    </InsightBody>
  )
}

interface ThirdPartyContentItemProps {
  insight: BestPracticeThirdPartyContent
}

const ThirdPartyContentDescription = ({ insight }: ThirdPartyContentItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          We did not detect any static resources from third parties in your script.{' '}
          <ReadMore article={docs.insights.bestPractices.thirdPartyContent} />
        </>
      )

    case 'FAILED':
      return (
        <>
          Your test made {quantity(insight.data.third_party_requests)} requests to{' '}
          {quantity(insight.data.domains.length)} different third party domains.
        </>
      )

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const ThirdPartyContentItem = ({ insight }: ThirdPartyContentItemProps) => {
  return (
    <InsightListItem header="Third Party Content" insight={insight}>
      <ThirdPartyContentDescription insight={insight} />
    </InsightListItem>
  )
}
