import React, { createContext, useContext, ReactNode, useEffect, useState } from 'react'
import { useHistory, useLocation, matchPath } from 'react-router-dom'

import { PROJ_ID_KEY } from 'constants/index'
import { Environment } from 'constants/environment'
import { Project, Account } from 'types'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useDatasource } from 'datasourceContext'
import { useAccount } from 'data/useAccount'
import { DatasourceErrorMessage } from 'components/DatasourceErrorMessage'
import { getDefaultProjectId } from 'utils'
import { useProject } from 'data/useProject'
import { routeMap } from 'routeMap'
import { PluginPagePlaceholder } from 'components/PluginPagePlaceholder'

type AppContextType = {
  orgId?: number
  isProd: boolean
  env: string
  project: Project
  projectId: number
  account: Account
}

type ProviderProps = {
  children: ReactNode
}

const AppContext = createContext<undefined | AppContextType>(undefined)

export const AppContextProvider = ({ children }: ProviderProps) => {
  const { ds } = useDatasource()
  const history = useHistory()
  const { pathname } = useLocation()

  const [savedProjectId, setSavedProjectId] = useLocalStorage<number | undefined>(PROJ_ID_KEY, undefined)
  const [defaultProjectId, setDefaultProjectId] = useState<number>()
  const [projectError, setProjectError] = useState<unknown>()

  const pathMatch = matchPath<{ projectId: string }>(pathname, { path: routeMap.project(':projectId') })
  const projectFromParams = pathMatch?.params?.projectId
  const projectId = (projectFromParams && +projectFromParams) || savedProjectId || defaultProjectId

  const { data: account, error: accountError } = useAccount()
  const { data: project } = useProject(projectId!, {
    alertOnError: false,
    enabled: !!projectId,
    onError: (error) => {
      // We might not have account data yet, so we handle error case in useEffect
      setProjectError(error)
    },
  })

  const env = ds.k6ApiEnv ?? Environment.Production
  const isProd = env === Environment.Production

  useEffect(() => {
    setSavedProjectId(projectId)
  }, [projectId, setSavedProjectId])

  useEffect(() => {
    if (account) {
      setDefaultProjectId(getDefaultProjectId(account))
    }
  }, [account])

  useEffect(() => {
    // Try failing gracefully by redirecting user to his default project
    if (projectError && defaultProjectId) {
      setProjectError(null)

      // Don't want to end up in an infinite loop
      if (defaultProjectId === projectId) {
        throw projectError
      }

      history.push(routeMap.project(defaultProjectId))
    }
  }, [projectError, defaultProjectId, history, projectId])

  if (accountError) {
    return <DatasourceErrorMessage />
  }

  if (!account || !project) {
    return <PluginPagePlaceholder />
  }

  return (
    <AppContext.Provider
      value={{
        isProd,
        env,
        project,
        account,
        projectId: project.id,
        orgId: project.organization_id,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  const context = useContext(AppContext)

  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppContextProvider')
  }

  return context
}
