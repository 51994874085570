import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { TestBuilderStoreProvider } from '@grafana/k6-test-builder'

import { useTest } from 'data/useTest'
import { Form } from './components/Form'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { Breadcrumb, useBreadcrumbs } from 'breadcrumbsContext'
import { PluginPage } from 'components/PluginPage'
import { routeMap } from 'routeMap'

export const Builder = () => {
  const { testId } = useParams<{ testId: string }>()
  const isNewTest = testId === 'new'

  const { data: test } = useTest(isNewTest ? null : Number(testId))
  const { pageNav, setBreadcrumbs } = useBreadcrumbs()

  const isLoading = !isNewTest && test === undefined

  useEffect(() => {
    if (isLoading) {
      return
    }

    const breadcrumbs: Breadcrumb[] = [
      { path: routeMap.builder(isNewTest ? 'new' : Number(testId)), name: test?.name || 'Test Builder' },
    ]

    if (isNewTest) {
      breadcrumbs.unshift({ path: routeMap.newTest, name: 'New test', label: 'Create a new test' })
    }

    setBreadcrumbs(breadcrumbs)
  }, [test, setBreadcrumbs, isLoading, isNewTest, testId])

  if (isLoading) {
    return <CenteredSpinner />
  }

  return (
    <PluginPage pageNav={pageNav}>
      <TestBuilderStoreProvider>
        <Form testId={testId} test={test} isNewTest={isNewTest} />
      </TestBuilderStoreProvider>
    </PluginPage>
  )
}
