import React from 'react'

import { Project } from 'types'
import { useAppContext } from 'appContext'
import { CodeSnippet } from 'components/CodeSnippet'
import { getCloudCommand, getProjectSnippet, getLoginCommand } from 'components/CLIRunGuide'

interface TabbedCLIGuideProps {
  project: Project
}

export const getCodeSnippetTabs = ({ token, projectId }: { token: string; projectId: number }) => [
  {
    label: '1. Authenticate',
    value: '0',
    code: getLoginCommand(token),
  },
  {
    label: '2. Specify Project',
    value: '1',
    code: getProjectSnippet('YOUR TEST NAME', projectId),
    dedent: false
  },
  {
    label: '3. Start test',
    value: '2',
    code: getCloudCommand('script.js'),
  },
]

export const TabbedCLIGuide = ({ project }: TabbedCLIGuideProps) => {
  const { account } = useAppContext()
  const tabs = getCodeSnippetTabs({ token: account.token.key, projectId: project.id })

  return <CodeSnippet code="" lang="js" tabs={tabs} initialTab="1" separator="chevron" />
}
