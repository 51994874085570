import { InsightsByStatus } from 'types/insights'
import { exhaustive } from 'utils/typescript'
import { InsightFilter } from './Insights.types'

export const filterInsights = (insights: InsightsByStatus, filter: InsightFilter) => {
  switch (filter) {
    case 'all':
      return [
        ...insights.FAILED,
        ...insights.PASSED,
        ...insights.SKIPPED,
        ...insights.NOT_APPLICABLE,
        ...insights.CREATED,
      ]

    case 'passed':
      return insights.PASSED

    case 'failed':
      return insights.FAILED

    case 'skipped':
      return [...insights.SKIPPED, ...insights.NOT_APPLICABLE, ...insights.CREATED]

    default:
      return exhaustive(filter, [])
  }
}
