import { QueryCache, QueryClient, QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'

import { sentryClient } from 'services/sentryClient'
import { ErrorCode } from 'types'
import { showAlert } from 'utils/showAlert'
import { routeMap } from 'routeMap'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 30000,
    },
  },
  queryCache: new QueryCache({
    onError: (error: any, query: any) => {
      sentryClient.captureException(error)
      console.error('Network Error:', error)

      const { history, alertOnError } = query.options.meta

      if (error.status === 404 || (error.status === 400 && error.data?.error?.code === ErrorCode.NOT_AUTHENTICATED)) {
        alertOnError && showAlert('Unable to load resource', 'error')
        history.replace(routeMap.home)
        return
      }

      alertOnError && showAlert('Failed to fetch resource, retrying...', 'error')
    },
  }),
})

export type QueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'> & {
  alertOnError?: boolean
}

export const useFetch = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: QueryOptions<TQueryFnData, TError, TData, TQueryKey>
) => {
  const history = useHistory()

  return useQuery(queryKey, queryFn, {
    ...options,
    meta: {
      alertOnError: options?.alertOnError ?? true,
      history: history,
    },
  })
}
