import React from 'react'
import { Button, Tooltip } from '@grafana/ui'

import { LogsFilter } from 'types/logs'
import { Container, EmptyMessage } from './EmptyQueryMessage.styles'

interface EmptyQueryMessageProps {
  filters: LogsFilter
  onFilterChange: (filters: LogsFilter) => void
}

export const EmptyQueryMessage = ({ onFilterChange, filters }: EmptyQueryMessageProps) => {
  const hasQueryFilter = !!filters?.queryFilter

  function handleFilterReset() {
    onFilterChange({ level: [], loadZone: [], queryFilter: '' })
  }

  return (
    <Container data-testid="logs-empty-query-message">
      <p>
        No messages received with the current filters
      </p>

      <Button variant="primary" onClick={handleFilterReset}>
        CLEAR FILTERS AND RETRY
      </Button>

      {hasQueryFilter && (
        <Tooltip
          content={
            <>
              The query filter performs a <strong>case sensitive</strong> lookup against log lines. Any log line that
              does not include an exact or partial match of your query will be filtered out.
            </>
          }
          placement="bottom"
        >
          <EmptyMessage>Why are the logs empty?</EmptyMessage>
        </Tooltip>
      )}
    </Container>
  )
}
