import React from 'react'

import { separatorFormatter, responseTimeFormatter } from 'utils/formatters'
import { WsMetricSummary } from 'types'
import { Statistic } from './Statistic'

interface WebSocketsSummaryProps {
  summary: WsMetricSummary
}

export const WebSocketsSummary = ({ summary }: WebSocketsSummaryProps) => {
  return (
    <>
      <Statistic label="SESSION COUNT" value={separatorFormatter(summary.sessions)} unit="sessions" />
      <Statistic label="MESSAGES SENT" value={separatorFormatter(summary.msgs_sent ?? 0)} unit="msgs" />
      <Statistic label="MESSAGES RECEIVED" value={separatorFormatter(summary.msgs_received ?? 0)} unit="msgs" />
      <Statistic label="P95 CONNECTING TIME" value={responseTimeFormatter(summary.connecting.p95 ?? 0)} unit="ms" />
      <Statistic
        label="P95 SESSION DURATION"
        value={responseTimeFormatter(summary.session_duration.p95 ?? 0)}
        unit="ms"
      />
    </>
  )
}
