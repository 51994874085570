import { SkippedDescription } from '../SkippedDescription'
import React from 'react'
import { InsightListItem } from '../InsightItem'
import { InsightBody } from '../InsightBody'
import { docs, ReadMore } from 'components/DocLink'
import { HttpThroughputLimit, HttpThroughputLimitFailed, ThroughputLimitInsightSegment } from 'types/insights/http'
import { requestRate, timing } from 'utils/formatters'
import { ChartMetric } from 'components/Chart/Chart.types'
import { HTTP_REQUEST_RATE, HTTP_RESPONSE_TIME, VUS_METRIC } from 'constants/metrics'
import { Chart } from 'components/Chart'
import { TestRun } from 'types'
import { useSegmentAnnotations } from './hooks'
import { formatThroughputLimitAnnotation } from './ThroughputLimit.utils'

const metrics: ChartMetric[] = [VUS_METRIC, HTTP_REQUEST_RATE, HTTP_RESPONSE_TIME]

interface FailedMessageProps {
  problems: ThroughputLimitInsightSegment[]
}

const FailedDescription = ({ problems }: FailedMessageProps) => {
  const minRate = problems.reduce<number | undefined>((result, value) => {
    if (result === undefined || value.avg_request_rate === undefined) {
      return value.avg_request_rate
    }

    return Math.min(result, value.avg_request_rate)
  }, undefined)

  if (minRate === undefined) {
    return <>The system under test is overloaded and is struggling to process requests in a timely manner.</>
  }

  return <>The system under test is overloaded and struggling to process more than {minRate} reqs/sec.</>
}

const FailedMessage = ({ problems }: FailedMessageProps) => {
  if (problems.length > 1) {
    return (
      <p>
        We&apos;re sending more requests to the system under test than it can process, indicating the presence of a
        performance bottleneck. The graph below shows several occurrences of the average response time increasing while
        the number of processed requests per second (throughput) flatlined, suggesting that the requests have started to
        queue on the application server(s).
      </p>
    )
  }

  const problem = problems[0]

  if (problem === undefined || problem.avg_request_rate === undefined || problem.avg_response_time_ms === undefined) {
    return (
      <p>
        We&apos;re sending more requests to the system under test than it can process, indicating the presence of a
        performance bottleneck. The graph below shows that the average response time has increased while the number of
        processed requests per second (throughput) has flatlined, suggesting that the requests have started to queue on
        the application server(s).
      </p>
    )
  }

  return (
    <p>
      We&apos;re sending more requests to the system under test than it can process, indicating the presence of a
      performance bottleneck. The graph below shows that the average response time has increased to{' '}
      {timing(problem.avg_response_time_ms)} while the number of processed requests per second (throughput) has
      flatlined at {requestRate(problem.avg_request_rate)}, suggesting that the requests have started to queue on the
      application server(s).
    </p>
  )
}

interface ThroughputLimitProps {
  testRun: TestRun
  insight: HttpThroughputLimitFailed
}

export const ThroughputLimit = ({ testRun, insight }: ThroughputLimitProps) => {
  const annotations = useSegmentAnnotations(insight.data.segments, formatThroughputLimitAnnotation)

  return (
    <InsightBody>
      <FailedMessage problems={insight.data.segments.detected_problems} />
      <p>
        <Chart title="Throughput Limit" testRun={testRun} metrics={metrics} annotations={annotations} />
      </p>
      <h4>Recommendations:</h4>
      <ul>
        <li>Investigate the cause of the bottleneck using an APM or server monitoring tool.</li>
        <li>After making changes, re-run this test to determine whether the issue has been resolved.</li>
      </ul>
      <p>
        <ReadMore article={docs.insights.http.throughputLimit} />
      </p>
    </InsightBody>
  )
}

interface ThroughputLimitItemProps {
  insight: HttpThroughputLimit
}

const ThroughputLimitDescription = ({ insight }: ThroughputLimitItemProps) => {
  switch (insight.status) {
    case 'PASSED':
      return (
        <>
          No throughput limit detected. <ReadMore article={docs.insights.http.throughputLimit} />
        </>
      )

    case 'FAILED':
      return <FailedDescription problems={insight.data.segments.detected_problems} />

    default:
      return <SkippedDescription insight={insight} />
  }
}

export const ThroughputLimitItem = ({ insight }: ThroughputLimitItemProps) => {
  return (
    <InsightListItem header="Throughput Limit" insight={insight}>
      <ThroughputLimitDescription insight={insight} />
    </InsightListItem>
  )
}
