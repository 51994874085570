import { useEffect, useState } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'

import { useDatasource } from 'datasourceContext'
import { TestRun } from 'types'
import { mergeArraysByKey } from 'utils/array'

import { useActiveRuns } from './useActiveRuns'
import { toTestRunsQueryKey } from './queryKeys'

const updateRuns = (runsFlat: TestRun[], testId: number, activeRuns: TestRun[]) => {
  const runsFiltered = activeRuns.filter((run) => run.test_id === testId)
  return mergeArraysByKey(runsFlat, runsFiltered, 'id')
}

export const useRuns = (testId: number) => {
  const { ds } = useDatasource()
  const [runsFlat, setRunsFlat] = useState<TestRun[]>([])

  const { data, fetchNextPage, isFetching, isLoading } = useInfiniteQuery(
    toTestRunsQueryKey(testId),
    ({ pageParam = 1 }) => ds.fetchRuns({ id: testId, page: pageParam }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage.meta) {
          return undefined
        }

        if (pages.length === lastPage.meta.count) {
          return undefined
        }

        return pages.length + 1
      },
    }
  )

  const { data: activeRuns, refresh } = useActiveRuns()

  const flattenRuns = () => {
    if (!data) {
      return
    }

    setRunsFlat(data.pages.flatMap((page) => page['k6-runs']))
  }

  const setActiveRuns = () => {
    if (!activeRuns) {
      return
    }

    setRunsFlat((runs) => updateRuns(runs, testId, activeRuns))
  }

  useEffect(flattenRuns, [data])
  useEffect(setActiveRuns, [activeRuns, testId])

  return {
    runs: runsFlat,
    fetchNextPage,
    refresh,
    isFetching,
    isLoading,
  }
}
