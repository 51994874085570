import {
  RUN_PROCESS,
  K6TestRunStatusesActive,
  TestRun,
  TestRunProcessingStatus,
  TestRunResultStatus,
  TestRunStatus,
} from 'types'

export const canShowResultStatus = (testRun: TestRun) => {
  return (
    isTestDone(testRun) &&
    testRun.run_status !== TestRunStatus.ABORTED_USER &&
    testRun.run_status !== TestRunStatus.TIMED_OUT &&
    testRun.result_status === TestRunResultStatus.FAILED
  )
}

export function isSecureRun(testRun: TestRun) {
  return testRun.run_process === RUN_PROCESS.K6_TO_CLOUD_SECURE
}

export const isTestStarting = (testRun: TestRun) => {
  return [TestRunStatus.CREATED, TestRunStatus.VALIDATED, TestRunStatus.QUEUED, TestRunStatus.INITIALIZING].includes(
    testRun.run_status
  )
}

export const isTestActive = (testRun: TestRun) => {
  return testRun && (K6TestRunStatusesActive.includes(testRun.run_status) || isTestProcessing(testRun))
}

export const isTestDone = (testRun: TestRun) => !isTestActive(testRun)

export const isTestAborted = (testRun: TestRun) =>
  [
    TestRunStatus.ABORTED_USER,
    TestRunStatus.ABORTED_SYSTEM,
    TestRunStatus.ABORTED_SCRIPT_ERROR,
    TestRunStatus.ABORTED_THRESHOLD,
    TestRunStatus.ABORTED_LIMIT,
  ].includes(testRun.run_status)

export const isTestProcessing = (testRun: TestRun) => {
  return testRun.processing_status === TestRunProcessingStatus.PROCESSING
}

export const isTestRunning = (testRun: TestRun) => testRun.run_status === TestRunStatus.RUNNING

export const isTestFailed = (testRun: TestRun) => testRun.result_status === TestRunResultStatus.FAILED

export const isTestTimedOut = (testRun: TestRun) => testRun.run_status === TestRunStatus.TIMED_OUT

export const isTestRunFromIngest = (testRun: TestRun) => testRun.run_process === RUN_PROCESS.K6_INGEST
