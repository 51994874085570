import React from 'react'
import { useTheme } from 'styled-components'

export const ScriptEditorIllustration = () => {
  const theme = useTheme()

  const background = theme.colors.background.secondary
  const secondaryBackground = theme.colors.background.primary
  const border = theme.colors.border.medium
  const innerBorder = theme.colors.border.weak
  const primary = theme.colors.primary.main
  const text = theme.colors.text.primary

  return (
    <svg className="illustration" width="98px" height="81px" viewBox="0 0 98 81">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="script-editor" transform="translate(-1.000000, -1.000000)">
          <polygon fill={secondaryBackground} points="8.5 8.5 98.5 8.5 98.5 81.5 8.5 81.5"></polygon>
          <path
            d="M8,8.5 C8,8.22385763 8.22385763,8 8.5,8 L98.5,8 C98.7761424,8 99,8.22385763 99,8.5 L99,81.5 C99,81.7761424 98.7761424,82 98.5,82 L8.5,82 C8.22385763,82 8,81.7761424 8,81.5 L8,8.5 Z M9,9 L9,81 L98,81 L98,9 L9,9 Z"
            fill={border}
          ></path>
          <polygon fill={background} points="91.5 1.5 1.5 1.5 1.5 74.5 91.5 74.5"></polygon>
          <path
            d="M1,1.5 C1,1.22385763 1.22385763,1 1.5,1 L91.5,1 C91.7761424,1 92,1.22385763 92,1.5 L92,74.5 C92,74.7761424 91.7761424,75 91.5,75 L1.5,75 C1.22385763,75 1,74.7761424 1,74.5 L1,1.5 Z M2,2 L2,74 L91,74 L91,2 L2,2 Z"
            fill={border}
          ></path>
          <path
            d="M11.5,41.75 C11.5,41.3357864 11.8357864,41 12.25,41 L30.75,41 C31.1642136,41 31.5,41.3357864 31.5,41.75 C31.5,42.1642136 31.1642136,42.5 30.75,42.5 L12.25,42.5 C11.8357864,42.5 11.5,42.1642136 11.5,41.75 Z"
            fill={text}
          ></path>
          <path
            d="M16.5,45.75 C16.5,45.3357864 16.8357864,45 17.25,45 L25.75,45 C26.1642136,45 26.5,45.3357864 26.5,45.75 C26.5,46.1642136 26.1642136,46.5 25.75,46.5 L17.25,46.5 C16.8357864,46.5 16.5,46.1642136 16.5,45.75 Z"
            fill={text}
          ></path>
          <path
            d="M28.5,45.75 C28.5,45.3357864 28.8357864,45 29.25,45 L47.75,45 C48.1642136,45 48.5,45.3357864 48.5,45.75 C48.5,46.1642136 48.1642136,46.5 47.75,46.5 L29.25,46.5 C28.8357864,46.5 28.5,46.1642136 28.5,45.75 Z"
            fill={primary}
          ></path>
          <path
            d="M50.5,45.75 C50.5,45.3357864 50.8357864,45 51.25,45 L78.75,45 C79.1642136,45 79.5,45.3357864 79.5,45.75 C79.5,46.1642136 79.1642136,46.5 78.75,46.5 L51.25,46.5 C50.8357864,46.5 50.5,46.1642136 50.5,45.75 Z"
            fill={text}
          ></path>
          <path
            d="M16.5,49.75 C16.5,49.3357864 16.8357864,49 17.25,49 L33.75,49 C34.1642136,49 34.5,49.3357864 34.5,49.75 C34.5,50.1642136 34.1642136,50.5 33.75,50.5 L17.25,50.5 C16.8357864,50.5 16.5,50.1642136 16.5,49.75 Z"
            fill={text}
          ></path>
          <path
            d="M36.5,49.75 C36.5,49.3357864 36.8357864,49 37.25,49 L72.75,49 C73.1642136,49 73.5,49.3357864 73.5,49.75 C73.5,50.1642136 73.1642136,50.5 72.75,50.5 L37.25,50.5 C36.8357864,50.5 36.5,50.1642136 36.5,49.75 Z"
            fill={text}
          ></path>
          <path
            d="M11.5,57.75 C11.5,57.3357864 11.8357864,57 12.25,57 L30.75,57 C31.1642136,57 31.5,57.3357864 31.5,57.75 C31.5,58.1642136 31.1642136,58.5 30.75,58.5 L12.25,58.5 C11.8357864,58.5 11.5,58.1642136 11.5,57.75 Z"
            fill={text}
          ></path>
          <path
            d="M16.5,61.75 C16.5,61.3357864 16.8357864,61 17.25,61 L25.75,61 C26.1642136,61 26.5,61.3357864 26.5,61.75 C26.5,62.1642136 26.1642136,62.5 25.75,62.5 L17.25,62.5 C16.8357864,62.5 16.5,62.1642136 16.5,61.75 Z"
            fill={text}
          ></path>
          <path
            d="M28.5,61.75 C28.5,61.3357864 28.8357864,61 29.25,61 L47.75,61 C48.1642136,61 48.5,61.3357864 48.5,61.75 C48.5,62.1642136 48.1642136,62.5 47.75,62.5 L29.25,62.5 C28.8357864,62.5 28.5,62.1642136 28.5,61.75 Z"
            fill={text}
          ></path>
          <path
            d="M50.5,61.75 C50.5,61.3357864 50.8357864,61 51.25,61 L78.75,61 C79.1642136,61 79.5,61.3357864 79.5,61.75 C79.5,62.1642136 79.1642136,62.5 78.75,62.5 L51.25,62.5 C50.8357864,62.5 50.5,62.1642136 50.5,61.75 Z"
            fill={primary}
          ></path>
          <path
            d="M16.5,65.75 C16.5,65.3357864 16.8357864,65 17.25,65 L33.75,65 C34.1642136,65 34.5,65.3357864 34.5,65.75 C34.5,66.1642136 34.1642136,66.5 33.75,66.5 L17.25,66.5 C16.8357864,66.5 16.5,66.1642136 16.5,65.75 Z"
            fill={text}
          ></path>
          <path
            d="M36.5,65.75 C36.5,65.3357864 36.8357864,65 37.25,65 L72.75,65 C73.1642136,65 73.5,65.3357864 73.5,65.75 C73.5,66.1642136 73.1642136,66.5 72.75,66.5 L37.25,66.5 C36.8357864,66.5 36.5,66.1642136 36.5,65.75 Z"
            fill={text}
          ></path>
          <path
            d="M8.5,6.5 C8.5,7.6045695 7.6045695,8.5 6.5,8.5 C5.3954305,8.5 4.5,7.6045695 4.5,6.5 C4.5,5.3954305 5.3954305,4.5 6.5,4.5 C7.6045695,4.5 8.5,5.3954305 8.5,6.5 Z M15.5,6.5 C15.5,7.6045695 14.6045695,8.5 13.5,8.5 C12.3954305,8.5 11.5,7.6045695 11.5,6.5 C11.5,5.3954305 12.3954305,4.5 13.5,4.5 C14.6045695,4.5 15.5,5.3954305 15.5,6.5 Z M20.5,8.5 C21.6045695,8.5 22.5,7.6045695 22.5,6.5 C22.5,5.3954305 21.6045695,4.5 20.5,4.5 C19.3954305,4.5 18.5,5.3954305 18.5,6.5 C18.5,7.6045695 19.3954305,8.5 20.5,8.5 Z"
            fill={text} // buttons
          ></path>
          <path
            d="M1,11.5 C1,11.2238576 1.22385763,11 1.5,11 L83.5,11 C83.7761424,11 84,11.2238576 84,11.5 C84,11.7761424 83.7761424,12 83.5,12 L1.5,12 C1.22385763,12 1,11.7761424 1,11.5 Z"
            fill={innerBorder}
          ></path>
          <path
            d="M11.5,31.15 C12.3986638,32.250992 13.7712714,32.8536002 15.19,32.77 C16.3912655,32.8240158 17.5587247,32.364093 18.4003743,31.5052669 C19.2420239,30.6464407 19.6782703,29.4699292 19.6,28.27 L19.6,19.9 L16.9,19.9 L16.9,28.27 C16.9663567,28.7809722 16.8048122,29.2952899 16.4582089,29.6765535 C16.1116056,30.0578172 15.6149655,30.2675025 15.1,30.25 C14.4019563,30.2095756 13.7511657,29.8841803 13.3,29.35 L11.5,31.15 Z"
            fill={primary}
          ></path>
          <path
            d="M21.5,30.79 C22.7666549,32.1082429 24.53275,32.8277631 26.36,32.77 C29.33,32.77 31.13,30.97 31.13,28.9 C31.13,24.49 24.83,25.48 24.83,23.32 C24.83,22.6 25.37,22.06 26.72,22.06 C27.6144677,22.0865733 28.4594652,22.4765721 29.06,23.14 L30.86,21.34 C29.7485408,20.2074746 28.2162518,19.5880386 26.63,19.63 C23.75,19.63 22.04,21.34 22.04,23.32 C22.04,27.73 28.34,26.74 28.34,28.9 C28.34,29.71 27.71,30.34 26.27,30.34 C25.1338301,30.3293725 24.0550776,29.8390305 23.3,28.99 L21.5,30.79 Z"
            fill={primary}
          ></path>
        </g>
      </g>
    </svg>
  )
}
