import React from 'react'
import { SupportLink } from 'components/SupportLink'
import { InsightListItem } from '../InsightItem'
import { DocLink, docs, ReadMore } from 'components/DocLink'
import { exhaustive } from 'utils/typescript'
import {
  BestPracticeOutdatedK6ReleaseUsed,
  BestPracticeOutdatedK6ReleaseUsedFailed,
} from 'types/insights/bestPractices'
import { InsightBody } from '../InsightBody'

interface OutdatedK6ReleaseUsedProps {
  insight: BestPracticeOutdatedK6ReleaseUsedFailed
}

export const OutdatedK6ReleaseUsed = ({ insight }: OutdatedK6ReleaseUsedProps) => {
  return (
    <InsightBody>
      <p>
        In this test, you used a significantly older version of k6 than the one we recommend. We regularly release newer
        versions of k6 that improve performance, add features, and fix issues found in previous versions. When you use a
        legacy version of k6, your tests may be affected by bugs that could make your test results less accurate and
        lead to false positives. Upgrading to a more recent version of k6 will ensure the most accurate and most
        consistent results.
      </p>

      <h4>Recommendations:</h4>
      <ul>
        <li>
          Download and install the latest version of k6{' '}
          <DocLink article={docs.gettingStarted.installation}>here</DocLink> or upgrade your packages if relevant.
        </li>
        <li>Update the k6 binary that your CI/CD pipeline uses for running tests.</li>
        <li>
          If you&apos;re part of an organization or team, collectively decide on a version of k6 to use going forward
          for consistency and ease of comparison.
        </li>
        <li>
          If you have questions about which version of k6 to use, please contact{' '}
          <SupportLink>our Support team</SupportLink>
        </li>
      </ul>
      <p>
        <ReadMore article={docs.insights.bestPractices.outdatedK6ReleaseUsed} />
      </p>
    </InsightBody>
  )
}

interface OutdatedK6ReleaseUsedItemProps {
  insight: BestPracticeOutdatedK6ReleaseUsed
}

const OutdatedK6ReleaseUsedDescription = ({ insight }: OutdatedK6ReleaseUsedItemProps) => {
  switch (insight.status) {
    case 'FAILED':
      return <>You are using a legacy version of k6 ({insight.data.k6_release_used})</>

    case 'PASSED':
    case 'SKIPPED':
    case 'N/A':
    case 'CREATED':
      return null

    default:
      return exhaustive(insight, null)
  }
}

export const OutdatedK6ReleaseUsedItem = ({ insight }: OutdatedK6ReleaseUsedItemProps) => {
  return (
    <InsightListItem header="Outdated k6 Release Used" insight={insight}>
      <OutdatedK6ReleaseUsedDescription insight={insight} />
    </InsightListItem>
  )
}
