import styled, { css } from 'styled-components'

import { TabSeparatorName } from './CodeSnippet.types'

const pipe = css`
  opacity: 0.1;
  width: 1px;
  height: 20px;
  background: ${({ theme }) => theme.colors.text.secondary};
`

const chevron = css`
  width: 6px;
  height: 6px;
  border-style: solid;
  border-width: 1px 1px 0 0;
  transform: rotate(45deg);
  position: relative;
  left: -1px;
`

export const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

export const Header = styled.header`
  background-color: ${({ theme }) => theme.colors.background.secondary};
  box-shadow: ${({ theme }) =>
    `0px 1px 0px 1px ${theme.colors.background.secondary} inset, 0px 0px 0px 1px ${theme.colors.border.weak} inset`};
  height: 34px;
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
`

export const FakeControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin-right: 10px;
`

export const FakeControlsItem = styled.span`
  width: 10px;
  height: 10px;
  background: ${({ theme }) => theme.colors.border.weak};
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
  height: 100%;
  flex: 1;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

export const Tab = styled.button<{ $active: boolean }>`
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  cursor: pointer;
  white-space: nowrap;
  background: transparent;
  border: 0;
  color: ${({ $active, theme }) => ($active ? theme.colors.text.link : theme.colors.text.secondary)};
  padding: 3px 10px 2px;
  height: 100%;
  opacity: ${({ $active }) => ($active ? 1 : 0.5)};
  outline: 0;
  box-shadow: ${({ $active, theme }) =>
    $active
      ? `0px 1px 0px 1px ${theme.colors.background.secondary} inset, 0px 0px 0px 1px ${theme.colors.text.link} inset`
      : ''};

  &:hover {
    opacity: ${({ $active }) => ($active ? 1 : 0.7)};
  }
`

export const TabSeparator = styled.div<{ $separator: TabSeparatorName }>`
  ${({ $separator }) => ($separator === 'pipe' ? pipe : chevron)}
`

export const Title = styled.span`
  white-space: nowrap;
  border: 0;
  color: ${({ theme }) => theme.colors.text.secondary};
  opacity: 0.5;
  outline: 0;
  font-size: 14px;
  font-weight: 500;
`

export const Heading = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`

export const Section = styled.section<{ $minHeight: number }>`
  min-height: ${({ $minHeight }) => `${$minHeight}px`};
  position: relative;
`

export const Pre = styled.pre`
  display: flex;
  border-radius: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-color: ${({ theme }) => theme.colors.border.weak};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(5)} ${theme.spacing(2)} ${theme.spacing(2)}` };
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

export const Code = styled.code<{ $lang: string }>`
  flex-grow: 1;
  display: block;
  // cspell: disable line
  font-family: Menlo, Monaco, 'Courier New', monospace;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.primary} !important;
  text-shadow: none;

  ${({ $lang }) => ($lang === 'js' ? JSStyles : SHStyles)}
`

export const ClipboardWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(1)};
  right: ${({ theme }) => theme.spacing(1)};
`

const CodeStyleBase = css`
  .token.boolean,
  .token.string {
    color: ${({ theme }) => theme.visualization.getColorByName('semi-dark-orange')};
  }

  .token.constant {
    color: ${({ theme }) => theme.visualization.getColorByName('light-blue')};
  }

  .token.function {
    color: ${({ theme }) => theme.colors.text.primary};
  }

  .token.punctuation {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`

const JSStyles = css`
  ${CodeStyleBase}

  .token.keyword {
    color: ${({ theme }) => theme.visualization.getColorByName('light-blue')};
  }

  .token.number {
    color: ${({ theme }) => theme.visualization.getColorByName('green')};
  }

  .token.operator {
    color: ${({ theme }) => theme.visualization.getColorByName('gray')};
    background-color: transparent;
  }

  .token.comment {
    opacity: 0.4;
  }
`

const SHStyles = css`
  ${CodeStyleBase}

  .token.property {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`
