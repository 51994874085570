import React, { ReactNode } from 'react'
import clsx from 'clsx'
import styled from 'styled-components'
import { isTopNavEnabled } from 'utils/topnav'

interface PageSectionProps {
  className?: string
  children?: ReactNode
}

export const PageSection = ({ className, children }: PageSectionProps) => {
  const isTopNav = isTopNavEnabled()
  // We piggyback on Grafana's page-body styles, so we won't have to worry about things
  // like theming and responsiveness.
  return <div className={clsx(!isTopNav && 'page-body', className)}>{children}</div>
}

export const FullHeightPage = styled(PageSection)`
  height: 100%;
  margin-bottom: 0;
`
