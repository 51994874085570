import React from 'react'

import { CenteredSpinner } from 'components/CenteredSpinner'
import { PluginPage } from 'components/PluginPage/PluginPage'
import { isTopNavEnabled } from 'utils/topnav'

export const PluginPagePlaceholder = () => (
  <PluginPage>
    <div className="page-loader-wrapper">
      <CenteredSpinner {...(!isTopNavEnabled() && { $height: 'auto' })} />
    </div>
  </PluginPage>
)
