import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, HorizontalGroup } from '@grafana/ui'

import { ButtonTypes, GoToK6Button } from 'components/GoToK6Button'
import { useRunTest } from 'data/useRunTest'
import { useHasUserWriteAccess } from 'data/usePermissions'
import { RunButtonController } from 'components/RunButtonController'
import { getTestConfigUrl } from 'utils/test'
import { LinkButton } from 'components/LinkButton'
import { routeMap } from 'routeMap'
import { Test } from 'types'

interface TestActionsProps {
  test?: Test
}

export const TestActions = ({ test }: TestActionsProps) => {
  const history = useHistory()
  const { mutateAsync: runTest, isLoading: isTestRunning } = useRunTest()
  const hasUserWriteAccess = useHasUserWriteAccess()

  const runButtonIcon = isTestRunning ? 'fa fa-spinner' : undefined

  if (!hasUserWriteAccess || !test) {
    return null
  }

  const handleRunTest = async () => {
    const { id } = await runTest(test.id)
    history.push(routeMap.testRun(id))
  }

  return (
    <HorizontalGroup justify={'flex-end'}>
      <HorizontalGroup>
        <RunButtonController test={test}>
          {({ isDisabled }) => (
            <Button key="settings" disabled={isTestRunning || isDisabled} icon={runButtonIcon} onClick={handleRunTest}>
              Run Test
            </Button>
          )}
        </RunButtonController>
        <LinkButton to={getTestConfigUrl(test)} button={{ variant: 'secondary' }}>
          Configure
        </LinkButton>
      </HorizontalGroup>
      <GoToK6Button type={ButtonTypes.Test} id={test.id}></GoToK6Button>
    </HorizontalGroup>
  )
}
