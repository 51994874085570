type Href = `/${string}`
type Docs = { [name: string]: Href | Docs }

export const docs = {
  root: '/',
  gettingStarted: {
    installation: '/getting-started/installation/',
  },
  metrics: {
    builtIn: '/using-k6/metrics/#built-in-metrics',
    custom: '/using-k6/metrics/#custom-metrics',
  },
  httpRequests: {
    urlGrouping: '/using-k6/http-requests/#url-grouping',
    tags: '/using-k6/http-requests/#http-request-tags',
  },
  modules: {
    localFilesystem: '/using-k6/modules#local-filesystem-modules',
  },
  thresholds: {
    main: '/using-k6/thresholds',
  },
  scenarios: {
    main: '/using-k6/scenarios',
  },
  options: {
    discardResponseBodies: '/using-k6/options#discard-response-bodies',
  },
  tutorials: {
    automatedPerformanceTesting: {
      localOrCloudExecution: '/testing-guides/automated-performance-testing/#local-vs-cloud-execution',
    },
  },
  javascript: {
    sleep: '/javascript-api/k6/sleep',
    http: {
      setResponseCallback: '/javascript-api/k6-http/setresponsecallback',
    },
  },
  insights: {
    overview: '/cloud/analyzing-results/overview',
    disabling: '/cloud/analyzing-results/performance-insights/#disabling-performance-insights',
    abortReason: {
      user: '/cloud/cloud-reference/test-status-codes/#aborted-by-user',
      system: '/cloud/cloud-reference/test-status-codes/#aborted-by-system',
      scriptError: '/cloud/cloud-reference/test-status-codes/#script-error',
      threshold: '/cloud/cloud-reference/test-status-codes/#aborted-by-threshold',
      limit: '/cloud/cloud-reference/test-status-codes/#aborted-by-limit',
    },
    bestPractices: {
      tooManyGroups: '/cloud/analyzing-results/performance-insights/#too-many-groups',
      tooManyMetrics: '/cloud/analyzing-results/performance-insights/#too-many-metrics',
      tooManyUrls: '/cloud/analyzing-results/performance-insights/#too-many-urls',
      tooManyTimeSeries: '/cloud/analyzing-results/performance-insights/#too-many-time-series',
      thirdPartyContent: '/cloud/analyzing-results/performance-insights/#third-party-content',
      outdatedK6ReleaseUsed: '/cloud/analyzing-results/performance-insights/#outdated-k6-release-used',
    },
    http: {
      highFailureRate: '/cloud/analyzing-results/performance-insights/#high-http-failure-rate',
      increasedFailureRate: '/cloud/analyzing-results/performance-insights/#increased-http-failure-rate',
      throughputLimit: '/cloud/analyzing-results/performance-insights/#throughput-limit',
    },
    health: {
      highLoadGeneratorCPUUsage: '/cloud/analyzing-results/performance-insights/#high-load-generator-cpu-usage',
      highLoadGeneratorMemoryUsage: '/cloud/analyzing-results/performance-insights/#high-load-generator-memory-usage',
    },
  },
  logs: {
    main: '/cloud/analyzing-results/logs',
  },
} as const

type DeepPropertyTypeUnion<T extends Docs> = {
  [P in keyof T]: T[P] extends Docs ? DeepPropertyTypeUnion<T[P]> : T[P]
}[keyof T]

export type DocArticle = DeepPropertyTypeUnion<typeof docs>
