import { useDatasource } from 'datasourceContext'
import { useAppContext } from 'appContext'
import { useFetch } from './useFetch'
import { toLoadZonesQueryKey } from './queryKeys'

export const useLoadZones = () => {
  const { ds } = useDatasource()
  const { orgId } = useAppContext()

  return useFetch(toLoadZonesQueryKey(orgId), ds.fetchLoadZones.bind(ds, orgId))
}
