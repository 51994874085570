import styled from 'styled-components'
import { Spinner as BaseSpinner } from '@grafana/ui'

export const List = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
`

export const Spinner = styled(BaseSpinner)`
  margin-left: 5px;
`
