import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useAppContext } from 'appContext'
import { Breadcrumb, useBreadcrumbs } from 'breadcrumbsContext'
import { CenteredSpinner } from 'components/CenteredSpinner'
import { useTest } from 'data/useTest'
import { Form } from './components'
import { FullHeightPage } from 'components/PagePanel'
import { PluginPage } from 'components/PluginPage'
import { routeMap } from 'routeMap'

export type FormFields = {
  name: string
  script: string
  apiError: string
  id: number
}

export const Script = () => {
  const { testId } = useParams<{ testId: string }>()
  const { project } = useAppContext()
  const { pageNav, setBreadcrumbs } = useBreadcrumbs()

  const isNewTest = testId === 'new'
  const { data: test } = useTest(isNewTest ? null : Number(testId))

  useEffect(() => {
    if (test === undefined && !isNewTest) {
      return
    }

    const breadcrumbs: Breadcrumb[] = [
      { path: routeMap.script(isNewTest ? 'new' : Number(testId)), name: test?.name || 'Test script' },
    ]

    if (isNewTest) {
      breadcrumbs.unshift({ path: routeMap.newTest, name: 'New test', label: 'Create a new test' })
    }

    setBreadcrumbs(breadcrumbs)
  }, [test, setBreadcrumbs, isNewTest, testId])

  if (!isNewTest && test === undefined) {
    return <CenteredSpinner />
  }

  return (
    <PluginPage pageNav={pageNav}>
      <FullHeightPage>
        <Form testId={testId} test={test} projectId={project.id} isNewTest={isNewTest} />
      </FullHeightPage>
    </PluginPage>
  )
}
