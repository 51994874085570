import { useQuery } from '@tanstack/react-query'
import { BG_TASKS_POLL_INTERVAL } from 'constants/index'
import { useDatasource } from 'datasourceContext'
import { isTestActive } from 'utils/testRun'
import { Summary, TestRun } from 'types'

import { toSummaryQueryKey } from './queryKeys'
import { QueryOptions } from './useFetch'

export const useSummary = (testRun: TestRun, options?: QueryOptions<Summary>) => {
  const { ds } = useDatasource()

  return useQuery({
    queryKey: toSummaryQueryKey(testRun.id),
    queryFn: () => ds.fetchSummary(testRun.id),
    refetchInterval: isTestActive(testRun) ? BG_TASKS_POLL_INTERVAL : false,
  })
}
