import React, { useState } from 'react'
import { Badge, BadgeColor, Dropdown, HorizontalGroup, IconButton, Menu } from '@grafana/ui'

import { TestRun, RUN_PROCESS } from 'types'
import { isTestSafe } from 'utils'
import { timeFormatter, vusFormatter } from 'utils/formatters'
import { getTestRunColorString, getTestRunBadgeIcon, getTestStatusText } from 'pages/utils'
import { APMMetaItem } from './APMMetaItem/APMMetaItem'
import { EditNoteButton, MetaItem } from './MetaItem'
import { LoadZones } from './LoadZones'
import { StartedBy } from './StartedBy'
import { SectionContent } from '../styles'
import { RunDetailsSection } from 'pages/TestRunPage/TestRunPage.styles'
import { NoteModal } from 'components/NoteModal'
import { Tooltip } from 'components/Tooltip'
import { useTrackFeature } from 'hooks/useTrackFeature'
import { useCloudAppUrl } from 'hooks/useK6Url'

interface TestRunMenuProps {
  run: TestRun
  onEditNote: () => void
}

const safeTooltipText = 'Result will not be deleted by data retention policy'

const LoadZoneOrLocal = ({ testRun }: { testRun: TestRun }) => {
  if (testRun.run_process === RUN_PROCESS.K6_INGEST) {
    return <MetaItem icon="arrow">Local execution</MetaItem>
  }

  return (
    <MetaItem icon="database">
      Load zones:
      <LoadZones nodes={testRun.nodes} distribution={testRun.distribution} />
    </MetaItem>
  )
}

const TestRunMenu = ({ run, onEditNote }: TestRunMenuProps) => {
  const hasNote = run.note && run.note.length > 0
  const trackFeature = useTrackFeature()
  const cloudAppUrl = useCloudAppUrl(`/runs/${run.id}`)

  const handleOpenToK6Click = () => {
    trackFeature('open_in_k6_cloud')
  }

  const menu = (
    <Menu>
      <Menu.Item label={`${hasNote ? 'Edit' : 'Create'} note`} icon="edit" onClick={onEditNote} />
      <Menu.Item
        label="Open in k6 cloud"
        icon="external-link-alt"
        url={cloudAppUrl}
        onClick={handleOpenToK6Click}
        target="_blank"
      />
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement="bottom-end">
      <IconButton name="ellipsis-v" size="lg" />
    </Dropdown>
  )
}

export const MetaBar = ({ testRun }: { testRun: TestRun }) => {
  const statusText = getTestStatusText(testRun)
  const statusColor = getTestRunColorString(testRun)
  const statusIcon = getTestRunBadgeIcon(testRun)
  const isBaselineTest = testRun.is_baseline

  const [showNoteModal, setShowNoteModal] = useState<boolean>(false)

  return (
    <RunDetailsSection>
      <SectionContent>
        <HorizontalGroup justify={'space-between'} spacing="sm">
          <HorizontalGroup spacing="sm" wrap>
            <Badge color={statusColor as BadgeColor} icon={statusIcon} text={statusText} />
            <MetaItem icon="users-alt">{vusFormatter(testRun.vus)}</MetaItem>
            <MetaItem icon="clock-nine">{timeFormatter(testRun.duration)}</MetaItem>
            <LoadZoneOrLocal testRun={testRun} />
            <MetaItem icon="user">
              <StartedBy userId={testRun.user_id} />
            </MetaItem>
            {isBaselineTest && <MetaItem icon="star">Baseline run</MetaItem>}

            {isTestSafe(testRun) && (
              <MetaItem icon="lock">
                <Tooltip content={safeTooltipText} placement="bottom">
                  <span>Safe</span>
                </Tooltip>
              </MetaItem>
            )}

            {testRun.note.length > 0 && (
              <MetaItem>
                <Tooltip show={!!testRun.note} content={testRun.note} placement="bottom">
                  <EditNoteButton icon="edit" onClick={() => setShowNoteModal(true)} fill="text">
                    Read note
                  </EditNoteButton>
                </Tooltip>
              </MetaItem>
            )}

            {testRun.metrics_exports?.map((metrics) => (
              <APMMetaItem key={metrics.id} data={metrics} />
            ))}
          </HorizontalGroup>
          <HorizontalGroup>
            <TestRunMenu run={testRun} onEditNote={() => setShowNoteModal(true)} />
          </HorizontalGroup>
        </HorizontalGroup>
      </SectionContent>
      <NoteModal isOpen={showNoteModal} onDismiss={() => setShowNoteModal(false)} run={testRun} />
    </RunDetailsSection>
  )
}
