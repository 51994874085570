import React from 'react'
import { Spinner } from '@grafana/ui'

import { useOrgRoles } from 'data/useOrgRoles'

export const StartedBy = ({ userId }: { userId: number }) => {
  const { data: roles } = useOrgRoles()

  if (!roles) {
    return <Spinner size={14} />
  }

  const role = roles.find((role) => role.user_id === userId)

  if (!role) {
    return null
  }

  return <>Started by: {role.user_email}</>
}
