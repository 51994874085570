import React, { ComponentProps } from 'react'
import styled from 'styled-components'
import { Switch as GrafanaSwitch } from '@grafana/ui'

const Container = styled.label`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
  text-transform: uppercase;

  > div {
    width: 46px;
    height: 24px;

    label::after {
      width: 20px;
      height: 20px;
      border-radius: 20px;
    }

    input:checked + label::after {
      transform: translate3d(23px, -50%, 0px);
    }
  }
`

const LabelText = styled.span<{ $active: boolean }>`
  color: ${({ theme, $active }) => ($active ? theme.colors.text.primary : theme.colors.text.disabled)};
  cursor: pointer;
`

interface SwitchProps extends ComponentProps<typeof GrafanaSwitch> {
  onLabel?: string
  offLabel?: string
}

export const Switch = ({ value, onChange, offLabel, onLabel }: SwitchProps) => {
  return (
    <Container>
      {offLabel && <LabelText $active={!value}>{offLabel}</LabelText>}
      <GrafanaSwitch onChange={onChange} value={value} />
      {onLabel && <LabelText $active={!!value}>{onLabel}</LabelText>}
    </Container>
  )
}
