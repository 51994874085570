import { TestRun, TestRunNotePayload } from 'types'
import { useDatasource } from 'datasourceContext'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from 'data/useFetch'
import { toTestQueryKey, toTestRunQueryKey, toTestRunsQueryKey } from 'data/queryKeys'

export const useUpdateTestRunNote = (testRun: TestRun) => {
  const { ds } = useDatasource()

  // List of all the queries which will be affected by this update.
  const testQueryKey = toTestQueryKey(testRun.test_id)
  const testRunQueryKey = toTestRunQueryKey(testRun.id)
  const testRunsQueryKey = toTestRunsQueryKey(testRun.test_id)
  const testRunNoteUpdateQueryKey = ['updateTestRunNote', testRun.id]
  const queryKeysContainingRun = [testQueryKey, testRunQueryKey, testRunsQueryKey, testRunNoteUpdateQueryKey]

  return useMutation({
    mutationFn: (payload: TestRunNotePayload) => ds.updateTestRunNote(testRun.id, payload),
    onSettled: () => {
      queryKeysContainingRun.forEach((queryKey) => queryClient.invalidateQueries(queryKey))
    },
  })
}
