import { useEffect, useMemo, useState } from 'react'

import { useTagValues } from 'data/useTagValues'
import { useHttpNames, useHttpStatuses } from 'data/useHttpStats'
import { useCheckNames } from 'data/useChecks'
import { useThresholdNames } from 'data/useThresholds'
import { useGRPCNames } from 'data/useGRPC'
import { useScenarios } from 'data/useScenarios'
import { useWebSocketsNames, useWebSocketsStatus } from 'data/useWebSocketsStats'

import {
  booleanOptions,
  tagValuesTogRPCStatus,
  tagValuesToHttpMethods,
  toOptions,
  toScenariosByMetric,
} from './CustomerFilter.utils'

import {
  TestRun,
  TestRunHttpFilterBy,
  TestRunThresholdsFilterBy,
  TestRunChecksFilterBy,
  TestRunFilter,
  TestRunGRPCFilterBy,
  TestRunWebSocketsFilterBy,
} from 'types'

type Option = { label: string; value: string }
type OptionsHookReturn = { options: Option[]; isFetching: boolean }

const useHttpURLOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isFetching } = useHttpNames(run)
  const options = useMemo(() => toOptions(data, 'name'), [data])
  return { options, isFetching }
}

const useThresholdNameOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isFetching } = useThresholdNames(run)
  const options = useMemo(() => toOptions(data, 'name'), [data])
  return { options, isFetching }
}

const useCheckNameOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isFetching } = useCheckNames(run)
  const options = useMemo(() => toOptions(data, 'name'), [data])
  return { options, isFetching }
}

const useBooleanOptions = (): OptionsHookReturn => {
  const options = useMemo(() => booleanOptions, [])

  return {
    options,
    isFetching: false,
  }
}

const useHttpMethodOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isFetching } = useTagValues(run, 'method')
  const options = useMemo(() => toOptions(tagValuesToHttpMethods(data), 'value'), [data])
  return { options, isFetching }
}

const useHttpStatusOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isFetching } = useHttpStatuses(run)
  const options = useMemo(() => toOptions(data, 'status'), [data])
  return { options, isFetching }
}

const useHttpScenariosOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isFetching } = useScenarios(run)
  const options = useMemo(() => toOptions(toScenariosByMetric(data, 'http_metric_summary'), 'value'), [data])
  return { options, isFetching }
}

const useGrpcURLOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isFetching } = useGRPCNames(run)
  const options = useMemo(() => toOptions(data, 'name'), [data])
  return { options, isFetching }
}

const useGRPCStatusOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isFetching } = useTagValues(run, 'status')
  const options = useMemo(() => toOptions(tagValuesTogRPCStatus(data), 'value'), [data])
  return { options: options as unknown as Option[], isFetching }
}

const useGRPCScenariosOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isFetching } = useScenarios(run)
  const options = useMemo(() => toOptions(toScenariosByMetric(data, 'grpc_metric_summary'), 'value'), [data])
  return { options, isFetching }
}

const useWebSocketsURLOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isFetching } = useWebSocketsNames(run)
  const options = useMemo(() => toOptions(data, 'name'), [data])
  return { options, isFetching }
}

const useWebSocketsStatusOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isFetching } = useWebSocketsStatus(run)
  const options = useMemo(() => toOptions(data, 'status'), [data])
  return { options, isFetching }
}

const useWebSocketsScenariosOptions = (run: TestRun): OptionsHookReturn => {
  const { data, isFetching } = useScenarios(run)
  const options = useMemo(() => toOptions(toScenariosByMetric(data, 'ws_metric_summary'), 'value'), [data])
  return { options, isFetching }
}

const dataHooks = {
  [TestRunHttpFilterBy.URL]: useHttpURLOptions,
  [TestRunHttpFilterBy.ExpectedResponse]: useBooleanOptions,
  [TestRunHttpFilterBy.Method]: useHttpMethodOptions,
  [TestRunHttpFilterBy.Status]: useHttpStatusOptions,
  [TestRunHttpFilterBy.Scenario]: useHttpScenariosOptions,
  [TestRunChecksFilterBy.Name]: useCheckNameOptions,
  [TestRunThresholdsFilterBy.Name]: useThresholdNameOptions,
  [TestRunThresholdsFilterBy.Tainted]: useBooleanOptions,
  [TestRunGRPCFilterBy.URL]: useGrpcURLOptions,
  [TestRunGRPCFilterBy.Status]: useGRPCStatusOptions,
  [TestRunGRPCFilterBy.Scenario]: useGRPCScenariosOptions,
  [TestRunWebSocketsFilterBy.URL]: useWebSocketsURLOptions,
  [TestRunWebSocketsFilterBy.Status]: useWebSocketsStatusOptions,
  [TestRunWebSocketsFilterBy.Scenario]: useWebSocketsScenariosOptions,
}

export function useCustomFilterOptions<T extends TestRunFilter>(filter: T, run: TestRun) {
  const [userCreatedOptions, setUserCreatedOptions] = useState<Array<{ label: string; value: string }>>([])
  const { options, isFetching } = dataHooks[filter.by](run)

  useEffect(() => {
    setUserCreatedOptions(
      filter.values
        .filter((value) => !options.find((option) => option.value === value))
        .map((value) => ({
          value,
          label: value,
        }))
    )
  }, [filter, options])

  return { options: [...options, ...userCreatedOptions], isFetching }
}
