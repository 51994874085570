import { FieldConfigSource } from '@grafana/data'
import { VisibilityMode } from '@grafana/schema'
import { GraphFieldConfig } from '@grafana/ui'

export const overviewPanelConfig: FieldConfigSource<GraphFieldConfig> = {
  defaults: {
    min: 0,
    custom: {
      spanNulls: true,
      showPoints: VisibilityMode.Never,
      lineWidth: 2,
    },
  },
  overrides: [
    {
      matcher: {
        id: 'byRegexp',
        // Need to match both "VUs" (displayed in run overview) and "value(vus)" (displayed in dashboards)
        options: /VUs|value\(vus\)|sum\[last\]\(vus\)/.toString(),
      },
      properties: [
        {
          id: 'custom.fillOpacity',
          value: 10,
        },
        {
          id: 'color',
          value: {
            mode: 'fixed',
            fixedColor: '#86868659',
          },
        },
      ],
    },
  ],
}
