import { SelectableValue, DataQuery, DataSourceJsonData } from '@grafana/data'
import { Environment } from 'constants/environment'
import { K6DataSource } from 'datasource/datasource'
import { TestConfig } from '@grafana/k6-test-builder'

export type ISODateString = string

export type DateLike = Date | string | number

export enum ErrorCode {
  // Custom Frontend errors
  PRODUCT_TOKEN_NOT_FOUND = -1000,
  AXIOS_REQUEST_CANCELED = -2000,
  NETWORK_ERROR = -2,
  UNKNOWN_ERR = -1,

  // Error codes copied from API repo api3/errors.py
  //  General errors 1-999
  UNKNOWN = 0,
  GENERAL = 1,
  VALIDATION = 2,
  NOT_FOUND = 3,
  NOT_ALLOWED = 4,
  NOT_AUTHENTICATED = 5,
  AUTHENTICATION_FAILED = 6,
  METHOD_NOT_ALLOWED = 7,
  ALREADY_MEMBER = 9,
  LIMITATION_REACHED = 10,
  INVALID_ARGUMENTS = 11,
  BAD_REQUEST = 12,

  REQUEST_RATE_LIMIT_REACHED = 100,

  //  Users codes 1000-1999
  ACCOUNT_INACTIVE = 1001,
  ACCOUNT_NOT_CONFIRMED = 1002,
  ACCOUNT_CONFIRM_EXPIRED = 1003,
  ACCOUNT_CONFIRMED = 1004,
  ACCOUNT_SOCIAL_AUTH_CANCELED = 1006,
  ACCOUNT_SOCIAL_AUTH_ALREADY_ASSOCIATED = 1007,
  ACCOUNT_TOKEN_ALREADY_EXISTS = 1008,
  ACCOUNT_BAD_PASSWORD_RESET_TOKEN = 1009,
  ACCOUNT_TOKENS_CANNOT_HAVE_ORGANIZATION = 1010,
  ACCOUNT_CREATION_NOT_ALLOWED = 1011,

  //  Project 2000-2999
  PROJECT_NOT_MEMBER = 2001,
  PROJECT_DELETE_DEFAULT = 2002,

  //  Organization 3000-3999
  ORGANIZATION_NOT_MEMBER = 3001,
  ORGANIZATION_ALREADY_EXISTS = 3002,
  ORGANIZATION_MAX_OWNED = 3004,
  ORGANIZATION_MAX_ACTIVE_CREDIT_CARDS = 3005,
  ORGANIZATION_INVALID_VAT = 3006,
  ORGANIZATION_TOKEN_ALREADY_EXISTS = 3050,
  ORGANIZATION_MAX_MEMBER = 3007,
  ORGANIZATION_OWNER_MUST_BE_ADMIN = 3008,
  ORGANIZATION_TOKEN_NOT_ALLOWED = 3009,
  ORGANIZATION_DELETE_DEFAULT = 3010,
  ORGANIZATION_DELETE_PREMIUM_SUBSCRIPTION = 3011,
  ORGANIZATION_TRANSFER_DEFAULT = 3012,

  //  Subscriptions 3100-3199
  SUBSCRIPTION_ALREADY_CANCELED = 3100,
  SUBSCRIPTION_CONFLICT = 3110,
  SUBSCRIPTION_NO_ADDON_PARENT = 3111,
  SUBSCRIPTION_PREV_TRIAL_EXISTS = 3112,
  SUBSCRIPTION_PREV_TRIAL_UNUSED = 3113,
  SUBSCRIPTION_NOT_COVERING_TEST = 3114,

  //  Proxy 3200-3299
  PROXY_FAILED_TO_START = 3200,
  PROXY_FAILED_TO_STOP = 3201,

  //  Notifications 3300-3999
  NOTIFICATION_CONFLICT = 3300,

  //  Tests 4000-4999
  TEST_INVALID_CONFIGURATION = 4000,
  TEST_COPY_NAME_NOT_PROVIDED = 4002,
  /* cspell:disable-next-line */
  TEST_RUN_NOT_ABORTABLE = 4003,
  TEST_RUN_NOT_EXPORTABLE = 4004,
  TEST_RUN_EXPORT_EXISTS = 4005,
  TEST_RUN_MAX_CONCURRENT = 4006,
  TEST_RUN_INSUFFICIENT_FUNDS = 4007,
  TEST_RUN_START_FAILED = 4009,
  TEST_RUN_BLACKLISTED_IP = 4010,
  TEST_RUN_BLACKLISTED_HOST = 4011,
  TEST_RUN_IP_TEST_LIMIT = 4012,
  TEST_RUN_START_MISSING_TEST_ID = 4013,
  TEST_LOAD_ZONE_LIMIT = 4014,
  TEST_RUN_STATUS_NOT_ALLOWED = 4015,

  //  User scenarios 5000-5999
  USER_SCENARIO_NAME_NOT_UNIQUE = 5001,
  USER_SCENARIO_COPY_NAME_NOT_PROVIDED = 5002,
  USER_SCENARIO_DATA_STORE_PERMISSION_DENIED = 5003,
  USER_SCENARIO_INUSE_BY_TEST = 5004,
  USER_SCENARIO_VALIDATIONS_MAX_RUNNING = 5005,

  //  Data files 6000-6999
  DATA_FILE_NAME_NOT_UNIQUE = 6001,
  DATA_FILE_UPLOAD_FAILED = 6002,
  DATA_FILE_CONVERSION_NOT_FINISHED = 6003,
  DATA_FILE_INUSE_BY_TEST = 6004,

  //  Integrations 7000-7999
  INTEGRATIONS_AUTHENTICATION_FAILED = 7001,
  INTEGRATIONS_API_CALL_FAILED = 7002,
  INTEGRATIONS_BAD_RESPONSE = 7003,

  SERVER_METRICS_AGENT_CONFIGURED = 7300,

  //  K6 overlap errors 8008-8999
  //  (the reason for not starting at 8000
  //  is for legacy reasons - errors 0 - 7 are already
  //  the same between api and k6 repos). These are echoed
  //  in k6-lib/constants/errors.py.

  TOO_MANY_REQUESTS = 8008,
  TOO_MANY_ANONYMOUS_TESTS_PER_HOUR = 8009,
  TOO_LONG_ANONYMOUS_TEST_DURATION = 8010,
  TEST_ALREADY_ABORTED = 8011,
  TOO_HIGH_METRICS_RATE = 8012,
  INTERNAL_INFRASTRUCTURE_ERROR = 8013,
  TEST_RUN_INVALID_DURATION = 8014,
  TEST_RUN_NO_DATA_TIMEOUT = 8015,
  TEST_RUN_TIMER_EXCEEDED = 8016,
  SLS_HAR_RECORDER_ERROR = 8017,
  /* cspell:disable-next-line */
  SLS_K6_CONV_ERROR = 8018,
  LOCKDOWN = 8019,
  INTERNAL_NETWORK_ERROR = 8020,
  USER_ALREADY_PROJECT_MEMBER = 8021,
  RECAPTCHA_CHALLENGE_FAILED = 8022,
  RECAPTCHA_CHALLENGE_REQUEST_ERROR = 8023,
  /* cspell:disable-next-line */
  SLS_K6_CONV_SCRIPT_ERROR = 8024,
}

export interface K6AppSettings {
  customText?: string
  customCheckbox?: boolean
}

export interface Settings {
  ds: K6DataSource
  query?: string
  index?: number
  components?: any[]
}

export interface TestsPageSettings extends Settings {}

export interface TestsPageState {
  org: string
  orgs: SelectableValue[]
  project: string
  projects: SelectableValue[]
  tests: Test[]
}

export type Tags = any[]

export interface Metric2 {
  check_id?: any
  contains: string
  group_id?: any
  id: string
  name: string
  tags: Tags
  test_run_id: number
  type: MetricType
}

export interface Agg {}

export interface Value {
  timestamp: string
  value: any
}
/* cspell:disable-next-line */
export interface Serie {
  agg: Agg
  id: string
  method: string
  metric_id: string
  test_run_id: number
  values: Value[]
}

export interface Meta {
  count: number
}

export interface Metrics {
  'k6-metrics': Metric2[]
  /* cspell:disable-next-line */
  'k6-serie': Serie[]
  meta: Meta
}

export interface Stats {
  count: number
  max: number
  mean: number
  min: number
  p95: number
  p99: number
  stdev: number
}

export interface NullStats {
  count: 0
  max: null
  mean: null
  min: null
  p95: null
  p99: null
  stdev: null
}

interface NullGrpcMetricSummary {
  duration: NullStats
  requests_count: null
  rps_max: null
  rps_mean: null
}

export interface GrpcMetricSummary {
  duration: Stats
  requests_count: number
  rps_max: number
  rps_mean: number
}

export interface ThresholdsSummary {
  successes: number
  total: number
}

interface NullHttpMetricSummary {
  duration: NullStats
  failures_count: null
  requests_count: null
  rps_max: null
  rps_mean: null
}

export interface HttpMetricSummary {
  duration: Stats
  failures_count: number
  requests_count: number
  rps_max: number
  rps_mean: number
}

interface NullWsMetricSummary {
  connecting: NullStats
  msgs_received: null
  msgs_sent: null
  ping: NullStats
  session_duration: NullStats
  sessions: null
}

export interface WsMetricSummary {
  connecting: Stats
  msgs_received: number | null
  msgs_sent: number | null
  ping: Stats
  session_duration: Stats
  sessions: number
}

export interface ChecksMetricSummary {
  hits_successes: number
  hits_total: number
  successes: number
  total: number
}

export interface Summary {
  checks_metric_summary: ChecksMetricSummary
  thresholds_summary: ThresholdsSummary
  http_metric_summary: HttpMetricSummary | NullHttpMetricSummary
  grpc_metric_summary: GrpcMetricSummary | NullGrpcMetricSummary
  ws_metric_summary: WsMetricSummary | NullWsMetricSummary
}

export interface Http {
  id: string
  test_run_id: number
  group_id?: any
  scenario_id: string
  http_metric_summary: HttpMetricSummary
  method: string
  name: string
  status: number
  expected_response: boolean
}

export interface VariableQuery {
  qtype: VariableQueryType
  query: string
}

export enum VariableQueryType {
  ORGANIZATIONS,
  PROJECTS,
  TESTS,
  TEST_RUNS,
}

export enum QueryType {
  METRIC = 'metric',
  URLS = 'urls',
  CHECKS = 'checks',
  THRESHOLDS = 'thresholds',
}

/**
 * These are options configured for each DataSource instance
 */
export interface CloudDataSourceOptions extends DataSourceJsonData {
  k6ApiEnv?: Environment
}

/**
 * Value that is used in the backend, but never sent over HTTP to the frontend
 */
export interface CloudSecureJsonData {
  apiToken?: string
}

export interface TestRunListItem {
  /*organizationId: number;
  organizationName: string;
  projectId: number;
  projectName: string;
  testId: number;
  testName: string;
  testCreated: Date;*/
  testRunId: number
  testRunCreated: Date
  testRunStarted: Date
  testRunStartedEpoch: number
  testRunEnded: Date
  testRunEndedEpoch: number
  testRunStatus: number
  testRunResultStatus: number
  testRunVUs: number
  testRunDuration: number
  testRunLoadTime: number
}

export interface Organization {
  id: number
  name: string
  logo: string | null
  owner_id: number
  description: string
  billing_address: string
  billing_country: string
  billing_email: string
  vat_number: string
  created: string
  updated: string
  is_default: boolean
  is_saml_org: boolean
  subscription_ids: number[]
  load_zone_ids: number[]
  can_trial: boolean
  is_personal: boolean
  data_retention_days: number
  vuh: number
  vuh_max: number
  vuh_overcharge: number
}

export interface Project {
  id: number
  name: string
  organization_id: number
  organization_name: string
  is_default: boolean
  is_k6: boolean
}

export interface Test {
  id: number
  created: string
  name: string
  test_runs: TestRun[]
  project_id: number
  creation_process: string
  last_test_run_id: number
  script: string
  request_builder_config: string
  config: TestConfig
}

export interface Tests {
  'k6-tests': Test[]
  meta: {
    count: number
  }
}

export enum NamedColors {
  Red = 'red',
  Blue = 'blue',
  Green = 'green',
  Orange = 'orange',
  Purple = 'purple',
  Gray = 'gray',
}

export type Color = NamedColors | `#${string}`

export const BadgeColors = {
  RED: 'red',
  BLUE: 'blue',
  GREEN: 'green',
  ORANGE: 'orange',
  PURPLE: 'purple',
  GRAY: 'gray',
}

export const BadgeColorCodes = {
  [BadgeColors.RED]: '#dc3545',
  [BadgeColors.GREEN]: '#28a745',
  [BadgeColors.ORANGE]: '#f1c40f',
  [BadgeColors.GRAY]: '#b5b5b5',
  [BadgeColors.BLUE]: '#168ce8',
  [BadgeColors.PURPLE]: '#9b59b6',
}

export enum BadgeIcons {
  SPINNER = 'fa fa-spinner',
  CHECK = 'check',
  EXCLAMATION_TRIANGLE = 'exclamation-triangle',
  MINUS_CIRCLE = 'minus-circle',
}

export enum TestRunStatus {
  CREATED = -2,
  VALIDATED = -1,
  QUEUED = 0,
  INITIALIZING = 1,
  RUNNING = 2,
  FINISHED = 3,
  TIMED_OUT = 4,
  ABORTED_USER = 5,
  ABORTED_SYSTEM = 6,
  ABORTED_SCRIPT_ERROR = 7,
  ABORTED_THRESHOLD = 8,
  ABORTED_LIMIT = 9,
}

export const TestRunStatusText = {
  [TestRunStatus.CREATED]: 'Created',
  [TestRunStatus.VALIDATED]: 'Validated',
  [TestRunStatus.QUEUED]: 'Queued',
  [TestRunStatus.INITIALIZING]: 'Initializing',
  [TestRunStatus.RUNNING]: 'Running',
  [TestRunStatus.FINISHED]: 'Finished',
  [TestRunStatus.TIMED_OUT]: 'Timed out',
  [TestRunStatus.ABORTED_USER]: 'Aborted by user',
  [TestRunStatus.ABORTED_SYSTEM]: 'Aborted by system',
  [TestRunStatus.ABORTED_SCRIPT_ERROR]: 'Aborted script error',
  [TestRunStatus.ABORTED_THRESHOLD]: 'Aborted by threshold',
  [TestRunStatus.ABORTED_LIMIT]: 'Aborted by limit',
}

export enum TestRunResultStatus {
  PASSED = 0,
  FAILED = 1,
}

export const TestRunResultStatusText = {
  [TestRunResultStatus.PASSED]: 'Test passed',
  [TestRunResultStatus.FAILED]: 'Test failed',
}

export enum TestRunProcessingStatus {
  /* cspell:disable-next-line */
  UNRUN = 0,
  PROCESSING = 1,
  FINISHED = 2,
  ERROR = 3,
}

export const RUN_PROCESS = {
  K6_INGEST: 'k6 to Ingest',
  K6_CLOUD: 'k6 to Cloud',
  K6_INGEST_WITH_THRESHOLDS: 'k6 to Ingest with Thresholds', // k6-operator (among other "things"?)
  K6_TO_CLOUD_SECURE: 'k6 to Cloud secure mode',
}

export const CREATION_PROCESS = {
  REQUEST_BUILDER: 'UI-REQUEST-BUILDER',
  SCRIPT_HANDWRITTEN: 'UI-SCRIPT-HANDWRITTEN',
  SCRIPT_CHROME: 'UI-SCRIPT-BROWSER-CHROME',
  SCRIPT_FIREFOX: 'UI-SCRIPT-BROWSER-FIREFOX',
  K6_CLOUD: 'K6-CLOUD',
}

export const CREATION_PROCESS_TEXTS = {
  [CREATION_PROCESS.REQUEST_BUILDER]: 'Test builder test',
  [CREATION_PROCESS.SCRIPT_HANDWRITTEN]: 'Script test',
  [CREATION_PROCESS.SCRIPT_CHROME]: 'Recorded test',
  [CREATION_PROCESS.SCRIPT_FIREFOX]: 'Recorded test',
  [CREATION_PROCESS.K6_CLOUD]: 'CLI k6 test',
}

export const K6TestRunStatusesActive = [
  TestRunStatus.RUNNING,
  TestRunStatus.CREATED,
  TestRunStatus.VALIDATED,
  TestRunStatus.QUEUED,
  TestRunStatus.INITIALIZING,
]

export enum BackgroundTaskType {
  TEST_RUN = 5,
  TEST_RESULT_EXPORT = 6,
}

// testRun delete_status
export const DELETE_STATUS = {
  DELETABLE: 0,
  // will never be hit by data-retention. This is set when you select a test-run to be 'safe'
  /* cspell:disable-next-line */
  NOEXPIRE: 1,
  // also safe, but this is set by us to avoid a user deleting or changing a test (for debug purposes)
  READONLY: 2,

  // These higher numbers track why a test-run was deleted:
  DELETED_USER: 3, //- test-run was already deleted by the user
  DELETED_EXPIRE: 4, //- test-run deleted by expired timeout (data retention)
  DELETION_IN_PROGRESS: 5, //- temporary state when waiting for metrics to be wiped.
}

export enum SUBSCRIPTION_TYPE {
  CUSTOM = 1,
  APP_DIRECT = 2,
  PREMIUM = 3,
  TRIAL = 4,
  FREE = 5,
  DATA_RETENTION = 7,
}

export enum SUBSCRIPTION_STATUS {
  ACTIVE = 1,
  CANCELED = 2,
  EXPIRED = 3,
  INACTIVE = 4,
  PAYMENT_LATE = 10,
}

export interface BackgroundTask {
  type: number
  test_run_id: number
}

export interface TestRunNode {
  load_zone_id: string
  public_ip: string
  size: string
}

export enum MetricType {
  COUNTER = 'counter',
  GAUGE = 'gauge',
  RATE = 'rate',
  TREND = 'trend',
}

export type MetricTypeName = 'counter' | 'gauge' | 'rate' | 'trend'

export interface Url {
  id: string
  projectId: number
  testRunId: number
  groupId?: string
  metrics?: Metric[]
  url: string
  method: string
  status: number
  httpStatus: number
  isWebSocket: boolean
  count: number
  loadTime: number
  min: number
  max: number
  mean: number
  stdev: number
  p95: number
  p99: number
}

export type CheckMetricSummary = {
  fail_count: number
  success_count: number
  success_rate: number
}

export type Check = {
  group_id?: string
  id: string
  metric_summary: CheckMetricSummary
  fail_count: number
  success_count: number
  success_rate: number
  name: string
  scenario_id: string
  test_run_id: number
}

export type ThresholdPercentile = `p(${number})`

export type ThresholdStat = 'count' | 'rate' | 'value' | 'avg' | 'min' | 'max' | 'med' | ThresholdPercentile

type CalcState = {
  max_created_at: Date
  max_time: Date
  min_time: Date
  tainted_value: number
}

export interface Threshold {
  id: number
  name: string
  stat: ThresholdStat
  tainted: boolean
  tainted_at?: string
  test_run_id: number
  value: number
  calculated_value: number
  calc_state: CalcState
}

export interface Metric {
  id: string
  name: string
  type: MetricType
  contains: string
  groupId?: string
  urlId?: string
  thresholdId?: string
  checkId?: string
  tags?: Map<string, string>
}

export enum GRPC_STATUS {
  OK,
  CANCELLED,
  UNKNOWN,
  INVALID_ARGUMENT,
  DEADLINE_EXCEEDED,
  NOT_FOUND,
  ALREADY_EXISTS,
  PERMISSION_DENIED,
  RESOURCE_EXHAUSTED,
  FAILED_PRECONDITION,
  ABORTED,
  OUT_OF_RANGE,
  UNIMPLEMENTED,
  INTERNAL,
  UNAVAILABLE,
  DATA_LOSS,
  UNAUTHENTICATED,
}

export interface GrpcUrl {
  id: string
  test_run_id: number
  group_id: string | null
  scenario_id: string | null
  name: string
  host: string
  method: string
  status: GRPC_STATUS
  grpc_metric_summary: GrpcMetricSummary
  scenario: string
  expected_response?: boolean
}

export interface WSValue {
  group_id?: any
  id: string
  name: string
  scenario_id: string
  scenario: string
  status: number
  test_run_id: number
  ws_metric_summary: WsMetricSummary
}

export interface WSPayload {
  '@count': number
  value: WSValue[]
}

export interface Scenario {
  id: string
  test_run_id: number
  name: string
  http_metric_summary: {
    duration: ScenarioDuration
    duration_median: number | null
    failures_count: number | null
    requests_count: number | null
    rps_mean: number | null
  }
  grpc_metric_summary: {
    duration: ScenarioDuration
    requests_count: number | null
    rps_mean: number | null
  }
  ws_metric_summary: {
    connecting: ScenarioDuration
    ping: ScenarioDuration
    session_duration: ScenarioDuration
    msgs_received: number | null
    msgs_sent: number | null
    sessions: number | null
  }
}

interface ScenarioDuration {
  count: number
  max: number | null
  mean: number | null
  min: number | null
  p95: number | null
  p99: number | null
  stdev: number | null
}

export interface SeriesDataPoint {
  timestamp: string
  value: number
}

export interface Series {
  id: string
  metricId: string
  aggregation: string
  values: SeriesDataPoint[]
}

export interface SeriesTag {
  id: number
  key: string
  value: string
}

export interface Query extends DataQuery {
  queryType: QueryType
  qtype: QueryType
  entity?: string
  projectId?: number | string
  testId?: number | string
  testRunId?: number | string
  metric?: string
  aggregation?: string
  tags?: SeriesTag[]
  uid?: string
}

export interface Org {
  id: number
  name: string
  is_default: boolean
}

export interface Overview {
  checks_hits_successes: number
  checks_hits_total: number
  checks_successes: number
  checks_total: number
  http_req_duration_avg: number
  http_reqs_avg: number
  test_run_id: number
  thresholds_successes: number
  thresholds_total: number
  urls_hits_successes: number
  urls_hits_total: number
  urls_successes: number
  urls_total: number
}

export interface FormattedRuns {
  loadTimes: number[]
  dateStrings: string[]
  status: any
}

export interface LoadZone {
  id: number
  name: string
  vendor: string
  country: string
  city: string
  aggregate_region: boolean
  latitude: number
  longitude: number
  configurable: boolean
  is_k6_only: boolean
  k6_load_zone_id: string
  public: boolean
}

type User = {
  application_type: unknown
  company: string
  company_size: number
  country: string
  date_joined: string
  email: string
  first_name: string
  gravatar_url: string
  has_password: boolean
  id: number
  industry: string
  is_saml_user: boolean
  job_title?: string
  last_name: string
  organization_ids: number[]
  status: number
  time_zone: string
}

type AdditionalUserData = {
  last_used_organization_id: number
  last_used_project_id: number
  max_number_of_organizations: number
  user_id: number
}

export type Account = {
  user: User
  additional_user_data: [AdditionalUserData]
  organization_roles: OrganizationRole[]
  project_roles: ProjectRole[]
  organizations: Organization[]
  subscriptions: Subscription[]
  token: {
    key: string
  }
}

export type Awaited<T> = T extends PromiseLike<infer U> ? U : T

export enum MetricsExportStatus {
  NOT_STARTED = 0,
  EXPORTING = 1,
  FINISHED = 2,
  ERROR = 3,
}

export type MetricsExport = {
  config: unknown
  created: string
  end_time_lock: string
  error_detail: string
  error_detail_public: string
  export_status: MetricsExportStatus
  finished: string | null
  id: number
  load_test_run: number
  provider: string
  started: string
}

export type TestRun = {
  created: string
  delete_status: number
  distribution: Array<[string, number]>
  duration: number
  ended: string
  id: number
  is_baseline: boolean
  load_time: number | null
  metrics_exports: MetricsExport[] | null
  nodes: TestRunNode[]
  note: string
  processing_status: TestRunProcessingStatus
  project_id: number
  result_status: TestRunResultStatus
  run_process: string
  run_status: TestRunStatus
  error_code?: ErrorCode | null
  started: string
  test_id: number
  vus: number
  user_id: number
  script: string
}

export type TestRuns = {
  'k6-runs': TestRun[]
  meta?: Meta
}

export type TimeSeriesItem = {
  [key: string]: number
} & {
  timestamp: Date
}

export type TimeSeriesValue = {
  timestamp: string
  value: number
}

export type TimeSeries = {
  contains: string
  method: string
  metric_name: string
  metric_type: string
  test_run_id: number
  values: TimeSeriesValue[]
}

export type TimeSeriesPayload = {
  value: TimeSeries[]
}

export type ProjectWithOrg = Project & {
  orgName: string
}

export enum TestSortOptions {
  LastTestRun = '-last_test_run',
  Created = '-created',
  Name = 'name',
}

export type OrganizationRole = {
  id: number
  organization_id: number
  role_id: number
  user_email: string
  user_id: number
}

export type ProjectRole = OrganizationRole & {
  project_id: number
}

export type RunInfo = {
  'k6-metrics': Array<{
    check_id?: number
    contains: string
    group_id: string
    id: string
    name: string
    project_id: number
    tags: SeriesTag[]
    test_run_id: number
    type: string
    url_id?: number
  }>

  'k6-thresholds': Threshold[]
}

export type PercentileAggregation = `0.${number}`

export type TimeSeriesQueryType =
  | 'test_runs'
  | 'http_urls'
  | 'checks'
  | 'thresholds'
  | 'grpc_url_success'
  | 'grpc_urls'
  | 'ws_urls'

export type RateMethod =
  | 'rate'
  | 'cumrate'
  | 'count'
  | 'cumcount'
  | 'nz_rps'
  | 'nz_count'
  | 'nz_cumcount'
  | 'passes'
  | 'failures'

export type TrendMethod =
  | 'avg'
  | 'median'
  | 'min'
  | 'max'
  | 'std'
  | 'count'
  | 'cummin'
  | 'cummax'
  | 'cumavg'
  | 'cumstd'
  | 'cummean'
  | 'cumcount'
  | PercentileAggregation

export type CounterMethod = 'rps' | 'sum' | 'cumsum' | 'cumrps'

export type GaugeMethod = 'max[last]'

export type ChecksSpecialCaseMethod = 'passes' | 'failures'
export type VUMetricSpecialCaseAggregation = 'sum[last]'

export type AggregationMethod =
  | TrendMethod
  | RateMethod
  | CounterMethod
  | GaugeMethod
  | ChecksSpecialCaseMethod
  | VUMetricSpecialCaseAggregation

export type FetchTimeSeriesArgs = {
  queryType: TimeSeriesQueryType
  testRunId: number
  metric?: string
  method?: AggregationMethod
  uid?: string | number
  tags?: TagPayload
}

export type Subscription = {
  id: number
  name: string
  description: string
  version: number
  starting: string
  expires: string
  status: number
  status_text: string
  price: number
  discount: number
  vuh: number
  vuh_max: number
  vuh_overcharge: number
  product_id: number
  period_days: number
  reset_days: number
  period_days_text: string
  rules: Rules
  rules_inheritance: RulesInheritance
  used_tests: number
  type: number
  is_addon: boolean
  addon_to: null
  addons: any[]
  /* cspell:disable-next-line */
  is_proratable: boolean
  is_usage: boolean
  /* cspell:disable-next-line */
  is_grandfatherable: boolean
}

export type Rules = {
  'load_test.allow_start': boolean
  'load_test.k6_cloud_execution.allow': boolean
  'load_test.k6_to_ingest_with_thresholds.allow': boolean
  'load_test.delete_sensitive_data.allow': boolean
  'load_test.load_zones.max': number
  'load_test.instances.max': number
  'tests.users.max': number
  'tests.duration.max': number
  'tests.max': number
  'tests.vuh.max': number
  'tests.concurrent.max': number
  'organization.members.max': number
  'organization.tokens.max': number
  'organization.usage_reports': boolean
  'subscription.cost.affects_parent_proration': boolean
  'organization.executive_summary': boolean
  'data.retention_days': number
  'data.retention_days.post_sub_expiry': number
  'data.retention_safe.max': number
  'apm.is_enabled': boolean
  'apm.providers.allowed': string[]
  'apm.providers.max': number
  'apm.metrics.max': number
  'load_zone.private.load_zone_ids': any[]
  'load_test.users.min': number
  'load_test.users.max': number
  'load_test.duration.max': number
  'load_test.extra_ip.max': number
  'load_test.ramp_time.min': number
  'load_test.ramp_steps.min': number
  'load_test.ramp_steps.max': number
  'load_test.tracks.min': number
  'load_test.tracks.max': number
  'load_test.sma.max': number
  'load_test.new_relic.max': number
  'load_test.new_relic.metrics.max': number
  'load_test.url_groups.max': number
  'load_test.source_ips_multiple.max': number
  'tests.threshold_configs.max': number
  'subscription.credits': number
  'user_scenarios.validations.max': number
  'load_zone.loadimpact.stockholm1.allow': boolean
  'load_zone.loadimpact.stockholm2.allow': boolean
  'load_zone.rax.chicago.allow': boolean
  'load_zone.rax.dallas.allow': boolean
  'load_zone.rax.london.allow': boolean
  'load_zone.rax.sydney.allow': boolean
  'beta.load_zones.allow': boolean
  'beta.test_worker.allow': boolean
  'beta.payment_provider.allow': boolean
}

export type Operator = 'equal' | 'not-equal'

export interface TagExpression {
  name: string
  operator: Operator
  values: string[]
}

export interface TagQuery {
  [tag: string]: TagExpression
}

export interface QueryRange {
  start: number
  end: number
}

export interface TimeSeriesQuery {
  metric: string
  method: AggregationMethod
  range?: QueryRange
  tags: TagQuery
}

export type RulesInheritance = {
  [key in keyof Rules]: {
    from_sub: number | null
    value: Rules[key]
  }
}

export type TagPayload = Array<{
  key: string
  value: string
}>

export type MetricPayload = {
  type: TimeSeriesQueryType
  method?: AggregationMethod
  metric?: string
  unit?: string
  label?: string
  uid?: string | number
  tags?: TagPayload
}

export type ApiError = {
  data: {
    error: {
      field_errors: Record<string, string> & {
        non_field_errors?: string[]
      }
    }
  }
}

export type OptionsValidationError = {
  data: {
    error: {
      message: string
      code: number
    }
  }
}

export interface TagValue {
  id: string
  tag_id: string
  test_run_id: number
  value: string
}

export interface TagValuePayload {
  value: TagValue[]
}

export enum TestRunFilterType {
  Http = 'http',
  Thresholds = 'thresholds',
  Checks = 'checks',
  GRPC = 'grpc',
  WebSockets = 'websockets',
}

export enum TestRunHttpFilterBy {
  URL = 'http_url',
  Status = 'http_status',
  Scenario = 'http_scenario',
  Method = 'method',
  ExpectedResponse = 'expected_response',
}

export enum TestRunThresholdsFilterBy {
  Name = 'threshold_name',
  Tainted = 'tainted',
}

export enum TestRunGRPCFilterBy {
  URL = 'grpc_url',
  Status = 'grpc_status',
  Scenario = 'grpc_scenario',
}

export enum TestRunChecksFilterBy {
  Name = 'check_name',
}

export enum TestRunWebSocketsFilterBy {
  URL = 'ws_url',
  Status = 'ws_status',
  Scenario = 'ws_scenario',
}

type TestRunFilterBase<T> = { by: T; label: string; values: string[] }

export type TestRunHttpFilter = TestRunFilterBase<TestRunHttpFilterBy>
export type TestRunThresholdsFilter = TestRunFilterBase<TestRunThresholdsFilterBy>
export type TestRunChecksFilter = TestRunFilterBase<TestRunChecksFilterBy>
export type TestRunGRPCFilter = TestRunFilterBase<TestRunGRPCFilterBy>
export type TestRunWebSocketsFilter = TestRunFilterBase<TestRunWebSocketsFilterBy>

export type TestRunFilter =
  | TestRunHttpFilter
  | TestRunThresholdsFilter
  | TestRunChecksFilter
  | TestRunGRPCFilter
  | TestRunWebSocketsFilter

export type TestRunFilterBy =
  | TestRunHttpFilterBy
  | TestRunThresholdsFilterBy
  | TestRunChecksFilterBy
  | TestRunGRPCFilterBy
  | TestRunWebSocketsFilterBy

export type TestRunNotePayload = Pick<TestRun, 'note'>
