import React, { useMemo, useState } from 'react'
import Prism, { Grammar } from 'prismjs'
import head from 'lodash/head'

import { identity } from 'utils/composition'
import { dedent as formatCode } from 'utils/string'
import { Clipboard } from 'components/Clipboard'
import { CodeSnippetProps, CodeSnippetTabProps } from './CodeSnippet.types'
import { getEstimatedHeight, getTab } from './CodeSnippet.utils'
import {
  ClipboardWrapper,
  Code,
  Container,
  Header,
  FakeControls,
  FakeControlsItem,
  Heading,
  Pre,
  Section,
  Tabs,
  Tab,
  TabSeparator,
  Title,
} from './CodeSnippet.styles'

const CodeSnippetTab = ({ active, tab, onClick }: CodeSnippetTabProps) => {
  function handleClick() {
    if (!active) {
      onClick(tab.value)
    }
  }

  return (
    <Tab $active={active} onClick={handleClick}>
      {tab.label}
    </Tab>
  )
}

export const CodeSnippet = ({
  canCopy = true,
  code,
  className,
  dedent = true,
  lang = 'js',
  heading,
  initialTab,
  separator = 'pipe',
  tabs = [],
  title,
}: CodeSnippetProps) => {
  const [activeTab, setActiveTab] = useState<string | undefined>(initialTab || head(tabs)?.value)

  const tab = getTab(activeTab, tabs)
  const formatter = tab?.dedent ?? dedent ? formatCode : identity
  const snippet = tab ? tab.code : code

  const highlightedSyntax = useMemo(
    () => snippet && Prism.highlight(formatter(snippet), Prism.languages[lang] as Grammar, lang),
    [formatter, snippet, lang]
  )

  return (
    <Container className={className}>
      <Header>
        <FakeControls>
          <FakeControlsItem />
          <FakeControlsItem />
          <FakeControlsItem />
        </FakeControls>

        {!!tabs.length && (
          <Tabs>
            {tabs.flatMap((tab, index) => {
              const TabComponent = (
                <CodeSnippetTab
                  key={`${tab.value}-tab`}
                  active={activeTab === tab.value}
                  tab={tab}
                  onClick={setActiveTab}
                />
              )

              return index === 0
                ? [TabComponent]
                : [<TabSeparator key={`${tab.value}-separator`} $separator={separator} />, TabComponent]
            })}
          </Tabs>
        )}

        {title && <Title>{title}</Title>}
        {heading && <Heading>{heading}</Heading>}
      </Header>
      <Section $minHeight={getEstimatedHeight(snippet)}>
        <Pre>
          <Code $lang={lang} dangerouslySetInnerHTML={{ __html: highlightedSyntax }} />
        </Pre>

        {canCopy && (
          <ClipboardWrapper>
            <Clipboard data={snippet} />
          </ClipboardWrapper>
        )}
      </Section>
    </Container>
  )
}
