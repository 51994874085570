import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { Input, Button, HorizontalGroup, ConfirmModal } from '@grafana/ui'
import { format } from 'date-fns'
import styled from 'styled-components'

import { CodeEditor } from 'components/CodeEditor/CodeEditor'
import { Test } from 'types'
import { useFormStatus } from 'hooks/useFormStatus'
import { FormStatusMessage } from 'components/FormStatusMessage/FormStatusMessage'
import { RunButtonController } from 'components/RunButtonController'
import { isReadOnlyTest } from 'utils/test'
import { useUpdateTest } from 'data/useUpdateTest'
import { useCreateTest } from 'data/useCreateTest'
import { useRunTest } from 'data/useRunTest'
import { FormFields } from '../Script'
import { DEFAULT_SCRIPT } from 'constants/scripts'
import { routeMap } from 'routeMap'

const Wrapper = styled.div`
  height: 100%;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`
const EditorWrapper = styled.div`
  flex-grow: 1;
`

type FormProps = {
  testId: string
  test?: Test
  projectId: number
  isNewTest: boolean
}

export const Form = ({ test, projectId, isNewTest, testId }: FormProps) => {
  const history = useHistory()
  const { state } = useLocation<{ scriptValue?: string }>()

  const [isScriptError, setIsScriptError] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const { mutateAsync: updateTest, error: updateError } = useUpdateTest()
  const { mutateAsync: createTest, error: createError } = useCreateTest()
  const { mutateAsync: runTest, error: runError } = useRunTest()

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    getValues,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<FormFields>({
    defaultValues: {
      name: test?.name,
      script: test?.script || test?.test_runs[0]?.script,
      id: test?.id,
    },
  })

  const saveButtonEnabled = isDirty && !isSubmitting
  const runButtonEnabled = !isSubmitting
  const isReadOnly = !!(test && isReadOnlyTest(test))

  const formStatus = useFormStatus({
    errors,
    isSubmitting,
    isScriptError,
    isUnsaved: !isNewTest && isDirty,
    test: test,
  })

  useEffect(() => {
    if (!isNewTest) {
      return
    }

    const name = `Test (${format(new Date(), 'dd/MM/yyyy-HH:mm:ss')})`
    const script = state?.scriptValue ?? DEFAULT_SCRIPT

    setValue('name', name, { shouldDirty: true })
    setValue('script', script, { shouldDirty: true })
  }, [isNewTest, setValue, state])

  useEffect(() => {
    const saveErrors = [createError, updateError]
    const saveError = saveErrors.find(Boolean)

    if (saveError) {
      // Even though it says non_field_errors, the only error I was able to trigger
      // was "name already exists" which belongs to name field.
      const message = saveError.data.error.field_errors?.non_field_errors?.[0] || 'Validation failed'
      setError('name', { message })
      return
    }

    if (runError) {
      throw runError
    }
  }, [createError, updateError, runError, setError])

  const handleSave: SubmitHandler<FormFields> = async (data) => {
    const response = await saveTest(data)
    if (!response) {
      return
    }

    reset(response)
    isNewTest && history.replace(routeMap.script(response.id))
  }

  const saveAndRun = async (data: FormFields) => {
    if (isDirty) {
      const response = await saveTest(data)
      return response && runTestAndRedirect(response.id)
    }
    return runTestAndRedirect(+testId)
  }

  const saveTest = async (data: FormFields) => {
    const payload = {
      ...data,
      creation_process: 'UI-SCRIPT-HANDWRITTEN',
      project_id: projectId,
    }

    const saveFn = isNewTest ? createTest : updateTest
    return saveFn(payload)
  }

  const runTestAndRedirect = async (testId: number) => {
    const run = await runTest(testId)
    run && history.push(routeMap.testRun(run.id))
  }

  const handleSaveAndRun: SubmitHandler<FormFields> = async (data) => {
    if (isScriptError && !showConfirmationModal) {
      // show modal
      setShowConfirmationModal(true)
      return
    }
    setShowConfirmationModal(false)
    await saveAndRun(data)
  }

  const saveButtonLabel = isNewTest ? 'Create' : 'Save'
  const saveAndRunButtonLabel = isDirty ? `${saveButtonLabel} and Run` : 'Run test'
  const isScriptNotAvailable = isReadOnly && !getValues('script')
  const overlayMessage = isScriptNotAvailable ? 'No script available.' : null

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(handleSave)}>
        <HorizontalGroup justify="space-between" height="auto">
          <HorizontalGroup>
            <Input width={50} {...register('name', { required: { value: true, message: 'name is required' } })} />
          </HorizontalGroup>
          <HorizontalGroup>
            <Button type="submit" disabled={!saveButtonEnabled}>
              {saveButtonLabel}
            </Button>
            <RunButtonController test={test}>
              {({ isDisabled }) => (
                <Button
                  onClick={handleSubmit(handleSaveAndRun)}
                  variant={isScriptError ? 'destructive' : 'primary'}
                  disabled={!runButtonEnabled || isDisabled}
                >
                  {saveAndRunButtonLabel}
                </Button>
              )}
            </RunButtonController>
          </HorizontalGroup>
        </HorizontalGroup>
        <FormStatusMessage {...formStatus} />
        <Controller
          name="script"
          control={control}
          rules={{
            required: { value: true, message: 'script is required' },
          }}
          render={({ field }) => (
            <EditorWrapper>
              <CodeEditor
                {...field}
                onValidation={setIsScriptError}
                readOnly={isReadOnly}
                overlayMessage={overlayMessage}
              />
            </EditorWrapper>
          )}
        />

        <ConfirmModal
          title="Script errors found"
          isOpen={showConfirmationModal}
          body="Looks like there are errors in your script, do you want to force run the test anyway?"
          confirmText="Force run test"
          onDismiss={() => setShowConfirmationModal(false)}
          onConfirm={handleSubmit(handleSaveAndRun)}
        />
      </form>
    </Wrapper>
  )
}
