import React, { useMemo } from 'react'

import { Check, NamedColors, TestRun } from 'types'
import { CHECK_PASSES, CHECK_FAILURES, VUS_METRIC } from 'constants/metrics'
import { Chart } from 'components/Chart'
import { MetricBuilder, TagQueryBuilder } from 'utils/metrics'

interface ChecksChartProps {
  run: TestRun
  check: Check
}

export const ChecksChart = ({ run, check }: ChecksChartProps) => {
  const metrics = useMemo(() => {
    const query = new TagQueryBuilder().equal('check', check.name).build()

    return [
      VUS_METRIC,
      new MetricBuilder(CHECK_PASSES).withTags(query).withColor(NamedColors.Green).build(),
      new MetricBuilder(CHECK_FAILURES).withTags(query).withColor(NamedColors.Red).build(),
    ]
  }, [check.name])

  return <Chart title={`Check: ${check.name}`} testRun={run} metrics={metrics} />
}
