import React, { ComponentProps, useEffect, useMemo, useState } from 'react'

import { LoadingContainer } from 'components/LoadingContainer'
import { useScenarios } from 'data/useScenarios'
import { TestRun } from 'types'
import { ProtocolType } from '../RunOverview.types'
import { filterScenariosByProtocol } from './Scenarios.utils'
import { ZeroState, ZeroStateHint } from './ZeroState'
import { Switch } from 'components/Switch'
import { ScenarioTable } from './ScenarioTable'
import { Controls, Line } from './Scenarios.styles'

interface ScenarioSummaryProps {
  testRun: TestRun
  protocol: ProtocolType
}

export const Scenarios = ({ testRun, protocol }: ScenarioSummaryProps) => {
  const { data, isLoading } = useScenarios(testRun, { keepPreviousData: true })
  const [isScenariosToggleOn, setIsScenarioToggleOn] = useState<boolean | undefined>()
  const handleToggle = () => setIsScenarioToggleOn((isOn) => !isOn)

  const scenarios = useMemo(() => filterScenariosByProtocol(data?.value, protocol), [data?.value, protocol])
  const showZeroState = data?.value.length === 0 || data?.value.every((scenario) => scenario.name === 'default')
  const showZeroStateHint = showZeroState && !isScenariosToggleOn
  // Show 0 when only default scenario is present
  const scenarioCount = showZeroState ? 0 : scenarios.length

  // Reset toggle when switching protocol tabs
  useEffect(() => {
    setIsScenarioToggleOn(undefined)
  }, [protocol])

  // Switch toggle on when there's more than 1 scenario and user
  // haven't manually switched it off
  useEffect(() => {
    if (scenarios.length > 1) {
      setIsScenarioToggleOn((isOn) => (isOn === undefined ? true : isOn))
    }
  }, [scenarios])

  return (
    <LoadingContainer loading={isLoading}>
      <Controls>
        <h2>Scenarios ({scenarioCount})</h2>
        <Line />
        <Switch value={isScenariosToggleOn} onChange={handleToggle} offLabel="hide" onLabel="show" />
      </Controls>

      {showZeroStateHint && <ZeroStateHint onClick={handleToggle} />}

      {isScenariosToggleOn && (
        <ScenarioContent showZeroState={showZeroState} testRun={testRun} scenarios={scenarios} protocol={protocol} />
      )}
    </LoadingContainer>
  )
}

const ScenarioContent = ({
  showZeroState,
  ...tableProps
}: ComponentProps<typeof ScenarioTable> & {
  showZeroState?: boolean
}) => {
  if (showZeroState) {
    return <ZeroState />
  }

  return <ScenarioTable {...tableProps} />
}
