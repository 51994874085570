import { FilterContextAction, FiltersState } from './Filters.types'

export const runDetailsFilterContextInitState = { http: [], thresholds: [], checks: [], grpc: [], websockets: [] }

export const runDetailsFiltersContextReducer = (state: FiltersState, action: FilterContextAction) => {
  const { type, payload } = action

  switch (type) {
    case 'ADD_NEW_FILTER': {
      return {
        ...state,
        [payload.filterType]: [...state[payload.filterType], { by: payload.by, label: payload.label, values: [] }],
      }
    }

    case 'REMOVE_FILTER': {
      return {
        ...state,
        [payload.filterType]: (state[payload.filterType] as any).filter((filter: any) => filter.by !== payload.by),
      }
    }

    case 'SET_FILTER_VALUE': {
      return {
        ...state,
        [payload.filterType]: state[payload.filterType].map((filter) => {
          if (filter.by === payload.by) {
            return { ...filter, values: payload.value }
          }

          return filter
        }),
      }
    }

    case 'REMOVE_ALL_FILTERS': {
      return {
        ...state,
        [payload.filterType]: [],
      }
    }

    default:
      return state
  }
}
