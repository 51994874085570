import React from 'react'
import { Button } from '@grafana/ui'

import { DataTableEmptyStateWrapper } from './DataTable.styles'

export const DataTableFilteredEmptyState = ({ onClearFiltersClick }: { onClearFiltersClick: () => void }) => {
  return (
    <DataTableEmptyStateWrapper>
      No records matched your filter(s)
      <Button size="md" type="button" variant="secondary" onClick={onClearFiltersClick}>
        Clear all filters
      </Button>
    </DataTableEmptyStateWrapper>
  )
}
