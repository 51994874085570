import { LegendDisplayMode, TooltipDisplayMode, SortOrder } from '@grafana/schema'
import { TimeSeriesOptions } from 'panels/types'

export const panelOptions: TimeSeriesOptions = {
  legend: {
    showLegend: true,
    displayMode: LegendDisplayMode.List,
    placement: 'bottom',
    calcs: [],
  },
  tooltip: {
    mode: TooltipDisplayMode.Multi,
    sort: SortOrder.None,
  },
}
